import React from 'react'
import {components as selectComponents} from 'react-select'
import styled from 'styled-components'
import colours from '../../../colours'
import closedIndicator from './assets/Mentor_details_dropdown_arrow_down.png'
import closedIndicator2x from './assets/Mentor_details_dropdown_arrow_down@2x.png'
import closedIndicator3x from './assets/Mentor_details_dropdown_arrow_down@3x.png'
import closedIndicator4x from './assets/Mentor_details_dropdown_arrow_down@4x.png'
import openIndicator from './assets/Mentor_details_dropdown_arrow_up.png'
import openIndicator2x from './assets/Mentor_details_dropdown_arrow_up@2x.png'
import openIndicator3x from './assets/Mentor_details_dropdown_arrow_up@3x.png'
import openIndicator4x from './assets/Mentor_details_dropdown_arrow_up@4x.png'
import css from './index.module.css'

const {ClearIndicator, DropdownIndicator, LoadingIndicator} = selectComponents

export const customTheme = {
  colors: {
    primary: '#28404a',
    primary75: '#4a778a',
    primary50: '#7ea8b9',
    primary25: '#bed3dc',
    danger: '#de350b',
    dangerLight: '#ffbdad',
    neutral0: 'hsl(0, 0%, 100%)',
    neutral5: 'hsl(0, 0%, 95%)',
    neutral10: 'hsl(0, 0%, 90%)',
    neutral20: 'hsl(0, 0%, 80%)',
    neutral30: 'hsl(0, 0%, 70%)',
    neutral40: 'hsl(0, 0%, 60%)',
    neutral50: 'hsl(0, 0%, 50%)',
    neutral60: 'hsl(0, 0%, 40%)',
    neutral70: 'hsl(0, 0%, 30%)',
    neutral80: 'hsl(0, 0%, 20%)',
    neutral90: 'hsl(0, 0%, 10%)'
  },
  spacing: {
    baseUnit: 4,
    controlHeight: 36,
    menuGutter: 4
  }
}

const DROPDOWN_INDICATOR_SIZE = 24

export const customStyles = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: (styles: React.CSSProperties, state: any): React.CSSProperties => ({
    ...styles,
    backgroundColor: '#ffc212',
    boxShadow: 'none',
    opacity: state.isDisabled ? 0.5 : 1,
    paddingLeft: '1rem',
    border: state.isFocused
      ? `2px solid ${colours.colours.darkText}`
      : state.selectProps.error
      ? `2px solid ${colours.colours.errorRed}`
      : '2px solid transparent',
    borderRadius: 18,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ':hover': {
      border: state.isFocused
        ? `2px solid ${colours.colours.darkText}`
        : state.selectProps.error
        ? `2px solid ${colours.colours.errorRed}`
        : '2px solid transparent'
    }
  }),
  input: (styles: React.CSSProperties): React.CSSProperties => ({
    ...styles,
    color: '#fff',
    padding: 0,
    margin: 0
  }),
  placeholder: (styles: React.CSSProperties): React.CSSProperties => ({
    ...styles,
    color: '#fff',
    padding: 0,
    margin: 0,
    opacity: 0.7,
    whiteSpace: 'nowrap'
  }),
  singleValue: (styles: React.CSSProperties): React.CSSProperties => ({
    ...styles,
    color: '#fff',
    padding: 0,
    margin: 0
  }),
  multiValue: (
    styles: React.CSSProperties,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: any
  ): React.CSSProperties => ({
    ...styles,
    backgroundColor: state.theme.colors.primary
  }),
  multiValueLabel: (styles: React.CSSProperties): React.CSSProperties => ({
    ...styles,
    color: '#fff'
  }),
  multiValueRemove: (styles: React.CSSProperties): React.CSSProperties => ({
    ...styles,
    color: '#fff'
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (styles: React.CSSProperties, state: any): React.CSSProperties => ({
    ...styles,
    color: state.isSelected ? '#fff' : state.theme.colors.primary
  }),
  valueContainer: (styles: React.CSSProperties): React.CSSProperties => ({
    ...styles,
    padding: 0
  }),
  clearIndicator: (styles: React.CSSProperties): React.CSSProperties => ({
    ...styles,
    padding: 0
  }),
  dropdownIndicator: (styles: React.CSSProperties): React.CSSProperties => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '0.25rem'
  }),
  loadingMessage: (
    styles: React.CSSProperties,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: any
  ): React.CSSProperties => ({
    ...styles,
    color: state.theme.colors.neutral60
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomLoadingIndicator(props: any) {
  if (LoadingIndicator == null) {
    return null
  }
  return <LoadingIndicator {...props} className={css.loading} />
}

const Dropdown = styled.img`
  width: ${DROPDOWN_INDICATOR_SIZE}px;
  height: ${DROPDOWN_INDICATOR_SIZE}px;
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomDropdownIndicator(props: any) {
  if (DropdownIndicator == null) {
    return null
  }
  const open = props.selectProps.menuIsOpen

  return (
    <DropdownIndicator {...props}>
      <Dropdown
        src={open ? openIndicator : closedIndicator}
        srcSet={
          open
            ? `${openIndicator}, ${openIndicator2x} 2x, ${openIndicator3x} 3x, ${openIndicator4x} 4x`
            : `${closedIndicator}, ${closedIndicator2x} 2x, ${closedIndicator3x} 3x, ${closedIndicator4x} 4x`
        }
      />
    </DropdownIndicator>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomClearIndicator(props: any) {
  if (ClearIndicator == null || !props.isFocused) {
    return null
  }
  return <ClearIndicator {...props} />
}
