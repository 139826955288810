import produce from 'immer'
import {Reducer} from 'redux'
import {LANGUAGE_TAG_EN_US} from '../../messages'
import {LOGOUT_ACTION} from '../authentication/authentication.action'
import {AppActions} from '../store'
import {
  ATTEMPT_UPDATE_LOCALE,
  MARK_TUTORIAL_SEEN,
  UPDATE_LOCALE
} from './ui.actions'
import {
  LOCALE_STORAGE_KEY,
  TUTORIAL_SEEN_KEY,
  TUTORIAL_SEEN_VALUE
} from './ui.constants'
import {Locale, UIState} from './ui.types'

const initialState: UIState = {
  locale:
    (localStorage.getItem(LOCALE_STORAGE_KEY) as Locale) || LANGUAGE_TAG_EN_US,
  tutorialSeen:
    sessionStorage.getItem(TUTORIAL_SEEN_KEY) === TUTORIAL_SEEN_VALUE,
  updating: false,
  error: null
}

const reducer: Reducer<UIState, AppActions> = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ATTEMPT_UPDATE_LOCALE:
        draft.updating = true
        draft.error = null
        break
      case LOGOUT_ACTION:
        // Reset the tutorial seen state when logging out.
        draft.tutorialSeen = false
        break
      case MARK_TUTORIAL_SEEN:
        draft.tutorialSeen = true
        break
      case UPDATE_LOCALE:
        if (action.error) {
          draft.error = action.payload
          draft.updating = false
          break
        }
        draft.locale = action.payload
        draft.updating = false
        break
    }
  })

export default reducer
