import {isNonBlankString, isPlainObject} from '../responseRefiners'
import Email, {Email as EmailT} from './email'

export type Consent<DateType extends Date | string = Date> = Readonly<{
  granted: boolean | null
  date: DateType | null
  byEmail: EmailT | null
}>

function parse(candidate: unknown): Consent {
  if (
    !isPlainObject(candidate) ||
    (candidate.granted !== null && typeof candidate.granted !== 'boolean') ||
    (candidate.date !== null && !isNonBlankString(candidate.date))
  ) {
    throw new Error(
      `Unexpected Consent ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }

  return {
    granted: candidate.granted,
    date: candidate.date === null ? null : new Date(candidate.date.trim()),
    byEmail: candidate.byEmail === null ? null : Email.parse(candidate.byEmail)
  }
}

const consent = {parse}
export default consent
