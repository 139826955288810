import {Variant} from './types'

export const BASE_CLASSES = `
  rounded cursor-pointer outline-none 
  focus:outline-none focus-visible:outline-none p-0.5
`

export function getVariantClasses(variant: Variant): string {
  if (variant === 'primary') {
    return `
      typography-body-2 text-navy underline underline-offset-2
      visited:text-purple hover:text-teal-secondary 
      focus-visible:ring-4 focus-visible:ring-navy
    `
  }
  if (variant === 'menu-item') {
    return `
      typography-subtitle text-white underline underline-offset-2
      hover:text-sky focus-visible:ring-4 focus-visible:ring-white
     `
  }
  throwUnhandledVariant(variant)
}

function throwUnhandledVariant(variant: never): never {
  throw new Error(`Unhandled variant: ${variant}`)
}
