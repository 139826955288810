import * as api from '../../api'
import {AppThunkAction} from '../store'
import {
  AttemptUserForgotPassword,
  ForgotPassword,
  ReceiveForgotPasswordResponse
} from './forgot-password.type'

export const ATTEMPT_FORGOT_PASSWORD = 'forgot-password/ATTEMPT_FORGOT_PASSWORD'
export const RECEIVE_FORGOT_PASSWORD_RESPONSE =
  'forgot-password/RECEIVE_FORGOT_PASSWORD_RESPONSE'

export function userForgotPassword(
  data: ForgotPassword
): AppThunkAction<AttemptUserForgotPassword | ReceiveForgotPasswordResponse> {
  return async function (dispatch) {
    dispatch({
      type: ATTEMPT_FORGOT_PASSWORD
    })

    try {
      await api.forgotPassword(data)
      dispatch({
        type: RECEIVE_FORGOT_PASSWORD_RESPONSE
      })
    } catch (error) {
      dispatch({
        type: RECEIVE_FORGOT_PASSWORD_RESPONSE,
        payload: error,
        error: true
      })
      throw error
    }
  }
}
