import React from 'react'
import {FormattedMessage} from 'react-intl'
import styled from 'styled-components'
import {devices} from '../../../device'
import {contentPadding, heading, text} from '../../../styled/mixins'
import Background from '../Background'
import Button from '../Button'
import Layout from '../Layout'
import * as Typography from '../Typography'

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Header = styled(Typography.Heading1)`
  ${heading};
  font-size: 2.25rem;
  text-align: left;
  margin-top: 2rem;
`

const SectionHeader = styled(Typography.Heading1)`
  margin-bottom: 2rem;
`

const LeadText = styled(Typography.BoldBody1)`
  ${text};
  text-align: left;
  margin-bottom: 2rem;
`

const Paragraph = styled(Typography.Body3).attrs({as: 'p'})`
  color: #28414b;
  margin-bottom: 2rem;
  text-align: left;

  @media ${devices.tablet} {
    text-align: justify;
  }
`

const ContentWrapper = styled.div`
  max-width: 100%;
  padding: 0 18px;
  ${contentPadding};

  @media ${devices.desktop} {
    max-width: 65%;
  }
`

const Back = styled.div`
  display: flex;
  justify-content: center;
`

const UpperCase = styled.div`
  text-transform: uppercase;
`

function LegalTerms() {
  return (
    <Layout noShadow>
      <Content>
        <Background />
        <ContentWrapper>
          <Header>
            <FormattedMessage id="registration.LegalTerms_H1" />
          </Header>
          <LeadText>
            <FormattedMessage id="registration.LegalTerms_subtitle" />
          </LeadText>
          <section>
            <SectionHeader>
              <FormattedMessage id="termsAndConditions.consent_h1" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="termsAndConditions.consent_p1_s1" />
              <FormattedMessage id="termsAndConditions.consent_p1_s2" />
              <FormattedMessage id="termsAndConditions.consent_p1_s3" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="termsAndConditions.consent_p2_s1" />
              <FormattedMessage id="termsAndConditions.consent_p2_s2" />
              <FormattedMessage id="termsAndConditions.consent_p2_s3" />
              <FormattedMessage id="termsAndConditions.consent_p2_s4" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="termsAndConditions.release_indemnity_h1" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="termsAndConditions.release_indemnity_p1" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="termsAndConditions.release_indemnity_p2" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="termsAndConditions.release_indemnity_p3" />
            </Paragraph>
            <Paragraph>
              <UpperCase>
                <FormattedMessage id="termsAndConditions.release_indemnity_p4_s1" />
                <FormattedMessage id="termsAndConditions.release_indemnity_p4_s2" />
              </UpperCase>
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="termsAndConditions.photography_h1" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="termsAndConditions.photography_p1_s1" />
              <FormattedMessage id="termsAndConditions.photography_p1_s2" />
              <FormattedMessage id="termsAndConditions.photography_p1_s3" />
              <FormattedMessage id="termsAndConditions.photography_p1_s4" />
              <FormattedMessage id="termsAndConditions.photography_p1_s5" />
            </Paragraph>
          </section>
          <Back>
            <Button colour="yellow" onClick={() => window.close()}>
              <FormattedMessage id="registration.MentorProfile_backButton" />
            </Button>
          </Back>
        </ContentWrapper>
      </Content>
    </Layout>
  )
}

export default LegalTerms
