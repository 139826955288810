import {isPlainObject} from '../responseRefiners'
import Consent, {Consent as ConsentT} from './consent'

export type Consents<DateType extends Date | string = Date> = Readonly<{
  competition?: ConsentT<DateType> | null
  location?: ConsentT<DateType> | null
  media?: ConsentT<DateType> | null
}>

export type ConsentType = keyof Consents

function parse(candidate: unknown): Consents {
  if (!isPlainObject(candidate)) {
    throw new Error(
      `Unexpected Consents ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }

  return {
    competition:
      candidate.competition == null
        ? candidate.competition
        : Consent.parse(candidate.competition),
    location:
      candidate.location == null
        ? candidate.location
        : Consent.parse(candidate.location),
    media:
      candidate.media == null ? candidate.media : Consent.parse(candidate.media)
  }
}

const consents = {parse}
export default consents
