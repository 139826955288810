import * as tours from './tours'

type Tours = typeof tours
type Tour = Tours[keyof Tours]

const COMPLETE = 'complete'

function isTourComplete(
  tour: Tour,
  mentorId: string,
  storage: Storage = localStorage
): boolean {
  return storage.getItem(buildStorageKey(tour, mentorId)) === COMPLETE
}

function markTourComplete(
  tour: Tour,
  mentorId: string,
  storage: Storage = localStorage
): void {
  storage.setItem(buildStorageKey(tour, mentorId), COMPLETE)
}

function markAddLearnerToursIncomplete(
  mentorId: string,
  storage: Storage = localStorage
) {
  storage.removeItem(buildStorageKey(tours.ADD_LEARNER_CARD, mentorId))
  storage.removeItem(buildStorageKey(tours.EDIT_LEARNER_CARD, mentorId))
}

function buildStorageKey(tour: Tour, mentorId: string): string {
  return `tours-${tour}-${mentorId}`
}

function markTableViewToursIncomplete(
  mentorId: string,
  storage: Storage = localStorage
) {
  storage.removeItem(buildStorageKey(tours.ADD_LEARNER_ROW, mentorId))
  storage.removeItem(buildStorageKey(tours.EDIT_LEARNER_ROW, mentorId))
}

export default {
  isTourComplete,
  markTourComplete,
  markAddLearnerToursIncomplete,
  markTableViewToursIncomplete
}
