import React from 'react'
import {Provider} from 'react-redux'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import AuthDispatcher from './AuthDispatcher'
import AccountDeletionPage from './components/AccountDeletionPage'
import AddCommunityPage from './components/AddCommunityPage'
import CommunitiesPage from './components/CommunitiesPage'
import CommunityToolSelectorPage from './components/CommunitiesPage/CommunityToolSelectorPage'
import EditCommunityPage from './components/EditCommunityPage'
import EducationalResourcesPage from './components/EducationalResourcesPage'
import EmailUnsubscribedPage from './components/email/EmailUnsubscribedPage'
import EmailUnsubscribeFailedPage from './components/email/EmailUnsubscribeFailedPage'
import EventsPage from './components/EventsPage'
import LearnerProfilePage from './components/LearnerProfilePage'
import LearnersPage from './components/LearnersPage'
import MentorProfilePage from './components/MentorProfilePage'
import SponsorsPage from './components/SponsorsPage'
import SuppliesShopPage from './components/SuppliesShopPage'
import ToolSelectorPage from './components/ToolSelectorPage'
import UserSearchPage from './components/UserSearchPage'
import {
  ACCOUNT_DELETION_ROUTE,
  ADD_COMMUNITY_ROUTE,
  CHECK_MAIL_ROUTE,
  COMMUNITIES_ROUTE,
  COMMUNITY_EVENTS_ROUTE,
  COMMUNITY_NOT_HERE_ROUTE,
  COMMUNITY_TOOL_SELECTOR_ROUTE,
  EDIT_COMMUNITY_ROUTE,
  EDUCATIONAL_RESOURCES_ROUTE,
  EMAIL_SENT_ROUTE,
  EMAIL_UNSUBSCRIBED_ROUTE,
  EMAIL_UNSUBSCRIBE_FAILED_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LEARNERS_ROUTE,
  LEARNER_PROFILE_ROUTE,
  LEGAL_TERMS_ROUTE,
  LOGIN_ROUTE,
  MENTOR_PROFILE_ROUTE,
  REGISTERED_ROUTE,
  REGISTER_LEARNER_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  ROOT_ROUTE,
  SPONSOR_IMAGES_ROUTE,
  SUPPLIES_SHOP_ROUTE,
  TERMS_ROUTE,
  TOOL_SELECTOR_ROUTE,
  USER_SEARCH_ROUTE
} from './global-constants/routingConstants'
import I18N from './I18N'
import ErrorBoundary from './legacy/components/ErrorBoundary'
import ForgotPasswordPage from './legacy/components/pages/ForgotPasswordPage'
import ForgotPasswordSuccessPage from './legacy/components/pages/ForgotPasswordSuccessPage/ForgotPasswordSuccessPage.component'
import LegalTermsPage from './legacy/components/pages/LegalTermsPage'
import LoginPage from './legacy/components/pages/LoginPage'
import NotFoundPage from './legacy/components/pages/NotFoundPage'
import RegistrationPage from './legacy/components/pages/RegistrationPage'
import RegistrationSuccessPage from './legacy/components/pages/RegistrationSuccessPage/RegistrationSuccess.component'
import ResetPasswordPage from './legacy/components/pages/ResetPassword/ResetPassword'
import RootPage from './legacy/components/pages/RootPage'
import TermsAndConditionsPage from './legacy/components/pages/TermsAndConditionsPage'
import VerificationEmailSentPage from './legacy/components/pages/VerificationEmailSentPage'
import PrivateRoute from './legacy/components/PrivateRoute'
import CommunityNotHerePage from './legacy/containers/CommunityNotHere.container'
import configureStore from './store/store'

const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <AuthDispatcher>
        <I18N>
          <ErrorBoundary>
            <Router>
              <Switch>
                <Route exact path={ROOT_ROUTE} component={RootPage} />
                <Route path={REGISTER_ROUTE} component={RegistrationPage} />
                <Route
                  path={REGISTERED_ROUTE}
                  component={RegistrationSuccessPage}
                />
                <Route
                  path={CHECK_MAIL_ROUTE}
                  component={ForgotPasswordSuccessPage}
                />
                <Route
                  path={EMAIL_SENT_ROUTE}
                  component={VerificationEmailSentPage}
                />
                <Route
                  path={COMMUNITY_NOT_HERE_ROUTE}
                  component={CommunityNotHerePage}
                />
                <Route
                  path={FORGOT_PASSWORD_ROUTE}
                  component={ForgotPasswordPage}
                />
                <Route
                  path={RESET_PASSWORD_ROUTE}
                  component={ResetPasswordPage}
                />
                <Route path={LOGIN_ROUTE} component={LoginPage} />
                <Route path={LEGAL_TERMS_ROUTE} component={LegalTermsPage} />
                <Route path={TERMS_ROUTE} component={TermsAndConditionsPage} />
                <Route
                  path={EMAIL_UNSUBSCRIBED_ROUTE}
                  component={EmailUnsubscribedPage}
                />
                <Route
                  path={EMAIL_UNSUBSCRIBE_FAILED_ROUTE}
                  component={EmailUnsubscribeFailedPage}
                />
                <Route
                  path={ACCOUNT_DELETION_ROUTE}
                  component={AccountDeletionPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_MENTOR', 'ROLE_ADMIN']}
                  path={TOOL_SELECTOR_ROUTE}
                  component={ToolSelectorPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_MENTOR']}
                  path={LEARNERS_ROUTE}
                  component={LearnersPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_MENTOR']}
                  path={EDUCATIONAL_RESOURCES_ROUTE}
                  component={EducationalResourcesPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_MENTOR']}
                  path={SUPPLIES_SHOP_ROUTE}
                  component={SuppliesShopPage}
                />

                <Redirect from={REGISTER_LEARNER_ROUTE} to={LEARNERS_ROUTE} />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={SPONSOR_IMAGES_ROUTE}
                  component={SponsorsPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={COMMUNITY_TOOL_SELECTOR_ROUTE}
                  component={CommunityToolSelectorPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={COMMUNITY_EVENTS_ROUTE}
                  component={EventsPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={EDIT_COMMUNITY_ROUTE}
                  component={EditCommunityPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={ADD_COMMUNITY_ROUTE}
                  component={AddCommunityPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={COMMUNITIES_ROUTE}
                  component={CommunitiesPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={COMMUNITY_TOOL_SELECTOR_ROUTE}
                  component={CommunityToolSelectorPage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={USER_SEARCH_ROUTE}
                  component={UserSearchPage}
                  exact
                />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={LEARNER_PROFILE_ROUTE}
                  component={LearnerProfilePage}
                />

                <PrivateRoute
                  allowedRoles={['ROLE_ADMIN']}
                  path={MENTOR_PROFILE_ROUTE}
                  component={MentorProfilePage}
                />

                <Route component={NotFoundPage} />
              </Switch>
            </Router>
          </ErrorBoundary>
        </I18N>
      </AuthDispatcher>
    </Provider>
  )
}

export default App
