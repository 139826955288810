import * as Sentry from '@sentry/browser'
import * as errorIdentifiers from './errorIdentifiers'
import {DUPLICATE_LEARNER_FOR_CONNECTED_MENTOR_ERROR_IDENTIFIER} from './errorIdentifiers'
import ServerError from './server'

export type ErrorIdentifier = ValueOf<typeof errorIdentifiers>

const EXCLUDED_ERRORS: ReadonlyArray<string> = [
  DUPLICATE_LEARNER_FOR_CONNECTED_MENTOR_ERROR_IDENTIFIER
]

export function captureException(err: Error): string | null {
  Sentry.withScope(scope => {
    let shouldNotReport = false
    if (ServerError.isServerError(err)) {
      shouldNotReport = checkErrorExclusions(err.identifiers)
      scope.setExtra('identifiers', err.identifiers)
    }
    if (!shouldNotReport) {
      return Sentry.captureException(err)
    }
  })
  return null
}

export function checkErrorExclusions(identifiers: ReadonlyArray<string>) {
  return identifiers.every(identifier => EXCLUDED_ERRORS.includes(identifier))
}
