import React from 'react'
import {useIntl} from 'react-intl'
import FocusableImage from '../../FocusableImage'
import {UnstyledExternalLink} from '../ExternalLink'
import appStoreIconUri from './assets/app-store-download-icon.svg'

export const APP_STORE_URL =
  'https://apps.apple.com/us/app/my-lemonade-day/id1465714143'

type SizeProps = Readonly<{
  width?: number
  height?: number
}>

function AppStoreDownloadLink({width = 120, height = 40}: SizeProps) {
  const intl = useIntl()
  return (
    <UnstyledExternalLink to={APP_STORE_URL}>
      <FocusableImage
        alt={intl.formatMessage({id: 'general.AppStoreAltText'})}
        height={height}
        src={appStoreIconUri}
        width={width}
      />
    </UnstyledExternalLink>
  )
}

export default AppStoreDownloadLink
