// Must be kept in sync with tailwind config
export const NAVY_PRIMARY = '#28414B'
export const RED_SECONDARY = '#9E0D0C'
export const TEAL_PRIMARY = '#58B1A4'

const colours = {
  swatches: {
    /**
     * #9ac93d
     */
    green: '#9ac93d',

    /**
     * #cb3333
     */
    red: '#cb3333',

    /**
     * #f15a24
     */
    orange: '#f15a24',

    /**
     * #24b7a7
     */
    blue: '#24b7a7',

    /**
     * #561350
     */
    purple: '#561350',

    /**
     * #ffcc00
     */
    yellow: '#ffcc00',

    /**
     * #28414b
     */
    grey: '#28414b',

    /**
     * #d1dde0
     */
    lightGrey: '#d1dde0'
  },
  components: {
    buttons: {
      yellow: {
        main: '#ffc212',
        shadow: '#bf920e'
      },
      turquoise: {
        main: '#24b7a7',
        shadow: '#1b897d'
      },
      green: {
        main: '#9ac93d',
        shadow: '#6c8f27'
      },
      red: {
        main: '#cb3333',
        shadow: '#8e2323'
      },
      white: {
        main: '#ffffff',
        shadow: '#8ba9b2'
      }
    },
    textFields: {
      yellow: '#ffc212'
    }
  },
  colours: {
    errorRed: '#CB3333',
    darkText: '#28414b',
    white: '#ffffff',
    turquoise: '#24b7a7',
    yellow: '#ffc212',
    grey: '#B3B3B3'
  }
}

export type swatch =
  | 'orange'
  | 'green'
  | 'red'
  | 'blue'
  | 'purple'
  | 'yellow'
  | 'grey'
  | 'lightGrey'

export default colours
