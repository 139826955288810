import React, {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import styled from 'styled-components'
import {devices} from '../../../device'
import ServerError from '../../../errors/server'
import {ResetPassword} from '../../../store/reset-password/reset-password.types'
import {heading} from '../../../styled/mixins'
import Alert from '../Alert'
import Button from '../Button'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import FormField from '../FormField'
import Label from '../Label/Label'
import Password from '../Password/Password'
import * as Typography from '../Typography'
import {isValid, ResetPasswordErrors, validate} from './validation'

interface Props {
  error?: ServerError | null
  submitting: boolean
  onSubmit(payload: ResetPassword): void
  onClearError: () => void
  token: string | null
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Heading = styled(Typography.BoldBody1)`
  ${heading}
`

type ResetPasswordFormValues = {
  password: string
  repeatedPassword: string
}

const AlertWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;

  @media ${devices.tablet} {
    margin-top: 0;
  }
`

const SideNote = styled(Typography.Body4)`
  margin-top: 0.25rem;
  margin-left: 1rem;
`

function ResetPasswordForm({
  error = null,
  submitting,
  onSubmit,
  token,
  onClearError
}: Props) {
  const intl = useIntl()
  const [values, setValues] = useState<ResetPasswordFormValues>({
    password: '',
    repeatedPassword: ''
  })
  const [errors, setErrors] = useState<ResetPasswordErrors>({
    password: '',
    repeatedPassword: ''
  })
  const [serverError, setServerError] = useState<string | null>(null)
  const alertRef = useRef<HTMLDivElement>(null)

  function setPartialError(errorKey: keyof ResetPasswordErrors, error: string) {
    setErrors(errs => ({...errs, [errorKey]: error}))
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const errors = validate(values.password, values.repeatedPassword, intl)
    setErrors(errors)
    if (isValid(errors) && token) {
      onSubmit({
        password: values.password,
        token
      })
    } else {
      onClearError()
    }
  }

  useEffect(() => {
    if (error != null) {
      setServerError('registration.resetPassword_ServerError_generic')
    } else {
      setServerError(null)
    }
  }, [error])

  useEffect(() => {
    if (serverError != null && alertRef.current != null) {
      alertRef.current.scrollIntoView()
    }
  }, [serverError, alertRef])

  return (
    <Form onSubmit={e => handleSubmit(e)} noValidate>
      {serverError && (
        <AlertWrapper>
          <Alert type="error" ref={alertRef}>
            <FormattedMessage id={serverError} />
          </Alert>
        </AlertWrapper>
      )}
      <Heading as="h1">
        <FormattedMessage id="registration.resetPassword_header1" />
      </Heading>
      <FormField>
        <Label htmlFor="password">
          <FormattedMessage id="general.password" />
        </Label>
        <Password
          id="password"
          name="password"
          colour="yellow"
          placeholder={intl.formatMessage({
            id: 'registration.resetPassword_newPasswordPH'
          })}
          value={values.password}
          onChange={e => {
            e.persist()
            setValues(vals => ({...vals, password: e.target.value}))
          }}
          error={Boolean(errors.password)}
          onBlur={() => setPartialError('password', '')}
        />
        {errors.password ? (
          <ErrorMessage>{errors.password}</ErrorMessage>
        ) : (
          <SideNote>
            <FormattedMessage id="registration.resetPassword_Fail_error1" />
          </SideNote>
        )}
      </FormField>
      <FormField>
        <Label htmlFor="repeatedPassword">
          <FormattedMessage id="registration.resetPassword_confirmPassword" />
        </Label>
        <Password
          id="repeatedPassword"
          name="repeatedPassword"
          colour="yellow"
          placeholder={intl.formatMessage({
            id: 'registration.resetPassword_confirmPasswordPH'
          })}
          value={values.repeatedPassword}
          onChange={e => {
            e.persist()
            setValues(vals => ({
              ...vals,
              repeatedPassword: e.target.value
            }))
          }}
          error={Boolean(errors.repeatedPassword)}
          onBlur={() => setPartialError('repeatedPassword', '')}
        />
        {errors.repeatedPassword && (
          <ErrorMessage>{errors.repeatedPassword}</ErrorMessage>
        )}
      </FormField>
      <Actions>
        <Button type="submit" disabled={submitting} colour="yellow">
          <FormattedMessage id="registration.resetPassword_changeButton" />
        </Button>
      </Actions>
    </Form>
  )
}

export default ResetPasswordForm
