import React from 'react'
import {useIntl} from 'react-intl'
import FocusableImage from '../../FocusableImage'
import {UnstyledExternalLink} from '../ExternalLink'
import pinterestIconUrl from './assets/pinterest_icon.svg'

export const PINTEREST_URL = 'https://www.pinterest.com/lemonadeday/'

function PinterestLink() {
  const intl = useIntl()
  return (
    <UnstyledExternalLink to={PINTEREST_URL}>
      <FocusableImage
        alt={intl.formatMessage({id: 'general.instagram'})}
        height={33}
        src={pinterestIconUrl}
        variant={'social'}
        width={33}
      />
    </UnstyledExternalLink>
  )
}

export default PinterestLink
