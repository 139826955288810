import React from 'react'
import styled from 'styled-components'

type Props = React.LabelHTMLAttributes<HTMLLabelElement>

const StyledLabel = styled.label`
  padding-left: 1rem;
  padding-bottom: 0.7rem;
`

function Label(props: Props) {
  return <StyledLabel {...props} />
}

export default Label
