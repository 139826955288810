import React from 'react'
import {useIntl} from 'react-intl'
import FocusableImage from '../../FocusableImage'
import {UnstyledExternalLink} from '../ExternalLink'
import playStoreIconUri from './assets/play-store-download-icon.svg'

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=org.lemonadeday.mylemonadeday'

type SizeProps = Readonly<{
  width?: number
  height?: number
}>

function PlayStoreDownloadLink({width = 135, height = 40}: SizeProps) {
  const intl = useIntl()
  return (
    <UnstyledExternalLink to={PLAY_STORE_URL}>
      <FocusableImage
        alt={intl.formatMessage({id: 'general.PlayStoreAltText'})}
        height={height}
        src={playStoreIconUri}
        width={width}
      />
    </UnstyledExternalLink>
  )
}

export default PlayStoreDownloadLink
