import React from 'react'
import CheckMail from './assets/mentor_gotmail_image-fs8.png'
import CheckMail2x from './assets/mentor_gotmail_image@2x-fs8.png'
import CheckMail3x from './assets/mentor_gotmail_image@3x-fs8.png'
import CheckMail4x from './assets/mentor_gotmail_image@4x-fs8.png'

function MailImage() {
  return (
    <img
      alt=""
      aria-hidden
      className="ml-[-50px] max-w-full object-contain"
      src={CheckMail}
      srcSet={`${CheckMail}, ${CheckMail2x} 2x, ${CheckMail3x} 3x, ${CheckMail4x} 4x`}
    />
  )
}

export default MailImage
