import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {ThunkDispatch} from 'redux-thunk'
import {createMentor} from '../../store/mentors/mentors.action'
import {CreateMentorFields} from '../../store/mentors/mentors.type'
import {AppActions, AppState} from '../../store/store'
import {Locale} from '../../store/ui/ui.types'
import RegistrationForm from '../components/pages/registration/RegistrationForm'

export interface DispatchProps {
  createMentor: (fields: CreateMentorFields) => Promise<void>
}

export interface StateProps {
  loading: boolean
  locale: Locale
}

const mapStateToProps = (state: AppState) => ({
  loading: state.mentors.creating,
  locale: state.ui.locale
})

const dispatchProps = (
  dispatch: ThunkDispatch<AppState, undefined, AppActions>
) => ({
  createMentor: (mentor: CreateMentorFields) => dispatch(createMentor(mentor))
})

export default withRouter(
  connect<StateProps, DispatchProps, Record<string, never>, AppState>(
    mapStateToProps,
    dispatchProps
  )(RegistrationForm)
)
