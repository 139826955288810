export const AFRICAN_AMERICAN = 'AFRICAN_AMERICAN'
export const AMERICAN_INDIAN_OR_ALASKA_NATIVE =
  'AMERICAN_INDIAN_OR_ALASKA_NATIVE'
export const ASIAN = 'ASIAN'
export const HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO'
export const NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER =
  'NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER'
export const NO_ANSWER = 'NO_ANSWER'
export const WHITE = 'WHITE'
export const OTHER = 'OTHER'
