import React from 'react'
import {useIntl} from 'react-intl'
import CountryCode from '../../domain/countryCode'
import TimeZone from '../../domain/timeZone'
import {TIME_ZONES, TIME_ZONES_BY_COUNTRY_CODE} from '../../domain/timeZones'
import TzIdentifier from '../../domain/tzIdentifier'
import SelectInput from '../SelectInput/SelectInput'

type Props<C extends CountryCode> = Readonly<{
  countryCode: C | null
  errorMessage?: string | null
  name?: string
  now?: Date
  value: TzIdentifier<C> | null
  onChange: (tzIdentifier: TzIdentifier | null) => void
}>

function TimeZoneSelect<C extends CountryCode>({
  errorMessage = null,
  countryCode,
  name = 'timezone',
  now = new Date(),
  value,
  onChange
}: Props<C>) {
  const intl = useIntl()
  const options = useOptions(countryCode)

  return (
    <SelectInput
      errorMessage={errorMessage}
      name={name}
      description={`${now.toLocaleString(intl.locale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'long',
        timeZone: value == null ? undefined : value
      })}`}
      label={intl.formatMessage({id: 'admin.communities.timeZone.label'})}
      options={options}
      onChange={onChange}
      placeholderText={intl.formatMessage({
        id: 'admin.communities.selectSearch'
      })}
      value={value}
    />
  )
}

function useOptions<C extends CountryCode>(countryCode: C | null) {
  const intl = useIntl()
  if (countryCode == null) {
    return TIME_ZONES.map((timeZone: TimeZone) => ({
      id: timeZone.tzIdentifier,
      name: TimeZone.format(timeZone)
    })).sort((a, b) => new Intl.Collator(intl.locale).compare(a.name, b.name))
  }
  const timeZones = TIME_ZONES_BY_COUNTRY_CODE[countryCode]
  return timeZones
    .map((timeZone: TimeZone) => {
      return {
        id: timeZone.tzIdentifier,
        name: TimeZone.format(timeZone)
      }
    })
    .sort((a, b) => new Intl.Collator(intl.locale).compare(a.name, b.name))
}

export default TimeZoneSelect
