export type PositiveInt = number & {brand: 'PositiveInt'}

function parse(candidate: unknown): PositiveInt {
  if (
    typeof candidate !== 'number' ||
    !Number.isInteger(candidate) ||
    candidate < 1
  ) {
    throw new Error(`Unexpected PositiveInteger ${candidate}`)
  }

  return candidate as PositiveInt
}

const positiveInteger = {parse}
export default positiveInteger
