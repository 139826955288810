import {produce} from 'immer'
import {Reducer} from 'redux'
import {RECEIVE_USER_LOG_IN} from '../authentication/authentication.action'
import {AppActions} from '../store'
import {
  ATTEMPT_CREATE_MENTOR,
  ATTEMPT_UPDATE_MENTOR,
  CREATE_MENTOR,
  UPDATE_MENTOR
} from './mentors.action'
import {CodeFields} from './mentors.type'

export interface MentorsState {
  creating: boolean
  byId: {
    [mentorId: string]: CodeFields
  }
}

const initialState = {
  error: null,
  creating: false,
  updating: false,
  byId: {}
}

const reducer: Reducer<MentorsState, AppActions> = (
  state = initialState,
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case RECEIVE_USER_LOG_IN:
        if (!action.error && action.payload != null) {
          const adult = action.payload.adult
          if (adult != null && 'code' in adult) {
            draft.byId[adult.id] = {
              code: adult.code
            }
          }
        }
        break
      case ATTEMPT_CREATE_MENTOR:
        draft.creating = true
        break
      case ATTEMPT_UPDATE_MENTOR:
        break
      case CREATE_MENTOR:
        draft.creating = false
        if (action.error) {
          break
        }
        draft.byId[action.payload.id] = {
          code: action.payload.code
        }
        break
      case UPDATE_MENTOR:
        if (action.error) {
          break
        }
        draft.byId[action.payload.id] = action.payload
        break
    }
  })

export default reducer
