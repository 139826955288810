import {ExclamationTriangleIcon} from '@heroicons/react/20/solid'
import React, {useState} from 'react'
import {SponsorImageKey} from '../../../store/sponsorImages/sponsorImages.types'
import LoadingIndicator from '../../LoadingIndicator'
import defaultUrl from '../assets/default_image.png'
import DeleteIcon from '../assets/DeleteIcon'
import UploadIcon from '../assets/UploadIcon'
import DeleteConfirmationPopup from './DeleteConfirmationPopup'
import ImageCropper from './ImageCropper'

interface Props {
  uploadKey: SponsorImageKey
  uri: string | null
  onUpload: (key: SponsorImageKey, image: File) => void
  onDelete: (key: SponsorImageKey) => void
}

const ImageUpload = ({uploadKey, uri, onUpload, onDelete}: Props) => {
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] =
    useState(false)
  const [showImageCropper, setShowImageCropper] = useState(false)
  const [imageSrcInvalid, setImageSrcInvalid] = useState(false)

  const uploadCroppedImage = (croppedFile: File) => {
    setShowImageCropper(false)
    if (croppedFile !== null) {
      onUpload(uploadKey, croppedFile)
    }
  }
  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <div className="relative flex w-[75px] justify-start">
      {uri == null ? (
        <div
          className="cursor-pointer focus:outline-none"
          onClick={() => setShowImageCropper(true)}
        >
          <img
            className="w-[75px] rounded-lg opacity-20"
            src={defaultUrl}
            alt={uploadKey}
          />
          <div className="absolute bottom-0 left-0 z-10">
            <UploadIcon />
          </div>
        </div>
      ) : (
        <>
          {!imageLoaded && (
            <div className="flex h-full w-full items-center">
              <LoadingIndicator />
            </div>
          )}
          {!imageSrcInvalid && (
            <img
              className={`w-[75px] rounded-lg ${
                imageLoaded && imageSrcInvalid ? 'none' : 'none'
              }`}
              src={uri}
              alt={uploadKey}
              onLoad={() => setImageLoaded(true)}
              onError={() => {
                setImageLoaded(true)
                setImageSrcInvalid(true)
              }}
            />
          )}
          {imageSrcInvalid && (
            <div
              title={` ${uploadKey} image failed to load`}
              className="relative flex w-[75px] justify-center"
            >
              <img
                className="rounded-lg opacity-20"
                src={defaultUrl}
                onLoad={() => setImageLoaded(true)}
                alt={uploadKey}
              />
              <ExclamationTriangleIcon
                strokeWidth={1.2}
                className="absolute m-auto flex h-8 w-8 cursor-pointer self-center fill-transparent stroke-orange-tertiary-20"
              />
              <div
                className="absolute left-0 bottom-0 z-10 cursor-pointer"
                onClick={() => setShowImageCropper(true)}
              >
                <UploadIcon />
              </div>
            </div>
          )}
          {imageLoaded && !imageSrcInvalid && (
            <div
              className="absolute right-2 top-2 z-10"
              onClick={() => setShowDeleteConfirmationPopup(true)}
            >
              <DeleteIcon />
            </div>
          )}
        </>
      )}

      <DeleteConfirmationPopup
        open={showDeleteConfirmationPopup}
        onAccept={() => {
          onDelete(uploadKey)
          setShowDeleteConfirmationPopup(false)
        }}
        onCancel={() => setShowDeleteConfirmationPopup(false)}
      />

      <ImageCropper
        open={showImageCropper}
        onCrop={cropped => uploadCroppedImage(cropped)}
        onCancel={() => setShowImageCropper(false)}
      />
    </div>
  )
}

export default ImageUpload
