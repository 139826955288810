import {
  ADULT_FIRST_NAME_ERROR_IDENTIFIER,
  ADULT_LAST_NAME_ERROR_IDENTIFIER,
  COMMUNITY_ID_ERROR_IDENTIFIER,
  COMMUNITY_NOT_FOUND_ERROR_IDENTIFIER,
  EMAIL_ERROR_IDENTIFIER,
  EMAIL_MALFORMED_ERROR_IDENTIFIER,
  GUARDIAN_EMAIL_ERROR_IDENTIFIER,
  LEGAL_ACCEPTED_DATE_ERROR_IDENTIFIER,
  PASSWORD_INVALID_ERROR_IDENTIFIER,
  PHONE_NUMBER_ERROR_IDENTIFIER,
  RECAPTCHA_ERROR_IDENTIFIER,
  RECAPTCHA_REQUEST_TOKEN_ERROR_IDENTIFIER,
  RECAPTCHA_VERIFICATION_ERROR_IDENTIFIER,
  REFERRER_ERROR_IDENTIFIER,
  TITLE_ERROR_IDENTIFIER,
  UNEXPECTED_COLUMN_COUNT_ERROR_IDENTIFIER,
  ZIP_ERROR_IDENTIFIER,
  ZIP_NOT_ASSOCIATED_WITH_COMMUNITY_ERROR_IDENTIFIER
} from './errorIdentifiers'

export interface ErrorFields {
  [identifier: string]: string
}

export const errorFields: ErrorFields = {
  [RECAPTCHA_REQUEST_TOKEN_ERROR_IDENTIFIER]: 'general.recaptchaError',
  [EMAIL_MALFORMED_ERROR_IDENTIFIER]:
    'registration.MentorProfile01_Fail_error6',
  [PASSWORD_INVALID_ERROR_IDENTIFIER]:
    'registration.MentorProfile_ServerError_serverInvalidPassword',
  [LEGAL_ACCEPTED_DATE_ERROR_IDENTIFIER]:
    'registration.MentorProfile_ServerError_serverLegalAcceptedDate',
  [TITLE_ERROR_IDENTIFIER]: 'registration.MentorDetails_Fail_errorTitle',
  [ADULT_FIRST_NAME_ERROR_IDENTIFIER]:
    'registration.MentorDetails_Fail_errorFirstName',
  [ADULT_LAST_NAME_ERROR_IDENTIFIER]:
    'registration.MentorDetails_Fail_errorLastName',
  [PHONE_NUMBER_ERROR_IDENTIFIER]: 'registration.MentorDetails_Fail_errorPhone',
  [COMMUNITY_ID_ERROR_IDENTIFIER]:
    'registration.MentorDetails_Fail_errorCommunity',
  [ZIP_ERROR_IDENTIFIER]: 'registration.MentorDetails_Fail_errorZip',
  [EMAIL_ERROR_IDENTIFIER]: 'registration.MentorProfile01_Fail_error1',
  [RECAPTCHA_ERROR_IDENTIFIER]: 'general.recaptchaValidationFailed',
  [RECAPTCHA_VERIFICATION_ERROR_IDENTIFIER]: 'general.recaptchaError',
  [COMMUNITY_NOT_FOUND_ERROR_IDENTIFIER]:
    'registration.MentorProfile_ServerError_serverCommunityIdNotFound',
  [ZIP_NOT_ASSOCIATED_WITH_COMMUNITY_ERROR_IDENTIFIER]:
    'registration.MentorProfile_ServerError_serverNoAssociatedZip',
  [REFERRER_ERROR_IDENTIFIER]: 'registration.MentorDetails_Fail_errorReferrer',
  [UNEXPECTED_COLUMN_COUNT_ERROR_IDENTIFIER]: 'uploadCSV.Unexpected_Columns',
  [GUARDIAN_EMAIL_ERROR_IDENTIFIER]: 'uploadCSV.invalid_guardian_email'
}
