export function isBoolean(candidate: unknown): candidate is boolean {
  return typeof candidate === 'boolean'
}

export function isNonBlankString(candidate: unknown): candidate is string {
  return typeof candidate === 'string' && candidate.trim() !== ''
}

export function isPlainObject(
  candidate: unknown
): candidate is Record<string, unknown> {
  return (
    typeof candidate === 'object' &&
    candidate !== null &&
    !Array.isArray(candidate)
  )
}
