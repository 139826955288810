import * as api from '../../api'
import {AppThunkAction} from '../store'
import {
  AttemptUserResetPassword,
  ReceiveResetPasswordResponse,
  ResetPassword
} from './reset-password.types'

export const ATTEMPT_RESET_PASSWORD = 'reset-password/ATTEMPT_RESET_PASSWORD'
export const RECEIVE_RESET_PASSWORD_RESPONSE =
  'reset-password/RECEIVE_RESET_PASSWORD_RESPONSE'

export function userResetPassword(
  data: ResetPassword
): AppThunkAction<AttemptUserResetPassword | ReceiveResetPasswordResponse> {
  return async function (dispatch) {
    dispatch({
      type: ATTEMPT_RESET_PASSWORD
    })

    try {
      await api.resetPassword(data)
      dispatch({
        type: RECEIVE_RESET_PASSWORD_RESPONSE
      })
    } catch (error) {
      dispatch({
        type: RECEIVE_RESET_PASSWORD_RESPONSE,
        payload: error,
        error: true
      })
      throw error
    }
  }
}
