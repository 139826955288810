import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {CommunityId} from '../../domain/community'
import EducationResource from '../../domain/educationResource'
import {educationResourceKeys} from '../../domain/educationResourceKeys'
import Id from '../../domain/id'
import {
  EDUCATIONAL_RESOURCES_ROUTE,
  TOOL_SELECTOR_ROUTE
} from '../../global-constants/routingConstants'
import useCommunity from '../../hooks/useCommunity'
import useEducationResources from '../../hooks/useEducationResources'
import {getMentor} from '../../store/mentors/mentors.selectors'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import HeadingGraphic from '../HeadingGraphic'
import Layout from '../Layout'
import {ExternalLinkStyledAsButton} from '../links/ExternalLink'
import LoadingIndicator from '../LoadingIndicator'
import TitleText from '../TitleText'
import educationalResourcesThumbnail from './assets/educational-resources-thumbnail.png'

function EducationalResourcesPage() {
  const mentor = useSelector(getMentor)
  const communityId: CommunityId = Id.parse(mentor?.communityId)
  const {isLoading, community} = useCommunity({communityId})
  const listEducationalResources = community && community.type !== 'PUBLIC'
  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="educationalResources.title" />
        </TitleText>
      }
    >
      <div
        className="
          mx-auto mb-2 w-full space-y-2 px-2
          md:mb-4 md:space-y-4 md:px-4 lg:mb-6 lg:space-y-6 lg:px-6
          xl:mb-8 xl:max-w-7xl xl:space-y-8 xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={EDUCATIONAL_RESOURCES_ROUTE}
            to={EDUCATIONAL_RESOURCES_ROUTE}
            label={<FormattedMessage id="educationalResources.shortTitle" />}
          />
        </Breadcrumb>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {listEducationalResources ? (
              <LicencedEducationalResources />
            ) : (
              <PublicEducationalResources />
            )}
          </>
        )}
      </div>
    </Layout>
  )
}

function LicencedEducationalResources() {
  const intl = useIntl()
  const {isLoading, error, educationResources} = useEducationResources()
  return (
    <>
      <HeadingGraphic
        title={intl.formatMessage({id: 'educationalResources.title'})}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {error ? (
            <div className="w-full text-center text-red">
              <FormattedMessage id="general.GenericError" />
            </div>
          ) : (
            <>
              {educationResources && (
                <ul className="flex flex-wrap justify-center md:gap-4">
                  {educationResources.map(resource => (
                    <EducationalResource
                      key={resource.id}
                      educationalResource={resource}
                    />
                  ))}
                </ul>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

function PublicEducationalResources() {
  const intl = useIntl()
  return (
    <>
      <HeadingGraphic
        title={intl.formatMessage({id: 'educationalResources.public.title'})}
      />
      <div className="mb-7 flex w-full flex-col md:flex-row">
        <img
          role="img"
          alt="Educational Resources Store"
          src={educationalResourcesThumbnail}
          className="h-64 w-64"
        />
        <div className="typography-body-1 flex h-full flex-row flex-wrap pl-3 pt-3">
          <FormattedMessage id="educationalResources.public.storeDescription" />
          <p className="pt-6">
            <ExternalLinkStyledAsButton to="https://estore.tmarks.com/lemonadeday/default.aspx?p=viewcat&showpage=4">
              <FormattedMessage id="educationalResources.public.buttonText" />
            </ExternalLinkStyledAsButton>
          </p>
        </div>
      </div>
    </>
  )
}

function EducationalResource({
  educationalResource
}: {
  educationalResource: EducationResource
}) {
  const imageSet = [
    `${educationalResource.imageUri},`,
    `${educationalResource.imageUri2x} 2x,`,
    `${educationalResource.imageUri3x} 3x,`
  ].join(' ')

  return (
    <li className="mb-7 w-56">
      <a
        target="_blank"
        href={educationalResource.resourceUri}
        className="flex flex-col items-center"
        rel="noreferrer"
      >
        <img
          role="img"
          aria-labelledby={`resource-${educationalResource.id}`}
          alt=""
          src={educationalResource.imageUri}
          srcSet={imageSet}
          className="h-48 w-48"
        />
        <div
          className="typography-headline-5 text-center"
          id={`resource-${educationalResource.id}`}
        >
          <FormattedMessage
            id={`educationalResources.resourceKey.${
              educationResourceKeys[educationalResource.id]
            }`}
          />
        </div>
      </a>
    </li>
  )
}

export default EducationalResourcesPage
