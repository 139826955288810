import React from 'react'
import {FormattedMessage} from 'react-intl'
import PublicFooter from '../Footer/PublicFooter'
import PublicHeader from '../Header/PublicHeader'
import MailImage from './MailImage'

function EmailUnsubscribedPage() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-between">
      <PublicHeader />
      <main className="flex w-full flex-col items-center justify-center space-y-4 px-4 pb-4">
        <MailImage />
        <h1 className="typography-headline-4 text-center text-teal-tertiary-20">
          <FormattedMessage id="email.unsubscribedSuccessfully" />
        </h1>
      </main>
      <PublicFooter />
    </div>
  )
}

export default EmailUnsubscribedPage
