import React from 'react'
import {useIntl} from 'react-intl'
import CountryCode from '../domain/countryCode'
import StateCode from '../domain/stateCode'
import {STATES, STATES_BY_COUNTRY_CODE} from '../domain/statesCodes'
import SelectInput from './SelectInput/SelectInput'

type Props<C extends CountryCode> = Readonly<{
  name?: string
  countryCode: C | null
  value: StateCode<C> | null
  onChange?: (stateCode: StateCode<C> | null) => void
}>

function StateSelect<C extends CountryCode>({
  name = 'state',
  countryCode,
  value,
  onChange
}: Props<C>) {
  const intl = useIntl()
  const options = useOptions(countryCode)
  return (
    <SelectInput
      name={name}
      label={intl.formatMessage({id: 'admin.communities.state'})}
      placeholderText={intl.formatMessage({
        id: 'admin.communities.selectSearch'
      })}
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

function useOptions<C extends CountryCode>(countryCode: C | null) {
  const intl = useIntl()
  if (countryCode == null) {
    return STATES.map(state => ({
      id: state.code,
      name: intl.formatMessage({id: state.nameMessageId})
    })).sort((a, b) => new Intl.Collator(intl.locale).compare(a.name, b.name))
  }
  return STATES_BY_COUNTRY_CODE[countryCode]
    .map(state => ({
      id: state.code,
      name: intl.formatMessage({id: state.nameMessageId})
    }))
    .sort((a, b) => new Intl.Collator(intl.locale).compare(a.name, b.name))
}

export default StateSelect
