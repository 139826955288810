import React from 'react'

type Props = {
  children: React.ReactNode
}

function Breadcrumb({children}: Props) {
  const crumbs: Array<React.ReactNode> = []
  React.Children.forEach(children, (child, index) => {
    if (index > 0) {
      crumbs.push(
        <div key={`divider-${index}`} aria-hidden>
          /
        </div>
      )
    }
    crumbs.push(child)
  })
  return (
    <ul
      role="list"
      className="flex max-w-full flex-wrap items-center space-x-2 pt-8 text-teal"
    >
      {crumbs}
    </ul>
  )
}

export default Breadcrumb
