import React from 'react'

type Variant = 'primary' | 'danger' | 'warning'

type Props = Readonly<{
  busy?: boolean
  variant?: Variant
}> &
  Omit<React.ComponentProps<'button'>, 'className'>

export const BASE_CLASSES = `
  typography-button block rounded-full py-2 px-4 text-white shadow-3d 
  outline-none focus:outline-none focus-visible:outline-none 
  focus-visible:ring-4 focus-visible:ring-navy
  active:translate-y-0.5 active:shadow-none disabled:opacity-50
`

export function StyledButton({
  busy = false,
  variant = 'primary',
  ...buttonProps
}: Props) {
  return (
    <button
      {...buttonProps}
      aria-busy={busy}
      className={`
        ${BASE_CLASSES}
        ${getVariantClasses(variant)}
        ${busy ? 'disabled:animate-pulse' : ''}
      `}
      disabled={busy}
    />
  )
}

export function getVariantClasses(variant: Variant) {
  if (variant === 'primary') {
    return 'bg-teal shadow-teal-secondary text-white hover:bg-teal-secondary hover:shadow-teal-tertiary-40'
  }
  if (variant === 'danger') {
    return 'bg-red shadow-red-secondary text-white hover:bg-red-secondary hover:shadow-maroon-tertiary-20'
  }
  if (variant === 'warning') {
    return 'bg-mustard shadow-lemon-secondary text-navy hover:text-white hover:bg-teal-secondary hover:shadow-teal-tertiary-40'
  }
  throwUnhandledVariant(variant)
}

function throwUnhandledVariant(variant: never): never {
  throw new Error(`Unhandled variant: ${variant}`)
}

export default StyledButton
