import {isPlainObject} from '../responseRefiners'
import {CommunityId} from './community'
import Email, {Email as EmailT} from './email'
import Id from './id'
import Name from './name'

export type MentorId = Id<'Mentor'>

export type Mentor = Readonly<{
  id: MentorId
  firstName: Name
  lastName: Name
  email: EmailT
  communityId: CommunityId
  communityName: Name
}>

function parse(candidate: unknown): Mentor {
  if (!isPlainObject(candidate)) {
    throw new Error(
      `Unexpected Mentor ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }
  return {
    id: Id.parse(candidate.id),
    firstName: Name.parse(candidate.firstName),
    lastName: Name.parse(candidate.lastName),
    email: Email.parse(candidate.email),
    communityId: Id.parse(candidate.communityId),
    communityName: Name.parse(candidate.communityName)
  }
}

const Mentor = {parse}
export default Mentor
