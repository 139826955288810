import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {userLoggedIn} from './store/authentication/authentication.action'
import {Login} from './store/authentication/authentication.type'
import {AppDispatch} from './store/store'

function AuthDispatcher({children}: {children: React.ReactNode}) {
  const dispatch = useDispatch<AppDispatch>()
  const [processedAuth, setProcessedAuth] = useState(false)
  useEffect(() => {
    // If we have saved auth data in sessionStorage, use it.
    if (window.sessionStorage) {
      const jsonAuthData = sessionStorage.getItem('auth')
      if (jsonAuthData) {
        const loginData = JSON.parse(jsonAuthData) as Login
        dispatch(userLoggedIn(loginData)).then(() => setProcessedAuth(true))
      } else {
        setProcessedAuth(true)
      }
    }
  }, [dispatch])

  return <>{processedAuth && children}</>
}

export default AuthDispatcher
