import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import {CommunityId} from '../domain/community'
import {EventId} from '../domain/event'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  communityId: CommunityId
  onSuccess?: () => void
  onError?: (error: Error) => void
}>

function useRolloverNotification({communityId, onSuccess, onError}: Options): {
  isBusy: boolean
  error: Error | null
  rolloverNotification: (eventId: EventId, plannedRolloverDate: Date) => void
} {
  const accessToken = useSelector(getAccessToken)
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const rolloverNotification = (
    eventId: EventId,
    plannedRolloverDate: Date
  ) => {
    setError(null)
    setIsBusy(true)
    postNotification(communityId, accessToken, eventId, plannedRolloverDate)
      .then(() => onSuccess?.())
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => {
        setIsBusy(false)
      })
  }
  return {isBusy, error, rolloverNotification}
}

async function postNotification(
  communityId: CommunityId,
  accessToken: string,
  eventId: EventId,
  plannedRolloverDate: Date
): Promise<void> {
  const url = new URL(
    `communities/${communityId}/currentEvents/${eventId}/rolloverNotifications`,
    API_URL
  ).toString()
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    },
    body: JSON.stringify({plannedRolloverDate})
  })

  if (response.status !== 202) {
    return ServerError.fromResponse(response)
  }
}

export default useRolloverNotification
