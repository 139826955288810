import {Middleware} from 'redux'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {AppActions, AppDispatch, AppState} from '../store/store'

const middleware: Middleware<Record<string, unknown>, AppState, AppDispatch> =
  () => (next: AppDispatch) => (action: AppActions) => {
    try {
      // Send Flux Standard Action errors to Sentry
      if (action.error === true && action.payload instanceof Error) {
        const error = action.payload
        if (
          !ServerError.isServerError(error) ||
          (ServerError.isServerError(error) &&
            error.status >= 500 &&
            error.status < 600)
        ) {
          captureException(error)
        }
      }
      return next(action)
    } catch (err) {
      // Send errors thrown during dispatch to Sentry
      captureException(err)
      throw err
    }
  }

export default middleware
