import clsx from 'clsx'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {isNonBlankString} from '../../responseRefiners'
import ValidationErrorMessage from '../ValidationErrorMessage'

type Props = {
  errorMessage?: string | null
  startDate: Date
  endDate: Date
  onStartDateChange: (startDate: Date) => void
  onEndDateChange: (endDate: Date) => void
  label?: string
  description?: string
}

function DateRangePicker({
  errorMessage = null,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  label,
  description
}: Props) {
  const isError = isNonBlankString(errorMessage)
  const timestampRangeStartElementId = 'timestamp-range-start'
  const timestampRangeEndElementId = 'timestamp-range-end'
  const timestampRangeErrorElementId = 'timestamp-range-error'

  function getFormattedDate(dateValue: Date) {
    const dateTimeLocalValue = new Date(
      dateValue.getTime() - dateValue.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, -1)
    return dateTimeLocalValue.substring(
      0,
      dateTimeLocalValue.indexOf(':', dateTimeLocalValue.indexOf(':') + 1)
    )
  }

  return (
    <div className="flex items-center justify-between p-2">
      <div className="typography-headline-5 text-navy">
        {label && <p className="after:content-[':']">{label}</p>}
        {description && <p className="typography-body-2">{description}</p>}
      </div>
      <div className="flex flex-col items-end">
        <div className="flex items-center justify-between space-x-4">
          <input
            aria-describedby={timestampRangeErrorElementId}
            aria-errormessage={timestampRangeErrorElementId}
            aria-invalid={isError}
            type="datetime-local"
            name={timestampRangeStartElementId}
            id={timestampRangeStartElementId}
            className={clsx(
              isError ? 'ring-red' : 'ring-steel-tertiary-20',
              `w-full rounded-md border-0 bg-white py-1.5 pl-10 text-navy shadow-sm outline-none ring-2 ring-inset
            placeholder:text-navy focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset
            focus-visible:ring-green`
            )}
            value={getFormattedDate(startDate)}
            onChange={event => {
              const value = event.target.value
              const date = new Date(value)
              onStartDateChange(isDateValid(date) ? date : startDate)
            }}
          />
          <label className="sr-only" htmlFor={timestampRangeStartElementId}>
            <FormattedMessage id="admin.communities.dates.pickerStartLabel" />
          </label>
          <input
            aria-describedby={timestampRangeErrorElementId}
            aria-errormessage={timestampRangeErrorElementId}
            aria-invalid={isError}
            type="datetime-local"
            name={timestampRangeEndElementId}
            id={timestampRangeEndElementId}
            className={clsx(
              isError ? 'ring-red' : 'ring-steel-tertiary-20',
              `w-full rounded-md border-0 bg-white py-1.5 pl-10 text-navy shadow-sm outline-none ring-2 ring-inset
            placeholder:text-navy focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset
            focus-visible:ring-green`
            )}
            value={getFormattedDate(endDate)}
            onChange={event => {
              const value = event.target.value
              const date = new Date(value)
              onEndDateChange(isDateValid(date) ? date : endDate)
            }}
          />
          <label className="sr-only" htmlFor={timestampRangeEndElementId}>
            <FormattedMessage id="admin.communities.dates.pickerEndLabel" />
          </label>
        </div>
        <ValidationErrorMessage id={timestampRangeErrorElementId}>
          {errorMessage}
        </ValidationErrorMessage>
      </div>
    </div>
  )
}

function isDateValid(date: Date) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !isNaN(date)
}

export default DateRangePicker
