import {validateEmail, validatePassword} from '../../../validations'
import {CredentialErrors, Credentials} from './loginForm.type'

export function validateCredentials(
  credentials: Credentials
): CredentialErrors {
  return {
    emailError: validateEmail(credentials.email),
    passwordError: validatePassword(
      credentials.password,
      'registration.MentorProfile01_Fail_error5',
      'registration.MentorProfile01_Fail_error2'
    )
  }
}
