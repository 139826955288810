import React, {FormEvent, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import Community from '../../domain/community'
import CommunityStatus from '../../domain/communityStatus'
import {INACTIVE} from '../../domain/communityStatuses'
import {LICENSED} from '../../domain/communityTypes'
import CountryCode from '../../domain/countryCode'
import CurrencyCode from '../../domain/currencyCode'
import Id from '../../domain/id'
import LanguageTag from '../../domain/languageTag'
import Name from '../../domain/name'
import StateCode from '../../domain/stateCode'
import TzIdentifier from '../../domain/tzIdentifier'
import ZipPill from '../../domain/zipPill'
import {CreateCommunityRequestBody} from '../../hooks/useCreateCommunity'
import CommunityStatusToggle from '../CommunityStatusToggle'
import CountrySelect from '../CountrySelect'
import CurrencySelect, {getCurrencyCodeByCountryCode} from '../CurrencySelect'
import DateRangePicker from '../DateRangePicker'
import Divider from '../Divider'
import EthnicityToggle from '../EthnicityToggle'
import FlatButton from '../FlatButton'
import GenderToggle from '../GenderToggle'
import LocaleSelect, {getLanguageTagByCountryCode} from '../LocaleSelect'
import StateSelect from '../StateSelect'
import TextInput from '../TextInput/AdminTextInput'
import TimeZoneSelect from '../TimeZoneSelect'
import ZipCodeInput from '../ZipCodeInput'

type Props = Readonly<{
  busy?: boolean
  onSubmit: (community: CreateCommunityRequestBody) => void
  onValidationFailure: (validationErrorCount: number) => void
}>

function AddCommunityForm({
  busy = false,
  onSubmit,
  onValidationFailure
}: Props) {
  const intl = useIntl()

  const formId = 'add-community-form'

  const [name, setName] = useState('')
  const [nameErrorMessage, setNameErrorMessage] = useState<string | null>(null)
  const [countryCode, setCountryCode] = useState<CountryCode | null>(null)
  const [countryCodeErrorMessage, setCountryCodeErrorMessage] = useState<
    string | null
  >(null)
  const [stateCode, setStateCode] = useState<StateCode | null>(null)
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode | null>(
    getCurrencyCodeByCountryCode(countryCode)
  )
  const [currencyCodeErrorMessage, setCurrencyCodeErrorMessage] = useState<
    string | null
  >(null)
  const [languageTag, setLanguageTag] = useState<LanguageTag | null>(
    getLanguageTagByCountryCode(countryCode)
  )
  const [languageTagErrorMessage, setLanguageTagErrorMessage] = useState<
    string | null
  >(null)
  const [tzIdentifier, setTzIdentifier] = useState<TzIdentifier | null>(null)
  const [tzIdentifierErrorMessage, setTzIdentifierErrorMessage] = useState<
    string | null
  >(null)
  const [zips, setZips] = useState<ReadonlyArray<ZipPill>>([])
  const [ethnicityEnabled, setEthnicityEnabled] = useState(false)
  const [genderEnabled, setGenderEnabled] = useState(false)
  const [status, setStatus] = useState<CommunityStatus>(INACTIVE)
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  )
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear() + 1, 0, 1)
  )
  const [dateRangeErrorMessage, setDateRangeErrorMessage] = useState<
    string | null
  >(null)

  function clearNameValidationsErrors() {
    setNameErrorMessage(null)
    onValidationFailure(0)
  }

  function clearCountryValidationErrors() {
    setCountryCodeErrorMessage(null)
    onValidationFailure(0)
  }

  function clearCurrencyValidationErrors() {
    setCurrencyCodeErrorMessage(null)
    onValidationFailure(0)
  }

  function clearLanguageValidationErrors() {
    setLanguageTagErrorMessage(null)
    onValidationFailure(0)
  }

  function clearTimeZoneValidationErrors() {
    setTzIdentifierErrorMessage(null)
    onValidationFailure(0)
  }

  function clearDateRangeValidationErrors() {
    setDateRangeErrorMessage(null)
    onValidationFailure(0)
  }

  function handleFormSubmission() {
    let valid = true
    let validationErrorCounter = 0

    if (!Name.isValid(name)) {
      setNameErrorMessage(
        intl.formatMessage({id: 'admin.communities.name.validationMessage'})
      )
      validationErrorCounter++
      valid = false
    }

    if (!CountryCode.isValid(countryCode)) {
      setCountryCodeErrorMessage(
        intl.formatMessage({
          id: 'admin.communities.country.validationMessage'
        })
      )
      validationErrorCounter++
      valid = false
    }

    if (!CurrencyCode.isValid(currencyCode)) {
      setCurrencyCodeErrorMessage(
        intl.formatMessage({
          id: 'admin.communities.currency.validationMessage'
        })
      )
      validationErrorCounter++
      valid = false
    }

    if (!LanguageTag.isValid(languageTag)) {
      setLanguageTagErrorMessage(
        intl.formatMessage({
          id: 'admin.communities.language.validationMessage'
        })
      )
      validationErrorCounter++
      valid = false
    }

    if (!TzIdentifier.isValid(tzIdentifier)) {
      setTzIdentifierErrorMessage(
        intl.formatMessage({
          id: 'admin.communities.timeZone.validationMessage'
        })
      )
      validationErrorCounter++
      valid = false
    }

    if (startDate >= endDate) {
      const validationMessage = intl.formatMessage({
        id: 'admin.communities.dates.validationMessage'
      })
      setDateRangeErrorMessage(validationMessage)
      validationErrorCounter++
      valid = false
    }

    if (valid) {
      onSubmit({
        ...Community.parse({
          id: Id.generate(),
          type: LICENSED,
          status,
          name,
          state: stateCode,
          country: countryCode,
          currency: currencyCode,
          locale: languageTag,
          timeZone: tzIdentifier,
          zips: zips.map((zip: ZipPill) => zip.code.trim()),
          ethnicityEnabled,
          genderEnabled,
          currentEventId: Id.generate()
        }),
        firstEventStartDate: startDate.toISOString(),
        firstEventEndDate: endDate.toISOString()
      })
    } else {
      onValidationFailure(validationErrorCounter)
    }
  }

  return (
    <form
      aria-label={intl.formatMessage({
        id: 'admin.communities.add.formLabel'
      })}
      id={formId}
      noValidate
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        handleFormSubmission()
      }}
    >
      <div className="rounded bg-white">
        <TextInput
          autoCapitalize="words"
          errorMessage={nameErrorMessage}
          id="community-name"
          inputMode="text"
          label={intl.formatMessage({id: 'admin.communities.name.label'})}
          onChange={event => {
            setName(event.target.value)
            clearNameValidationsErrors()
          }}
          required
          type="text"
          value={name}
        />
        <Divider />
        <CountrySelect
          errorMessage={countryCodeErrorMessage}
          onChange={selectedCountryCode => {
            setCountryCode(selectedCountryCode)
            if (selectedCountryCode !== countryCode) {
              setStateCode(null)
            }
            setCurrencyCode(getCurrencyCodeByCountryCode(selectedCountryCode))
            setLanguageTag(getLanguageTagByCountryCode(selectedCountryCode))
            clearCountryValidationErrors()
            clearCurrencyValidationErrors()
            clearLanguageValidationErrors()
          }}
          value={countryCode}
        />
        <Divider />
        <StateSelect
          countryCode={countryCode}
          onChange={setStateCode}
          value={stateCode}
        />
        <Divider />
        <CurrencySelect
          errorMessage={currencyCodeErrorMessage}
          onChange={selectedCurrencyCode => {
            setCurrencyCode(selectedCurrencyCode)
            clearCurrencyValidationErrors()
          }}
          value={currencyCode}
        />
        <Divider />
        <LocaleSelect
          errorMessage={languageTagErrorMessage}
          onChange={selectedLanguageTag => {
            setLanguageTag(selectedLanguageTag)
            clearLanguageValidationErrors()
          }}
          value={languageTag}
        />
        <Divider />
        <TimeZoneSelect
          errorMessage={tzIdentifierErrorMessage}
          countryCode={countryCode}
          onChange={selectedTzIdentifier => {
            setTzIdentifier(selectedTzIdentifier)
            clearTimeZoneValidationErrors()
          }}
          value={tzIdentifier}
        />
        <Divider />
        <DateRangePicker
          errorMessage={dateRangeErrorMessage}
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={startDate => {
            setStartDate(startDate)
            clearDateRangeValidationErrors()
          }}
          onEndDateChange={endDate => {
            setEndDate(endDate)
            clearDateRangeValidationErrors()
          }}
          label={intl.formatMessage({id: 'admin.communities.dates.title'})}
          description={intl.formatMessage({
            id: 'admin.communities.dates.description'
          })}
        />
        <Divider />
        <ZipCodeInput
          zips={zips}
          onDelete={deletedZip =>
            setZips(zips.filter(zip => zip.code !== deletedZip.code))
          }
          onAdd={newZips =>
            setZips(
              zips
                .concat(newZips)
                .filter((zip, index, array) => array.indexOf(zip) === index)
            )
          }
          onSort={() => {
            setZips([...zips].sort((a, b) => a.code.localeCompare(b.code)))
          }}
        />
        <Divider />
        <EthnicityToggle
          checked={ethnicityEnabled}
          onChange={setEthnicityEnabled}
        />
        <Divider />
        <GenderToggle checked={genderEnabled} onChange={setGenderEnabled} />
        <Divider />
        <CommunityStatusToggle status={status} onChange={setStatus} />
      </div>
      <div className="mx-auto mt-2 w-fit md:mt-4 lg:mt-6 xl:mb-8">
        <FlatButton
          aria-label={intl.formatMessage({
            id: 'admin.communities.add.save'
          })}
          disabled={busy}
          id="add-community-button"
          form={formId}
          type="submit"
          variant="success"
        >
          <FormattedMessage id="admin.communities.add.save" />
        </FlatButton>
      </div>
    </form>
  )
}

export default AddCommunityForm
