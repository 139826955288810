import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'
import {getCommunitiesAction} from '../../store/communities/communities.action'
import {getAllCommunities} from '../../store/communities/communities.selectors'
import {AppActions, AppState} from '../../store/store'
import CommunityNotHerePage from '../components/pages/CommunityNotHerePage'

export interface DispatchProps {
  getLicensedCommunities: () => void
}

export interface StateProps {
  readonly numberOfCommunities: number
}

export type Props = StateProps

function CommunityNotHere({
  getLicensedCommunities,
  numberOfCommunities
}: StateProps & DispatchProps) {
  useEffect(() => {
    if (numberOfCommunities === 0) {
      getLicensedCommunities()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <CommunityNotHerePage numberOfCommunities={numberOfCommunities} />
}

const mapStateToProps = (state: AppState): StateProps => ({
  numberOfCommunities: getAllCommunities(state).length
})

const dispatchProps = (
  dispatch: ThunkDispatch<AppState, undefined, AppActions>
): DispatchProps => ({
  getLicensedCommunities: () => dispatch(getCommunitiesAction(['LICENSED']))
})

export default connect<
  StateProps,
  DispatchProps,
  Record<string, never>,
  AppState
>(
  mapStateToProps,
  dispatchProps
)(CommunityNotHere)
