export const ADULT_FIRST_NAME_ERROR_IDENTIFIER =
  '4da197ed-50e6-40e4-8c22-32119e34154c'
export const ADULT_LAST_NAME_ERROR_IDENTIFIER =
  '706e5b62-d107-4c0c-8d5b-bd8b8d894d74'
export const COMMUNITY_ID_ERROR_IDENTIFIER =
  '17dd6dde-f9e7-4bb8-99ae-a272ff8a5c7f'
export const COMMUNITY_NOT_FOUND_ERROR_IDENTIFIER =
  'bed4f137-9d37-4919-b113-ccd5f7f89867'
export const DUPLICATE_COMMUNITY_ERROR_IDENTIFIER =
  '7a774045-5d75-4b65-9f29-b57f6ec0ed31'
export const EVENT_NOT_FOUND_ERROR_IDENTIFIER =
  '771c55ca-46d3-4f5e-9b4b-e25398ee856b'
export const EVENT_OVERLAPPING_ERROR_IDENTIFIER =
  '363c929a-1e7a-4d57-beab-26b020e687a8'
export const DUPLICATE_LEARNER_FOR_CONNECTED_MENTOR_ERROR_IDENTIFIER =
  '3a28711c-5924-487f-890b-3fd9e48bf5d0'
export const EMAIL_ERROR_IDENTIFIER = '63210a0a-c8ab-4c1c-93e7-a221481fb70f'
export const EMAIL_MALFORMED_ERROR_IDENTIFIER =
  'b8459927-87b9-4ebc-a3a7-67481523493b'
export const EMAIL_NOT_VERIFIED_ERROR_IDENTIFIER =
  '2a48d95a-52bb-4b8b-a286-4d877ff6845b'
export const GUARDIAN_EMAIL_ERROR_IDENTIFIER =
  '954e6f63-8ed6-4cc1-be70-5081e09c6812'
export const LEARNER_LAST_NAME_ERROR_IDENTIFIER =
  'fd60f360-71da-4666-a971-22e82015378b'
export const LEARNER_FIRST_NAME_ERROR_IDENTIFIER =
  '3cb33d2b-803c-47e9-b8ac-864226224b1b'
export const LEGAL_ACCEPTED_DATE_ERROR_IDENTIFIER =
  '2006d79c-8c17-49c5-bfc0-ac4bb95ef7b8'
export const PASSWORD_INVALID_ERROR_IDENTIFIER =
  '25bc64fb-27a3-4666-811e-5c0940e23904'
export const PHONE_NUMBER_ERROR_IDENTIFIER =
  '700308ca-2651-4909-90d2-e9d0b2669ec7'
export const RECAPTCHA_ERROR_IDENTIFIER = '0ce0a434-acb1-4864-ba19-08fcfacc09cd'
export const RECAPTCHA_REQUEST_TOKEN_ERROR_IDENTIFIER =
  '63d0928e-92ff-4f40-acac-cd10a605763f'
export const RECAPTCHA_VERIFICATION_ERROR_IDENTIFIER =
  'e5b355cf-2871-43a5-8a04-10b13ffb14aa'
export const REFERRER_ERROR_IDENTIFIER = 'cb770db6-1501-4900-a77e-15dca623f4be'
export const TITLE_ERROR_IDENTIFIER = '7b0732fd-a51f-4ecc-bc30-5e76cc8025b6'
export const UNEXPECTED_COLUMN_COUNT_ERROR_IDENTIFIER =
  'fe69af2a-dfb5-4635-8218-37b13b21eaf2'
export const ZIP_ERROR_IDENTIFIER = 'efce3b64-6b49-4c76-bd89-303064ecfae2'
export const ZIP_NOT_ASSOCIATED_WITH_COMMUNITY_ERROR_IDENTIFIER =
  'c85aaff0-e0f6-40a8-9905-9acae63b5b74'
export const COMMUNITY_EVENT_START_DATE = '642cbbcb-1f7c-43c3-8763-990c428d11f3'
export const COMMUNITY_EVENT_END_DATE = '37931ea8-7eb6-4831-8a3d-92bf6b718f6d'
export const COMMUNITY_EVENT_OVERLAPPING =
  '363c929a-1e7a-4d57-beab-26b020e687a8'
