import React from 'react'
import {useRouteMatch} from 'react-router'
import {UnstyledInternalLink} from '../links/InternalLink'

type Props = {
  path: string
  label: React.ReactNode
  to: string
}

function BreadcrumbItem({label, path, to}: Props) {
  const match = useRouteMatch()
  const isCurrent = path === match.path
  return (
    <li>
      <div className="flex items-center">
        <UnstyledInternalLink
          aria-current={isCurrent ? 'page' : undefined}
          className={
            'typography-headline-6 whitespace-nowrap text-teal hover:text-teal hover:underline hover:underline-offset-2'
          }
          to={to}
        >
          {label}
        </UnstyledInternalLink>
      </div>
    </li>
  )
}

export default BreadcrumbItem
