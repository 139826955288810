import React from 'react'
import {defineMessages, FormattedMessage} from 'react-intl'

const messages = defineMessages({
  notFound: {
    defaultMessage: 'Not found',
    id: 'notFound'
  }
})

function NotFoundPage() {
  return (
    <h1>
      <FormattedMessage {...messages.notFound} />
    </h1>
  )
}

export default NotFoundPage
