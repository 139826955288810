import React from 'react'

type Variant = 'primary' | 'social'

type Props = Readonly<{
  /**
   * Alternative and or accessible text.
   */
  alt: string
  /**
   * Image height
   */
  height: number
  /**
   * Image uri.
   */
  src: string
  /**
   * Style variant. (default: primary)
   */
  variant?: Variant
  /**
   * Image width
   */
  width: number
}> &
  React.ComponentProps<'img'>

/**
 * An img with a navy ring focus-visible styling.
 */
function FocusableImage({
  alt,
  src,
  height,
  variant = 'primary',
  width,
  ...imageProps
}: Props) {
  return (
    <img
      {...imageProps}
      alt={alt}
      className={`
        rounded p-0.5 outline-none
        focus:outline-none focus-visible:outline-none
        ${getVariantClasses(variant)}
      `}
      width={width}
      height={height}
      referrerPolicy="same-origin"
      src={src}
    />
  )
}

export function getVariantClasses(variant: Variant): string {
  if (variant === 'primary') {
    return 'focus-visible:ring-4 focus-visible:ring-navy'
  }
  if (variant === 'social') {
    return 'focus-visible:ring-4 focus-visible:ring-mustard'
  }
  throwUnhandledVariant(variant)
}

function throwUnhandledVariant(variant: never): never {
  throw new Error(`Unhandled variant: ${variant}`)
}

export default FocusableImage
