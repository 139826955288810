import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import styled from 'styled-components'
import {
  default as lemmyLogo,
  default as logo
} from '../../../components/Header/assets/business_app_logo.png'
import logo2X from '../../../components/Header/assets/business_app_logo@2x.png'
import logo3X from '../../../components/Header/assets/business_app_logo@3x.png'
import logo4X from '../../../components/Header/assets/business_app_logo@4x.png'
import lemmyLogo2X from '../../../components/Header/assets/lemmy_logo@2x.png'
import lemmyLogo3X from '../../../components/Header/assets/lemmy_logo@3x.png'
import lemmyLogo4X from '../../../components/Header/assets/lemmy_logo@4x.png'
import LanguageSelector from '../../../components/Header/LanguageSelector'
import {
  InternalLinkStyledAsButton,
  UnstyledInternalLink
} from '../../../components/links/InternalLink'
import {devices} from '../../../device'
import {
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  ROOT_ROUTE
} from '../../../global-constants/routingConstants'

type StyleProps = {
  noShadow?: boolean
}

type Props = StyleProps & RouteComponentProps<Record<string, string>>

const Container = styled.header<StyleProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 54px;
  padding: 9px 18px;
  z-index: 10;
  box-shadow: ${({noShadow = false}) =>
    noShadow ? 'unset' : '0 7px 9px -7px rgba(0, 0, 0, 0.4)'};

  @media ${devices.tablet} {
    height: 72px;
    padding: 18px 36px;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: flex-start;
`

const HeaderActionWrap = styled.div`
  margin-left: 1rem;

  @media ${devices.tablet} {
    margin-left: 2rem;
  }
`

const Logo = styled.img`
  height: 36px;
`

function Header({location, noShadow}: Props) {
  const intl = useIntl()

  return (
    <Container noShadow={noShadow}>
      <UnstyledInternalLink to={ROOT_ROUTE}>
        <picture>
          <source
            media={devices.tablet}
            srcSet={`${logo}, ${logo2X} 2x, ${logo3X} 3x, ${logo4X} 4x`}
          />
          <Logo
            src={lemmyLogo}
            srcSet={`${lemmyLogo}, ${lemmyLogo2X} 2x, ${lemmyLogo3X} 3x, ${lemmyLogo4X} 4x`}
            alt={intl.formatMessage({id: 'registration.WebHeaderNav_logo'})}
          />
        </picture>
      </UnstyledInternalLink>
      <Actions>
        <LanguageSelector />
        {location.pathname !== LOGIN_ROUTE && (
          <HeaderActionWrap>
            <InternalLinkStyledAsButton to={LOGIN_ROUTE}>
              <FormattedMessage id="registration.WebHeaderNav_loginButton" />
            </InternalLinkStyledAsButton>
          </HeaderActionWrap>
        )}
        {location.pathname === LOGIN_ROUTE && (
          <HeaderActionWrap>
            <InternalLinkStyledAsButton to={REGISTER_ROUTE}>
              <FormattedMessage id="registration.WebHeaderNav_registerButton" />
            </InternalLinkStyledAsButton>
          </HeaderActionWrap>
        )}
      </Actions>
    </Container>
  )
}

export default withRouter(Header)
