import React, {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import useRequestAccountDeletion from '../../hooks/useRequestAccountDeletion'
import Background from '../../legacy/components/Background'
import Layout from '../../legacy/components/Layout'
import DeleteAccountForm from './DeleteAccountForm'

function AccountDeletionPage() {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const {busy, error, requestAccountDeletion} = useRequestAccountDeletion({
    onSuccess: () => setSubmitSuccess(true)
  })

  return (
    <Layout noShadow>
      <div className="flex w-full flex-grow flex-col-reverse flex-wrap items-center justify-between px-5">
        <Background />
        <div className="z-10 flex w-full flex-grow items-center xl:max-w-4xl">
          {submitSuccess ? (
            <div className="w-full text-center">
              <FormattedMessage id="account.delete.submitSuccess" />
            </div>
          ) : (
            <DeleteAccountForm
              submitError={error}
              onSubmit={requestAccountDeletion}
              busy={busy}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default AccountDeletionPage
