import * as EDUCATIONAL_RESOURCE_TYPES from './educationResourceTypes'
import {FAQ, GUIDE, OTHER} from './educationResourceTypes'

type EducationResourceType = ValueOf<typeof EDUCATIONAL_RESOURCE_TYPES>
const EXPECTED_EDUCATION_RESOURCE_TYPES = Object.values(
  EDUCATIONAL_RESOURCE_TYPES
)

/**
 * Parse a {@link EducationResourceType} from a candidate of unknown type.
 *
 * @param candidate The candidate to parse.
 * @return A valid typed {@link EducationResourceType}.
 */
function parse(candidate: unknown): EducationResourceType {
  if (candidate !== FAQ && candidate !== GUIDE && candidate !== OTHER) {
    throw new Error(
      `Unexpected Education Resource Type, expected one of ${EXPECTED_EDUCATION_RESOURCE_TYPES.join(
        ','
      )}`
    )
  }

  return candidate
}

const EducationResourceType = {parse}
export default EducationResourceType
