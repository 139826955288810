import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import {CommunityId} from '../domain/community'
import {EventId} from '../domain/event'
import Id from '../domain/id'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  communityId: CommunityId
  onError: (error: Error) => void
  onSuccess: (eventId: EventId) => void
}>

type RequestBody<DateType extends Date | string = Date> = Readonly<{
  communityId: CommunityId
  startDate: DateType
  endDate: DateType
  current: boolean
}>

function useCreateEvent({communityId, onError, onSuccess}: Options): {
  busy: boolean
  createEvent: (event: RequestBody) => void
  error: Error | null
} {
  const accessToken = useSelector(getAccessToken)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const createEvent = (requestBody: RequestBody) => {
    setBusy(true)
    setError(null)
    postEvent(accessToken, communityId, requestBody)
      .then(id => onSuccess?.(id))
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setBusy(false))
  }

  return {busy, createEvent, error}
}

async function postEvent(
  accessToken: string,
  communityId: CommunityId,
  requestBody: RequestBody
): Promise<EventId> {
  const url = new URL(`communities/${communityId}/events`, API_URL).toString()
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    },
    body: JSON.stringify(requestBody)
  })

  if (response.status !== 201) {
    return ServerError.fromResponse(response)
  }

  const location = response.headers.get('Location')
  if (location == null) {
    throw new Error(`Unexpected Location header ${location}`)
  }

  return Id.parse(location.slice(location.lastIndexOf('/') + 1))
}

export default useCreateEvent
