import React from 'react'

export type FlatButtonVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'ambulance'
  | 'transparent'

type Props = Readonly<{
  variant?: FlatButtonVariant
  icon?: React.ReactNode
  width?: 'full' | 'auto'
}> &
  Omit<React.ComponentProps<'button'>, 'className'>

export const FLAT_BUTTON_BASE_STYLES =
  'flex items-center justify-center gap-2 rounded p-2 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-navy disabled:bg-steel-tertiary-10 disabled:text-white'

function FlatButton({
  variant = 'secondary',
  icon: Icon,
  children,
  width = 'auto',
  ...buttonProps
}: Props) {
  return (
    <button
      className={`${getFlatButtonColourClasses(variant)} ${getTypographyClasses(
        variant
      )} ${FLAT_BUTTON_BASE_STYLES} ${getWidthOption(width)}`}
      {...buttonProps}
    >
      {Icon == null ? null : <span>{Icon}</span>}
      {children}
    </button>
  )
}

export function getFlatButtonColourClasses(variant: FlatButtonVariant): string {
  if (variant === 'primary') {
    return 'bg-teal text-white'
  }
  if (variant === 'secondary') {
    return 'bg-mustard'
  }
  if (variant === 'success') {
    return 'bg-green text-navy'
  }
  if (variant === 'danger') {
    return 'bg-red text-white'
  }
  if (variant === 'ambulance') {
    return 'bg-white text-red'
  }
  if (variant == 'transparent') {
    return 'bg-transparent hover:text-teal-secondary'
  }
  throwUnhandledVariant(variant)
}

function getWidthOption(width: Props['width']) {
  if (width === 'full') {
    return 'w-full'
  }
  if (width === 'auto') {
    return ''
  }

  new Error(`Unhandled width option: ${width}`)
}

function getTypographyClasses(variant: FlatButtonVariant): string {
  if (variant === 'ambulance') {
    return 'typography-subtitle-2 uppercase underline'
  } else if (variant === 'transparent') {
    return 'typography-subtitle-2 underline'
  } else {
    return 'typography-button'
  }
}

export function throwUnhandledVariant(variant: never): never {
  throw new Error(`Unhandled variant: ${variant}`)
}

export default FlatButton
