import {PencilIcon} from '@heroicons/react/24/outline'
import React, {useCallback, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useRouteMatch} from 'react-router'
import {MentorId} from '../../domain/mentor'
import ServerError from '../../errors/server'
import {
  TOOL_SELECTOR_ROUTE,
  USER_SEARCH_ROUTE
} from '../../global-constants/routingConstants'
import useMentor from '../../hooks/useMentor'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import FlatButton from '../FlatButton'
import Layout from '../Layout'
import LoadingIndicator from '../LoadingIndicator'
import TitleText from '../TitleText'
import Toast from '../Toast'
import UserProfileTable, {UserProfileRow} from '../UserProfileTable'
import EditMentorDialog from './EditMentorDialog'

function MentorProfilePage() {
  const intl = useIntl()
  const {
    params: {mentorId}
  } = useRouteMatch<{mentorId: MentorId}>()
  const [error, setErrorValue] = useState<Error | null>(null)
  const setError = useCallback(error => setErrorValue(error), [setErrorValue])
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [editLearnerDialogOpen, setEditLearnerDialogOpen] = useState(false)
  const {isLoading, mentor, refetchMentor} = useMentor({
    mentorId,
    onError: setError
  })

  function onSuccess(message: string) {
    setError(null)
    setSuccessMessage(message)
    window.scroll({top: 0, behavior: 'smooth'})
  }

  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="admin.userSettings.title" />
        </TitleText>
      }
      toast={
        <MentorProfileToast error={error} successMessage={successMessage} />
      }
    >
      <div
        className="
          mx-auto mb-2 w-full px-2
          md:mb-4 md:px-4 lg:mb-6 lg:px-6
          xl:mb-8 xl:max-w-7xl xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          {/* TODO: Update after search page is merged */}
          <BreadcrumbItem
            path={USER_SEARCH_ROUTE}
            to={USER_SEARCH_ROUTE}
            label={<FormattedMessage id="admin.userSettings.title" />}
          />
        </Breadcrumb>
        <h2 className="typography-headline-4 my-2 md:my-4 lg:my-6 xl:my-8">
          <FormattedMessage id="admin.mentorProfile.title" />
        </h2>
        {isLoading && <LoadingIndicator />}
        {mentor && !isLoading && (
          <>
            <div className="mt-8 overflow-x-auto rounded bg-white">
              <UserProfileTable isLoading={isLoading}>
                <UserProfileRow
                  left={intl.formatMessage({
                    id: 'admin.mentorProfile.firstName'
                  })}
                  middle={mentor.firstName}
                />
                <UserProfileRow
                  left={intl.formatMessage({
                    id: 'admin.mentorProfile.lastName'
                  })}
                  middle={mentor.lastName}
                />
                <UserProfileRow
                  left={intl.formatMessage({id: 'admin.mentorProfile.email'})}
                  middle={mentor.email}
                />
                <UserProfileRow
                  noBorder
                  left={intl.formatMessage({
                    id: 'admin.mentorProfile.communityLabel'
                  })}
                  middle={mentor.communityName}
                  right={
                    <FlatButton
                      variant="secondary"
                      onClick={() => {
                        setError(null)
                        setEditLearnerDialogOpen(true)
                      }}
                    >
                      <PencilIcon className="h-6 w-6" />
                      <span className="typography-headline-5">
                        <FormattedMessage id="general.edit" />
                      </span>
                    </FlatButton>
                  }
                />
              </UserProfileTable>
            </div>
            <EditMentorDialog
              open={editLearnerDialogOpen}
              onClose={() => setEditLearnerDialogOpen(false)}
              onSuccess={() => {
                setEditLearnerDialogOpen(false)
                refetchMentor()
                onSuccess?.(
                  intl.formatMessage({
                    id: 'admin.learnerProfile.migrateLearnerSuccess'
                  })
                )
              }}
              onError={error => {
                setEditLearnerDialogOpen(false)
                setError(error)
              }}
              mentor={mentor}
            />
          </>
        )}
      </div>
    </Layout>
  )
}

type ToastProps = {
  error?: Error | null
  successMessage?: string | null
}

function MentorProfileToast({error, successMessage}: ToastProps) {
  return (
    <>
      {error && (
        <Toast variant="error">
          <FormattedMessage
            id={
              ServerError.isServerError(error) && error.status === 404
                ? 'admin.mentorProfile.mentorNotFoundTitle'
                : 'general.GenericError'
            }
          />
        </Toast>
      )}
      {successMessage && <Toast variant="success">{successMessage}</Toast>}
    </>
  )
}

export default MentorProfilePage
