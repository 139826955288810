import {isBoolean, isNonBlankString, isPlainObject} from '../responseRefiners'
import {CommunityId} from './community'
import Id from './id'

export type EventId = Id<'Event'>

type Event<DateType extends Date | string = Date> = Readonly<{
  id: EventId
  communityId: CommunityId
  startDate: DateType
  endDate: DateType
  current: boolean
}>

function parse(candidate: unknown): Event {
  if (
    !isPlainObject(candidate) ||
    !Id.isValid(candidate.communityId) ||
    !isNonBlankString(candidate.startDate) ||
    !isNonBlankString(candidate.endDate) ||
    !isBoolean(candidate.current)
  ) {
    throw new Error(
      `Unexpected Event ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }

  return {
    id: Id.parse(candidate.id),
    communityId: Id.parse(candidate.communityId),
    startDate: new Date(candidate.startDate.trim()),
    endDate: new Date(candidate.endDate.trim()),
    current: candidate.current
  }
}

const Event = {parse}
export default Event
