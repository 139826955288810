import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import {CommunityId} from '../domain/community'
import {MentorId} from '../domain/mentor'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  onError?: (error: Error) => void
  onSuccess?: () => void
}>

function useMigrateMentorToCommunity({onError, onSuccess}: Options): {
  busy: boolean
  error: Error | null
  migrateMentorToCommunity: (
    mentorId: MentorId,
    communityId: CommunityId,
    migrateLearners: boolean,
    migrateProgress: boolean
  ) => void
} {
  const accessToken = useSelector(getAccessToken)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const migrateMentorToCommunity = (
    mentorId: MentorId,
    communityId: CommunityId,
    migrateLearners: boolean,
    migrateProgress: boolean
  ) => {
    setBusy(true)
    setError(null)
    putMigrateMentorToCommunity(
      accessToken,
      mentorId,
      communityId,
      migrateLearners,
      migrateProgress
    )
      .then(() => onSuccess?.())
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setBusy(false))
  }

  return {busy, error, migrateMentorToCommunity}
}

async function putMigrateMentorToCommunity(
  accessToken: string,
  mentorId: MentorId,
  communityId: CommunityId,
  migrateLearners: boolean,
  migrateProgress: boolean
): Promise<void> {
  const url = new URL(`mentors/${mentorId}/communities/${communityId}`, API_URL)
  url.searchParams.append('migrateLearners', `${migrateLearners}`)
  url.searchParams.append('migrateProgress', `${migrateProgress}`)
  const response = await fetch(url.toString(), {
    method: 'PUT',
    headers: {
      ...authorizationHeaderFromAccessToken(accessToken)
    }
  })

  if (response.status !== 204) {
    return ServerError.fromResponse(response)
  }
}

export default useMigrateMentorToCommunity
