import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import Id from '../domain/id'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  onError?: (error: Error) => void
  onSuccess?: () => void
}>

function useDeleteEvent({onError, onSuccess}: Options): {
  busy: boolean
  deleteEvent: (id: Id<'Event'>) => void
  error: Error | null
} {
  const accessToken = useSelector(getAccessToken)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const deleteEvent = (id: Id<'Event'>) => {
    setBusy(true)
    setError(null)
    fireDeleteEventRequest(accessToken, id)
      .then(() => onSuccess?.())
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setBusy(false))
  }

  return {busy, deleteEvent, error}
}

async function fireDeleteEventRequest(
  accessToken: string,
  id: Id<'Event'>
): Promise<void> {
  const url = new URL(`events/${id}`, API_URL).toString()
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      ...authorizationHeaderFromAccessToken(accessToken)
    }
  })

  if (response.status !== 204) {
    return ServerError.fromResponse(response)
  }
}

export default useDeleteEvent
