import {PencilIcon, XMarkIcon} from '@heroicons/react/24/outline'
import React, {FormEvent, useCallback, useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useRouteMatch} from 'react-router'
import {CommunityId} from '../../domain/community'
import Id from '../../domain/id'
import {IndividualLearner, LearnerId} from '../../domain/learner'
import PercentageFloatingPoint from '../../domain/percentageFloatingPoint'
import ServerError from '../../errors/server'
import {
  TOOL_SELECTOR_ROUTE,
  USER_SEARCH_ROUTE
} from '../../global-constants/routingConstants'
import useLearner from '../../hooks/useLearner'
import useMigrateLearnerToCommunity from '../../hooks/useMigrateLearnerToCommuntiy'
import useTimeRelativeToNow from '../../hooks/useTimeRelativeToNow'
import {getCommunitiesAction} from '../../store/communities/communities.action'
import {getAllCommunities} from '../../store/communities/communities.selectors'
import {AppDispatch} from '../../store/store'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import Dialog from '../Dialog'
import Divider from '../Divider'
import FlatButton from '../FlatButton'
import Layout from '../Layout'
import LoadingIndicator from '../LoadingIndicator'
import SelectInput from '../SelectInput/SelectInput'
import TitleText from '../TitleText'
import Toast from '../Toast'
import ToggleInput from '../ToggleInput'
import UserProfileTable, {UserProfileRow} from '../UserProfileTable'

const NO_VALUE = '-'
const DEFAULT_PROGRESS_PERCENTAGE = '0%'

function LearnerProfilePage() {
  const intl = useIntl()
  const {
    params: {learnerId}
  } = useRouteMatch<{learnerId: LearnerId}>()
  const [error, setErrorValue] = useState<Error | null>(null)
  const setError = useCallback(error => setErrorValue(error), [setErrorValue])
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [editLearnerDialogOpen, setEditLearnerDialogOpen] = useState(false)
  const isLoading = false
  const {learner, refetchLearner} = useLearner({
    learnerId: Id.parse(learnerId),
    onError: setError
  })
  const lastSyncDate = useTimeRelativeToNow(learner?.lastSyncDate ?? null)

  function onSuccess(message: string) {
    setError(null)
    setSuccessMessage(message)
    window.scroll({top: 0, behavior: 'smooth'})
  }

  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="admin.userSettings.title" />
        </TitleText>
      }
      toast={
        <LearnerProfileToast error={error} successMessage={successMessage} />
      }
    >
      <div
        className="
          mx-auto mb-2 w-full px-2
          md:mb-4 md:px-4 lg:mb-6 lg:px-6
          xl:mb-8 xl:max-w-7xl xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={USER_SEARCH_ROUTE}
            to={USER_SEARCH_ROUTE}
            label={<FormattedMessage id="admin.userSettings.title" />}
          />
        </Breadcrumb>
        <h2 className="typography-headline-4 my-2 md:my-4 lg:my-6 xl:my-8">
          <FormattedMessage id="admin.learnerProfile.title" />
        </h2>
        <div className="mt-8 rounded bg-white">
          <UserProfileTable isLoading={isLoading}>
            <UserProfileRow
              left={intl.formatMessage({id: 'learnerCard.firstName_label'})}
              middle={learner?.firstName}
            />
            <UserProfileRow
              left={intl.formatMessage({id: 'learnerCard.lastName_label'})}
              middle={learner?.lastName}
            />
            <UserProfileRow
              left={intl.formatMessage({
                id: 'admin.userSettings.usernameLabel'
              })}
              middle={learner?.username}
            />
            <UserProfileRow
              left={intl.formatMessage({id: 'admin.learnerProfile.status'})}
              middle={learner?.accountStatus}
            />
            <UserProfileRow
              left={intl.formatMessage({
                id: 'admin.learnerProfile.progress'
              })}
              middle={
                learner?.programProgress == null
                  ? DEFAULT_PROGRESS_PERCENTAGE
                  : PercentageFloatingPoint.format(learner.programProgress)
              }
            />
            <UserProfileRow
              left={intl.formatMessage({
                id: 'admin.learnerProfile.lastSync'
              })}
              middle={lastSyncDate ?? NO_VALUE}
            />
            <UserProfileRow
              noBorder
              left={intl.formatMessage({
                id: 'admin.learnerProfile.learnerCommunity'
              })}
              middle={learner?.communityName}
              right={
                <FlatButton
                  variant="secondary"
                  onClick={() => {
                    setError(null)
                    setSuccessMessage(null)
                    setEditLearnerDialogOpen(true)
                  }}
                >
                  <PencilIcon className="h-6 w-6" />
                  <span className="typography-headline-5">
                    <FormattedMessage id="general.edit" />
                  </span>
                </FlatButton>
              }
            />
          </UserProfileTable>
        </div>
      </div>
      <EditLearnerDialog
        open={editLearnerDialogOpen}
        onClose={() => setEditLearnerDialogOpen(false)}
        onSuccess={() => {
          setEditLearnerDialogOpen(false)
          refetchLearner()
          onSuccess?.(
            intl.formatMessage({
              id: 'admin.learnerProfile.migrateLearnerSuccess'
            })
          )
        }}
        onError={error => {
          setEditLearnerDialogOpen(false)
          setError(error)
        }}
        learner={learner}
      />
    </Layout>
  )
}

type EditLearnerDialogProps = {
  open: boolean
  onClose: () => void
  onError: (error: Error) => void
  onSuccess: () => void
  learner?: IndividualLearner
}

function EditLearnerDialog({
  open,
  onClose,
  onSuccess,
  onError,
  learner
}: EditLearnerDialogProps) {
  const dispatch = useDispatch<AppDispatch>()
  const communities = useSelector(getAllCommunities)
  const intl = useIntl()
  const formId = 'edit-learner'
  const [loading, setLoading] = useState(false)
  const [communityErrorMessage, setCommunityErrorMessage] = useState<
    string | null
  >(null)
  const [newCommunityId, setNewCommunityId] = useState<CommunityId | null>(null)
  const [migrateProgress, setMigrateProgress] = useState(false)
  const {busy, migrateLearnerToCommunity} = useMigrateLearnerToCommunity({
    onSuccess,
    onError
  })
  const lastSyncDate = useTimeRelativeToNow(learner?.lastSyncDate ?? null)

  useEffect(() => {
    setLoading(true)
    dispatch(getCommunitiesAction())
      .catch(onError)
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, onError])

  function handleFormSubmission() {
    let valid = true

    if (!Id.isValid(newCommunityId)) {
      setCommunityErrorMessage(
        intl.formatMessage({
          id: 'admin.learnerProfile.community.validationMessage'
        })
      )
      valid = false
    }

    if (newCommunityId === learner?.communityId) {
      setCommunityErrorMessage(
        intl.formatMessage({
          id: 'admin.learnerProfile.community.communityConflict'
        })
      )
      valid = false
    }

    if (valid && learner && newCommunityId) {
      migrateLearnerToCommunity(learner.id, newCommunityId, migrateProgress)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="flex flex-col items-center">
        <div className="-mt-6 mb-6 flex w-full items-center justify-between">
          <Dialog.Title className="typography-headline-4">
            <FormattedMessage id="admin.learnerProfile.editTitle" />
          </Dialog.Title>
          <XMarkIcon className="-mr-2 -mt-2 h-7 w-7" onClick={onClose} />
        </div>
        {loading && communities.length === 0 ? (
          <LoadingIndicator />
        ) : (
          <>
            <Divider />
            {learner && (
              <UserProfileTable>
                <UserProfileRow
                  compact
                  left={intl.formatMessage({id: 'learnerCard.firstName_label'})}
                  right={learner.firstName}
                />
                <UserProfileRow
                  compact
                  left={intl.formatMessage({id: 'learnerCard.lastName_label'})}
                  right={learner.lastName}
                />
                <UserProfileRow
                  compact
                  left={intl.formatMessage({
                    id: 'admin.userSettings.usernameLabel'
                  })}
                  right={learner.username}
                />
                <UserProfileRow
                  compact
                  left={intl.formatMessage({id: 'admin.learnerProfile.status'})}
                  right={learner.accountStatus}
                />
                <UserProfileRow
                  compact
                  left={intl.formatMessage({
                    id: 'admin.learnerProfile.progress'
                  })}
                  right={
                    learner?.programProgress == null
                      ? DEFAULT_PROGRESS_PERCENTAGE
                      : PercentageFloatingPoint.format(learner.programProgress)
                  }
                />
                <UserProfileRow
                  compact
                  left={intl.formatMessage({
                    id: 'admin.learnerProfile.lastSync'
                  })}
                  right={lastSyncDate ?? NO_VALUE}
                />
              </UserProfileTable>
            )}
            <form
              aria-label={intl.formatMessage({
                id: 'admin.learnerProfile.formLabel'
              })}
              name="edit-learner"
              id={formId}
              className="min-w-full"
              onSubmit={(event: FormEvent<HTMLFormElement>) => {
                event.preventDefault()
                handleFormSubmission()
              }}
              noValidate
            >
              <SelectInput
                errorMessage={communityErrorMessage}
                name="community"
                label={intl.formatMessage({
                  id: 'admin.learnerProfile.community.label'
                })}
                placeholderText={intl.formatMessage({
                  id: 'admin.learnerProfile.community.placeholder'
                })}
                options={communities.map(community => ({
                  id: community.id,
                  name: community.name
                }))}
                value={newCommunityId}
                onChange={selectedCommunityId => {
                  setNewCommunityId(selectedCommunityId as CommunityId)
                }}
              />
              <Divider />
              <div className="my-1">
                <ToggleInput
                  checked={migrateProgress}
                  description="admin.learnerProfile.moveProgress.description"
                  label="admin.learnerProfile.moveProgress.title"
                  onChange={setMigrateProgress}
                />
              </div>
              <Divider />
              <div className="mx-auto mt-2 flex w-full justify-end md:mt-4 lg:mt-6">
                <FlatButton
                  aria-label={intl.formatMessage({
                    id: 'admin.learnerProfile.migrateLearner'
                  })}
                  id="migrate-learner-button"
                  form={formId}
                  type="submit"
                  variant="primary"
                  disabled={busy}
                >
                  <FormattedMessage id="admin.learnerProfile.migrateLearner" />
                </FlatButton>
              </div>
            </form>
          </>
        )}
      </div>
    </Dialog>
  )
}

type ToastProps = {
  error?: Error | null
  successMessage?: string | null
}

function LearnerProfileToast({error, successMessage}: ToastProps) {
  return (
    <>
      {error && (
        <Toast variant="error">
          <FormattedMessage
            id={
              ServerError.isServerError(error) && error.status === 404
                ? 'learners.learnerNotFoundDescription'
                : 'general.GenericError'
            }
          />
        </Toast>
      )}
      {successMessage && <Toast variant="success">{successMessage}</Toast>}
    </>
  )
}

export default LearnerProfilePage
