import {Menu, Transition} from '@headlessui/react'
import {ArrowUpIcon, ChevronDownIcon} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import React, {ReactNode} from 'react'
import {FormattedMessage} from 'react-intl'
import FlatButton from '../FlatButton'
import {UserType} from './types'

type UserTypeSelectorProps = {
  onSelect: (value: UserType) => void
  selected?: UserType
}

export function UserTypeSelector({onSelect, selected}: UserTypeSelectorProps) {
  const options: ReadonlyArray<{id: UserType; name: string}> = [
    {
      id: 'learner',
      name: 'admin.userSettings.userType.learner'
    },
    {
      id: 'mentor',
      name: 'admin.userSettings.userType.mentor'
    }
  ]
  const selectedOption = options.find(option => option.id === selected)

  return (
    <div className="flex items-center justify-between py-2 px-2">
      <label className="typography-headline-5">
        <FormattedMessage id="admin.userSettings.userType.label" />
      </label>
      <div>
        <Menu>
          <Menu.Button className="headline typography-headline-5 bold relative flex w-52 cursor-default select-none items-center justify-between rounded-md bg-teal py-2 px-6 text-navy">
            <FormattedMessage
              id={
                selectedOption?.name ||
                'admin.userSettings.userType.placeholder'
              }
            />
            <ChevronDownIcon className="h-4 w-4 stroke-2" />
          </Menu.Button>
          <Menu.Items className="absolute z-10 mt-4 flex max-h-60 w-52 flex-col overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {options.map(option => (
              <Menu.Item key={option.id}>
                {({active}) => (
                  <a
                    className={clsx(
                      'headline typography-headline-5 relative cursor-default select-none py-2 pl-5 text-navy',
                      active && 'bg-steel-tertiary-10'
                    )}
                    onClick={() => onSelect(option.id)}
                  >
                    <FormattedMessage id={option.name} />
                  </a>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>
    </div>
  )
}

type Column<T> = {
  label: string
  value: (item: T) => ReactNode
}

type TableProps<T> = {
  columns: Column<T>[]
  rows: ReadonlyArray<T>
  isLoading?: boolean
}

export function Table<T>({isLoading, columns, rows}: TableProps<T>) {
  return (
    <div className="mt-8 overflow-x-auto rounded bg-white">
      <table
        className={`min-w-full divide-y divide-grey-tertiary-10 ${
          isLoading && 'animate-pulse'
        }`}
      >
        <thead>
          <tr className="typography-body-1 font-semibold">
            {columns.map((col, index) => (
              <th key={index} scope="col" className="p-4 text-left">
                {col.label && <FormattedMessage id={col.label} />}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="min-w-full">
          {rows.map((row, index) => (
            <TableRow<T>
              key={index}
              columns={columns}
              row={row}
              noBorder={rows.length - 1 === index}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

function TableRow<T>({
  columns,
  row,
  noBorder
}: {
  columns: Column<T>[]
  row: T
  noBorder?: boolean
}) {
  return (
    <tr
      className={clsx(
        'typography-headline-5',
        !noBorder && 'border-b border-grey-tertiary-10'
      )}
    >
      {columns.map((col, index) => (
        <td
          key={index}
          className="whitespace-nowrap px-4 py-2 text-navy-secondary sm:table-cell"
        >
          {col.value(row)}
        </td>
      ))}
    </tr>
  )
}

export function Footer({
  rowCount,
  defaultPageSize
}: {
  rowCount: number
  defaultPageSize: number
}) {
  return (
    <>
      {rowCount > 0 && (
        <>
          {rowCount >= defaultPageSize && (
            <div className="mt-4 flex items-center justify-between px-4">
              <p className="text-center">
                <FormattedMessage id="admin.userSettings.pageSizeExceeded" />
              </p>
              <FlatButton
                icon={<ArrowUpIcon aria-hidden className="h-6 w-6" />}
                onClick={() => window.scroll({top: 0, behavior: 'smooth'})}
              >
                <FormattedMessage id="general.top" />
              </FlatButton>
            </div>
          )}
        </>
      )}
      {rowCount === 0 && (
        <p className="text-center">
          <FormattedMessage id="admin.userSettings.searchPrompt" />
        </p>
      )}
    </>
  )
}

export function TransitionWrapper({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <Transition
      show
      appear
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={className}
    >
      {children}
    </Transition>
  )
}

export function FormContainer({children}: {children: ReactNode}) {
  return <div className="rounded bg-white px-4 py-2">{children}</div>
}
