import React from 'react'
import {FormattedMessage} from 'react-intl'

type Props = Readonly<{
  name: string
  label: string
  description?: string
  value: string
}>

function ReadOnlyField({label, description, value, name}: Props) {
  const id = name
  const labelId = `${id}-label`
  const labelElementId = `${id}-label`

  return (
    <div className="flex items-center justify-between p-2 text-navy">
      <div>
        <label
          className="typography-headline-5 block after:content-[':']"
          id={labelElementId}
          htmlFor={id}
        >
          <FormattedMessage id={label} />
        </label>
        {description && (
          <p className="typography-body-2">
            <FormattedMessage id={description} />
          </p>
        )}
      </div>
      <input
        aria-labelledby={labelId}
        className="w-full max-w-[640px] pl-3"
        disabled
        id={id}
        value={value}
      />
    </div>
  )
}

export default ReadOnlyField
