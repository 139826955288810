import {MagnifyingGlassIcon, PlusCircleIcon} from '@heroicons/react/24/outline'
import React, {useEffect, useRef, useState} from 'react'
import {FormattedDate, FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router'
import Community from '../../domain/community'
import {
  ADD_COMMUNITY_ROUTE,
  COMMUNITIES_ROUTE,
  COMMUNITY_ID_ROUTE_PLACEHOLDER,
  COMMUNITY_TOOL_SELECTOR_ROUTE,
  EVENT_ID_ROUTE_PLACEHOLDER,
  MESSAGE_SEARCH_PARAM,
  SELECTED_COMMUNITY_ID_SEARCH_PARAM,
  TOOL_SELECTOR_ROUTE
} from '../../global-constants/routingConstants'
import {getCommunitiesAction} from '../../store/communities/communities.action'
import {getAllCommunities} from '../../store/communities/communities.selectors'
import {
  Community as CommunityT,
  CommunityId
} from '../../store/communities/communities.type'
import {AppDispatch} from '../../store/store'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import Layout from '../Layout'
import InternalLink, {
  InternalLinkStyledAsFlatButton
} from '../links/InternalLink'
import TitleText from '../TitleText'
import Toast from '../Toast'

const CommunityTableRow = ({
  community,
  isNewCommunity
}: {
  community: CommunityT
  key: CommunityId
  isNewCommunity: boolean
}) => {
  const formattedName = Community.formatName(community)

  const rowRef = useRef<HTMLTableRowElement>(null)
  useEffect(() => {
    if (isNewCommunity && rowRef != null && rowRef.current != null) {
      rowRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }, [isNewCommunity])

  return (
    <tr
      className={isNewCommunity ? 'bg-green-tertiary-30/20' : ''}
      ref={isNewCommunity ? rowRef : null}
    >
      <th
        className="font-medium whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0"
        scope="row"
      >
        <span className="flex sm:hidden">
          <InternalLink
            to={`${COMMUNITY_TOOL_SELECTOR_ROUTE.replace(
              ':communityId',
              community.id
            ).replace(':eventId', community.currentEventId)}`}
          >
            <span>{formattedName}</span>
          </InternalLink>
        </span>
        <span className="hidden sm:flex">{formattedName}</span>
      </th>
      <td className="hidden whitespace-nowrap px-3 py-4 sm:table-cell">
        <InternalLink
          to={`${COMMUNITY_TOOL_SELECTOR_ROUTE.replace(
            COMMUNITY_ID_ROUTE_PLACEHOLDER,
            community.id
          ).replace(EVENT_ID_ROUTE_PLACEHOLDER, community.currentEventId)}`}
        >
          <span>
            <FormattedDate value={community.currentEventStartDate} />
            {` - `}
            <FormattedDate value={community.currentEventEndDate} />
          </span>
        </InternalLink>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-navy-secondary sm:table-cell">
        {`${community.sponsorCount}/${community.sponsorTotal}`}
      </td>
    </tr>
  )
}

function CommunitiesTable({
  communities,
  isLoading,
  selectedCommunityId
}: {
  communities: CommunityT[]
  isLoading: boolean
  error?: Error | null
  selectedCommunityId: string | null
}) {
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const filteredCommunities = communities.filter(community =>
    Community.formatName(community)
      .toLocaleLowerCase()
      .includes(searchTerm.toLocaleLowerCase())
  )

  return (
    <div className="typography-body-1 min-h-full px-4 sm:px-6">
      <div className="mt-8 flow-root">
        <div className="flex">
          <div className="mt-2 flex w-full">
            <div className="relative mt-2 w-full rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-grey-tertiary-20"
                  aria-hidden="true"
                />
              </div>
              <input
                onChange={({target: {value: newSearchTerm}}) =>
                  setSearchTerm(newSearchTerm)
                }
                type="text"
                name="search"
                id="search"
                value={searchTerm || undefined}
                className="sm:text-sm block w-full rounded-md border-0 py-1.5 pl-10 text-navy outline-none ring-1 ring-inset ring-grey-tertiary-10 placeholder:text-grey-tertiary-20 focus:outline-none focus:ring-inset focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-1
             focus-visible:ring-grey-tertiary-10 focus-visible:ring-offset-1 focus-visible:ring-offset-grey-tertiary-10 sm:leading-6"
                placeholder={intl.formatMessage({
                  id: 'admin.communities.search'
                })}
              />
            </div>
          </div>
        </div>
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table
              className={`min-w-full divide-y divide-grey-tertiary-20 ${
                isLoading && 'animate-pulse'
              }`}
            >
              <thead>
                <tr className="typography-body-1 font-semibold">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left sm:pl-0"
                  >
                    <FormattedMessage id="manageCommunity.community_name" />
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left sm:table-cell"
                  >
                    <FormattedMessage id="manageCommunity.event_date" />
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left sm:table-cell"
                  >
                    <FormattedMessage id="manageCommunity.community_sponsors" />
                  </th>
                </tr>
              </thead>
              <tbody className="min-w-full divide-y divide-grey-tertiary-10">
                {filteredCommunities.map((community: CommunityT) => {
                  return (
                    <CommunityTableRow
                      isNewCommunity={selectedCommunityId === community.id}
                      community={community}
                      key={community.name}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const CommunitiesPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const communities = useSelector(getAllCommunities)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const selectedCommunityId = searchParams.get(
    SELECTED_COMMUNITY_ID_SEARCH_PARAM
  )
  const message = searchParams.get(MESSAGE_SEARCH_PARAM)

  useEffect(() => {
    setLoading(true)
    dispatch(getCommunitiesAction())
      .catch(setError)
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch])

  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="manageCommunity.community_manager" />
        </TitleText>
      }
      toast={
        selectedCommunityId &&
        message && (
          <Toast>
            {message === 'created' && (
              <FormattedMessage id="admin.communities.add.success" />
            )}
            {message === 'updated' && (
              <FormattedMessage id="admin.communities.edit.success" />
            )}
          </Toast>
        )
      }
    >
      <div className="mx-auto w-full justify-center px-4 sm:px-6 lg:w-8/12 lg:px-8">
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={COMMUNITIES_ROUTE}
            to={COMMUNITIES_ROUTE}
            label={<FormattedMessage id="manageCommunity.community_manager" />}
          />
        </Breadcrumb>
        <div className="max-w-full pt-4 sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h3 className="typography-headline-4 font-bold inline">
              <FormattedMessage id="admin.communities.select" />
            </h3>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <InternalLinkStyledAsFlatButton
              icon={<PlusCircleIcon className="h-6 w-6" />}
              to={ADD_COMMUNITY_ROUTE}
              variant="primary"
            >
              <FormattedMessage id="admin.communities.add.title" />
            </InternalLinkStyledAsFlatButton>
          </div>
        </div>
        <div className="-mx-4 mt-8 rounded bg-white sm:-mx-0">
          <CommunitiesTable
            communities={communities}
            error={error}
            isLoading={loading}
            selectedCommunityId={selectedCommunityId}
          />
        </div>
      </div>
    </Layout>
  )
}

export default CommunitiesPage
