export const A000 = 'A000'
export const A001 = 'A001'
export const A002 = 'A002'
export const A003 = 'A003'
export const A004 = 'A004'
export const A005 = 'A005'
export const A006 = 'A006'
export const A007 = 'A007'
export const A008 = 'A008'
export const A009 = 'A009'
export const A010 = 'A010'
export const A011 = 'A011'
export const A012 = 'A012'
export const A013 = 'A013'
export const A014 = 'A014'
export const A015 = 'A015'
export const A016 = 'A016'
export const A017 = 'A017'
export const A018 = 'A018'
export const A019 = 'A019'
export const A020 = 'A020'
export const A021 = 'A021'
export const A022 = 'A022'
export const A023 = 'A023'
export const A024 = 'A024'
export const A025 = 'A025'
export const A026 = 'A026'
export const A027 = 'A027'
export const A028 = 'A028'
export const A029 = 'A029'
export const A030 = 'A030'
export const A031 = 'A031'
export const A032 = 'A032'
export const A033 = 'A033'
export const A034 = 'A034'
export const A035 = 'A035'
export const A036 = 'A036'
export const A037 = 'A037'
export const A038 = 'A038'
export const A039 = 'A039'
export const A040 = 'A040'
export const A041 = 'A041'
export const A042 = 'A042'
export const A043 = 'A043'
export const A044 = 'A044'
export const A045 = 'A045'
export const A046 = 'A046'
export const A047 = 'A047'
export const A048 = 'A048'
export const A049 = 'A049'
export const A050 = 'A050'
export const A051 = 'A051'
export const A052 = 'A052'
export const A053 = 'A053'
export const A054 = 'A054'
export const A055 = 'A055'
export const A056 = 'A056'
export const A057 = 'A057'
export const A058 = 'A058'
export const A059 = 'A059'
export const A060 = 'A060'
export const A061 = 'A061'
export const A062 = 'A062'
export const A063 = 'A063'
export const A064 = 'A064'
export const A065 = 'A065'
export const A066 = 'A066'
export const A067 = 'A067'
export const A068 = 'A068'
export const A069 = 'A069'
export const A070 = 'A070'
export const A071 = 'A071'
export const A072 = 'A072'
export const A073 = 'A073'
export const A074 = 'A074'
export const A075 = 'A075'
export const A076 = 'A076'
export const A077 = 'A077'
export const A078 = 'A078'
export const A079 = 'A079'
export const A080 = 'A080'
export const A081 = 'A081'
export const A082 = 'A082'
export const A083 = 'A083'
export const A084 = 'A084'
export const A085 = 'A085'
export const A086 = 'A086'
export const A087 = 'A087'
export const A088 = 'A088'
export const A089 = 'A089'
export const A090 = 'A090'
export const A091 = 'A091'
export const A092 = 'A092'
export const A093 = 'A093'
export const A094 = 'A094'
export const A095 = 'A095'
export const A096 = 'A096'
export const A097 = 'A097'
export const A098 = 'A098'
export const A099 = 'A099'
export const A100 = 'A100'
