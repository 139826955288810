import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {API_URL} from '../config'
import Mentor, {Mentor as MentorT} from '../domain/mentor'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

export type MentorQuery = {
  firstName?: string
  lastName?: string
  email?: string
  page?: number
  pageSize?: number
}

type Options = {
  enabled?: boolean
  initialQuery?: MentorQuery
  onSuccess?: (mentors: ReadonlyArray<MentorT>) => void
}

function useMentors({enabled = true, initialQuery, onSuccess}: Options = {}): {
  error: Error | null
  isLoading: boolean
  mentors: ReadonlyArray<MentorT> | undefined
  refetchMentors: (alternateQuery?: MentorQuery) => void
} {
  const accessToken = useSelector(getAccessToken)
  const [mentors, setMentors] = useState<ReadonlyArray<MentorT> | undefined>()
  const [error, setError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [defaultQuery] = useState(initialQuery)

  const fetchMentors = useCallback(
    (alternateQuery?: MentorQuery) => {
      setError(null)
      setIsLoading(true)
      getMentors(accessToken, alternateQuery || defaultQuery)
        .then(mentors => {
          setMentors(mentors)
          onSuccess?.(mentors)
        })
        .catch(error => {
          captureException(error)
          setError(error)
        })
        .finally(() => setIsLoading(false))
    },
    [accessToken, defaultQuery, onSuccess]
  )

  useEffect(() => {
    if (enabled) {
      fetchMentors()
    }
  }, [enabled, fetchMentors])

  return {error, isLoading, mentors, refetchMentors: fetchMentors}
}

async function getMentors(
  accessToken: string,
  query?: MentorQuery
): Promise<ReadonlyArray<MentorT>> {
  const url = new URL('adults', API_URL)
  if (query) {
    const searchParams = parseSearchParamsFrom(query)
    searchParams.forEach((value, key) => url.searchParams.append(key, value))
  }
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  })

  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }

  const body = await response.json()

  return parseResponseBody(body)
}

export function parseResponseBody(candidate: unknown): ReadonlyArray<MentorT> {
  if (!Array.isArray(candidate)) {
    throw new Error('Unexpected response body. Expected an array of Mentors.')
  }

  try {
    return candidate.map(Mentor.parse)
  } catch (error) {
    throw new Error(
      `Unexpected response body. Expected an array of Mentors. ${error.message}`,
      {cause: error}
    )
  }
}

export function parseSearchParamsFrom(query: MentorQuery) {
  const searchParams = new URLSearchParams()
  if (query.firstName) {
    searchParams.append('firstName', `${query.firstName}`)
  }
  if (query.lastName) {
    searchParams.append('lastName', `${query.lastName}`)
  }
  if (query.email) {
    searchParams.append('email', `${query.email}`)
  }
  if (query.page) {
    searchParams.append('page', `${query.page}`)
  }
  if (query.pageSize) {
    searchParams.append('pageSize', `${query.pageSize}`)
  }
  return searchParams
}

export default useMentors
