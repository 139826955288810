import {EnvelopeIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import {ExclamationTriangleIcon} from '@heroicons/react/24/solid'
import clsx from 'clsx'
import React, {FormEvent, useState} from 'react'
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl
} from 'react-intl'
import {CommunityId} from '../../domain/community'
import Event, {EventId} from '../../domain/event'
import useDeleteEvent from '../../hooks/useDeleteEvent'
import useRolloverNotification from '../../hooks/useRolloverNotification'
import useUpdateCommunityCurrentEvent from '../../hooks/useUpdateCommunityCurrentEvent'
import useUpdateEvent from '../../hooks/useUpdateEvent'
import {isNonBlankString} from '../../responseRefiners'
import DateRangePicker from '../DateRangePicker'
import DateTimeDisplay from '../DateTimeDisplay'
import Dialog from '../Dialog'
import FlatButton from '../FlatButton'
import RollOverIcon from '../icons/RollOverIcon'
import ValidationErrorMessage from '../ValidationErrorMessage'

type EventRowVariant = 'current' | 'future'

type Props = {
  event: Event
  onDeleteError?: (error: Error) => void
  onDeleteSuccess?: (message: string) => void
  onRollOverError?: (error: Error) => void
  onRollOverSuccess?: (message: string) => void
  onUpdateError?: (error: Error) => void
  onUpdateSuccess?: (message: string) => void
  onRolloverNotificationError?: (error: Error) => void
  onRolloverNotificationSuccess?: (message: string) => void
  variant?: EventRowVariant
}

function EventRow({
  event,
  onDeleteError,
  onDeleteSuccess,
  onRollOverError,
  onRollOverSuccess,
  onUpdateError,
  onUpdateSuccess,
  onRolloverNotificationError,
  onRolloverNotificationSuccess,
  variant = 'future'
}: Props) {
  const intl = useIntl()

  const [rollOverConfirmationDialogOpen, setRollOverConfirmationDialogOpen] =
    useState(false)
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [rolloverNotificationDialogOpen, setRolloverNotificationDialogOpen] =
    useState(false)

  return (
    <>
      <div className="flex w-full items-center justify-between rounded bg-white p-4 shadow-md">
        <div className="flex items-center space-x-4">
          <DateTimeDisplay
            displayValue={intl.formatDate(event.startDate, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}
          />
          <DateTimeDisplay displayValue={intl.formatTime(event.startDate)} />
          <div>
            <FormattedMessage id="admin.events.toDate" />
          </div>
          <DateTimeDisplay
            displayValue={intl.formatDate(event.endDate, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}
          />
          <DateTimeDisplay displayValue={intl.formatTime(event.endDate)} />
        </div>
        <div className="flex items-center space-x-4">
          {variant === 'current' ? (
            <FlatButton
              aria-label="Edit event"
              icon={<PencilIcon className="h-6 w-6" />}
              title="Edit"
              onClick={() => setEditDialogOpen(true)}
              type="button"
            >
              <FormattedMessage id="admin.events.edit" />
            </FlatButton>
          ) : (
            <>
              <FlatButton
                aria-label="Edit event"
                icon={<PencilIcon className="h-6 w-6" />}
                title="Edit"
                onClick={() => setEditDialogOpen(true)}
                type="button"
              />
              <FlatButton
                aria-label={intl.formatMessage({
                  id: 'admin.events.rollOverTitle'
                })}
                icon={<RollOverIcon />}
                onClick={() => setRollOverConfirmationDialogOpen(true)}
                title={intl.formatMessage({
                  id: 'admin.events.rollOverTitle'
                })}
                type="button"
                variant="success"
              />
              <FlatButton
                aria-label={intl.formatMessage({
                  id: 'admin.events.rolloverNotificationTitle'
                })}
                icon={<EnvelopeIcon className="h-6 w-6" />}
                onClick={() => setRolloverNotificationDialogOpen(true)}
                title={intl.formatMessage({
                  id: 'admin.events.rolloverNotificationTitle'
                })}
                type="button"
                variant="success"
              />
              <FlatButton
                aria-label={intl.formatMessage({
                  id: 'admin.events.deleteConfirmationTitle'
                })}
                icon={<TrashIcon className="h-6 w-6" />}
                onClick={() => setDeleteConfirmationDialogOpen(true)}
                title={intl.formatMessage({
                  id: 'admin.events.deleteConfirmationTitle'
                })}
                type="button"
                variant="danger"
              />
            </>
          )}
        </div>
      </div>
      <RollOverConfirmationDialog
        communityId={event.communityId}
        event={event}
        onClose={() => setRollOverConfirmationDialogOpen(false)}
        onError={error => onRollOverError?.(error)}
        onSuccess={() =>
          onRollOverSuccess?.(
            intl.formatMessage({id: 'admin.events.rollOverSuccessMessage'})
          )
        }
        open={rollOverConfirmationDialogOpen}
      />
      <EditEventDialog
        onClose={() => setEditDialogOpen(false)}
        open={editDialogOpen}
        onError={error => onUpdateError?.(error)}
        onSuccess={() => {
          onUpdateSuccess?.(
            intl.formatMessage({id: 'admin.events.eventUpdateSuccess'})
          )
        }}
        event={event}
      />
      <DeleteConfirmationDialog
        eventId={event.id}
        onClose={() => setDeleteConfirmationDialogOpen(false)}
        onError={error => onDeleteError?.(error)}
        onSuccess={() => {
          onDeleteSuccess?.(
            intl.formatMessage({id: 'admin.events.deleteSuccessMessage'})
          )
        }}
        open={deleteConfirmationDialogOpen}
      />
      <RolloverNotificationDialog
        event={event}
        onClose={() => setRolloverNotificationDialogOpen(false)}
        onError={error => {
          onRolloverNotificationError?.(error)
        }}
        onSuccess={() =>
          onRolloverNotificationSuccess?.(
            intl.formatMessage({id: 'admin.events.eventNotificationSuccessful'})
          )
        }
        open={rolloverNotificationDialogOpen}
      />
    </>
  )
}

function DateConfirmationDisplay({event}: Readonly<{event: Event}>) {
  return (
    <div className="mx-auto w-fit rounded bg-green/20 p-3 text-left">
      <span className="block">
        <FormattedMessage id="admin.events.eventDates" />
      </span>
      <div className="py-3">
        <span className="inline-block">
          <span className="mr-3 rounded bg-steel-tertiary-10 p-2">
            <FormattedDate
              value={event.startDate}
              day="2-digit"
              year="numeric"
              month="2-digit"
            />
          </span>
          <span className="mr-3 rounded bg-steel-tertiary-10 p-2">
            <FormattedTime value={event.startDate} />
          </span>
          <FormattedMessage id="admin.events.toDate" />
          <span className="mx-3 rounded bg-steel-tertiary-10 p-2">
            <FormattedDate
              value={event.endDate}
              day="2-digit"
              year="numeric"
              month="2-digit"
            />
          </span>
          <span className="rounded bg-steel-tertiary-10 p-2">
            <FormattedTime value={event.endDate} />
          </span>
        </span>
      </div>
    </div>
  )
}

type RollOverConfirmationDialogProps = Readonly<{
  communityId: CommunityId
  event: Event
  onClose: () => void
  onError: (error: Error) => void
  onSuccess: () => void
  open: boolean
}>

function RollOverConfirmationDialog({
  communityId,
  event,
  onClose,
  onError,
  onSuccess,
  open
}: RollOverConfirmationDialogProps) {
  const {busy, updateCommunityCurrentEvent} = useUpdateCommunityCurrentEvent({
    onError,
    onSuccess
  })

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Title
        className="typography-headline-4"
        id="rollover-dialog-heading"
      >
        <FormattedMessage id="admin.events.rollOverTitle" />
      </Dialog.Title>
      <Dialog.Description
        className="typography-headline-6 mt-8 rounded-md text-center"
        id="rollover-dialog-description"
      >
        <span className="inline-flex flex-col items-center space-y-4">
          <span>
            <FormattedMessage id="admin.events.rollOverConfirmationDescription1" />
          </span>
          <span className="inline-flex items-center">
            <span className="mr-2 block">
              <ExclamationTriangleIcon className="h-8 w-8" />
            </span>
            <span>
              <FormattedMessage id="admin.events.rollOverConfirmationDescription2" />
            </span>
          </span>
        </span>
        <span className="mt-8 inline-block">
          <FormattedMessage id="admin.events.rollOverConfirmationDescription3" />
        </span>
      </Dialog.Description>
      <div
        className="typography-headline-6 mx-auto mt-8 w-fit rounded p-3 text-left"
        id="rollover-dialog-event-dates"
      >
        <DateConfirmationDisplay event={event} />
      </div>
      <div
        className="mt-8 flex justify-center gap-10"
        id="rollover-dialog-buttons"
      >
        <div className="w-1/4">
          <FlatButton
            onClick={onClose}
            type="button"
            variant="success"
            width="full"
          >
            <FormattedMessage id="general.cancel" />
          </FlatButton>
        </div>
        <div className="w-1/4">
          <FlatButton
            disabled={busy}
            onClick={() => {
              updateCommunityCurrentEvent(communityId, event.id)
              onClose()
            }}
            type="button"
            width="full"
            variant="success"
          >
            <FormattedMessage id="general.confirm" />
          </FlatButton>
        </div>
      </div>
    </Dialog>
  )
}

type EditEventDialogProps = Readonly<{
  event: Event
  open: boolean
  onClose: () => void
  onError: (error: Error) => void
  onSuccess: () => void
}>

function EditEventDialog({
  event,
  onClose,
  onError,
  onSuccess,
  open
}: EditEventDialogProps) {
  const [startDate, setStartDate] = useState(event.startDate)
  const [endDate, setEndDate] = useState(event.endDate)
  const intl = useIntl()
  const [dateRangeErrorMessage, setDateRangeErrorMessage] = useState<
    string | null
  >(null)
  const {updateEvent} = useUpdateEvent({onSuccess, onError})

  const formId = 'edit-event'
  function handleFormSubmission() {
    let valid = true

    if (startDate > endDate) {
      setDateRangeErrorMessage(
        intl.formatMessage({
          id: 'admin.add_community.dates.validation_message'
        })
      )
      valid = false
    }

    if (endDate < new Date()) {
      setDateRangeErrorMessage(
        intl.formatMessage({
          id: 'admin.events.eventDatesPastValidation'
        })
      )
      valid = false
    }

    if (valid) {
      updateEvent(
        Event.parse({
          id: event.id,
          communityId: event.communityId,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          current: event.current
        })
      )
      handleClose()
    }
  }

  function clearDateRangeValidationErrors() {
    setDateRangeErrorMessage(null)
  }

  function handleClose() {
    clearDateRangeValidationErrors()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Dialog.Title className="typography-headline-4 block">
        <FormattedMessage id="admin.events.editTitle" />
      </Dialog.Title>
      <Dialog.Description className="typography-headline-6 mt-8 space-y-4 text-center">
        <FormattedMessage id="admin.events.editDescription" />
      </Dialog.Description>
      <div className="mx-auto mt-8 flex justify-center gap-10">
        <form
          className="w-full"
          aria-label={intl.formatMessage({
            id: 'admin.events.editTitle'
          })}
          name="edit-event"
          id={formId}
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleFormSubmission()
          }}
        >
          <DateRangePicker
            errorMessage={dateRangeErrorMessage}
            label={intl.formatMessage({id: 'admin.events.eventDate'})}
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={newStartDate => setStartDate(newStartDate)}
            onEndDateChange={newEndDate => setEndDate(newEndDate)}
          />
          <div className="mt-8 flex items-center justify-center gap-10">
            <div className="w-1/4">
              <FlatButton
                variant="success"
                onClick={handleClose}
                width="full"
                type="button"
              >
                <FormattedMessage id="general.cancel" />
              </FlatButton>
            </div>
            <div className="w-1/4">
              <FlatButton
                disabled={false}
                variant="success"
                form={formId}
                type="submit"
                width="full"
              >
                <FormattedMessage id="general.save" />
              </FlatButton>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  )
}

type DeleteConfirmationDialogProps = Readonly<{
  eventId: EventId
  onClose: () => void
  onError: (error: Error) => void
  onSuccess: () => void
  open: boolean
}>

function DeleteConfirmationDialog({
  eventId,
  onClose,
  onError,
  onSuccess,
  open
}: DeleteConfirmationDialogProps) {
  const {busy, deleteEvent} = useDeleteEvent({
    onError,
    onSuccess
  })

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Title className="typography-headline-4">
        <FormattedMessage id="admin.events.deleteConfirmationTitle" />
      </Dialog.Title>
      <Dialog.Description className="typography-headline-6 mt-8 space-y-8 rounded-md bg-green/20 px-6 py-10 text-center">
        <FormattedMessage id="admin.events.deleteConfirmationDescription1" />
        <br aria-hidden />
        <FormattedMessage id="admin.events.deleteConfirmationDescription2" />
      </Dialog.Description>
      <ValidationErrorMessage id=""></ValidationErrorMessage>
      <div className="mx-auto mt-8 flex justify-center gap-10">
        <div className="w-1/4">
          <FlatButton variant="success" onClick={onClose} width="full">
            <FormattedMessage id="general.cancel" />
          </FlatButton>
        </div>
        <div className="w-1/4">
          <FlatButton
            disabled={busy}
            variant="success"
            onClick={() => {
              deleteEvent(eventId)
              onClose()
            }}
            width="full"
          >
            <FormattedMessage id="general.confirm" />
          </FlatButton>
        </div>
      </div>
    </Dialog>
  )
}

type RolloverNotificationDialogProps = Readonly<{
  event: Event
  onClose: () => void
  onError: (error: Error) => void
  onSuccess: () => void
  open: boolean
}>

function RolloverNotificationDialog({
  event,
  onClose,
  onError,
  onSuccess,
  open
}: RolloverNotificationDialogProps) {
  const intl = useIntl()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const isError = isNonBlankString(errorMessage)
  const [rolloverDate, setRolloverDate] = useState(
    new Date(new Date(event.startDate).getFullYear(), 0, 1)
  )

  const {isBusy, rolloverNotification} = useRolloverNotification({
    communityId: event.communityId,
    onSuccess,
    onError
  })

  function getFormattedDate(dateValue: Date) {
    const dateTimeLocalValue = new Date(
      dateValue.getTime() - dateValue.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, -1)
    return dateTimeLocalValue.substring(
      0,
      dateTimeLocalValue.indexOf(':', dateTimeLocalValue.indexOf(':') + 1)
    )
  }

  function handleFormSubmission() {
    setErrorMessage(null)
    rolloverNotification(event.id, rolloverDate)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Title className="typography-headline-4">
        <FormattedMessage id="admin.events.rolloverNotificationTitle" />
      </Dialog.Title>
      <Dialog.Description className="typography-headline-6 mt-6 rounded-md px-6 py-10 text-center">
        <span className="inline-block">
          <FormattedMessage id="admin.events.rolloverNotificationDescription" />
        </span>
      </Dialog.Description>
      <div className="typography-headline-6 space-y-8">
        <DateConfirmationDisplay event={event} />
        <form
          className="typography-headline-6 mx-auto flex w-fit items-center justify-between space-x-4"
          aria-label={intl.formatMessage({
            id: 'admin.events.rolloverNotificationTitle'
          })}
          name="create-event"
          id="rollover-notification-form"
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleFormSubmission()
          }}
        >
          <label htmlFor="rollover-date">
            <FormattedMessage id="admin.events.eventRolloverDate" />
          </label>
          <input
            aria-describedby="rollover-date-error"
            aria-errormessage="rollover-date-error"
            aria-invalid={isError}
            type="datetime-local"
            name="rollover-date"
            id="rollover-date"
            className={clsx(
              isError ? 'ring-red' : 'ring-steel-tertiary-20',
              `rounded-md border-0 bg-white py-1.5 pl-10 text-navy shadow-sm outline-none ring-2 ring-inset placeholder:text-navy
                focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-green`
            )}
            value={getFormattedDate(rolloverDate)}
            onChange={event => {
              setErrorMessage(null)
              const value = event.target.value
              const date = new Date(value)
              setRolloverDate(isDateValid(date) ? date : rolloverDate)
            }}
          />
        </form>
        <ValidationErrorMessage id="rollover-date-error">
          {errorMessage}
        </ValidationErrorMessage>
      </div>
      <div className="mx-auto mt-16 flex justify-center gap-10">
        <div className="w-1/4">
          <FlatButton variant="success" onClick={onClose} width="full">
            <FormattedMessage id="general.cancel" />
          </FlatButton>
        </div>
        <div className="w-1/4">
          <FlatButton
            disabled={isBusy}
            type="submit"
            form="rollover-notification-form"
            variant="success"
            width="full"
          >
            <FormattedMessage id="learners.guardianChange_confirm" />
          </FlatButton>
        </div>
      </div>
    </Dialog>
  )
}

function isDateValid(date: Date) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !isNaN(date)
}

export default EventRow
