import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {ENV} from './config'
import './index.css'
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')

if (!container) {
  throw new Error('Missing container DOM node')
}

Sentry.init({
  dsn: 'https://64dfdea228784af9931c32cbea04939a@sentry.io/1760670',
  enabled: ENV === 'production',
  release: process.env.REACT_APP_VERSION
})

ReactDOM.render(<App />, container)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
