import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router'
import {CHECK_MAIL_ROUTE} from '../../global-constants/routingConstants'
import {userForgotPassword} from '../../store/forgot-password/forgot-password.action'
import {AppDispatch} from '../../store/store'
import ForgotPasswordForm, {Payload} from '../components/ForgotPasswordForm'

function ForgotPasswordFormContainer({
  history,
  location: {state}
}: RouteComponentProps<Record<string, string>>) {
  const dispatch = useDispatch<AppDispatch>()
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)

  function handleSubmit(payload: Payload) {
    setSubmitting(true)
    setError(null)
    dispatch(userForgotPassword(payload))
      .then(() => {
        setSubmitting(false)
        history.push(CHECK_MAIL_ROUTE)
      })
      .catch(err => {
        setSubmitting(false)
        setError(err)
        console.error(err)
      })
  }

  return (
    <ForgotPasswordForm
      error={error}
      submitting={submitting}
      onSubmit={payload => handleSubmit(payload)}
      onClearError={() => setError(null)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      locationState={state}
    />
  )
}

export default withRouter(ForgotPasswordFormContainer)
