import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import Community, {CommunityId} from '../domain/community'
import ZipPill from '../domain/zipPill'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {isPlainObject} from '../responseRefiners'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type ResponseBody = Readonly<{
  community: Community
  zips: ReadonlyArray<ZipPill>
}>

type Options = Readonly<{
  communityId: CommunityId
  onError?: (error: Error) => void
  onSuccess?: (community: Community) => void
}>

function useCommunity({communityId, onError, onSuccess}: Options): {
  error: Error | null
  isLoading: boolean
  community: Community | undefined
  zips: ReadonlyArray<ZipPill> | undefined
} {
  const accessToken = useSelector(getAccessToken)
  const [community, setCommunity] = useState<Community | undefined>(undefined)
  const [zips, setZips] = useState<ReadonlyArray<ZipPill> | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchCommunity = useCallback(() => {
    setError(null)
    setIsLoading(true)
    getCommunity(accessToken, communityId)
      .then(({community, zips}) => {
        setCommunity(community)
        setZips(zips)
        onSuccess?.(community)
      })
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setIsLoading(false))
  }, [accessToken, communityId, onSuccess, onError])

  useEffect(() => {
    fetchCommunity()
  }, [fetchCommunity])

  return {error, isLoading, community, zips}
}

async function getCommunity(
  accessToken: string,
  communityId: CommunityId
): Promise<ResponseBody> {
  const url = new URL(`communities/${communityId}`, API_URL)
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    }
  })

  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }

  const body = await response.json()

  return parseResponseBody(body)
}

export function parseResponseBody(candidate: unknown): ResponseBody {
  if (!isPlainObject(candidate)) {
    throw new Error(
      `Unexpected Community response ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }, expected Community with ZipPill array`
    )
  }

  const zipPills = parseZipPills(candidate)
  const communityCandidate = Object.assign({}, candidate, {
    zips: zipPills.map(zip => zip.code)
  })

  return {
    community: Community.parse(communityCandidate),
    zips: zipPills
  }
}

function parseZipPills(candidate: unknown): ReadonlyArray<ZipPill> {
  if (!isPlainObject(candidate) || !Array.isArray(candidate.zips)) {
    throw new Error(
      `Unexpected Community response ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }, expected Community with ZipPill array`
    )
  }

  return candidate.zips.map(ZipPill.parse)
}

export default useCommunity
