import React from 'react'

type Props = {
  title: string
}

function HeadingGraphic({title}: Props) {
  return (
    <div className="min-h-60 flex w-full min-w-full items-center justify-center bg-[url('/public/heading-banner.svg')] bg-no-repeat">
      <h2 className="typography-alt-headline-mobile md:typography-alt-headline text-center">
        {title}
      </h2>
    </div>
  )
}

export default HeadingGraphic
