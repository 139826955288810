import React from 'react'
import {FormattedMessage} from 'react-intl'

type MentorCodeBannerProps = Readonly<{
  mentorCode: string
}>

function MentorCodeBanner({mentorCode}: MentorCodeBannerProps) {
  return (
    <section
      className="
        flex w-full flex-row justify-center space-x-4 bg-teal-tertiary-10 p-4
      "
    >
      <Pill mentorCode={mentorCode} />
    </section>
  )
}

type PillProps = Readonly<{
  mentorCode: string
}>

const MENTOR_CODE_LABEL_ID = 'mentor-code-label'

function Pill({mentorCode}: PillProps) {
  return (
    <div className="flex flex-row">
      <h2
        id={MENTOR_CODE_LABEL_ID}
        className="
          typography-subtitle-2 block rounded-l-full bg-mustard px-4 py-2 text-navy
        "
      >
        <FormattedMessage id="learners.mentorCode.label" />
      </h2>
      <span
        aria-labelledby={MENTOR_CODE_LABEL_ID}
        className="
          typography-subtitle-2 block rounded-r-full bg-white px-4 py-2 text-navy
        "
      >
        {mentorCode}
      </span>
    </div>
  )
}

export default MentorCodeBanner
