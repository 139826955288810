import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {API_URL} from '../config'
import {AdultId} from '../domain/adult'
import Learner, {ListedLearner} from '../domain/learner'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

export type LearnerQuery = {
  firstName?: string
  lastName?: string
  username?: string
  mentorId?: AdultId
  page?: number
  pageSize?: number
}

type Options = Readonly<{
  enabled?: boolean
  initialQuery?: LearnerQuery
  onSuccess?: (learners: ReadonlyArray<ListedLearner>) => void
}>

function useLearners({enabled = true, onSuccess, initialQuery}: Options = {}): {
  error: Error | null
  isLoading: boolean
  learners: ReadonlyArray<ListedLearner> | undefined
  refetchLearners: (alternateQuery?: LearnerQuery) => void
} {
  const accessToken = useSelector(getAccessToken)
  const [learners, setLearners] = useState<
    ReadonlyArray<ListedLearner> | undefined
  >(undefined)
  const [error, setError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [defaultQuery] = useState(initialQuery)

  const fetchLearners = useCallback(
    (alternateQuery?: LearnerQuery) => {
      setError(null)
      setIsLoading(true)
      getLearners(accessToken, alternateQuery || defaultQuery)
        .then(learners => {
          setLearners(learners)
          onSuccess?.(learners)
        })
        .catch(error => {
          captureException(error)
          setError(error)
        })
        .finally(() => setIsLoading(false))
    },
    [accessToken, defaultQuery, onSuccess]
  )

  useEffect(() => {
    if (enabled) {
      fetchLearners()
    }
  }, [enabled, fetchLearners])

  return {error, isLoading, learners, refetchLearners: fetchLearners}
}

async function getLearners(
  accessToken: string,
  query?: LearnerQuery
): Promise<ReadonlyArray<ListedLearner>> {
  const url = new URL('learners', API_URL)
  if (query) {
    const searchParams = parseSearchParamsFrom(query)
    searchParams.forEach((value, key) => url.searchParams.append(key, value))
  }
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  })

  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }

  const body = await response.json()

  return parseResponseBody(body)
}

export function parseResponseBody(
  candidate: unknown
): ReadonlyArray<ListedLearner> {
  if (!Array.isArray(candidate)) {
    throw new Error('Unexpected response body. Expected an array of Learners.')
  }

  try {
    return candidate.map(Learner.parseListedLearner)
  } catch (error) {
    throw new Error(
      `Unexpected response body. Expected an array of Learners. ${error.message}`,
      {cause: error}
    )
  }
}

export function parseSearchParamsFrom(query: LearnerQuery): URLSearchParams {
  const searchParams = new URLSearchParams()
  if (query.firstName) {
    searchParams.append('firstName', `${query.firstName}`)
  }
  if (query.lastName) {
    searchParams.append('lastName', `${query.lastName}`)
  }
  if (query.username) {
    searchParams.append('username', `${query.username}`)
  }
  if (query.mentorId) {
    searchParams.append('mentorId', `${query.mentorId}`)
  }
  if (query.page) {
    searchParams.append('page', `${query.page}`)
  }
  if (query.pageSize) {
    searchParams.append('pageSize', `${query.pageSize}`)
  }
  return searchParams
}

export default useLearners
