import React, {FormEvent, useRef, useState} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {FormattedMessage, useIntl} from 'react-intl'
import AccountDeletionRequest from '../../domain/accountDeletionRequest'
import ServerError from '../../errors/server'
import ReCaptcha from '../../legacy/components/ReCaptcha'
import {isNonBlankString} from '../../responseRefiners'
import FlatButton from '../FlatButton'
import TextArea from '../TextArea'
import AdminTextInput from '../TextInput/AdminTextInput'

type Props = Readonly<{
  busy?: boolean
  submitError: Error | null
  onSubmit: (accountDeletionRequest: AccountDeletionRequest) => void
}>

function DeleteAccountForm({busy = false, submitError, onSubmit}: Props) {
  const intl = useIntl()

  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [identifier, setIdentifier] = useState('')
  const [identifierError, setIdentifierError] = useState<string | null>(null)
  const [recaptcha, setRecaptcha] = useState<string | null>('')
  const [recaptchaError, setRecaptchaError] = useState<string | null>(null)
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState<string | null>(null)

  function handleSubmit() {
    let valid = true
    if (!isNonBlankString(identifier)) {
      valid = false
      setIdentifierError(
        intl.formatMessage({id: 'account.delete.identifierError'})
      )
    }
    if (!isNonBlankString(message)) {
      valid = false
      setMessageError(intl.formatMessage({id: 'account.delete.messageError'}))
    }
    if (!isNonBlankString(recaptcha)) {
      valid = false
      setRecaptchaError(
        intl.formatMessage({id: 'general.recaptchaValidationFailed'})
      )
    }

    if (valid) {
      onSubmit({
        username: identifier,
        message: message,
        recaptchaUserResponseToken: recaptcha ? recaptcha : ''
      })
    }
  }

  return (
    <form
      className="h-96 w-full"
      name="delete-account-form"
      aria-label={intl.formatMessage({
        id: 'account.delete.formLabel'
      })}
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        handleSubmit()
      }}
    >
      <h1
        id="account-deletion-request"
        className="typography-headline-4 py-2 text-center"
      >
        <FormattedMessage id="account.delete.formLabel" />
      </h1>
      <div className="rounded">
        <p>
          <FormattedMessage id="account.delete.description" />
        </p>
        <AdminTextInput
          id={'username/email'}
          label={intl.formatMessage({
            id: 'account.delete.identifierLabel'
          })}
          value={identifier}
          onChange={event => setIdentifier(event.target.value)}
          errorMessage={identifierError}
        />
        <TextArea
          label={intl.formatMessage({id: 'account.delete.messageLabel'})}
          id="delete-request-message"
          value={message}
          onChange={event => setMessage(event.target.value)}
          errorMessage={messageError}
        />
        <div className="flex w-full items-center justify-center">
          <ReCaptcha
            ref={recaptchaRef}
            onChange={(value: string | null) => {
              if (value == null) {
                setRecaptcha(value)
                return // Show nothing as null is returned when captcha expires
              } else {
                setRecaptcha(value)
                setRecaptchaError('')
              }
            }}
            onErrored={() =>
              setRecaptchaError(
                intl.formatMessage({id: 'general.recaptchaError'})
              )
            }
            value={recaptcha}
            recaptchaError={recaptchaError}
          />
        </div>
        {submitError && (
          <div className="flex w-full items-center justify-center text-red">
            <FormattedMessage
              id={
                ServerError.isServerError(submitError) &&
                submitError.status === 500
                  ? 'general.GenericError'
                  : 'account.delete.submitError'
              }
            />
          </div>
        )}
        <div className="flex w-full items-center justify-center pt-4">
          <FlatButton type="submit" disabled={busy}>
            <FormattedMessage id="account.delete.submit" />
          </FlatButton>
        </div>
      </div>
    </form>
  )
}

export default DeleteAccountForm
