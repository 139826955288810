import React from 'react'
import styled from 'styled-components'
import {devices} from '../../../device'
import backgroundLeft from './assets/left.png'
import backgroundLeft2X from './assets/left@2x.png'
import backgroundLeft3X from './assets/left@3x.png'
import backgroundLeft4X from './assets/left@4x.png'
import backgroundRight from './assets/right.png'
import backgroundRight2X from './assets/right@2x.png'
import backgroundRight3X from './assets/right@3x.png'
import backgroundRight4X from './assets/right@4x.png'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  pointer-events: none;

  @media ${devices.tablet} {
    margin-top: -160px;
    padding: 0 100px;
  }
`

const BackgroundImage = styled.img`
  max-width: 50%;
  object-fit: contain;
`

const BackgroundImageLeft = styled(BackgroundImage)`
  width: 339px;
`

const BackgroundImageRight = styled(BackgroundImage)`
  width: 336px;
`

function Background() {
  return (
    <Wrapper>
      <BackgroundImageLeft
        src={backgroundLeft}
        srcSet={`${backgroundLeft}, ${backgroundLeft2X} 2x, ${backgroundLeft3X} 3x, ${backgroundLeft4X} 4x`}
      />
      <BackgroundImageRight
        src={backgroundRight}
        srcSet={`${backgroundRight}, ${backgroundRight2X} 2x, ${backgroundRight3X} 3x, ${backgroundRight4X} 4x`}
      />
    </Wrapper>
  )
}

export default Background
