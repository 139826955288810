import React from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
  children: React.ReactNode
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 114px;
  width: 100%;
`

function FormField({className, children}: Props) {
  return <Container className={className}>{children}</Container>
}

export default FormField
