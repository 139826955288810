import * as COMMUNITY_TYPES from './communityTypes'
import {LICENSED, PACKAGE, PUBLIC, TEST} from './communityTypes'

type CommunityType = ValueOf<typeof COMMUNITY_TYPES>

const EXPECTED_COMMUNITY_TYPES = Object.values(COMMUNITY_TYPES)

/**
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is an expected {@link CommunityType}.
 */
function isValid(candidate: unknown): boolean {
  return (
    candidate === LICENSED ||
    candidate === PACKAGE ||
    candidate === PUBLIC ||
    candidate === TEST
  )
}

/**
 * Parse a {@link CommunityType} from a candidate of unknown type.
 *
 * @param candidate The candidate to parse.
 * @return A valid typed {@link CommunityType}.
 */
function parse(candidate: unknown): CommunityType {
  if (
    candidate !== LICENSED &&
    candidate !== PACKAGE &&
    candidate !== PUBLIC &&
    candidate !== TEST
  ) {
    throw new Error(
      `Unexpected CommunityType ${candidate}, expected one of ${EXPECTED_COMMUNITY_TYPES.join(
        ', '
      )}`
    )
  }

  return candidate
}

const CommunityType = {isValid, parse}
export default CommunityType
