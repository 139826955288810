import clsx from 'clsx'
import React, {ComponentProps} from 'react'
import {isNonBlankString} from '../../responseRefiners'
import ValidationErrorMessage from '../ValidationErrorMessage'

function TextArea({
  errorMessage = null,
  id,
  label,
  ...textAreaProps
}: Readonly<{
  errorMessage?: string | null
  id: string
  label: string
}> &
  Omit<ComponentProps<'textarea'>, 'className'>) {
  const errorElementId = `${id}-error`
  const labelElementId = `${id}-label`
  const isError = isNonBlankString(errorMessage)

  return (
    <div className="flex flex-col items-end p-2 text-navy">
      <div className="flex w-full items-center justify-between">
        <label
          id={labelElementId}
          htmlFor={id}
          className="typography-headline-5 after:content-[':']"
        >
          {label}
        </label>
        <textarea
          rows={4}
          id={id}
          {...textAreaProps}
          aria-describedby={errorElementId}
          aria-errormessage={errorElementId}
          aria-invalid={isError}
          aria-labelledby={labelElementId}
          className={clsx(
            'w-full max-w-[640px] rounded-md border-0 p-2 shadow-sm outline-none ring-2 ring-inset',
            'focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-green',
            isError && 'ring-red',
            !isError && 'ring-steel-tertiary-20'
          )}
        />
      </div>
      <ValidationErrorMessage id={errorElementId}>
        {errorMessage}
      </ValidationErrorMessage>
    </div>
  )
}

export default TextArea
