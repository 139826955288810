import React from 'react'
import {FormattedMessage} from 'react-intl'
import spinnerUri from './assets/spinner.svg'

function LoadingIndicator() {
  return (
    <div className="flex items-center justify-center">
      <img className="animate-spin" src={spinnerUri} alt="" />
      <span className="sr-only" role="alert" aria-live="assertive">
        <FormattedMessage id="learners.loading" />
      </span>
    </div>
  )
}

export default LoadingIndicator
