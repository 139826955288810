import {LinkIcon} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import * as H from 'history'
import React, {AriaAttributes, HTMLAttributeAnchorTarget} from 'react'
import {Link} from 'react-router-dom'
import {
  FlatButtonVariant,
  FLAT_BUTTON_BASE_STYLES,
  getFlatButtonColourClasses
} from '../../FlatButton'
import {
  BASE_CLASSES as BUTTON_BASE_CLASSES,
  getVariantClasses as getButtonVariantClasses
} from '../../StyledButton/index'
import {Variant} from '../types'
import {BASE_CLASSES, getVariantClasses} from '../util'

type LinkProps<S = H.LocationState> = Readonly<{
  /**
   * Link content.
   */
  children: React.ReactNode
  /**
   * Whether the location should replace the current location in the history
   * stack rather than be pushed onto the stack.
   */
  replace?: boolean
  /**
   * The anchor target (default: _self).
   */
  target?: HTMLAttributeAnchorTarget
  /**
   * The location to navigate to.
   */
  to: H.LocationDescriptor<S>
}> &
  AriaAttributes

type LinkVariantProps = Readonly<{
  /**
   * Link variant.
   */
  variant?: Variant
}>

/**
 * A styled react-router-dom Link opened with referrerPolicy=same-origin and
 * target=_self (default).
 *
 * @see https://reactrouter.com/docs/en/v6/getting-started/concepts#definitions
 */
function InternalLink({
  children,
  replace,
  target = '_self',
  to,
  variant = 'primary',
  ...rest
}: LinkProps & LinkVariantProps) {
  return (
    <UnstyledInternalLink
      className={`
        ${BASE_CLASSES}
        ${getVariantClasses(variant)}
      `}
      replace={replace}
      target={target}
      to={to}
      {...rest}
    >
      {children}
    </UnstyledInternalLink>
  )
}

/**
 * A styled react-router-dom Link opened with referrerPolicy=same-origin
 * and target=_self (default).
 *
 * @see https://reactrouter.com/docs/en/v6/getting-started/concepts#definitions
 */
export function InternalLinkStyledAsButton({
  children,
  replace,
  target = '_self',
  to,
  ...rest
}: LinkProps & LinkVariantProps) {
  return (
    <UnstyledInternalLink
      className={`
        ${BUTTON_BASE_CLASSES}
        ${getButtonVariantClasses('primary')},
        'hover:underline hover:underline-offset-2'
      `}
      replace={replace}
      target={target}
      to={to}
      {...rest}
    >
      {children}
    </UnstyledInternalLink>
  )
}

type InternalLinkStyledAsFlatButtonProps = LinkProps & {
  icon?: React.ReactNode
  variant?: FlatButtonVariant
}

/**
 * A react-router-dom Link styled as the FlatButton opened with referrerPolicy=same-origin
 * and target=_self (default).
 *
 * @see https://reactrouter.com/docs/en/v6/getting-started/concepts#definitions
 */
export function InternalLinkStyledAsFlatButton({
  variant = 'primary',
  children,
  replace,
  target = '_self',
  to,
  icon: Icon,
  ...rest
}: InternalLinkStyledAsFlatButtonProps) {
  return (
    <UnstyledInternalLink
      className={clsx(
        FLAT_BUTTON_BASE_STYLES,
        getFlatButtonColourClasses(variant),
        'hover:underline hover:underline-offset-2'
      )}
      replace={replace}
      target={target}
      to={to}
      {...rest}
    >
      <span aria-hidden className="h-6 w-6">
        {Icon == null ? <LinkIcon rotate={45} className="rotate-45" /> : Icon}
      </span>
      {children}
    </UnstyledInternalLink>
  )
}

export function UnstyledInternalLink({
  children,
  className,
  replace,
  target = '_self',
  to,
  ...rest
}: LinkProps & LinkVariantProps & Readonly<{className?: string}>) {
  return (
    <Link
      {...rest}
      className={
        className == null
          ? 'outline-none focus:outline-none focus-visible:outline-none'
          : className
      }
      referrerPolicy="same-origin"
      replace={replace}
      target={target}
      to={to}
    >
      {children}
    </Link>
  )
}

export default InternalLink
