import {ArrowsUpDownIcon, XMarkIcon} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import React, {useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import ZipPill from '../../domain/zipPill'
import FlatButton from '../FlatButton'

type Props = Readonly<{
  zips: ReadonlyArray<ZipPill>
  onAdd?: (code: ReadonlyArray<ZipPill>) => void
  onDelete?: (deletedZip: ZipPill) => void
  onSort?: () => void
}>

/**
 * A zip input component comprised of optionally deletable zip pills and a text input for adding new zips.
 *
 * @param zips The array of zips to seed the component.
 * @param onAdd The function called when new zips should be added.
 * @param onDelete The function called when a zip is deleted.
 * @param onSort The function called when the zips are sorted.
 */
function ZipCodeInput({zips, onAdd, onDelete, onSort}: Props) {
  const intl = useIntl()
  const zipCodeContainerRef = useRef<HTMLUListElement | null>(null)
  const textInputRef = useRef<HTMLInputElement | null>(null)

  const [newZips, setNewZips] = useState('')

  const focusOnTextInput = () => {
    if (textInputRef.current) {
      textInputRef.current.focus()
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault()
      onAdd?.(
        newZips.split(',').map(zip => ({code: zip.trim(), deletable: true}))
      )
      setNewZips('')
    }
  }

  return (
    <div className="flex flex-col items-end gap-2 p-2">
      <div className="flex w-full items-start justify-between">
        <div>
          <p className="typography-headline-5 after:content-[':']">
            <FormattedMessage id="manageCommunity.add_zip_codes" />
          </p>
          <p className="typography-body-2">
            <FormattedMessage id="manageCommunity.comma_separated_list" />
          </p>
        </div>
        <ul
          ref={zipCodeContainerRef}
          onMouseEnter={focusOnTextInput}
          className="
            flex max-h-60 min-h-[48px] w-full max-w-[640px] flex-wrap gap-1 overflow-hidden
            overflow-y-auto rounded-md border-2 border-steel-tertiary-20 p-2
          "
        >
          {zips.map(({deletable, code}, idx) => (
            <li key={idx}>
              <ZipPillChip
                key={idx}
                code={code}
                deletable={deletable}
                onDelete={deletedZip => onDelete?.(deletedZip)}
              />
            </li>
          ))}
        </ul>
      </div>
      <div
        className="
          flex w-full max-w-[640px] flex-wrap justify-start gap-1 outline-none focus:outline-none
          focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-steel-tertiary-20
        "
      >
        <input
          className="
            w-full rounded p-2 outline-none ring-2 ring-inset ring-steel-tertiary-20 focus:outline-none
            focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-green
          "
          type="text"
          value={newZips}
          ref={textInputRef}
          onKeyPress={handleKeyPress}
          aria-label={intl.formatMessage({
            id: 'manageCommunity.enter_zip_codes'
          })}
          placeholder={intl.formatMessage({
            id: 'manageCommunity.enter_zip_codes'
          })}
          onChange={e => setNewZips(e.target.value)}
        />
      </div>
      <p>
        <FormattedMessage
          id="manageCommunity.zip_code_count"
          values={{count: zips.length}}
        />
      </p>
      <FlatButton
        icon={<ArrowsUpDownIcon className="h-6 w-6" />}
        onClick={onSort}
        type="button"
      >
        <FormattedMessage id="general.sort" />
      </FlatButton>
    </div>
  )
}

type ZipPillComponentProps = ZipPill &
  Readonly<{onDelete?: (deletedItem: ZipPill) => void}>

function ZipPillChip({
  code,
  onDelete,
  deletable = false
}: ZipPillComponentProps) {
  const intl = useIntl()
  return (
    <span
      className={clsx(
        'inline-flex items-center justify-center gap-1 rounded-full bg-green py-1 px-3',
        deletable ? 'opacity-100' : ' opacity-50'
      )}
    >
      <span className="text-navy-primary">{code}</span>
      {deletable && (
        <button
          aria-label={intl.formatMessage(
            {
              id: 'general.delete'
            },
            {thing: code}
          )}
          className="
            rounded-full outline-none focus:outline-none
            focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-navy
          "
          onClick={() => onDelete?.({deletable, code: code})}
          type="button"
        >
          <XMarkIcon className="h-5 w-5 text-navy outline-none" />
        </button>
      )}
    </span>
  )
}

export default ZipCodeInput
