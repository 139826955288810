import React from 'react'
import styled from 'styled-components'
import {ReactComponent as ErrorCallout} from './assets/error_callout.svg'
import {ReactComponent as Exclamation} from './assets/exclamation.svg'
import {ReactComponent as SuccessCallout} from './assets/success_callout.svg'
import {ReactComponent as Tick} from './assets/tick.svg'

export type AlertType = 'success' | 'error'

interface Props {
  type: AlertType
  children: React.ReactNode
}

const CALLOUT_SIZE = 25
const ICON_SIZE = 34

const Container = styled.div`
  max-width: 360px;
`

const Content = styled.div<{backgroundColour: string}>`
  display: flex;
  background-color: ${props => props.backgroundColour};
  border-radius: 8px;
  min-height: 72px;
`

const Inset = styled.div<{backgroundColour: string}>`
  display: flex;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${props => props.backgroundColour};
  padding: 8px;
`

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`

const CalloutWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
`

function Alert({type, children}: Props, ref: React.Ref<HTMLDivElement>) {
  const {
    backgroundColour,
    insetBackgroundColour,
    iconComponent: Icon,
    calloutComponent: Callout
  } = getValuesForType(type)
  return (
    <Container ref={ref}>
      <Content role="alert" backgroundColour={backgroundColour}>
        <Inset backgroundColour={insetBackgroundColour}>
          <Icon width={ICON_SIZE} height={ICON_SIZE} />
        </Inset>
        <Main>{children}</Main>
      </Content>
      <CalloutWrapper>
        <Callout width={CALLOUT_SIZE} height={CALLOUT_SIZE} />
      </CalloutWrapper>
    </Container>
  )
}

function getValuesForType(type: AlertType) {
  if (type === 'success') {
    return {
      backgroundColour: '#a7e2dc',
      insetBackgroundColour: '#24b7a7',
      iconComponent: Tick,
      calloutComponent: SuccessCallout
    }
  }
  if (type === 'error') {
    return {
      backgroundColour: '#f3bfb5',
      insetBackgroundColour: '#e05f45',
      iconComponent: Exclamation,
      calloutComponent: ErrorCallout
    }
  }
  return assertNever(type)
}

function assertNever(type: never): never {
  throw new Error(`Unhandled type: "${type}"`)
}

export default React.forwardRef(Alert)
