import * as Sentry from '@sentry/browser'
import React, {Component, ErrorInfo} from 'react'
import styled from 'styled-components'
import {captureException} from '../../../errors'
import Button from '../Button'
import * as Typography from '../Typography'
import logo from './assets/business_app_logo.png'
import logo2X from './assets/business_app_logo@2x.png'
import logo3X from './assets/business_app_logo@3x.png'
import logo4X from './assets/business_app_logo@4x.png'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Message = styled(Typography.Heading1)`
  margin-top: 2rem;
  color: #cb3333;
`

const ReportButton = styled(Button)`
  margin-top: 2rem;
`

interface Props {
  readonly children: JSX.Element
}

interface State {
  readonly hasError: boolean
  readonly eventId: string | null
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {hasError: false, eventId: null}
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras({componentStack: errorInfo.componentStack})
      const eventId = captureException(error)
      this.setState({eventId: eventId})
    })
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }
    return (
      <Container>
        <img
          src={logo}
          srcSet={`${logo}, ${logo2X} 2x, ${logo3X} 3x, ${logo4X} 4x`}
          alt="Lemonade Day Logo"
        />
        <Message>Sorry, something appears to have gone wrong!</Message>
        <ReportButton
          colour="yellow"
          onClick={() =>
            Sentry.showReportDialog({
              eventId: this.state.eventId == null ? void 0 : this.state.eventId
            })
          }
        >
          Report feedback
        </ReportButton>
      </Container>
    )
  }
}

export default ErrorBoundary
