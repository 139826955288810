import React from 'react'
import ToggleInput from './ToggleInput'

type Props = Readonly<{
  checked: boolean
  onChange: (value: boolean) => void
}>

function EthnicityToggle({checked, onChange}: Props) {
  return (
    <ToggleInput
      checked={checked}
      description="admin.communities.ethnicity.description"
      label="admin.communities.ethnicity.title"
      onChange={onChange}
    />
  )
}

export default EthnicityToggle
