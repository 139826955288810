import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import Community from '../domain/community'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  onSuccess?: () => void
  onError?: (error: Error) => void
}>

function useUpdateCommunity({onSuccess, onError}: Options): {
  isBusy: boolean
  error: Error | null
  updateCommunity: (community: Community) => void
} {
  const accessToken = useSelector(getAccessToken)
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const updateCommunity = (community: Community) => {
    setError(null)
    setIsBusy(true)
    putCommunity(accessToken, community)
      .then(() => onSuccess?.())
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => {
        setIsBusy(false)
      })
  }
  return {isBusy, error, updateCommunity}
}

async function putCommunity(
  accessToken: string,
  community: Community
): Promise<void> {
  const url = new URL(`communities/${community.id}`, API_URL).toString()
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    },
    body: JSON.stringify(community)
  })

  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
}

export default useUpdateCommunity
