import React from 'react'
import SelectBase from 'react-select'
import {Props as SelectProps} from 'react-select/src/Select'
import {
  CustomClearIndicator,
  CustomDropdownIndicator,
  CustomLoadingIndicator,
  customStyles,
  customTheme
} from './selectCommonComponents'

export type Props<OptionType> = SelectProps<OptionType> & {
  error?: string
}

function Select<OptionType>({
  styles,
  theme,
  components,
  ...props
}: Props<OptionType>) {
  return (
    <>
      <SelectBase<OptionType>
        styles={{...customStyles, ...styles}}
        theme={defaultTheme => ({...defaultTheme, ...customTheme, ...theme})}
        components={{
          ...components,
          IndicatorSeparator: null,
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: CustomDropdownIndicator,
          LoadingIndicator: CustomLoadingIndicator
        }}
        isClearable
        {...props}
      />
    </>
  )
}

export default Select
