import * as ACCOUNT_STATUSES from './accountStatuses'
import {ACTIVE, INVITED, LOCKED, RESET} from './accountStatuses'

export type AccountStatus = ValueOf<typeof ACCOUNT_STATUSES>

const EXPECTED_STATUSES = Object.values(ACCOUNT_STATUSES)

function parse(candidate: unknown): AccountStatus {
  if (
    candidate !== ACTIVE &&
    candidate !== INVITED &&
    candidate !== LOCKED &&
    candidate !== RESET
  ) {
    throw new Error(
      `Unexpected AccountStatus ${candidate}, expected one of ${EXPECTED_STATUSES.join(
        ', '
      )}`
    )
  }

  return candidate
}

const accountStatus = {parse}
export default accountStatus
