import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import {CommunityId} from '../domain/community'
import {EventId} from '../domain/event'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  onError?: (error: Error) => void
  onSuccess?: () => void
}>

function useUpdateCommunityCurrentEvent({onError, onSuccess}: Options): {
  busy: boolean
  error: Error | null
  updateCommunityCurrentEvent: (
    communityId: CommunityId,
    eventId: EventId
  ) => void
} {
  const accessToken = useSelector(getAccessToken)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const updateCommunityCurrentEvent = (
    communityId: CommunityId,
    eventId: EventId
  ) => {
    setBusy(true)
    setError(null)
    putCommunityCurrentEvent(accessToken, communityId, eventId)
      .then(onSuccess)
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setBusy(false))
  }

  return {busy, error, updateCommunityCurrentEvent}
}

async function putCommunityCurrentEvent(
  accessToken: string,
  communityId: CommunityId,
  eventId: EventId
): Promise<void> {
  const url = new URL(
    `communities/${communityId}/currentEvents/${eventId}`,
    API_URL
  ).toString()
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    }
  })

  if (response.status !== 204) {
    return ServerError.fromResponse(response)
  }
}

export default useUpdateCommunityCurrentEvent
