import React from 'react'
import {useDropzone} from 'react-dropzone'
import {FormattedMessage} from 'react-intl'
import StyledButton from '../../StyledButton'

interface UploadProps {
  onDrop: (acceptedFiles: File[]) => void
}

const DragAndDrop = ({onDrop}: UploadProps) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: ['image/png', 'image/jpeg'],
    multiple: false,
    onDrop
  })

  return (
    <div className="flex w-full cursor-pointer justify-center">
      <div
        className="
          flex h-[300px] w-[400px] flex-col flex-wrap items-center justify-center
          gap-4 rounded-md shadow-sm hover:shadow-lg focus:outline-none
        "
        {...getRootProps()}
      >
        <img src={require('../assets/empty_image.png')} />
        <p className="w-full text-center">
          <FormattedMessage id="manageCommunity.image_drag" />
        </p>
        <p className="w-full text-center">
          <FormattedMessage id="manageCommunity.or" />
        </p>
        <StyledButton variant="primary">
          <FormattedMessage id="manageCommunity.browse" />
        </StyledButton>

        <input {...getInputProps()} />
      </div>
    </div>
  )
}

export default DragAndDrop
