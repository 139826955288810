import {isNonBlankString} from '../responseRefiners'

type Zip = string & {brand: 'Zip'}

/**
 * Confirms if the candidate is a valid {@link Zip}.
 *
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is a valid {@link Zip}.
 */
function isValid(candidate: unknown): boolean {
  return isNonBlankString(candidate)
}

/**
 * Parse a {@link Zip} from a candidate of unknown type.
 *
 * @param candidate The candidate to parse.
 * @return A valid typed {@link Zip}.
 */
function parse(candidate: unknown): Zip {
  if (!isNonBlankString(candidate)) {
    throw new Error('Zip may not be blank, empty or null')
  }

  return candidate.trim() as Zip
}

const Zip = {isValid, parse}
export default Zip
