import React from 'react'
import CheckYourMail from '../../CheckYourMail'
import Layout from '../../Layout'

function VerificationEmailSentPage() {
  return (
    <Layout noShadow>
      <CheckYourMail
        heading="registration.resendVerificationEmail_Sent_H1"
        mainText="registration.resendVerificationEmail_Sent_B1"
        description="registration.resendVerificationEmail_Sent_B2"
      />
    </Layout>
  )
}

export default VerificationEmailSentPage
