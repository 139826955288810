import {isNonBlankString} from '../responseRefiners'

type Name = string & {brand: 'Name'}

function parse(candidate: unknown): Name {
  if (!isNonBlankString(candidate)) {
    throw new Error('Name may not be blank, empty or null')
  }

  return candidate.trim() as Name
}

/**
 * Confirms if the candidate is a valid Name.
 *
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is a valid name or not.
 */
function isValid(candidate: unknown): boolean {
  return isNonBlankString(candidate)
}

const Name = {isValid, parse}
export default Name
