export default {
  general: {
    GenericError:
      'Lo sentimos, algo inesperado ha ocurrido. Favor de contactar a su administrador.',
    SessionExpired: 'Su sesión ha terminado.\nFavor de entrar nuevamente.',
    Logout: 'Terminar sesión',
    Close: 'Cerrar',
    AppStoreAltText:
      'Descargue la aplicación "Mi Día de la Limonada" de App Store.',
    PlayStoreAltText: 'Obtener Mi Día de la Limonada en Google Play.',
    MLD: 'My Lemonade Day',
    menu: 'Menú',
    busy: 'BUSY...',
    facebook: 'únete a la conversación en Facebook',
    instagram: 'únete a la conversación en Instagram',
    pinterest: 'únete a la conversación en Pinterest',
    twitter: 'únete a la conversación en Twitter',
    youtube: 'únete a la conversación en Youtube',
    sort: 'SORT',
    delete: 'Delete {thing}',
    active: 'Activo',
    inactive: 'Inactivo',
    blankErrorMessage: '* no puede estar en blanco',
    open: 'Abrirse',
    save: 'Salva',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    edit: 'Editar',
    top: 'La cima',
    clear: 'Limpiar',
    country: {
      ca: 'Canadá (CA)',
      mx: 'México (MX)',
      us: 'Estados Unidos (US)'
    },
    currency: {
      cad: 'Dólar canadiense (CAD)',
      usd: 'Dólar de los Estados Unidos (USD)'
    },
    locale: {
      'en-us': 'Inglés (US)',
      'es-mx': 'Español (MX)'
    },
    state: {
      'ca-ab': 'Alberta (AB)',
      'ca-bc': 'British Columbia (BC)',
      'ca-mb': 'Manitoba (MB)',
      'ca-nb': 'New Brunswick (NB)',
      'ca-nl': 'Newfoundland and Labrador (NL)',
      'ca-ns': 'Nova Scotia (NS)',
      'ca-nt': 'Northwest Territories (NT)',
      'ca-nu': 'Nunavut (NU)',
      'ca-on': 'Ontario (ON)',
      'ca-pe': 'Prince Edward Island (PE)',
      'ca-qc': 'Quebec (QC)',
      'ca-sk': 'Saskatchewan (SK)',
      'ca-yt': 'Yukon (YT)',
      'mx-agu': 'Aguascalientes (AGU)',
      'mx-bcn': 'Baja California (BCN)',
      'mx-bcs': 'Baja California Sur (BCS)',
      'mx-cam': 'Campeche (CAM)',
      'mx-chh': 'Chihuahua (CHH)',
      'mx-chp': 'Chiapas (CHP)',
      'mx-coa': 'Coahuila de Zaragoza (COA)',
      'mx-col': 'Colima (COL)',
      'mx-dur': 'Durango (DUR)',
      'mx-gro': 'Guerrero (GRO)',
      'mx-gua': 'Guanajuato (GUA)',
      'mx-hid': 'Hidalgo (HID)',
      'mx-jal': 'Jalisco (JAL)',
      'mx-mex': 'México (MEX)',
      'mx-mic': 'Michoacán de Ocampo (MIC)',
      'mx-mor': 'Morelos (MOR)',
      'mx-nay': 'Nayarit (NAY)',
      'mx-nle': 'Nuevo León (NLE)',
      'mx-oax': 'Oaxaca (OAX)',
      'mx-pue': 'Puebla (PUE)',
      'mx-que': 'Querétaro (QUE)',
      'mx-roo': 'Quintana Roo (ROO)',
      'mx-sin': 'Sinaloa (SIN)',
      'mx-slp': 'San Luis Potosí (SLP)',
      'mx-son': 'Sonora (SON)',
      'mx-tab': 'Tabasco (TAB)',
      'mx-tam': 'Tamaulipas (TAM)',
      'mx-tla': 'Tlaxcala (TLA)',
      'mx-ver': 'Veracruz de Ignacio de la Llave (VER)',
      'mx-yuc': 'Yucatán (YUC)',
      'mx-zac': 'Zacatecas (ZAC)',
      'us-ak': 'Alaska (AK)',
      'us-al': 'Alabama (AL)',
      'us-ar': 'Arkansas (AR)',
      'us-az': 'Arizona (AZ)',
      'us-ca': 'California (CA)',
      'us-co': 'Colorado (CO)',
      'us-ct': 'Connecticut (CT)',
      'us-de': 'Delaware (DE)',
      'us-fl': 'Florida (FL)',
      'us-ga': 'Georgia (GA)',
      'us-hi': 'Hawaii (HI)',
      'us-ia': 'Iowa (IA)',
      'us-id': 'Idaho (ID)',
      'us-il': 'Illinois (IL)',
      'us-in': 'Indiana (IN)',
      'us-ks': 'Kansas (KS)',
      'us-ky': 'Kentucky (KY)',
      'us-la': 'Louisiana (LA)',
      'us-ma': 'Massachusetts (MA)',
      'us-md': 'Maryland (MD)',
      'us-me': 'Maine (ME)',
      'us-mi': 'Michigan (MI)',
      'us-mn': 'Minnesota (MN)',
      'us-mo': 'Missouri (MO)',
      'us-ms': 'Mississippi (MS)',
      'us-mt': 'Montana (MT)',
      'us-nc': 'North Carolina (NC)',
      'us-nd': 'North Dakota (ND)',
      'us-ne': 'Nebraska (NE)',
      'us-nh': 'New Hampshire (NH)',
      'us-nj': 'New Jersey (NJ)',
      'us-nm': 'New Mexico (NM)',
      'us-nv': 'Nevada (NV)',
      'us-ny': 'New York (NY)',
      'us-oh': 'Ohio (OH)',
      'us-ok': 'Oklahoma (OK)',
      'us-or': 'Oregon (OR)',
      'us-pa': 'Pennsylvania (PA)',
      'us-ri': 'Rhode Island (RI)',
      'us-sc': 'South Carolina (SC)',
      'us-sd': 'South Dakota (SD)',
      'us-tn': 'Tennessee (TN)',
      'us-tx': 'Texas (TX)',
      'us-ut': 'Utah (UT)',
      'us-va': 'Virginia (VA)',
      'us-vt': 'Vermont (VT)',
      'us-wa': 'Washington (WA)',
      'us-wi': 'Wisconsin (WI)',
      'us-wv': 'West Virginia (WV)',
      'us-wy': 'Wyoming (WY)'
    },
    password: 'Contraseña',
    passwordPlaceHolder: 'Entrar su contraseña',
    recaptchaValidationFailed: 'Recaptcha no pudo hacer la validación',
    recaptchaError: 'Recaptcha ha detectado un problema'
  },
  home: {
    welcome: 'Bienvenido al Día de la Limonada'
  },
  layout: {
    footerText:
      'Descargue la aplicación "Mi Día de la Limonada" para acceder al contenido de estudio.'
  },
  learners: {
    mentorCode: {
      label: 'Código de mentor'
    },
    toggle: {
      cards: 'Tarjeta',
      screenReader: 'Código de mentor',
      table: 'Tabla'
    },
    title: 'Su gestor de aprendizaje',
    tutorial: '¿Cómo agrego un alumno?',
    reasonForMentorCode: '¿Para qué es el código de mentor?',
    error_heading: '¡Uy!',
    error_body:
      'No hemos podido cargar a sus alumnos. Compruebe su red y vuelva a intentarlo.',
    error_retry: 'procesar de nuevo',
    loading: 'Cargando……',
    request_failure: 'El pedido falló',
    request_failure_try_again: 'Vuelva a intentarlo más tarde.',
    duplicateLearner_title: 'Estudiante duplicado',
    duplicateLearner_summary:
      'Ya existe un estudiante con este nombre y apellido en esta tabla o en la de otro mentor conectado.',
    duplicateLearner_description:
      'Pero, si en efecto se tratase de un estudiante diferente, entonces haga que la combinación de su primer nombre y apellido sea única. Al final hemos añadido automáticamente un número al azar, sin embargo favor de actualizarlo como corresponde antes de guardar.',
    duplicateLearner_dismiss: 'Ok',
    guardianChange_title: '¿Cambiar el correo electrónico del tutor legal?',
    guardianChange_description:
      'Al cambiar el correo electrónico del tutor legal estará eliminando la siguiente información del estudiante: fotos, localización del puesto, y participaciones en concursos.',
    guardianChange_confirmation:
      '¿Está seguro de que quiere cambiar el correo electrónico de los tutores legales? Esta acción no se puede deshacer.',
    guardianChange_dismiss: 'No',
    guardianChange_confirm: 'Si',
    removeLearner_title: 'Eliminar estudiante',
    removeLearner_description:
      'Al remover a este estudiante estará removiendo la siguiente información del estudiante: fotos, localización del puesto, y participaciones en concursos. No puede deshacer esta acción.',
    removeLearner_confirmation:
      '¿Está seguro de que quiere remover a este estudiante?',
    removeLearner_confirm: 'Remover ',
    removeLearner_dismiss: 'No',
    resetLearnerPassword: {
      title: '¿Recuperar la contraseña del estudiante?',
      description:
        'Una vez que esté satisfecho con la contraseña que aparece a continuación, comunique la nueva contraseña a su alumno y haga clic en confirmar para completar el restablecimiento.',
      confirmation:
        '¿Está seguro que quiere recuperar la contraseña de este estudiante?\nNo puede deshacer esta acción.',
      safety:
        'Al cerrar esta ventana el aviso con la contraseña se perderá para siempre por motivos de seguridad.',
      regenerate: 'Generar contraseña',
      confirm: 'Confirmar',
      dismiss: 'Cancelar'
    },
    learnerNotFoundTitle: 'Alumno no encontrado',
    learnerNotFoundDescription:
      'No se puede actualizar al alumno, ya que es posible que su cuenta haya sido eliminada',
    tours: {
      addLearner: {
        toggle:
          'Si está agregando un grupo de estudiantes, pruebe utilizar la tabla de estudiante.',
        names:
          'El nombre y el apellido del estudiante son los únicos campos requeridos. Cada uno de sus estudiantes requerirá un nombre sin repetir.',
        guardian:
          'También puede agregar un correo electrónico del tutor legal para su estudiante o, en caso de ser usted quien asume esa responsabilidad, agregar su correo electrónico para aceptar permisos. Este paso es opcional, pero su estudiante no tendrá acceso a subir fotos, agregar ubicación o participar de competencias sin su permiso.',
        save: 'Una vez que haya añadido a su estudiante, haga clic en el botón "Guardar este estudiante".'
      },
      consent: {
        text: 'Se le pedirá que dé 3 tipos de permisos para cada estudiante.'
      },
      editLearner: {
        card: {
          edit: 'Si hay algún error, puede pulsar el botón "Editar" para cambiar la información del estudiante.',
          view: 'Pulse "Ver más" para más información sobre un estudiante, cambiar su contraseña o eliminarlo.'
        },
        row: {
          edit: 'Si hay algún error, puede ulsar el ícono del lápiz para editar la información del estudiante.',
          view: 'Pulse el ícono del perfil para más información sobre el estudiante, cambiar su contraseña o editar su perfil.'
        }
      },
      back: 'Volver',
      done: 'Hecho',
      next: 'Próximo',
      skip: 'Saltar',
      uploadCsv:
        'Puede utilizar un csv para subir varios estudiantes de una sola vez.'
    }
  },
  registration: {
    WebHeaderNav_loginButton: 'Iniciar sesión',
    WebHeaderNav_registerButton: 'Inscripción',
    WebHeaderNav_logo: 'Logo Día de la Limonada',
    WebFooterNav_footerTitle:
      'Con su ayuda, sembraremos las semillas que cambian vidas.',
    WebFooterNav_donateButton: 'Donar',
    MentorDetails_H1:
      '¡Bienvenido, estamos muy entusiasmados de tenerle con nosotros!',
    MentorDetails_H2: 'Favor de entrar sus detalles',
    MentorDetails_title_dropdown: 'Título (Opcional)',
    MentorDetails_title_dropdown_notHere: '¿No lo encuentra?',
    MentorDetails_title_dropdown_option:
      '{title, select, miss {Srta} mr {Sr} mrs {Sra} dr {Dr} prof {Prof} rev {Rvdo} sir {Señor} sr {Hmna} fr {Padre} other {Other}}',
    MentorDetails_selectPH: 'Seleccionar',
    MentorDetails_firstName: 'Primer nombre',
    MentorDetails_firstNamePH: 'Escriba su primer nombre',
    MentorDetails_lastName: 'Apellido',
    MentorDetails_lastNamePH: 'Escriba su apellido',
    MentorDetails_contactNumber: 'Número de teléfono (Opcional)',
    MentorDetails_contactNumberPH: '000-000-0000',
    MentorDetails_community_dropdown: 'Comunidad',
    MentorDetails_communityPH: 'Escriba el nombre de su comunidad',
    MentorDetails_zipCode_dropdown: 'Código postal',
    MentorDetails_zipCodePH: 'Escriba su código postal',
    mentorDetails_findDropdown:
      '¿Cómo se enteró de My Lemonade Day? (Opcional)',
    mentorDetails_findDropdown_PH: '*Favor de especificar',
    MentorDetails_referrer_dropdown_option:
      "{referrer, select, nickelodeon {Nickelodeon} community_event {Evento comunitario} social_media {Redes sociales} youth_organization {Organización juvenil} faith_based_organization {Organización de base de fe} school {Escuela} advertisement {Anuncio} raising_canes {Raising Cane's} other {Other}}",
    mentorDetails_findDropdown_option8: 'Otro (favor de especificar)',
    MentorDetails_nextButton: 'Próximo',
    MentorDetails_Fail_errorTitle: 'El título es requerido',
    MentorDetails_Fail_errorFirstName: 'El primer nombre es requerido',
    MentorDetails_Fail_errorLastName: 'El apellido es requerido',
    MentorDetails_Fail_errorPhone: 'El número de teléfono es requerido',
    MentorDetails_Fail_errorCommunity: 'El nombre de la comunidad es requerido',
    MentorDetails_Fail_errorZip: 'El código postal es requerido',
    MentorDetails_Fail_errorReferrer: 'El referente es requerido',
    MentorProfile_H1:
      'Para registrarse entre su correo electrónico y cree una contraseña',
    MentorProfile_email: 'Correo electrónico',
    MentorProfile_emailPH: 'Entrar su dirección de correo electrónico',
    MentorProfile_confirm: 'Confirmar su contraseña',
    MentorProfile_confirmPH: 'Confirmar su contraseña',
    MentorProfile_backButton: 'Volver',
    MentorProfile_doneButton: 'Hecho',
    MentorProfile_TsCs: 'Acepto los términos de {link}',
    MentorProfile_TsCs_link: 'Acuerdo y política de privacidad',
    MentorProfile01_Fail_error1: 'El correo electrónico ya está en uso',
    MentorProfile01_Fail_error2:
      '*La contraseña tiene que tener al menos 8 caracteres',
    MentorProfile01_Fail_error3: 'La contraseña no es la misma',
    MentorProfile01_Fail_error4:
      'La dirección de correo electrónico es requerida',
    MentorProfile01_Fail_error5: 'La contraseña es requerida',
    MentorProfile01_Fail_error6:
      'La dirección de correo electrónico no es válida',
    MentorProfile_ServerError_generic:
      'Lo sentimos, algo inesperado ha ocurrido. Favor de contactar a su administrador.',
    MentorProfile_ServerError_serverInvalidPassword:
      'La contraseña tiene que tener entre 8 y 255 caracteres',
    MentorProfile_ServerError_serverLegalAcceptedDate:
      'Tiene que aceptar los términos',
    MentorProfile_ServerError_serverCommunityIdNotFound:
      'No hemos encontrado la comunidad',
    MentorProfile_ServerError_serverNoAssociatedZip:
      'El código postal no está asociado a la comunidad',
    CommunityNotHere_H1:
      '{numberOfCommunities, number} ¡Comunidades hasta el momento!',
    CommunityNotHere_B1:
      '¡El Día de la Limonada expande su alcance diariamente!',
    CommunityNotHere_B2:
      'Lamentablemente, su comunidad aun no ha sido registrada',
    CommunityNotHere_BackButton: 'Volver',
    VerifyEmail_H1: 'Gracias por registrarse.',
    VerifyEmail_B1:
      'Favor de verificar su dirección de correo electrónico antes de que pueda crear los perfiles de estudiantes.',
    VerifyEmail_B2:
      'Si no puede encontrar nuestro correo electrónico, entonces verifique su buzón de spam.',
    mentorLogin_H1:
      'Si no puede encontrar nuestro correo electrónico, entonces verifique su buzón de spam',
    mentorLogin_email: 'Correo electrónico',
    mentorLogin_emailPH: 'Escribir la correo electrónico aquí',
    mentorLogin_passwordPH: 'Escribir la contraseña aquí',
    mentorLogin_forgotPassword: '¿Se le olvido la contraseña?',
    mentorLogin_forgotPassword_send: 'Enviar',
    mentorLogin_resetPassword_Email_H1: 'Entrar su correo electrónico abajo',
    mentorLogin_resetPassword_Email_H2:
      'Le enviaremos un correo electrónico para que cambie su contraseña',
    mentorLogin_loginButton: 'Entrar',
    mentorLogin_signup: '¿No tiene una cuenta?',
    mentorLogin_signup_link: 'Inscripción',
    mentorLoginFail_error: 'Correo electrónico o contraseña incorrecta',
    resetPassword_Success_B1: 'Ha tenido éxito cambiando su contraseña',
    LegalTerms_H1: 'Acuerdo y política de privacidad',
    LegalTerms_subtitle:
      'Tomamos en serio su privacidad. Nunca compartiremos ni venderemos su información.',
    LegalTerms_section1_title: 'Compromiso con su privacidad',
    LegalTerms_section1_p:
      'Esta página le pertenece y es operada por P4L dba Lemonade Day (Lemonade Day). Su privacidad en la Internet es de suma importancia para nosotros. En Lemonade Day queremos que su experiencia en línea sea agradable, educativa y segura. Debido a que recopilamos cierto tipo de información sobre nuestros usuarios, sentimos que es necesario que entienda completamente los términos y condiciones sobre la recopilación y uso de información. Este documento sobre la privacidad revela el tipo de información que recopilamos y cómo la utilizamos.',
    LegalTerms_section2_title:
      'La información que Lemonade Day recopila y monitorea',
    LegalTerms_section2_p:
      'El Día de la Limonada recopila dos tipos de información sobre sus usuarios: Información que los usuarios proveen de manera opcional y voluntaria para recibir nuestros boletines de noticias electrónicos, participar de nuestros tablones de anuncios o foros, escribirle a un amigo, y participar en sondeos y encuestas:',
    LegalTerms_section2_p1:
      'Información que el Día de la Limonada obtiene de los datos de seguimiento combinado al calcular las vistas de página a través de nuestras páginas web. Esta información nos permite ajustar mejor nuestro contenido a las necesidades de los lectores y ayudar a nuestros anunciantes y auspiciadores a entender mejor la demografía de nuestra audiencia. Debido a que la mayoría de los ingresos de el Día de la Limonada provienen de auspicios y anuncios, el brindar información sobre los datos demográficos combinados es esencial para que nuestros servicios continúen siendo gratis para los usuarios. Bajo ninguna circunstancia, el Día de la Limonada divulga a una tercera parte información sobre un usuario individual.',
    LegalTerms_section3_title:
      'El Día de la Limonada recopila información de los usuarios en los siguientes procesos:',
    LegalTerms_section3_p:
      'Información opcional voluntaria. Ofrecemos los siguientes servicios gratis, que requieren que los usuarios provean cierto tipo de información personal:',
    LegalTerms_section3_p1:
      '1. Boletines de noticias (Envíos): Ofreceremos a los usuarios un boletín de noticias electrónico gratis. Lemonade Day recopila las direcciones de correo electrónico de los usuarios que se suscriben voluntariamente. Los usuarios pueden salirse de la lista de envíos visitando el enlace que se provee en cada boletín de noticias para llevar a los usuarios a la página donde pueden manejar la suscripción. Los usuarios también pueden suscribirse a los boletines de noticias al momento de registrarse.',
    LegalTerms_section3_p2:
      '2. Tablones de anuncios/foros: Los usuarios de los Tablones de anuncios y foros en la página tienen que inscribirse por separado para utilizar estos servicios (libre de costo) y publicar mensajes, aunque no tienen que registrarse para visitar la página. En la inscripción, el usuario tiene que proveer un nombre de usuario, contraseña y dirección de correo electrónico.',
    LegalTerms_section3_p3:
      '3. Servicio “Enviar por correo electrónico a un amigo”: Al oprimir “enviar por correo electrónico a un amigo", los usuarios de nuestra página pueden remitir electrónicamente un enlace, página o documentos a otra persona. El usuario tiene que proveer su dirección de correo electrónico y la del recipiente. Esta información solamente se utiliza cuando hay errores en la transmisión, y, por supuesto, para dejarle saber al recipiente quién envió el correo. No utilizamos esta información para ningún otro propósito.',
    LegalTerms_section3_p4:
      '4. Encuestas: Ocasionalmente, el Día de la Limonada pudiese llevar a cabo encuestas para dirigir mejor nuestro contenido a nuestra audiencia. Hay veces, cuando compartimos la información demográfica global de estas encuestas con nuestros auspiciadores, anunciantes y socios. Nunca compartimos información específica de las personas con terceros.',
    LegalTerms_section4_title: 'Niños',
    LegalTerms_section4_p:
      'Conforme con la Ley federal de Protección de la Privacidad Infantil en Internet de 1998 (COPPA, por sus siglas en inglés), a sabiendas nunca solicitaremos información personal identificable de ninguna persona menor de 13 años sin el consentimiento de los padres.',
    LegalTerms_section5_title: 'Monitoreo del uso',
    LegalTerms_section5_p:
      'Lemonade Day monitorea los patrones de tráfico de los usuarios a través de todas sus páginas. Sin embargo, no correlacionamos esta información con los datos de usuarios individuales. Al leer la información del navegador (información que está disponible en los navegadores de todos los usuarios), Lemonade Day desglosa las estadísticas generales de uso conforme al nombre de dominio del usuario, tipo de navegador, y el tipo de MIME.',
    LegalTerms_section5_p1:
      'Aunque, a veces, Lemonade Day monitorea y cataloga los términos de búsqueda que los usuarios entran en su función de Búsqueda, nunca asociamos este ejercicio a usuarios individuales. Utilizamos esta información para determinar, conforme al tráfico, las áreas de nuestras páginas que son del agrado o no de nuestros usuarios. No monitoreamos lo que leen los usuarios individuales, sino el desempeño general de cada página. Esto nos permite continuar mejorando nuestro servicio.',
    LegalTerms_section5_p2:
      'Es posible, que pongamos un archivo de texto llamado “cookie” en los archivos del navegador de su computadora. El “cookie” en si mismo no contiene información Personal, aunque nos permitirá relacionar su actividad en esta página a información que ha brindado de manera específica y a sabiendas. Pero, la única información que un “cookie” puede contener es aquella que usted mismo proveyó. Un “cookie” no puede leer data de su disco duro o archivos” de “cookies” creados por otras páginas. Lemonade Day utiliza los “cookies” para monitorear los patrones de tráfico de los usuarios (como se describe arriba). Nuestro sistema de publicidad entrega un solo “cookie” para monitorear mejor la tasa de impresiones y clics de los anuncios.',
    LegalTerms_section5_p3:
      'Puede rechazar los “cookies” apagándolos en su navegador. Si usted ha programado su navegador para que le avise antes de aceptar los “cookies”, recibirá el mensaje de alerta con cada “cookie”. No necesita activar los “cookies” para utilizar esta página. Sin embargo, necesita que los “cookies” estén activos para participar en el tablón de anuncios, foros, encuestas y sondeos.',
    LegalTerms_section6_title: 'Uso de la información',
    LegalTerms_section6_p:
      'El Día de la Limonada utiliza cualquier información que nuestros usuarios nos brinden voluntariamente para mejorar su experiencia en nuestra red de páginas, ya sea para proveer elementos interactivos o personalizados en nuestras páginas o preparar mejor contenido para el futuro basado en los intereses de nuestros usuarios.',
    LegalTerms_section6_p1:
      'Como indicamos arriba, el Día de la Limonada utiliza la información que nuestros usuarios nos brindan voluntariamente para enviar boletines electrónicos y permitir que los usuarios participen en las encuestas, sondeos, tablones de anuncios, y foros. Enviamos a nuestros subscriptores boletines en un itinerario regular (dependiendo del boletín), y, ocasionalmente, enviamos ediciones especiales cuando entendemos que los subscriptores pudiesen tener un interés particular en algo que estemos haciendo. El Día de la Limonada nunca comparte las listas de envío de sus boletines con ninguna otra parte, incluyendo anunciantes, auspiciadores o socios.',
    LegalTerms_section6_p2:
      'Lemonade Day crea informes colectivos sobre la información demográfica de los usuarios y los patrones de tráfico para los anunciantes, auspiciadores y socios. Esto permite que nuestros anunciantes hagan una publicidad más efectiva, y que los usuarios reciban anuncios que sean relevantes a sus necesidades. Debido a que no monitoreamos los patrones de uso de los usuarios individuales, un anunciante o auspiciador nunca sabrá que un usuario específico hizo clic en su anuncio. No daremos a conocer ninguna información sobre un usuario específico, excepto para cumplir con leyes aplicables o un proceso legal válido, o para proteger la seguridad personal de nuestros usuarios o el público.',
    LegalTerms_section7_title: 'Compartiendo la información',
    LegalTerms_section7_p:
      'El Día de la Limonada utiliza la información descrita arriba para ajustar nuestro contenido para satisfacer las necesidades de nuestros usuarios y ayudar a nuestros anunciantes a entender mejor la demografía de nuestra audiencia. Esto es esencial para que nuestro servicio continúe siendo gratis. No compartiremos información sobre usuarios específicos con ninguna otra parte, excepto para cumplir con leyes aplicables o un proceso legal válido, o para proteger la seguridad personal de nuestros usuarios o el público.',
    LegalTerms_section8_title: 'Seguridad',
    LegalTerms_section8_p:
      'El Día de la Limonada opera redes de datos seguras, protegidas por sistemas de cortafuegos y de protección de contraseñas estándares en la industria. Nuestras políticas de seguridad y privacidad son revisadas periódicamente y mejoradas según sea necesario, y solamente los individuos autorizados tienen acceso a la información que nos proveen nuestros clientes.',
    LegalTerms_section9_title: 'Política de exclusión voluntaria',
    LegalTerms_section9_p:
      'Le proveemos a nuestros usuarios opciones prácticas dondequiera que sean necesarias. Las mismas incluyen, la opción de no recibir los boletines electrónicos. El no participar en ciertas áreas interactivas alivia completamente la necesidad de recopilar cualquier información que sea identificable de nuestros usuarios.',
    LegalTerms_section10_title: 'Su consentimiento',
    LegalTerms_section10_p:
      'Al usar esta página, usted permite que el Día de la Limonada recopile y utilice esta información. Si decidimos cambiar nuestra política de privacidad, publicaremos estos cambios en esta página para que siempre esté al tanto de la información que recopilamos, el uso que le damos, y bajo cuales circunstancias la damos a conocer.',
    resetPassword_Sent_H1: 'Verificar su correo electrónico',
    resetPassword_Sent_B1:
      'Le hemos enviado un enlace por correo electrónico donde puede cambiar su contraseña',
    resetPassword_Sent_B2:
      'Verifique su archivo de spam, si no puede encontrar nuestro correo electrónico',
    resendVerificationEmail_Sent_H1: 'Verificar su correo electrónico',
    resendVerificationEmail_Sent_B1:
      'Favor de verificar su correo electrónico antes de que pueda crear los perfiles de estudiantes',
    resendVerificationEmail_Sent_B2:
      'Verifique su archivo de spam si no puede encontrar nuestro correo electrónico',
    resetPassword_Sent_backButton: 'Volver',
    resetPassword_header1: 'Cambie o recupere su contraseña',
    resetPassword_newPasswordPH: 'Entrar su contraseña',
    resetPassword_confirmPassword: 'Confirmar su contraseña',
    resetPassword_confirmPasswordPH: 'Confirmar su contraseña',
    resetPassword_changeButton: 'Cambiar',
    resetPassword_Fail_error1:
      '*La contraseña debe tener al menos 8 caracteres',
    resetPassword_Fail_error2: 'Se requiere contraseña',
    resetPassword_Fail_error3: 'La contraseña no es la misma',
    resetPassword_ServerError_generic:
      'Lo sentimos, algo inesperado ha ocurrido. Favor de volver a tratar, o contacte a su administrador.',
    ForgotPassword_ServerError_generic:
      'Lo sentimos, algo inesperado ha ocurrido. Favor de volver a tratar, o contacte a su administrador.',
    organizationMembership_H1: 'Díganos a qué organización pertenece',
    organizationMembership_relationship_label: 'Rol',
    organizationMembership_relationship_options:
      '{relationship, select, GUARDIAN {Tutor/ Padre / Mentor} GROUP_LEADER {Líder de grupo} TEACHER {Maestro} other {Other}}',
    organizationMembership_relationship_error:
      'Se requiere indicar la relación',
    organizationMembership_organization_label: 'Organización/Escuela',
    organizationMembership_organization_optional_label:
      'Organización/Escuela (Opcional)',
    organizationMembership_organizationPH:
      'Ingrese el nombre de la organización',
    organizationMembership_organization_error:
      'Se requiere indicar el nombre de la organización',
    organizationMembership_group_label: 'Grupo',
    organizationMembership_group_optional_label: 'Grupo (Opcional)',
    organizationMembership_groupPH: 'Ingrese el nombre de su grupo',
    organizationMembership_group_error: 'Se requiere el nombre del grupo'
  },
  login: {
    verifiedMentor_Login_top_popUp_H1:
      'Gracias por verificar su correo electrónico',
    verifiedMentor_Login_Fail_top_popUp_H1:
      'Ocurrió un problema al verificar su correo electrónico. Favor de volver a tratar.',
    verifiedMentor_Login_Fail_top_popUp_H2:
      'Correo electrónico para asistencia {supportEmail}',
    mentorLoginFail_top_popUp_H1:
      'Favor de verificar su correo electrónico antes de entrar',
    mentorLoginFail_top_popUp_H2: 'Volver a enviar el correo electrónico'
  },
  learnerCreator: {
    error_general_title: '¡Algo falló!',
    error_general_support:
      'Si el asunto persiste, favor de contactar a asistencia',
    error_general_dismiss: 'Entendido',
    error_duplicate_title: 'DEstudiante duplicado',
    error_duplicate_summary:
      'Ya existe un estudiante con este nombre y apellido en esta tabla o en la de otro mentor conectado',
    error_duplicate_description:
      'Pero, si en efecto se tratase de un estudiante diferente, entonces haga que la combinación de su primer nombre y apellido sea única. Al final hemos añadido automáticamente un número al azar, sin embargo favor de actualizarlo como corresponde antes de guardar.',
    error_duplicate_cancel: 'Descartar esta actualización',
    error_duplicate_save: 'Guardar esta actualización',
    error_duplicate_failed:
      'Intento fallido, la combinación de nombre y apellido no es única',
    topbar_button_save: 'Guardar',
    topbar_button_cancel: 'Cancelar',
    topbar_button_upload_csv: 'Subir CSV',
    pagination_clear_all: 'Eliminar todos los filtros',
    pagination_page: 'Página',
    pagination_of: 'para',
    pagination_show: 'demostrar',
    addLearner_tooltip_invited:
      'El estudiante aun tiene que aceptar la invitación por correo electrónico.',
    addLearner_tooltip_locked:
      'Cuenta bloqueda. Demasiadas entradas de contraseñas incorrectas.',
    addLearner_tooltip_reset:
      'El estudiante aun tiene que responder al cambio de contraseña.',
    addLearner_tooltip_add: 'Añadir estudiante',
    addLearner_tooltip_not_sent: 'Enviar correo electrónico al tutor legal',
    addLearner_tooltip_sent: 'Correo electrónico al tutor ya fue enviado',
    addLearner_tooltip_disabled:
      'El envío de correos electrónicos está inhabilitado',
    addLearner_filter_search_placeholder: 'Buscar los récords',
    addLearner_filter_min: 'Min',
    addLearner_filter_max: 'Max',
    addLearner_filter_lesson: 'Lección',
    addLearner_filter_to: 'para',
    addLearner_all: 'Todos',
    addLearner_title: 'Registrar estudiante',
    addLearner_section1_title: 'Estudiante',
    addLearner_section1_column1: 'Perfil',
    addLearner_section1_column1_filter_title: 'Filtrar el perfil por',
    addLearner_section1_column1_filter_option_1: 'No ha sido registrado aun',
    addLearner_section1_column1_filter_option_2: 'Registrado',
    addLearner_section1_column1_filter_option_3: 'Bloquedo fuera de la cuenta',
    addLearner_section1_column2: 'Primer nombre',
    addLearner_section1_column2_filter_title: 'Filtrar el primer nombre por',
    addLearner_section1_column2PH: 'Añadir primer nombre',
    addLearner_section1_column2_required: 'Se requiere el primer nombre',
    addLearner_section1_column3_filter_title: 'Filtrar el apellido por',
    addLearner_section1_column3: 'Apellido',
    addLearner_section1_column3PH: 'Añadir apellido',
    addLearner_section1_column3_required: 'Se requiere el apellido',
    addLearner_section1_duplicate_learner_for_created_by_mentor:
      'Ya tiene un alumno con este nombre en su tabla. Modifique este nombre; le sugerimos que incluya la inicial del segundo nombre.',
    addLearner_section1_duplicate_learner_for_connected_mentor:
      'Otro mentor relacionado con este alumno ya ha utilizado este nombre en su tabla. Modifique este nombre; le sugerimos que incluya la inicial del segundo nombre.',
    addLearner_section2_title: 'Permiso',
    addLearner_section2_column1: 'Medio',
    addLearner_section2_column1_filter_title:
      'Filtrar las entradas de medios por',
    addLearner_section2_column1_filter_option_1: 'Todos',
    addLearner_section2_column1_filter_option_2: 'Aceptado',
    addLearner_section2_column1_filter_option_3: 'Rechazado',
    addLearner_section2_column1_filter_option_4: 'No especificado',
    addLearner_section2_column2: 'Localización',
    addLearner_section2_column2_filter_title: 'Filtrar la localización por',
    addLearner_section2_column2_filter_option_1: 'Todos',
    addLearner_section2_column2_filter_option_2: 'Aceptado',
    addLearner_section2_column2_filter_option_3: 'Rechazado',
    addLearner_section2_column2_filter_option_4: 'No especificado',
    addLearner_section2_column3: 'Entrada a competencia',
    addLearner_section2_column3_filter_title:
      'Filtrar las entradas a competencias por',
    addLearner_section2_column3_filter_option_1: 'Todos',
    addLearner_section2_column3_filter_option_2: 'Aceptado',
    addLearner_section2_column3_filter_option_3: 'Rechazado',
    addLearner_section2_column3_filter_option_4: 'No especificado',
    addLearner_section2_column4: 'Correo electrónico del tutor legal',
    addLearner_section2_column4_filter_title:
      'Filtrar el correo electrónico por',
    addLearner_section2_column4PH: 'Añadir dirección de correo electrónico',
    addLearner_section2_column4_invalid: 'Correo electrónico es inválido',
    addLearner_section2_column5: 'Enviado',
    addLearner_section2_column5_filter_title:
      'Filtrar invitaciones de mentores por',
    addLearner_section2_column5_filter_option_1: 'Enviado',
    addLearner_section2_column5_filter_option_2: 'No enviado',
    addLearner_section2_column5_filter_option_3: 'Es mentor/mentora',
    addLearner_section2_column6: 'Número de contacto',
    addLearner_section2_column6_filter_title: 'Filtrar número de contacto por',
    addLearner_section2_column6PH: 'Añadir número de contacto',
    addLearner_section2_column6_invalid: 'Número de contacto es inválido',
    addLearner_section3_title: 'Programa',
    addLearner_section3_next_step_full: '¿Qué hacemos ahora?',
    addLearner_section3_next_step_short: 'Próximos pasos',
    addLearner_section3_now:
      'Ahora, hacer que su estudiante descargue la aplicación de MLD para crear su perfil.',
    addLearner_section3_column1: 'Sync',
    addLearner_section3_column1_filter_title: 'Filtrar el sync por',
    addLearner_section3_column2: 'Lección',
    addLearner_section3_column2_filter_title: 'Filtrar las lecciones por',
    addLearner_section3_column3: 'Progreso',
    addLearner_section3_column3_filter_title: 'Filtrar el progreso por',
    addLearner_section3_column4: 'Resultados',
    addLearner_section3_column4_filter_title: 'Filtrar los resultados por',
    addLearner_section3_column4_filter_option_1: 'No ha sido sometido',
    addLearner_section3_column4_filter_option_2: 'Sometido',
    addLearner_section3_column4_filter_option_3: 'En progreso',
    addLearner_mentorCode_title: 'Código del mentor',
    addLearner_mentorCode_button: '¿Qué sucede ahora?',
    addLearner_save_entry: 'Guarde este estudiante',
    mentorCode_popup_step: 'paso',
    mentorCode_popup_back: 'Volver',
    mentorCode_popup_next: 'Próximo',
    mentorCode_popup_done: 'Hecho',
    mentorCode_popup1_title: 'Poner a sus estudiantes en la tabla',
    mentorCode_popup1_body:
      'Añadir a todos sus estudiantes a la tabla entrando su nombre y apellido',
    mentorCode_popup2_title: 'Añadir un tutor legal',
    mentorCode_popup2_body:
      'Este paso es opcional, pero el estudiante no podrá subir fotos, añadir una localización o participar en competencias sin el permiso de un tutor legal. Añada el correo electrónico del tutor legal para solicitar permiso o añada su dirección si es que usted estará asumiendo la responsabilidad como tutor legal.',
    mentorCode_popup3_title: 'Decargar la Aplicación',
    mentorCode_popup3_body:
      'Dependiendo del aparato que utilice su estudiante, abrir la tienda de <apple>App</apple> o de <google>Play</google>, buscar My Lemonade Day y descarcar la aplicación.',
    mentorCode_popup4_title: 'Abrir la Aplicación',
    mentorCode_popup4_body:
      'Buscar el ícono de My Lemonade Day en su aparato, hacer clic para abrir la Aplicación.',
    mentorCode_popup5_title: 'Añadir el perfil del estudiante',
    mentorCode_popup5_body:
      'Desde la página del perfil hacer clic en el botón de <highlight>Añadir estudiante</highlight>.',
    mentorCode_popup6_title: 'Crear cuenta',
    mentorCode_popup6_body:
      'Si su estudiante no se ha registrado en My Lemonade Day, clic el botón de <highlight>Registro</highlight>. De lo contrario, hacer que su estudiante añada su perfil existente haciendo clic en el botón de <highlight>Entrar</highlight>.',
    mentorCode_popup7_title: 'Añadir el nombre',
    mentorCode_popup7_body:
      'Hacer que su estudiante entre su nombre y apellido exactamente igual a los que usted entró en la tabla de estudiantes',
    mentorCode_popup8_title:
      'Añadir su <highlight>Código de mentor</highlight>',
    mentorCode_popup8_body:
      'Hacer que el estudiante entre el <highlight>{mentorCode}</highlight> en la caja del código de mentor. Tiene que estar exacto para poder continuar.',
    mentorCode_popup9_title: 'Confirmar la comunifad',
    mentorCode_popup9_body:
      'Hemos entrado la comunidad y el código postal por el estudiante. Si el código postal fuera diferente, puede cambiarlo, de lo contrario, continuar con la información existente.',
    mentorCode_popup10_title: 'Seleccionar nombre de usuario',
    mentorCode_popup10_body:
      'Ahora, su estudiante tiene que escoger un nombre de usuario que sea único y apropiado. Asegurarse de que sea simple para que no pase trabajo recordándolo en un futuro.',
    mentorCode_popup11_title: 'Crear una etiqueta secreta.',
    mentorCode_popup11_body:
      'Por último, su estudiante tiene que crear una etiqueta secreta. Asegurar de que, por motivos de seguridad, no compartan su contraseña con nadie.',
    mentorCode_popup12_title: '¡Iniciar el aprendizaje!',
    mentorCode_popup12_body:
      'Ahora, que han terminado, hacer clic en <highlight>¡Ir!</highlight> para entrar al programa o, si usted tiene que añadir a otros estudiantes hacer clic en el botón de volver.',
    change_legal_guardian_email_popup_title:
      '¿Cambiar el correo electrónico del tutor legal?',
    change_legal_guardian_email_popup_message:
      'Al cambiar el correo electrónico del tutor legal estará eliminando la siguiente información del estudiante: fotos, localización del puesto, y participaciones en concursos.',
    change_legal_guardian_email_popup_submessage:
      'No puede deshacer esta acción.',
    change_legal_guardian_email_popup_accept: 'Sí',
    change_legal_guardian_email_popup_decline: 'No'
  },
  learnerProfile: {
    learner_profile_popup_title: 'Prefil del estudiante',
    learner_profile_popup_title_progress: 'Progreso',
    learner_profile_popup_title_date_last_synced:
      'Fecha de la última sincronización',
    learner_profile_popup_title_age: 'Edad',
    learner_profile_popup_title_grade: 'Grado',
    learner_profile_popup_title_gender: 'Género',
    learner_profile_popup_title_ethnicity: 'Origen étnico',
    learner_profile_popup_gender_male: 'Masculino',
    learner_profile_popup_gender_female: 'Femenino',
    learner_profile_popup_gender_no_answer: 'Sin responder',
    learner_profile_popup_gender_unknown: 'Se desconoce',
    learner_profile_popup_ethnicity_african_american: 'Afroamericano',
    learner_profile_popup_ethnicity_american_indian_or_alaska_native:
      'Indio Americano / Oriundo de Alaska',
    learner_profile_popup_ethnicity_asian: 'Asiático',
    learner_profile_popup_ethnicity_hispanic_or_latino: 'Hispano/Latino',
    learner_profile_popup_ethnicity_native_hawaiian_or_pacific_islander:
      'Oriundo de Hawai / Islas del Pacífico',
    learner_profile_popup_ethnicity_white: 'Blanco',
    learner_profile_popup_ethnicity_no_answer: 'Sin responder',
    learner_profile_popup_ethnicity_unknown: 'Se desonoce',
    learner_profile_popup_reset_password: 'Recuperar contraseña',
    learner_profile_popup_username_label: 'Nombre de usuario',
    learner_profile_popup_remove_learner_button:
      '¿Eliminar el nombre del estudiante?',
    learner_profile_popup_forgot_password_warning:
      'Este estudiante ha olvidado su contraseña.',
    learner_profile_popup_reset_password_title:
      '¿Recuperar la contraseña del estudiante?',
    learner_profile_popup_reset_password_message:
      '¿Está seguro que quiere recuperar la contraseña de este estudiante?',
    learner_profile_popup_reset_password_submessage:
      'No puede deshacer esta acción.',
    learner_profile_popup_reset_password_yes: 'Contraseña nueva',
    learner_profile_popup_reset_password_no: 'No',
    learner_profile_popup_reset_password_complete_title:
      'La contraseña nueva de su estudiante',
    learner_profile_popup_reset_password_complete_message:
      'Favor de comunicarle esta contraseña a',
    learner_profile_popup_reset_password_complete_submessage:
      'De ahora en adelante esta será su contraseña.',
    learner_profile_popup_reset_password_complete_warning:
      'Al cerrar esta ventana el aviso con la contraseña se perderá para siempre por motivos de seguridad.',
    learner_profile_popup_password_reset_text:
      'Contraseña nueva. Solicitar a su estudiante que entre nuevamente en la aplicación.',
    learner_profile_popup_remove_learner_title:
      '¿Eliminar el nombre del estudiante?',
    learner_profile_popup_remove_learner_message:
      'Este estudiante ha olvidado su contraseña.',
    learner_profile_popup_remove_learner_submessage:
      'No puede deshacer esta acción.',
    learner_profile_popup_remove_learner_submessage_legal_guardian:
      'Al remover a este estudiante estará removiendo la siguiente información del estudiante: fotos, localización del puesto, y participaciones en concursos.\nNo puede deshacer esta acción.',
    learner_profile_popup_remove_learner_yes: 'Remover',
    learner_profile_popup_remove_learner_no: 'No',
    learner_profile_popup_remove_learner_failed_title: 'Error en solicitud',
    learner_profile_popup_remove_learner_failed_message: 'Vuelva a intentarlo'
  },
  learnerConsent: {
    learner_consent_accept_button_text: 'Aceptar',
    learner_consent_decline_button_text: 'Rechazar',
    learner_consent_terms_text: 'Entiendo estos',
    learner_consent_terms_conditions: 'términos y condiciones',
    learner_consent_media_title: 'Permiso de audiovisuales',
    learner_consent_media_text:
      'Como el tutor legal, le doy permiso a este menor a subir audiovisuales a la aplicación de My Lemonade Day. Estoy consciente de que este material, pudiese ser utilizado para los concursos de Lemonade Day.',
    learner_consent_media_warning:
      'Este estudiante tiene que entrar a la aplicación para reflejar este permiso.',
    learner_consent_location_title: 'Permiso de localización',
    learner_consent_location_text:
      'Como el Tutor Legal, le doy permiso a este menor a publicar la dirección, horas de operación, menú, y negocios cerca de su puesto de limonada en el mapa público de puestos de El Día de la Limonada.',
    learner_consent_location_warning:
      'Este estudiante tiene que entrar a la aplicación para reflejar este permiso.',
    learner_consent_competition_title: 'Permiso para entrar a las competencias',
    learner_consent_competition_text:
      'Como el tutor legal, le doy permiso a este menor a entrar a las competencias de Lemonade Day a través de la aplicación El Día de la Limonada. Estoy consciente de que me contactarán en la eventualidad de que este menor sea el ganador de dichas competencias.',
    learner_consent_competition_warning:
      'Este estudiante tiene que entrar a la aplicación para reflejar este permiso.'
  },
  learnerProfileLearnerInfoPopup: {
    learner_profile_learner_info_popup_title: 'Información del estudiante',
    learner_profile_learner_info_popup_text1:
      'Para añadir a un estudiante, tiene que entrar su primer nombre y apellido.',
    learner_profile_learner_info_popup_text2:
      'Cuando un estudiante crea su perfil, usted podrá ver la información adicional que provea.'
  },
  learnerProfileLegalGuardianEmailPopup: {
    learner_profile_legal_guardian_email_popup_title:
      'Información para el tutor legal',
    learner_profile_legal_guardian_email_popup_text1:
      'El padre/madre, o tutor legal del menor tiene que dar su consentimiento legal para cierta información. Para poder dar el permiso tienen que registrarse como su mentor.',
    learner_profile_legal_guardian_email_popup_text2:
      'Si es el tutor legal del menor, entrar su dirección de correo electrónico en el espacio para la Dirección de correo electrónico del tutor legal para dar el consentimiento apropiado.'
  },
  learnerProfileProgramPopup: {
    learner_profile_program_popup_title: 'Información sobre el programa',
    learner_profile_program_popup_text:
      'Una vez el estudiante se haya registrado y comenzado las lecciones, usted podrá ver y monitorear el progreso, así como descargar sus resultados cuando estén finales.'
  },
  learnerProfilePermissionPopup: {
    learner_profile_permission_popup_title1: 'Información sobre los permisos',
    learner_profile_permission_popup_text1:
      'Los estudiantes entran en la aplicación de My Lemonade mucha información que requiere protección. Algunos aspectos de esta información requieren el consentimiento legal del tutor del menor.',
    learner_profile_permission_popup_title2: 'Subir audiovisual',
    learner_profile_permission_popup_text2:
      'Hay varias lecciones donde solicitamos que el estudiante suba material audiovisual. El tutor legal tiene que dar su consentimiento antes de que podamos permitirle al estudiante tal cosa. Si el permiso no existiese, el estudiante podrá continuar con su interacción anterior con la lección, incluyendo subir audiovisual, sin tener que subir el audiovisual.',
    learner_profile_permission_popup_title3: 'Localización del puesto',
    learner_profile_permission_popup_text3:
      'Para poder ayudar a los estudiantes con el tráfico de clientes, Lemonade Day tiene un mapa público donde pueden publicar la fecha, y localización de su puesto. El estudiante necesita recibir permiso de su tutor legal antes de que podamos permitirle al estudiante añadir la localización de su puesto. Si no se obtiene el consentimiento, las lecciones que requieren esta información serán bloqueadas y el estudiante podrá continuar sin ellas.',
    learner_profile_permission_popup_title4: 'Participar en las competencias',
    learner_profile_permission_popup_text4:
      'Como recompensa a los estudiantes por su trabajo duro, Lemonade Day lleva a cabo dos competencias todos los años. Una es una rifa al azar de una bicicleta en cada comunidad y la otra es el Joven Empresario del Año para premios adicionales. Un tutor legal tiene que dar su consentimiento para que el estudiante participe de estas competencias.',
    learner_profile_permission_popup_note:
      'Si los permisos no se reciben, no podrán recibir el 100% de su progreso en el programa'
  },
  learnerTaskProgress: {
    learner_task_progress_title: 'Progreso de la aplicación My Lemonade Day',
    learner_task_progress_module1_title: 'Mis metas',
    learner_task_progress_module2_title: 'Mi plan',
    learner_task_progress_module3_title: 'Mi puesto',
    learner_task_progress_module4_title: 'Mis resultados',
    learner_task_progress_module_switcher: 'Interruptor de módulo',
    learner_task_progress_prev_module: 'Módulo anterior',
    learner_task_progress_next_module: 'Próximo módulo',

    learner_task_progress_module11_title: 'Ser un empresario',
    learner_task_progress_module12_title: 'Gastar, ahorrar, compartir',
    learner_task_progress_module21_title: 'Plan de negocios',
    learner_task_progress_module22_title: 'Mi equipo',
    learner_task_progress_module23_title: 'Encontrar mi localización',
    learner_task_progress_module24_title: 'El cliente',
    learner_task_progress_module25_title: 'Servicio al cliente',
    learner_task_progress_module26_title: 'Mis gastos',
    learner_task_progress_module27_title: 'Diseñar el producto',
    learner_task_progress_module28_title: 'Diseñar el puesto',
    learner_task_progress_module29_title: 'Encontrar su distintivo',
    learner_task_progress_module210_title: 'Publicidad',
    learner_task_progress_module211_title: 'Plan de negocios final',
    learner_task_progress_module31_title: 'Consiguiendo un inversionista',
    learner_task_progress_module32_title: 'Compra de materiales',
    learner_task_progress_module33_title: 'Haciendo que suceda',
    learner_task_progress_module34_title: 'Preparativos de última hora',
    learner_task_progress_module35_title: '¡Llegó el Día de la Limonada!',
    learner_task_progress_module41_title: 'Reflexión',
    learner_task_progress_module42_title: 'Contabilidad',
    learner_task_progress_module43_title: 'Diviendo su ganancia'
  },
  termsAndConditions: {
    consent_h1: 'Consentimiento',
    consent_p1_s1: `Como padre o tutor legal del menor(es) mencionados arriba, les doy mi consentimiento para que participen en el Día de la Limonada.`,
    consent_p1_s2: `Entiendo que el Día de la Limonada es un evento familiar, y acepto a que yo (u otro adulto responsable autorizado por mí) asistiré y supervisaré a mishijos.`,
    consent_p1_s3: `Entiendo que los organizadores de El Día de la Limonada no son ni serán responsables de supervisar a mishijos.`,
    consent_p2_s1: `También entiendo que ciertos municipios podrían tener ciertos requisitos de salud y seguridad para que mishijos participen de El Día de la Limonada.`,
    consent_p2_s2: `Acepto cumplir y asegurar que mis hijos cumplan con estos requisitos`,
    consent_p2_s3: `(Vaya a LemonadeDay.org y verifique la página web de su ciudad para conocer algunos requisitos locales)`,
    consent_p2_s4: `También entiendo que hay ciertos municipios que requieren que se les provea con anticipación  el nombre de la persona que supervisará a mishijos y la ubicación exacta del puesto de limonada, y acepto a cumplir con estos requisitos.`,
    release_indemnity_h1: 'Relevo de responsabilidad e indemnización',
    release_indemnity_p1: `Por la presente, relevo y libero, y prometo no demandar a P4L d/b/a Lemonade Day, los organizadores del Día de la Limonada, y sus directores, oficiales, empleados, agentes, voluntarios, representantes, dueños, miembros, afiliados, sucesores, encargados, y a cualquiera que esté asociado a P4L o Lemonade Day (colectivamente las “Partes Relevadas”) de toda responsabilidad conmigo, mis hijos,  los representantes personales de mis hijos , encargados, herederos, familiares inmediatos, de cualquier y toda reclamación, demandas, acciones,  quejas, demandas legales, pérdidas o daños por cualquier daño hacia mi o mis hijos en relación a la participación de mis hijos en el Día de la Limonada, incluyendo, pero sin limitarse a daños personales o de la propiedad, causados o alegadamente causados, en su totalidad o en parte, por la negligencia de las Partes Liberadas o de otro modo.`,
    release_indemnity_p2: `Si, a pesar de este relevo, Yo, mis hijos, o los representantes personales de mis hijos, encargados, herederos, o familiares inmediatos, hacen una reclamación en contra de alguna de las Partes Relevadas mencionadas arriba, Yo acuerdo a indemnizar, defender y exonerara las Partes Relevadas de cualquier gasto de litigio, honorarios de abogados, o gastos incurridos debido a una reclamación en contra de cualquiera de las Partes Relevadas, hasta cuando dicho daño fuera causado  por negligencias de las Partes Relevadas.`,
    release_indemnity_p3: `Más aun, Yo acuerdo a relevar, indemnizar, defender, y exonerar a las Partes Relevadas de y en contra de cualquier y toda reclamación, demandas, quejas, demandas legales, pérdidas, daños, y cualquier otra forma de responsabilidad que cualquiera de ellos pueda sostener (a) por el incumplimiento de mis hijos con las leyes aplicables; y (b) por cualquier daño causado por mí o mis hijos en relación a la participación de mis hijos en el Día de la Limonada.`,
    release_indemnity_p4_s1: `He leído este consentimiento de los padres y lo firmo voluntaria y libremente entendiendo que, al aceptarlo, renuncio a derechos sustanciales que yo o mis hijos pudiésemos, de otra manera, tener para recuperar daños por pérdidas causadas por culpa de las Partes Relevadas.`,
    release_indemnity_p4_s2: `También confirmo que soy el padre o el tutor legal de loshijos mencionados arriba.`,
    photography_h1: 'Relevo de fotografías',
    photography_p1_s1: `Por la presente, doy permios a P4L d/b/a Lemonade Day, para hacer fotografías instantáneas, grabaciones de videos y audio, y otras grabaciones de mí y/o de mishijos, de usar mi nombre y/o el de mishijos y/o de mi parecido y/o el parecido de mishijos y/o usar citas orales mías y/o de mis hijos (los “Materiales Autorizados”) y a reproducir y distribuir los Materiales Autorizados en o a través de los medios.`,
    photography_p1_s2: `También autorizo a Lemonade Day a usar los Materiales Autorizados completados, y a usar mi nombre o el de mis hijos, y parecido, para efectos promocionales y comerciales del Día de la Limonada sin compensación o remuneración alguna para mí y/o mishijos; asimismo, Lemonade Day no me cobrará o cuantificará a mí o mishijos ningún tipo de honorario o costo por servicio por la participación voluntaria mía o de mishijos en cualquier producción de un producto audiovisual.`,
    photography_p1_s3: `Autorizo a Lemonade Day a usar, editar, copiar, publicar o exhibir cualquier Material Autorizado para cualquier  propósito legal en cualquier parte del mundo`,
    photography_p1_s4: `Renuncio al derecho de revisar cualquier material producido por Lemonade Day, incluyendo aquellos que usen mi nombre o el de mishijos y parecido y/o que sean Materiales Autorizados.`,
    photography_p1_s5: `Más aun, renuncio y le entrego a Lemonade Day todos los derechos, títulos, e intereses en y para todos los Materiales Autorizados que yo y/o mishijos pudiésemos tener incluyendo, pero sin limitarse, fotografías instantáneas, grabaciones de videos y de audio, negativos, impresos, reproducciones y copias de los originales, negativos, grabaciones, duplicados, impresos y citas verbales para materiales impresos, y no objetaré o tomaré acción legal alguna en contra de Lemonade Day por usar, reproducir, o acción similar de cualquiera de los Materiales Autorizados.`
  },
  manageCommunity: {
    community_manager: 'Gerente de la comunidad',
    sponsor_manager_title: 'Patrocinador del gerente de la comunidad',
    community_name: 'Nombre',
    mentor_registration_link: 'Enlace de registro de mentor',
    event_date: 'Fecha actual del evento',
    community_sponsors: 'Patrocinadores',
    sponsorship_slot_count:
      '{uploaded, number}/{total, number} espacios para patrocinadores',
    communities: 'Comunidades',
    learner_creator: 'Creador del estudiante',
    search: 'Buscar',
    done: 'Hecho ',
    confirm_delete: '¿Está seguro que quiere borrar esta imagen?',
    delete: 'Borrar',
    upload_image: 'Suba la imagen',
    image_drag: 'Arrastre y suelte la imagen (.png o .jpg) aquí',
    or: 'o',
    browse: 'Buscar',
    file_size_warning: 'Limite el archivo 2MB o menos',
    image_size_warning:
      'Para los mejores resultados, suba una imagen de 693x390 pixeles o más.',
    new_image: 'Escoja una imagen nueva',
    crop: 'Cortar',
    add_zip_codes: 'ADD ZIP CODES',
    enter_zip_codes: 'ENTER ZIP CODES',
    zip_code_count: 'ZIP CODE COUNT: {count, number}',
    comma_separated_list: 'USE COMMAS TO SEPARATE ZIP CODES IN THE LIST.'
  },
  uploadCSV: {
    heading: 'Suba .csv',
    step1:
      'Paso 1. Descargue el formato .csv abajo. Las primeras tres líneas son ejemplos para guiarle.',
    step2:
      'Paso 2. Añada un estudiante por línea a partir de la línea 4. Excluimos las primeras 3 filas de ejemplos al procesar su .csv.',
    step3:
      'Paso 3. Elija abajo su archivo .csv terminado para subir sus estudiantes',
    step4:
      'Paso 4. Si sus datos se agregaron correctamente, cierre esta ventana y vea a sus alumnos agregados a la tabla.',
    button_text: 'Escoger un archivo',
    input_text: 'Nombre del archivo .csv',
    requirements_header: 'Requisitos para validar el archivo',
    requirements: 'Requisitos',
    column_1_title: 'Columna 1:',
    column_1_detail: 'Nombre',
    column_2_title: 'Columna 2:',
    column_2_detail: 'Apellido',
    column_3_title: 'Columna 3:',
    column_3_detail: 'Correo electrónico del tutor legal',
    column_4_title: 'Columna 4:',
    column_4_detail: 'Teléfono de contacto del tutor legal',
    download_template:
      'Descagar el formato .csv de la información de los estudiantes',
    uploading: 'Subiendo el archivo',
    success: 'Los estudiantes subieron exitosamente',
    validationError: 'Archivo inválido',
    file_name: 'Nombre del archivo:',
    file_validated: 'Archivo validado:',
    file_size: 'Tamaño del archivo:',
    line_count: 'Número de líneas:',
    unexpected_columns: 'Número de columnas inesperado.',
    invalid_csv: 'Verifique que su .csv tenga el formato correcto.',
    errors: 'Errores:',
    warnings: 'Advertencias:',
    empty_csv:
      'Su .csv tenía tres líneas o menos. Note que ignoramos las primeras 3 líneas del .csv porque son las líneas de ejemplo en el formato. Favor de añadir a sus estudiantes a partir de la línea 4.',
    line_number: 'Línea {lineNumber, number}: ',
    invalid_guardian_email:
      'El correo electrónico del tutor legal es inválido.',
    empty_first_name: `Falta el primer nombre del estudiante.`,
    empty_last_name: `Falta el apellido del estudiante.`,
    duplicate_rows: 'Hay más de un estudiante con este nombre en su .csv.'
  },
  learnerCard: {
    username: 'Nombre de usuario: {username}',
    guardianEmail_label: 'Correo electrónico del tutor',
    guardianContactNumber_label: 'Teléfono de contacto del tutor',
    age_label: 'Edad',
    grade:
      '{grade, select, FIRST {1} SECOND {2} THIRD {3} FOURTH {4} FIFTH {5} SIXTH {6} SEVENTH {7} EIGHTH {8} OTHER {Otro} other {Otro}}',
    grade_label: 'Grado',
    gender:
      '{gender, select, MALE {Masculino} FEMALE {Femenino} NO_ANSWER {Sin responder} OTHER {Otro} other {Otro}}',
    gender_label: 'Género',
    ethnicity:
      '{ethnicity, select, AFRICAN_AMERICAN {Afroamericano} AMERICAN_INDIAN_OR_ALASKA_NATIVE {Indio Americano / Oriundo de Alaska} ASIAN {Asiático} HISPANIC_OR_LATINO {Hispano/Latino} NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER {Oriundo de Hawai / Islas del Pacífico} NO_ANSWER {Sin responder} WHITE {Blanco} OTHER {Otro} other {Otro}}',
    ethnicity_label: 'Origen étnico',
    lesson_label: 'Lección',
    lastSync_label: 'última sincronización',
    edit_label: 'Editar {learnerName}',
    cancel_label: 'Cancelar la edición de {learnerName}',
    viewMore: 'Ver más',
    viewMore_label: 'Ver más sobre {learnerName}',
    viewLess: 'Ver menos',
    viewLess_label: 'Ver menos sobre {learnerName}',
    more_label: 'Más sobre {learnerName}',
    resetPassword: 'Recuperar contraseña',
    resettingPassword: 'Restablecer la contraseña...',
    resetPassword_label: 'Restablecer la contraseña de {learnerName}',
    resettingPassword_label: 'Restableciendo la contraseña de {learnerName}',
    removeLearner: 'Eliminar estudiante',
    removeLearner_label: 'Eliminar a {learnerName}',
    removingLearner: 'Eliminar al alumno...',
    removingLearner_label: 'Eliminando a {learnerName}',
    saveChanges: 'Guardar los cambios',
    saveChanges_label: 'Guardar los cambios de {learnerName}',
    savingChanges: 'Guardando los cambios...',
    savingChanges_label: 'Guardando los cambios de {learnerName}',
    saveLearner_label: 'Guarde este estudiante',
    consent: 'Consentimiento del tutor legal',
    consentType:
      '{consentType, select, location {Localización} media {Medio} competition {Competencias} other {Otro}}',
    manageConsent_label:
      'Administrar {consentType, select, location {el localización} media {subir audiovisual} competition {la participar en las competencias} other {otro}} consentimiento para {learnerName}',
    pendingConsent: 'Pendiente',
    grantedConsent: 'Otorgado',
    deniedConsent: 'Denegado',
    consentTermsAndConditions: 'Términos y condiciones',
    firstName: 'Nombre',
    firstName_label: 'Nombre del alumno',
    lastName: 'Apellido',
    lastName_label: 'Apellido del alumno',
    emailErrorMessage: '* debe ser una de correo electrónico válida',
    optional_suffix: '{prefix} (Opcional)',
    disabled_guardian_warning:
      'Solo el tutor legal actual puede editar el correo electrónico y el número de contacto del tutor.',
    addNewLearner_header: 'Agregar nuevo estudiante',
    addLearnerForm_label: 'Formulario para agregar alumnos',
    notRegistered_label: 'no registrado',
    notRegistered_link: 'El estudiante no se registró en la aplicación.',
    done: 'Hecho',
    downloadApp:
      'Descargue la aplicación en el dispositivo del estudiante para acceder al contenido de estudio.',
    rememberMentorCode: 'Recuerde su Código de mentor para registrarse',
    viewMentorCode: 'Código del mentor: {mentorCode}',
    consent_disabled_warning:
      'Solo los tutores pueden gestionar el consentimiento.'
  },
  consentView: {
    heading: 'Otorgar el consentimiento',
    step: '{step}/{totalSteps}',
    consentHeading:
      '{consentType, select, location {Permiso de localización} media {Permiso de audiovisuales} competition {Permiso para entrar a las competencias} other {Otro}}',
    consentDescription:
      '{consentType, select, location {Como el Tutor Legal, le doy permiso a este menor a publicar la dirección, horas de operación, menú, y negocios cerca de su puesto de limonada en el mapa público de puestos de El Día de la Limonada..} media {Como el tutor legal, le doy permiso a este menor a subir audiovisuales a la aplicación de My Lemonade Day. Estoy consciente de que este material, pudiese ser utilizado para los concursos de Lemonade Day.} competition {Como el tutor legal, le doy permiso a este menor a entrar a las competencias de Lemonade Day a través de la aplicación El Día de la Limonada. Estoy consciente de que me contactarán en la eventualidad de que este menor sea el ganador de dichas competencias.} other {Como el tutor legal, le doy permiso a este menor}}',
    consentWarning:
      'Este estudiante tiene que entrar a la aplicación para reflejar este permiso.',
    consentTerms: 'Entiendo estos <linkto>términos y condiciones</linkto>.',
    deny: 'Denegar',
    denying: 'Denegando...',
    denied: 'Denegado',
    grant: 'Otorgar',
    granting: 'Otorgando...',
    granted: 'Otorgado',
    checkedErrorMessage: '* se debe comprobar',
    next: 'Próximo',
    previous: 'Anterior'
  },
  email: {
    unsubscribedSuccessfully: 'Cancelar suscripción exitosamente',
    embarrassment: 'Bueno, esto es vergonzoso. Parece que algo ha salido mal.',
    unsubscribeFailed: 'Cancelación de suscripción fallida',
    contactSupport: 'Comuníquese con el soporte para obtener más ayuda'
  },
  admin: {
    communities: {
      select: 'Seleccione una comunidad',
      search: 'Buscar comunidades',
      selectOne: 'Seleccione uno',
      selectSearch: 'Seleccione para buscar',
      notFoundError: 'Comunidad no encontrada. Inténtalo de nuevo.',
      add: {
        title: 'Agregar una nueva comunidad',
        formLabel: 'Agregar formulario de comunidad',
        success: 'Se ha creado una nueva comunidad con éxito.',
        save: 'Salva esta comunidad'
      },
      edit: {
        title: 'Edit community',
        formLabel: 'Edit community form',
        success: 'The community has been updated successfully.'
      },
      name: {
        label: 'Nombre',
        validationMessage: 'Se requiere el nombre'
      },
      country: {
        label: 'País',
        validationMessage: 'se requiere país'
      },
      state: 'Estatal',
      currency: {
        label: 'Moneda',
        validationMessage: 'Se requiere moneda'
      },
      language: {
        label: 'Idioma',
        validationMessage: 'Se requiere idioma'
      },
      ethnicity: {
        title: 'Etnicidad habilitada',
        description:
          '¿Quiere incluir la pregunta sobre origen étnico en la encuesta de la lección 1.1?'
      },
      gender: {
        title: 'Género habilitado',
        description:
          '¿Quieres incluir la pregunta de género en la encuesta de la lección 1.1?'
      },
      timeZone: {
        label: 'Zona horaria',
        validationMessage: 'Se requiere zona horaria'
      },
      joinActiveCommunitiesDescription:
        'Los alumnos y mentores pueden unirse a comunidades activas',
      duplicateCommunity: 'Nombre de comunidad duplicado, elija otro nombre',
      validationMessage:
        'Errores: ciertas entradas no son válidas, ver más abajo',
      dates: {
        title: 'primer evento',
        description: 'esto se puede actualizar más tarde',
        start: 'Comienzo',
        end: 'final',
        pickerStartLabel: 'Fecha de inicio del primer evento',
        pickerEndLabel: 'Fecha de finalización del primer evento',
        validationMessage:
          'La fecha de inicio no puede ser posterior a la fecha de finalización'
      }
    },
    communityToolSelector: {
      title: 'Selector de herramientas comunitarias',
      subTitle: '¿Qué te gustaría gestionar?',
      events: 'Eventos comunitarios',
      sponsors: 'Patrocinadores de la comunidad',
      settings: 'Configuración de la comunidad'
    },
    edit_community: {
      heading: 'Configuración de la comunidad'
    },
    events: {
      title: 'Administrador de evento',
      heading: 'Eventos comunitarios',
      toDate: 'para',
      currentEvent: 'Evento actual',
      upcomingEvent: 'Evento proximo',
      futureEvents: 'Eventos futuros',
      noUpcomingEvents: 'No hay eventos próximos',
      add: 'Agregar un próximo evento',
      deleteConfirmationTitle: 'Eliminar el próximo evento',
      edit: 'Editar',
      editTitle: 'Edit evento',
      editDescription:
        'Editar el evento actual cambiará la cantidad de días de cuenta regresiva hasta el Día de Mi Limonada en la aplicación para todos los estudiantes de esta comunidad.',
      deleteConfirmationDescription1:
        '¿Estás seguro de que deseas eliminar este próximo evento?',
      deleteConfirmationDescription2: 'Esta acción no se puede deshacer.',
      deleteSuccessMessage: 'Evento eliminado',
      eventDate: 'Fecha del evento',
      eventDatesPastValidation:
        'Los próximos eventos deben programarse en el futuro.',
      eventAddSuccess: 'Evento agregado exitosamente',
      eventUpdateSuccess: 'Evento actualizado exitosamente',
      overlappingErrorMessage:
        'El evento se superpone con un evento existente; elija fechas alternativas.',
      rollOverTitle: 'Pase el próximo evento',
      rollOverConfirmationDescription1:
        '¿Estás segura/seguro de que quieres pasar a este evento?',
      rollOverConfirmationDescription2:
        'Se restablecerá todo el progreso del alumno.',
      rollOverConfirmationDescription3: 'Esta acción no se puede deshacer.',
      rollOverSuccessMessage:
        'El evento comunitario se transfirió correctamente',
      notFoundError: 'Evento no encontrado. Inténtalo de nuevo.',
      eventDates: 'Fechas del evento',
      rolloverNotificationTitle:
        'Enviar correo electrónico notificando a los usuarios sobre el próximo rollover',
      rolloverNotificationDescription:
        '¿Está seguro de que desea enviar un correo electrónico a todos los mentores de esta comunidad para advertirles sobre la próxima transferencia?',
      eventRolloverDate: 'Fecha de renovación:',
      eventNotificationSuccessful:
        'Mentores enviados por correo electrónico con éxito.'
    },
    sponsors: {
      title: 'Gerente de patrocinadores de la comunidad',
      subTitle: 'patrocinadores de la comunidad',
      communityName: 'Nombre'
    },
    toolSelector: {
      title: 'Selector de herramientas',
      subTitle: '¿Qué quisiera hacer?'
    },
    userSettings: {
      title: 'Configuración de Usuario y Migración',
      heading: 'Encontrar un usuario',
      userType: {
        label: 'Seleccionar tipo de usuario',
        placeholder: 'Seleccionar',
        learner: 'Estudiante',
        mentor: 'Mentor'
      },
      searchIndexLabel: 'Búsqueda por',
      searchType: {
        username: 'Nombre de usuario',
        name: 'Nombre',
        email: 'Correo electrónico'
      },
      usernameLabel: 'Nombre de usuario',
      firstNameLabel: 'Nombre de pila',
      lastNameLabel: 'Apellido',
      emailLabel: 'Buscar por dirección de correo electrónico',
      searchSubmit: 'Buscar',
      searchPrompt:
        'No se encontraron resultados. Pruebe con un término diferente y busque nuevamente.',
      pageSizeExceeded:
        'Número máximo de resultados mostrados. Agregue más caracteres si no se muestra el usuario correcto.',
      nameColumn: 'Nombre',
      communityColumn: 'Comunidad'
    },
    learnerProfile: {
      title: 'Prefil del estudiante',
      status: 'Estado de la cuenta',
      progress: 'Progreso',
      lastSync: 'Fecha de la última sincronización',
      learnerCommunity: 'Comunidad de estudiantes',
      editTitle: 'Error al buscar alumnos',
      formLabel: 'Editar alumno',
      nameLabel: 'Nombre',
      community: {
        label: 'País',
        placeholder: 'País',
        validationMessage: 'Por Favor Seleccione Una Comunidad',
        communityConflict:
          'Seleccione una comunidad en la que el alumno no se encuentre actualmente'
      },
      moveProgress: {
        title: 'Mover datos de progreso actuales',
        description: ' '
      },
      migrateLearner: 'Mover datos de progreso actuales',
      migrateLearnerSuccess: 'La comunidad cambió exitosamente'
    },
    mentorProfile: {
      title: 'Perfil del mentor',
      firstName: 'Nombre del mentor',
      lastName: 'Apellido del mentor',
      email: 'Correo electrónico',
      communityLabel: 'Comunidad de mentores',
      mentorNotFoundTitle: 'Mentor no encontrado',
      formLabel: 'Editar mentor',
      editDialogTitle: 'Cambiar comunidad',
      currentCommunity: 'Comunidad actual',
      community: {
        label: 'Seleccionar nueva comunidad',
        placeholder: 'Escribe nueva comunidad',
        validationMessage: 'Por Favor Seleccione Una Comunidad',
        communityConflict:
          'Seleccione una comunidad en la que el mentor no se encuentre actualmente'
      },
      migrateLearners: {
        title: "Move all this mentor's learners",
        description: ' '
      },
      migrateProgress: {
        title: 'Mover los datos actuales del progreso del alumno',
        description: ' '
      },
      migrateMentor: 'Cambiar comunidad'
    }
  },
  account: {
    delete: {
      formLabel: 'Solicitud de eliminación de cuenta',
      identifierLabel: 'Nombre de usuario/\nDirección de correo electrónico',
      identifierError:
        'Por favor introduzca su nombre de usuario/dirección de correo electrónico',
      description:
        'Si desea que eliminemos todos los datos de su cuenta de la aplicación My Lemonade Day, complete este formulario y nos aseguraremos de que esto suceda dentro de los 5 días hábiles.',
      messageLabel:
        'Díganos qué parte de su información le gustaría que eliminemos',
      messageError:
        'Por favor ingrese algunos detalles sobre la eliminación de su cuenta',
      submit: 'Entregar',
      submitError: `No pudimos encontrar ningún usuario con esos detalles. Verifique el nombre de usuario/correo electrónico y la contraseña e inténtelo nuevamente.`,
      submitSuccess: 'Se ha enviado su solicitud de eliminación de cuenta.'
    }
  },
  educationalResources: {
    title: 'Descargar recursos educativos',
    shortTitle: 'Recursos educativos',
    resourceKey: {
      MLD_MENTOR_GUIDE: 'Mi guía de mentores del día de la limonada',
      TEACHER_GUIDE_YOUTH: `Guía del profesor - Jóvenes`,
      TEACHER_GUIDE_TEEN: 'Guía del profesor - Adolescente',
      PROGRAM_TIMELINE: 'Información general: cronograma del programa',
      IMPLEMENTATION_GUIDE: 'Guía de implementación',
      GENERAL_FAQ_EN_US: 'Preguntas frecuentes generales',
      GENERAL_FAQ_ES_MX: 'Preguntas frecuentes generales - Español',
      MENTOR_FAQ_EN_US: 'Preguntas frecuentes de mentores + maestros',
      MENTOR_FAQ_ES_MX: 'Preguntas frecuentes de mentores + maestros - Español',
      JOURNAL: 'Mi diario imprimible del día de la limonada',
      COMMON_CORE_TEKS: 'Alineación de Núcleo Común + TEKS',
      FAITH_MENTOR_GUIDE_EN_US: 'Guía de mentores basados en la fe',
      FAITH_MENTOR_GUIDE_ES_MX: 'Guía de mentores basados en la fe - Español',
      TEACHER_SUITE: 'Conjunto de recursos digitales para profesores',
      SUPPLY_LIST: 'Lista de suministros',
      PACKING_LIST: 'Lista de embalaje',
      CUSTOMER_SERVICE_QUESTIONS: 'Preguntas de servicio al cliente',
      JUNIOR_MARKET_JOURNAL: 'Diario del mercado juvenil',
      OFFICIAL_STAND_SIGN: 'Señal de stand oficial'
    },
    public: {
      title: 'Nuestra tienda del día de la limonada',
      storeDescription:
        'En nuestra Tienda del Día de la Limonada, puede descargar una gran cantidad de recursos premium gratuitos que ayudarán a sus alumnos a aprovechar al máximo la experiencia de Mi Día de la Limonada.',
      buttonText: 'Ir a la tienda'
    }
  },
  suppliesShop: {
    title: 'Suministros',
    pageTitle: 'Comprar suministros',
    detail:
      'La lección 3.2 de la aplicación My Lemonade Day trata sobre la compra de suministros. ¡Recomendamos esperar a que sus alumnos lleguen a esta lección para que puedan participar en el proceso de compra!',
    listHeading:
      'Aquí hay una selección de listas de ideas de Amazon para ayudarlo a encontrar lo que necesita:',
    lists: {
      recipe: 'Suministros de recetas',
      stand: 'Suministros para stands',
      twist: 'Suministros de torsión'
    },
    confirmation: {
      heading: {
        stand: '¿Compraste algún material para stand de nuestra lista?',
        recipe: '¿Compraste algún material para recetas de nuestra lista?',
        twist: '¿Compraste algún material giratorio de nuestra lista?'
      },
      body: 'Agradecemos sus comentarios.',
      yes: 'Sí, lo hice',
      no: 'No, no lo hice'
    }
  }
}
