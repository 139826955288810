import React from 'react'
import CheckYourMail from '../../CheckYourMail'
import Layout from '../../Layout'

function ForgotPasswordSuccess() {
  return (
    <Layout noShadow>
      <CheckYourMail
        heading="registration.resetPassword_Sent_H1"
        mainText="registration.resetPassword_Sent_B1"
        description="registration.resetPassword_Sent_B2"
      />
    </Layout>
  )
}

export default ForgotPasswordSuccess
