import {getCommunities} from '../../api'
import CommunityStatus from '../../domain/communityStatus'
import CommunityType from '../../domain/communityType'
import {AppThunkAction} from '../store'
import {
  AttemptGetCommunitiesAction,
  Community,
  ReceiveCommunitiesAction
} from './communities.type'

export const ATTEMPT_GET_COMMUNITIES = 'communities/ATTEMPT_GET_COMMUNITIES'
export const RECEIVE_COMMUNITIES = 'communities/RECEIVE_COMMUNITIES'

export function getCommunitiesAction(
  communityTypes: Array<CommunityType> = [],
  communityStatuses: Array<CommunityStatus> = []
): AppThunkAction<AttemptGetCommunitiesAction | ReceiveCommunitiesAction> {
  return async (dispatch, getState) => {
    const {
      communities: {fetching}
    } = getState()

    if (!fetching) {
      dispatch({
        type: ATTEMPT_GET_COMMUNITIES
      })

      try {
        const communities: Community[] = await getCommunities(
          communityTypes,
          communityStatuses
        )

        dispatch({
          type: RECEIVE_COMMUNITIES,
          payload: communities
        })
      } catch (error) {
        dispatch({
          type: RECEIVE_COMMUNITIES,
          payload: error,
          error: true
        })
      }
    }
  }
}
