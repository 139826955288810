import * as api from '../../api'
import {LANGUAGE_TAG_EN_US, LANGUAGE_TAG_ES_MX} from '../../messages'
import {getPossibleAdult} from '../adults/adults.selectors'
import {AdultId} from '../adults/adults.type'
import {AppThunkAction} from '../store'
import {
  LOCALE_STORAGE_KEY,
  TUTORIAL_SEEN_KEY,
  TUTORIAL_SEEN_VALUE
} from './ui.constants'
import {
  AttemptUpdateLocaleActionType,
  Locale,
  MarkTutorialSeenActionType,
  UpdateLocaleActionType
} from './ui.types'

export const ATTEMPT_UPDATE_LOCALE = 'ui/ATTEMPT_UPDATE_LOCALE'
export const MARK_TUTORIAL_SEEN = 'ui/MARK_TUTORIAL_SEEN'
export const UPDATE_LOCALE = 'ui/UPDATE_LOCALE'

export function updateLocale(
  locale: Locale,
  adultId?: AdultId
): UpdateLocaleActionType {
  if (locale === LANGUAGE_TAG_EN_US) {
    localStorage.setItem(LOCALE_STORAGE_KEY, locale)
    return {
      type: UPDATE_LOCALE,
      payload: locale,
      meta: {adultId}
    }
  }
  if (locale === LANGUAGE_TAG_ES_MX) {
    localStorage.setItem(LOCALE_STORAGE_KEY, locale)
    return {
      type: UPDATE_LOCALE,
      payload: locale,
      meta: {adultId}
    }
  }
  return assertNever(locale)
}

function assertNever(locale: never): never {
  throw new Error(`Unknown locale: ${locale}`)
}

export function setLocale(
  locale: Locale
): AppThunkAction<AttemptUpdateLocaleActionType | UpdateLocaleActionType> {
  return async (dispatch, getState) => {
    const state = getState()
    const adult = getPossibleAdult(state)

    const {
      ui: {updating}
    } = getState()

    if (!updating) {
      dispatch({type: ATTEMPT_UPDATE_LOCALE})
      try {
        if (adult != null) {
          const response = await api.updateAdult({...adult, locale}, state)
          dispatch(updateLocale(response.locale, adult.id))
        } else {
          dispatch(updateLocale(locale))
        }
      } catch (error) {
        dispatch({
          type: UPDATE_LOCALE,
          error: true,
          payload: error,
          meta: {adultId: adult == null ? undefined : adult.id}
        })
        throw error
      }
    }
  }
}

export function markTutorialSeen(): MarkTutorialSeenActionType {
  sessionStorage.setItem(TUTORIAL_SEEN_KEY, TUTORIAL_SEEN_VALUE)
  return {type: MARK_TUTORIAL_SEEN}
}
