import {PencilIcon} from '@heroicons/react/24/outline'
import React, {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {ListedLearner} from '../../domain/learner'
import {
  LEARNER_ID_ROUTE_PLACEHOLDER,
  LEARNER_PROFILE_ROUTE
} from '../../global-constants/routingConstants'
import useLearners, {LearnerQuery} from '../../hooks/useLearners'
import Divider from '../Divider'
import FlatButton from '../FlatButton'
import {InternalLinkStyledAsFlatButton} from '../links/InternalLink'
import LoadingIndicator from '../LoadingIndicator'
import TextInput from '../TextInput/AdminTextInput'
import RadioButtonGroupInput from './RadioButtonGroupInput'
import {
  Footer,
  FormContainer,
  Table,
  TransitionWrapper,
  UserTypeSelector
} from './sections'
import {UserType} from './types'

export type LearnerSearchIndex = 'username' | 'name'

function SearchLearner({
  setError,
  defaultPageSize,
  setSelectedUserType
}: {
  setError: (error: boolean) => void
  defaultPageSize: number
  setSelectedUserType: (userType: UserType) => void
}) {
  const intl = useIntl()
  const {error, isLoading, learners, refetchLearners} = useLearners({
    enabled: false,
    initialQuery: {pageSize: defaultPageSize}
  })
  const [learnerSearchIndex, setLearnerSearchIndex] =
    useState<LearnerSearchIndex>('username')
  const [usernameSearchText, setUsernameSearchText] = useState('')
  const [firstNameSearchText, setFirstNameSearchText] = useState('')
  const [lastNameSearchText, setLastNameSearchText] = useState('')
  const [searchTextErrorMessage, setSearchTextErrorMessage] = useState<
    string | null
  >(null)

  function submitSearch() {
    const query: LearnerQuery = {pageSize: defaultPageSize}
    if (learnerSearchIndex === 'username') {
      const trimmedUsernameSearchText = usernameSearchText.trim()
      query.username = trimmedUsernameSearchText
        ? trimmedUsernameSearchText
        : undefined
    } else {
      const trimmedFirstNameSearchText = firstNameSearchText.trim()
      const trimmedLastNameSearchText = lastNameSearchText.trim()
      query.firstName = trimmedFirstNameSearchText
        ? trimmedFirstNameSearchText
        : undefined
      query.lastName = trimmedLastNameSearchText
        ? trimmedLastNameSearchText
        : undefined
    }
    refetchLearners(query)
  }

  function clearSearch() {
    setUsernameSearchText('')
    setFirstNameSearchText('')
    setLastNameSearchText('')
    refetchLearners()
  }

  useEffect(() => {
    if (error) {
      setError(true)
      window.scrollTo({top: 0})
    } else {
      setError(false)
    }
  }, [error, setError])

  return (
    <div>
      <FormContainer>
        <UserTypeSelector onSelect={setSelectedUserType} selected="learner" />
        <TransitionWrapper>
          <Divider />
          <RadioButtonGroupInput
            name="user-search-type-input"
            label="admin.userSettings.searchIndexLabel"
            value={learnerSearchIndex}
            onChange={setLearnerSearchIndex}
            options={[
              {
                value: 'username',
                label: intl.formatMessage({
                  id: 'admin.userSettings.searchType.username'
                })
              },
              {
                value: 'name',
                label: intl.formatMessage({
                  id: 'admin.userSettings.searchType.name'
                })
              }
            ]}
          />
          <Divider />
          <div className="pt-2">
            {learnerSearchIndex === 'username' && (
              <TextInput
                autoCapitalize="words"
                errorMessage={searchTextErrorMessage}
                id="username-search"
                inputMode="text"
                label={intl.formatMessage({
                  id: 'admin.userSettings.usernameLabel'
                })}
                onChange={event => {
                  setUsernameSearchText(event.target.value)
                  setSearchTextErrorMessage(null)
                }}
                required
                type="search"
                value={usernameSearchText}
              />
            )}
            {learnerSearchIndex === 'name' && (
              <>
                <TextInput
                  autoCapitalize="words"
                  errorMessage={searchTextErrorMessage}
                  id="first-name-search"
                  inputMode="text"
                  label={intl.formatMessage({
                    id: 'admin.userSettings.firstNameLabel'
                  })}
                  onChange={event => {
                    setFirstNameSearchText(event.target.value)
                    setSearchTextErrorMessage(null)
                  }}
                  required
                  type="text"
                  value={firstNameSearchText}
                />
                <TextInput
                  autoCapitalize="words"
                  errorMessage={searchTextErrorMessage}
                  id="last-name-search"
                  inputMode="text"
                  label={intl.formatMessage({
                    id: 'admin.userSettings.lastNameLabel'
                  })}
                  onChange={event => {
                    setLastNameSearchText(event.target.value)
                    setSearchTextErrorMessage(null)
                  }}
                  required
                  type="text"
                  value={lastNameSearchText}
                />
              </>
            )}
          </div>
        </TransitionWrapper>
      </FormContainer>
      <TransitionWrapper className="my-8 flex justify-center space-x-4">
        <FlatButton
          onClick={submitSearch}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              submitSearch()
            }
          }}
          variant="primary"
          type="button"
        >
          <FormattedMessage id="admin.userSettings.searchSubmit" />
        </FlatButton>
        <FlatButton
          onClick={clearSearch}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              clearSearch()
            }
          }}
          variant="primary"
          type="button"
        >
          <FormattedMessage id="general.clear" />
        </FlatButton>
      </TransitionWrapper>
      {!learners && isLoading && <LoadingIndicator />}
      {learners && learners.length > 0 && (
        <Table<ListedLearner>
          isLoading={isLoading}
          columns={[
            {
              label: '',
              value: ({id}) => (
                <div className="flex justify-end">
                  <InternalLinkStyledAsFlatButton
                    variant="secondary"
                    icon={<PencilIcon />}
                    to={LEARNER_PROFILE_ROUTE.replace(
                      LEARNER_ID_ROUTE_PLACEHOLDER,
                      id
                    )}
                  >
                    Edit
                  </InternalLinkStyledAsFlatButton>
                </div>
              )
            },
            {
              label: 'admin.userSettings.nameColumn',
              value: ({firstName, lastName}) => `${firstName} ${lastName}`
            },
            {
              label: 'admin.learnerProfile.learnerCommunity',
              value: ({communityName}) => communityName
            },
            {
              label: 'admin.userSettings.usernameLabel',
              value: ({username}) => username
            }
          ]}
          rows={learners}
        />
      )}
      {learners && (
        <Footer rowCount={learners.length} defaultPageSize={defaultPageSize} />
      )}
    </div>
  )
}

export default SearchLearner
