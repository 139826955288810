import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {REGISTER_ROUTE} from '../../../../global-constants/routingConstants'
import {contentPadding, heading, text} from '../../../../styled/mixins'
import {Props} from '../../../containers/CommunityNotHere.container'
import {StyledButton as Button} from '../../Button'
import Layout from '../../Layout'
import * as Typography from '../../Typography'
import communityCentre from './assets/community_centre.png'
import communityCentre2X from './assets/community_centre@2x.png'
import communityCentre3X from './assets/community_centre@3x.png'
import communityCentre4X from './assets/community_centre@4x.png'

const Image = styled.img`
  padding: 0 16px;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 2rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${contentPadding};
`

const Header = styled(Typography.Heading1)`
  ${heading};
`

const LeadText = styled(Typography.BoldBody3)`
  ${text};
`

const SubText = styled(Typography.Body4)`
  ${text};
`

const BackButton = Button.withComponent(Link)

const TextWrapper = styled.div`
  margin-bottom: 1.5rem;
`

function CommunityNotHerePage({numberOfCommunities}: Props) {
  return (
    <Layout noShadow>
      <Content>
        <Image
          src={communityCentre}
          srcSet={`${communityCentre}, ${communityCentre2X} 2x, ${communityCentre3X} 3x, ${communityCentre4X} 4x`}
        />
        <TextWrapper>
          <Header>
            <FormattedMessage
              id="registration.CommunityNotHere_H1"
              values={{numberOfCommunities}}
            />
          </Header>
          <LeadText>
            <FormattedMessage id="registration.CommunityNotHere_B1" />
          </LeadText>
          <SubText>
            <FormattedMessage id="registration.CommunityNotHere_B2" />
          </SubText>
        </TextWrapper>
        <BackButton colour="yellow" to={REGISTER_ROUTE}>
          <FormattedMessage id="registration.CommunityNotHere_BackButton" />
        </BackButton>
      </Content>
    </Layout>
  )
}

export default CommunityNotHerePage
