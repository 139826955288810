import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useLocation} from 'react-router'
import {devices} from '../../device'
import {
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  ROOT_ROUTE
} from '../../global-constants/routingConstants'
import {
  InternalLinkStyledAsButton,
  UnstyledInternalLink
} from '../links/InternalLink'
import logo from './assets/business_app_logo.png'
import logo2X from './assets/business_app_logo@2x.png'
import logo3X from './assets/business_app_logo@3x.png'
import logo4X from './assets/business_app_logo@4x.png'
import lemmyLogo from './assets/lemmy_logo.png'
import lemmyLogo2X from './assets/lemmy_logo@2x.png'
import lemmyLogo3X from './assets/lemmy_logo@3x.png'
import lemmyLogo4X from './assets/lemmy_logo@4x.png'
import LanguageSelector from './LanguageSelector'

function PublicHeader() {
  const location = useLocation()

  return (
    <header className="z-10 flex min-h-[54px] w-full flex-wrap items-center justify-between py-2 px-4 md:h-[72px] md:py-4 md:px-9">
      <UnstyledInternalLink to={ROOT_ROUTE}>
        <picture>
          <source
            media={devices.tablet}
            srcSet={`${logo}, ${logo2X} 2x, ${logo3X} 3x, ${logo4X} 4x`}
          />
          <img
            alt=""
            aria-hidden
            src={lemmyLogo}
            srcSet={`${lemmyLogo}, ${lemmyLogo2X} 2x, ${lemmyLogo3X} 3x, ${lemmyLogo4X} 4x`}
          />
        </picture>
      </UnstyledInternalLink>
      <div className="flex space-x-4">
        <LanguageSelector />
        {location.pathname !== LOGIN_ROUTE && (
          <InternalLinkStyledAsButton to={LOGIN_ROUTE}>
            <FormattedMessage id="registration.WebHeaderNav_loginButton" />
          </InternalLinkStyledAsButton>
        )}
        {location.pathname === LOGIN_ROUTE && (
          <InternalLinkStyledAsButton to={REGISTER_ROUTE}>
            <FormattedMessage id="registration.WebHeaderNav_registerButton" />
          </InternalLinkStyledAsButton>
        )}
      </div>
    </header>
  )
}

export default PublicHeader
