import * as api from '../../api'
import {AppThunkAction} from '../store'
import {FluxStandardAction} from '../type'
import {SponsorImage, SponsorImageKey} from './sponsorImages.types'

export const DELETE_SPONSOR_IMAGE = 'sponsorImages/DELETE_SPONSOR_IMAGE'
export const RECEIVE_SPONSOR_IMAGES = 'sponsorImages/RECEIVE_SPONSOR_IMAGES'
export const UPLOAD_SPONSOR_IMAGE = 'sponsorImages/UPLOAD_SPONSOR_IMAGE'

export type DeleteSponsorImageAction = FluxStandardAction<
  typeof DELETE_SPONSOR_IMAGE,
  void,
  {key: SponsorImageKey; communityId: string; eventId: string}
>

export type ReceiveSponsorImagesAction = FluxStandardAction<
  typeof RECEIVE_SPONSOR_IMAGES,
  Array<SponsorImage>,
  {communityId: string; eventId: string}
>

export type UploadSponsorImageAction = FluxStandardAction<
  typeof UPLOAD_SPONSOR_IMAGE,
  SponsorImage,
  {communityId: string; eventId: string}
>

export type SponsorImageActions =
  | DeleteSponsorImageAction
  | ReceiveSponsorImagesAction
  | UploadSponsorImageAction

export function fetchSponsorImages(
  communityId: string,
  eventId: string
): AppThunkAction<ReceiveSponsorImagesAction> {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      const sponsorImages = await api.getSponsorImages(
        communityId,
        eventId,
        state
      )
      dispatch({
        type: RECEIVE_SPONSOR_IMAGES,
        meta: {communityId, eventId},
        payload: sponsorImages
      })
    } catch (err) {
      dispatch({
        type: RECEIVE_SPONSOR_IMAGES,
        error: true,
        meta: {communityId, eventId},
        payload: err as Error
      })
      throw err
    }
  }
}

export function uploadSponsorImage(
  communityId: string,
  eventId: string,
  key: SponsorImageKey,
  image: File
): AppThunkAction<UploadSponsorImageAction> {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      const uri = await api.uploadSponsorImage(
        communityId,
        eventId,
        key,
        image,
        state
      )
      dispatch({
        type: UPLOAD_SPONSOR_IMAGE,
        meta: {communityId, eventId},
        payload: {key, uri}
      })
    } catch (err) {
      dispatch({
        type: UPLOAD_SPONSOR_IMAGE,
        error: true,
        meta: {communityId, eventId},
        payload: err as Error
      })
      throw err
    }
  }
}

export function deleteSponsorImage(
  communityId: string,
  eventId: string,
  key: SponsorImageKey
): AppThunkAction<DeleteSponsorImageAction> {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      await api.deleteSponsorImage(communityId, eventId, key, state)
      dispatch({
        type: DELETE_SPONSOR_IMAGE,
        meta: {key, communityId, eventId},
        payload: undefined
      })
    } catch (err) {
      dispatch({
        type: DELETE_SPONSOR_IMAGE,
        error: true,
        meta: {key, communityId, eventId},
        payload: err
      })
      throw err
    }
  }
}
