import {Dialog as HeadlessDialog} from '@headlessui/react'
import React from 'react'

type Props = Readonly<{
  open: boolean
  onClose: () => void
  children: React.ReactNode
}>

function Dialog({children, ...props}: Props) {
  return (
    <HeadlessDialog {...props} className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center">
        <div id="dialog-overlay" className="fixed inset-0 bg-black/50" />
        <HeadlessDialog.Panel className="relative mx-auto w-full max-w-3xl">
          <div className="relative m-3 rounded-3xl bg-white p-8 md:p-12">
            {children}
          </div>
        </HeadlessDialog.Panel>
      </div>
    </HeadlessDialog>
  )
}

Dialog.Title = HeadlessDialog.Title
Dialog.Description = HeadlessDialog.Description

export default Dialog
