import * as AVATAR_KEYS from './avatarKeys'
import {
  A000,
  A001,
  A002,
  A003,
  A004,
  A005,
  A006,
  A007,
  A008,
  A009,
  A010,
  A011,
  A012,
  A013,
  A014,
  A015,
  A016,
  A017,
  A018,
  A019,
  A020,
  A021,
  A022,
  A023,
  A024,
  A025,
  A026,
  A027,
  A028,
  A029,
  A030,
  A031,
  A032,
  A033,
  A034,
  A035,
  A036,
  A037,
  A038,
  A039,
  A040,
  A041,
  A042,
  A043,
  A044,
  A045,
  A046,
  A047,
  A048,
  A049,
  A050,
  A051,
  A052,
  A053,
  A054,
  A055,
  A056,
  A057,
  A058,
  A059,
  A060,
  A061,
  A062,
  A063,
  A064,
  A065,
  A066,
  A067,
  A068,
  A069,
  A070,
  A071,
  A072,
  A073,
  A074,
  A075,
  A076,
  A077,
  A078,
  A079,
  A080,
  A081,
  A082,
  A083,
  A084,
  A085,
  A086,
  A087,
  A088,
  A089,
  A090,
  A091,
  A092,
  A093,
  A094,
  A095,
  A096,
  A097,
  A098,
  A099,
  A100
} from './avatarKeys'

export type AvatarKey = ValueOf<typeof AVATAR_KEYS>

const EXPECTED_AVATAR_KEYS = Object.values(AVATAR_KEYS)

function parse(candidate: unknown): AvatarKey {
  if (
    candidate !== A000 &&
    candidate !== A001 &&
    candidate !== A002 &&
    candidate !== A003 &&
    candidate !== A004 &&
    candidate !== A005 &&
    candidate !== A006 &&
    candidate !== A007 &&
    candidate !== A008 &&
    candidate !== A009 &&
    candidate !== A010 &&
    candidate !== A011 &&
    candidate !== A012 &&
    candidate !== A013 &&
    candidate !== A014 &&
    candidate !== A015 &&
    candidate !== A016 &&
    candidate !== A017 &&
    candidate !== A018 &&
    candidate !== A019 &&
    candidate !== A020 &&
    candidate !== A021 &&
    candidate !== A022 &&
    candidate !== A023 &&
    candidate !== A024 &&
    candidate !== A025 &&
    candidate !== A026 &&
    candidate !== A027 &&
    candidate !== A028 &&
    candidate !== A029 &&
    candidate !== A030 &&
    candidate !== A031 &&
    candidate !== A032 &&
    candidate !== A033 &&
    candidate !== A034 &&
    candidate !== A035 &&
    candidate !== A036 &&
    candidate !== A037 &&
    candidate !== A038 &&
    candidate !== A039 &&
    candidate !== A040 &&
    candidate !== A041 &&
    candidate !== A042 &&
    candidate !== A043 &&
    candidate !== A044 &&
    candidate !== A045 &&
    candidate !== A046 &&
    candidate !== A047 &&
    candidate !== A048 &&
    candidate !== A049 &&
    candidate !== A050 &&
    candidate !== A051 &&
    candidate !== A052 &&
    candidate !== A053 &&
    candidate !== A054 &&
    candidate !== A055 &&
    candidate !== A056 &&
    candidate !== A057 &&
    candidate !== A058 &&
    candidate !== A059 &&
    candidate !== A060 &&
    candidate !== A061 &&
    candidate !== A062 &&
    candidate !== A063 &&
    candidate !== A064 &&
    candidate !== A065 &&
    candidate !== A066 &&
    candidate !== A067 &&
    candidate !== A068 &&
    candidate !== A069 &&
    candidate !== A070 &&
    candidate !== A071 &&
    candidate !== A072 &&
    candidate !== A073 &&
    candidate !== A074 &&
    candidate !== A075 &&
    candidate !== A076 &&
    candidate !== A077 &&
    candidate !== A078 &&
    candidate !== A079 &&
    candidate !== A080 &&
    candidate !== A081 &&
    candidate !== A082 &&
    candidate !== A083 &&
    candidate !== A084 &&
    candidate !== A085 &&
    candidate !== A086 &&
    candidate !== A087 &&
    candidate !== A088 &&
    candidate !== A089 &&
    candidate !== A090 &&
    candidate !== A091 &&
    candidate !== A092 &&
    candidate !== A093 &&
    candidate !== A094 &&
    candidate !== A095 &&
    candidate !== A096 &&
    candidate !== A097 &&
    candidate !== A098 &&
    candidate !== A099 &&
    candidate !== A100
  ) {
    throw new Error(
      `Unexpected AvatarKey ${candidate}, expected one of ${EXPECTED_AVATAR_KEYS.join(
        ', '
      )}`
    )
  }

  return candidate
}

const avatarKey = {parse}
export default avatarKey
