import CountryCode from './countryCode'
import {TIME_ZONES_BY_COUNTRY_CODE} from './timeZones'

type TimeZone<C extends CountryCode = CountryCode> =
  typeof TIME_ZONES_BY_COUNTRY_CODE[C][number]

/**
 * Format time string in the form: TzIdentifier (AbbreviationStandard UtcOffsetStandard / AbbreviationDaylightSavings UtcOffsetDaylightSavings)
 *
 * @param timeZone The {@link TimeZone} object to format.
 */
function format(timeZone: TimeZone): string {
  return `${timeZone.tzIdentifier} (${timeZone.abbreviationStd} ${timeZone.utcOffsetStd} / ${timeZone.abbreviationDst} ${timeZone.utcOffsetDst})`
}

const TimeZone = {format}
export default TimeZone
