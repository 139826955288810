import {isNonBlankString, isPlainObject} from '../responseRefiners'
import EducationResourceType from './educationResourceType'
import Id from './id'
import LanguageTag from './languageTag'

export type EducationResourceId = Id<'EducationResource'>

type EducationResource = {
  id: EducationResourceId
  type: EducationResourceType
  imageUri: string
  imageUri2x: string
  imageUri3x: string
  resourceUri: string
  locale: LanguageTag
}

function parse(candidate: unknown): EducationResource {
  if (
    !isPlainObject(candidate) ||
    !Id.isValid(candidate.id) ||
    !isNonBlankString(candidate.imageUri) ||
    !isNonBlankString(candidate.imageUri2x) ||
    !isNonBlankString(candidate.imageUri3x) ||
    !isNonBlankString(candidate.resourceUri)
  ) {
    throw new Error(
      `Unexpected EducationResource ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }

  return {
    id: Id.parse(candidate.id),
    type: EducationResourceType.parse(candidate.type),
    imageUri: candidate.imageUri,
    imageUri2x: candidate.imageUri2x,
    imageUri3x: candidate.imageUri3x,
    resourceUri: candidate.resourceUri,
    locale: LanguageTag.parse(candidate.locale)
  }
}

const EducationResource = {parse}
export default EducationResource
