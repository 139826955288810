import * as GENDERS from './genders'
import {FEMALE, MALE, NO_ANSWER, OTHER} from './genders'

export type Gender = ValueOf<typeof GENDERS>

const EXPECTED_GENDERS = Object.values(GENDERS)

function parse(candidate: unknown): Gender {
  if (
    candidate !== FEMALE &&
    candidate !== MALE &&
    candidate !== NO_ANSWER &&
    candidate !== OTHER
  ) {
    throw new Error(
      `Unexpected Gender ${candidate}, expected one of ${EXPECTED_GENDERS.join(
        ', '
      )}`
    )
  }

  return candidate
}

function parseOtherGender(candidate: unknown): string | null {
  if (candidate !== null && typeof candidate !== 'string') {
    throw new Error('OtherGender must be a string or null')
  }

  return candidate == null ? null : candidate.trim()
}

const gender = {parse, parseOtherGender}
export default gender
