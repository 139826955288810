import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {FormattedMessage} from 'react-intl'
import styled from 'styled-components'
import {SITE_KEY} from '../../../config'
import {devices} from '../../../device'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

interface Props {
  onChange: (value: string | null) => void
  onErrored: () => void
  recaptchaError: string | null
  value: string | Error | null
}

const CaptchaContainer = styled.div`
  min-height: 133px;
  margin-bottom: 1rem;

  @media ${devices.tablet} {
    margin-bottom: 2rem;
  }
`

const Captcha = styled(ReCAPTCHA)`
  margin: 1rem 0;
`

function ReCaptcha(
  {recaptchaError, onChange, onErrored, value}: Props,
  ref: React.Ref<ReCAPTCHA>
) {
  function displayRecaptchaError(
    error: string | null,
    value: string | Error | null
  ) {
    if (error) {
      return value !== null
    }

    return false
  }

  const showRecaptchaError = displayRecaptchaError(recaptchaError, value)

  return (
    <CaptchaContainer>
      <Captcha
        ref={ref}
        sitekey={SITE_KEY}
        onChange={onChange}
        onErrored={onErrored}
      />
      {showRecaptchaError && recaptchaError && (
        <ErrorMessage>
          <FormattedMessage id={recaptchaError} />
        </ErrorMessage>
      )}
    </CaptchaContainer>
  )
}

export default React.forwardRef(ReCaptcha)
