import React, {
  FormEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {FormattedMessage, useIntl} from 'react-intl'
import {RouteComponentProps} from 'react-router-dom'
import {ValueType} from 'react-select/src/types'
import styled from 'styled-components'
import InternalLink from '../../../../components/links/InternalLink'
import {devices} from '../../../../device'
import {
  COMMUNITY_NOT_HERE_ROUTE,
  LEGAL_TERMS_ROUTE,
  REGISTERED_ROUTE
} from '../../../../global-constants/routingConstants'
import {CreateMentorFields} from '../../../../store/mentors/mentors.type'
import CommunitySelect from '../../../containers/CommunitySelect.container'
import {
  DispatchProps,
  StateProps
} from '../../../containers/RegistrationForm.container'
import ZipCodeSelect from '../../../containers/ZipCodeSelect.container'
import Alert from '../../Alert'
import Button from '../../Button'
import CheckBox from '../../CheckBox'
import ErrorMessage from '../../ErrorMessage/ErrorMessage'
import FormField from '../../FormField'
import Label from '../../Label/Label'
import Password from '../../Password/Password'
import ReCaptcha from '../../ReCaptcha'
import Select from '../../Select'
import SelectWithTextOther from '../../Select/SelectWithTextOther'
import TextField from '../../TextField'
import * as Typography from '../../Typography'
import {
  RegistrationFormErrors,
  RegistrationFormValues,
  SelectOption,
  SelectWithFreeTextOption
} from './form-data.type'
import {
  isValid,
  mapServerErrors,
  validateOrganizationMembership,
  validateUserContactInfo,
  validateUserDetails
} from './validation'

type FormScreen = 'DETAILS' | 'ORGANIZATION_MEMBERSHIP' | 'CONTACT'

type Props = DispatchProps & StateProps & RouteComponentProps

const TITLES = ['miss', 'mr', 'mrs', 'dr', 'prof', 'rev', 'sir', 'sr', 'fr']
const RELATIONSHIPS = ['GUARDIAN', 'GROUP_LEADER', 'TEACHER']

const REFERRER_OPTIONS = [
  'community_event',
  'social_media',
  'youth_organization',
  'faith_based_organization',
  'nickelodeon',
  'school',
  'advertisement',
  'raising_canes'
]
const FIELD_MARGIN = '10px'

const StyledTitleNameRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledTitleContainer = styled(FormField)`
  width: 100%;

  @media ${devices.tablet} {
    width: 30%;
  }
`

const StyledNameContainer = styled(FormField)`
  width: 100%;

  @media ${devices.tablet} {
    margin-left: ${FIELD_MARGIN};
    width: calc(70% - ${FIELD_MARGIN});
  }
`

const StyledWelcomeText = styled(Typography.BoldBody1)`
  text-align: center;
  display: block;
  margin-bottom: 0.5rem;

  @media ${devices.tablet} {
    margin-bottom: 1rem;
  }
`

const StyledDescription = styled(Typography.Body1)`
  display: block;
  text-align: center;
  margin-bottom: 0.5rem;

  @media ${devices.tablet} {
    margin-bottom: 1rem;
  }
`

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
`

const TermsConditionsWrapper = styled.div`
  display: inline-block;
`

const StyledForm = styled.form`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  outline: none;
`

const StyledWrapper = styled.div`
  margin: 2rem 0;
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const AlertContent = styled.div`
  text-align: center;
`

const ErrorNotHereWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 16px;
`

const SideNote = styled(Typography.Body4)`
  margin-top: 0.25rem;
  margin-left: 1rem;
`

function extractInputValue(event: SyntheticEvent<HTMLInputElement>): string {
  const target = event.currentTarget
  return target.value
}

function extractInputPhoneNumber(event: SyntheticEvent<HTMLInputElement>) {
  const value = extractInputValue(event)
  return value.replace(/[^0-9-]/g, '')
}

function extractPassthrough<Type>(value: Type): Type {
  return value
}

function transformUserPhoneInput(str: string): string {
  const transformedPhone = str.replace(/[^0-9]/g, '').split('')
  const separator = '-'

  if (str.length > 3) {
    transformedPhone.splice(3, 0, separator)
  }

  if (str.length > 6) {
    transformedPhone.splice(7, 0, separator)
  }

  return transformedPhone.join('')
}

function RegistrationForm({createMentor, loading, history, locale}: Props) {
  const intl = useIntl()

  const [screen, setScreen] = useState<FormScreen>('DETAILS')

  const [values, setValues] = useState<RegistrationFormValues>({
    title: null,
    name: '',
    surname: '',
    phone: '',
    community: null,
    zip: null,
    referrer: null,
    email: '',
    password: '',
    repeatedPassword: '',
    recaptcha: '',
    termsConditions: false,
    relationship: null,
    organization: '',
    group: ''
  })

  const [errors, setErrors] = useState<RegistrationFormErrors>({
    generic: '',
    title: '',
    name: '',
    surname: '',
    phone: '',
    community: '',
    zip: '',
    email: '',
    password: '',
    repeatedPassword: '',
    recaptcha: '',
    referrer: '',
    relationship: '',
    organization: '',
    group: ''
  })

  const {
    title,
    name,
    surname,
    phone,
    email,
    password,
    repeatedPassword,
    community,
    zip,
    termsConditions,
    recaptcha,
    referrer,
    relationship,
    organization,
    group
  } = values

  const {
    generic: genericError,
    title: titleError,
    name: nameError,
    surname: surnameError,
    phone: phoneError,
    email: emailError,
    password: passwordError,
    repeatedPassword: repeatedPasswordError,
    community: communityError,
    zip: zipError,
    recaptcha: recaptchaError,
    referrer: referrerError,
    relationship: relationshipError,
    organization: organizationError,
    group: groupError
  } = errors

  useEffect(() => {
    window.scrollTo({top: 0})
  }, [screen])

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const focusRef = useRef<HTMLFormElement>(null)
  useEffect(() => {
    if (focusRef.current != null) {
      focusRef.current.focus()
    }
  }, [focusRef, screen])

  const alertRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (genericError !== '' && alertRef.current != null) {
      alertRef.current.scrollIntoView()
    }
  }, [genericError, alertRef])

  function goToOrganizationMembershipSubForm() {
    const validationResult = validateUserDetails(values)

    if (isValid(validationResult)) {
      setScreen('ORGANIZATION_MEMBERSHIP')
    } else {
      setErrors(errs => ({...errs, ...validationResult}))
    }
  }

  function goBackToDetailsSubForm() {
    setErrors(errs => ({...errs, ...validateUserDetails(values)}))
    setScreen('DETAILS')
  }

  function goToContactSubForm() {
    const validationResult = validateOrganizationMembership(values)

    if (isValid(validationResult)) {
      // set recaptcha value to '' because the recaptcha component re-renders unchecked
      setValues(vals => ({...vals, recaptcha: ''}))
      setScreen('CONTACT')
    } else {
      setErrors(errs => ({...errs, ...validationResult}))
    }
  }

  function goBackToOrganizationMembershipSubForm() {
    setErrors(errs => ({
      ...errs,
      ...validateOrganizationMembership(values)
    }))
    setScreen('ORGANIZATION_MEMBERSHIP')
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    const validationResult = validateUserContactInfo(values)

    setErrors(errs => ({...errs, ...validationResult}))

    if (!community || !zip || !relationship) {
      throw new Error('Missing data for Mentor Registration')
    }

    let serverReferrerValue

    if (!referrer) {
      serverReferrerValue = null
    } else if (!referrer.freeTextUserInput) {
      serverReferrerValue = referrer.value.toUpperCase()
    } else {
      serverReferrerValue = referrer.value.trim()
    }

    if (isValid(validationResult)) {
      const mentorFields: CreateMentorFields = {
        email: email.trim(),
        // optional
        title: title ? title.value : null,
        lastName: surname,
        // optional
        phoneNumber: phone.trim() === '' ? null : phone,
        locale,
        zip: zip.value,
        communityId: community.value,
        referrer: serverReferrerValue,
        firstName: name,
        password,
        recaptchaUserResponseToken: recaptcha,
        legalAcceptedDate: new Date(),
        relationship: relationship.value,
        organization: organization.trim() === '' ? null : organization,
        group: group.trim() === '' ? null : group
      }

      createMentor(mentorFields)
        .then(() => history.replace(REGISTERED_ROUTE))
        .catch(error => {
          setErrors(errs => {
            const combined = {...errs, ...mapServerErrors(error)}

            if (
              combined.zip ||
              combined.title ||
              combined.community ||
              combined.name ||
              combined.surname
            ) {
              setScreen('DETAILS')
            }

            return combined
          })
          if (recaptchaRef.current != null) {
            recaptchaRef.current.reset()
          }
        })
    }
  }

  function createHandler<Value, Type>(
    key: keyof RegistrationFormValues,
    extractValue: (value: Value) => Type
  ) {
    return function handler(value: Value) {
      const extractedValue = extractValue(value)

      setValues(vals => ({
        ...vals,
        [key]: extractedValue
      }))
    }
  }

  function clearError(key: keyof RegistrationFormErrors) {
    return () => {
      setErrors(errs => ({
        ...errs,
        [key]: ''
      }))
    }
  }

  function onReCaptchaChange(value: string | null) {
    setValues(vals => ({...vals, recaptcha: value}))
    setErrors(errs => ({...errs, recaptcha: ''}))
  }

  function onReCaptchaError() {
    setValues(vals => ({...vals, recaptcha: new Error('Captcha Error')}))
  }

  if (screen === 'DETAILS') {
    return (
      <StyledWrapper>
        <StyledWelcomeText>
          <FormattedMessage id="registration.MentorDetails_H1" />
        </StyledWelcomeText>
        <StyledDescription>
          <FormattedMessage id="registration.MentorDetails_H2" />
        </StyledDescription>
        <StyledForm onSubmit={handleSubmit} ref={focusRef} tabIndex={-1}>
          <StyledTitleNameRow>
            <StyledTitleContainer>
              <Label htmlFor="title">
                <FormattedMessage id="registration.MentorDetails_title_dropdown" />
              </Label>
              <Select<SelectOption>
                id="title"
                name="title"
                value={title}
                placeholder={intl.formatMessage({
                  id: 'registration.MentorDetails_selectPH'
                })}
                error={titleError}
                options={TITLES.map(title => ({
                  label: intl.formatMessage(
                    {
                      id: 'registration.MentorDetails_title_dropdown_option'
                    },
                    {title}
                  ),
                  value: title
                }))}
                onChange={createHandler<
                  ValueType<SelectOption>,
                  ValueType<SelectOption>
                >('title', extractPassthrough)}
                onBlur={clearError('title')}
              />
              {titleError && (
                <ErrorMessage>
                  <FormattedMessage id={titleError} />
                </ErrorMessage>
              )}
            </StyledTitleContainer>
            <StyledNameContainer>
              <Label htmlFor="name">
                <FormattedMessage id="registration.MentorDetails_firstName" />
              </Label>
              <TextField
                id="name"
                name="name"
                value={name}
                placeholder={intl.formatMessage({
                  id: 'registration.MentorDetails_firstNamePH'
                })}
                onChange={createHandler<
                  SyntheticEvent<HTMLInputElement>,
                  string
                >('name', extractInputValue)}
                colour="yellow"
                error={!!nameError}
                onBlur={clearError('name')}
              />
              {nameError && (
                <ErrorMessage>
                  <FormattedMessage id={nameError} />
                </ErrorMessage>
              )}
            </StyledNameContainer>
          </StyledTitleNameRow>
          <FormField>
            <Label htmlFor="surname">
              <FormattedMessage id="registration.MentorDetails_lastName" />
            </Label>
            <TextField
              id="surname"
              name="surname"
              value={surname}
              placeholder={intl.formatMessage({
                id: 'registration.MentorDetails_lastNamePH'
              })}
              onChange={createHandler<SyntheticEvent<HTMLInputElement>, string>(
                'surname',
                extractInputValue
              )}
              colour="yellow"
              error={!!surnameError}
              onBlur={clearError('surname')}
            />
            {surnameError && (
              <ErrorMessage>
                <FormattedMessage id={surnameError} />
              </ErrorMessage>
            )}
          </FormField>
          <FormField>
            <Label htmlFor="phone">
              <FormattedMessage id="registration.MentorDetails_contactNumber" />
            </Label>
            <TextField
              id="phone"
              name="phone"
              value={phone}
              placeholder={intl.formatMessage({
                id: 'registration.MentorDetails_contactNumberPH'
              })}
              onChange={createHandler<SyntheticEvent<HTMLInputElement>, string>(
                'phone',
                extractInputPhoneNumber
              )}
              colour="yellow"
              error={!!phoneError}
              onBlur={() => {
                clearError('phone')()
                if (phone) {
                  setValues(vals => ({
                    ...vals,
                    phone: transformUserPhoneInput(phone)
                  }))
                }
              }}
            />
            {phoneError && (
              <ErrorMessage>
                <FormattedMessage id={phoneError} />
              </ErrorMessage>
            )}
          </FormField>
          <FormField>
            <Label htmlFor="community">
              <FormattedMessage id="registration.MentorDetails_community_dropdown" />
            </Label>
            <CommunitySelect
              id="community"
              onChange={createHandler<
                ValueType<SelectOption>,
                ValueType<SelectOption>
              >('community', extractPassthrough)}
              value={community ? community : null}
              filterCommunities={zip != null && !zip.freeTextUserInput}
              selectedZipId={zip ? zip.value : null}
              error={communityError}
              onBlur={clearError('community')}
            />
            <ErrorNotHereWrap>
              <InternalLink to={COMMUNITY_NOT_HERE_ROUTE}>
                <FormattedMessage id="registration.MentorDetails_title_dropdown_notHere" />
              </InternalLink>
              {communityError && (
                <ErrorMessage>
                  <FormattedMessage id={communityError} />
                </ErrorMessage>
              )}
            </ErrorNotHereWrap>
          </FormField>
          <FormField>
            <Label htmlFor="zip">
              <FormattedMessage id="registration.MentorDetails_zipCode_dropdown" />
            </Label>
            <ZipCodeSelect
              id="zip"
              value={zip}
              selectedCommunityId={community ? community.value : null}
              onChange={createHandler<
                ValueType<SelectOption>,
                ValueType<SelectOption>
              >('zip', extractPassthrough)}
              error={zipError}
              onBlur={clearError('zip')}
            />
            {zipError && (
              <ErrorMessage>
                <FormattedMessage id={zipError} />
              </ErrorMessage>
            )}
          </FormField>
          <FormField>
            <Label htmlFor="referrer">
              <FormattedMessage id="registration.mentorDetails_findDropdown" />
            </Label>
            <SelectWithTextOther<SelectWithFreeTextOption>
              value={referrer}
              changeHandler={createHandler<
                ValueType<SelectWithFreeTextOption>,
                ValueType<SelectWithFreeTextOption>
              >('referrer', extractPassthrough)}
              onBlur={clearError('referrer')}
              options={REFERRER_OPTIONS.sort().map(referrer => ({
                label: intl.formatMessage(
                  {
                    id: 'registration.MentorDetails_referrer_dropdown_option'
                  },
                  {referrer}
                ),
                value: referrer,
                freeTextUserInput: false
              }))}
              createOption={(
                label: string,
                value: string,
                isUserFreeText: boolean
              ): SelectWithFreeTextOption => ({
                label,
                value,
                freeTextUserInput: isUserFreeText
              })}
            />
            {referrerError && (
              <ErrorMessage>
                <FormattedMessage id={referrerError} />
              </ErrorMessage>
            )}
          </FormField>
          <StyledButtonContainer>
            <Button
              type="button"
              colour="yellow"
              onClick={goToOrganizationMembershipSubForm}
            >
              <FormattedMessage id="registration.MentorDetails_nextButton" />
            </Button>
          </StyledButtonContainer>
        </StyledForm>
      </StyledWrapper>
    )
  } else if (screen === 'ORGANIZATION_MEMBERSHIP') {
    return (
      <StyledWrapper>
        <StyledWelcomeText>
          <FormattedMessage id="registration.organizationMembership_H1" />
        </StyledWelcomeText>
        <StyledForm>
          <FormField>
            <Label htmlFor="relationship">
              <FormattedMessage id="registration.organizationMembership_relationship_label" />
            </Label>
            <Select<SelectOption>
              id="relationship"
              name="relationship"
              value={relationship}
              placeholder={intl.formatMessage({
                id: 'registration.MentorDetails_selectPH'
              })}
              options={RELATIONSHIPS.map(relationship => ({
                label: intl.formatMessage(
                  {
                    id: 'registration.organizationMembership_relationship_options'
                  },
                  {relationship}
                ),
                value: relationship
              }))}
              onChange={createHandler<
                ValueType<SelectOption>,
                ValueType<SelectOption>
              >('relationship', extractPassthrough)}
              onBlur={clearError('relationship')}
            />
            {relationshipError && (
              <ErrorMessage>
                <FormattedMessage id={relationshipError} />
              </ErrorMessage>
            )}
          </FormField>
          <FormField>
            <Label htmlFor="organization">
              <FormattedMessage
                id={
                  relationship?.value === 'GUARDIAN'
                    ? 'registration.organizationMembership_organization_optional_label'
                    : 'registration.organizationMembership_organization_label'
                }
              />
            </Label>
            <TextField
              id="organization"
              name="organization"
              value={organization}
              placeholder={intl.formatMessage({
                id: 'registration.organizationMembership_organizationPH'
              })}
              onChange={createHandler<SyntheticEvent<HTMLInputElement>, string>(
                'organization',
                extractInputValue
              )}
              onBlur={clearError('organization')}
              error={!!organizationError}
              colour="yellow"
            />
            {organizationError && (
              <ErrorMessage>
                <FormattedMessage id={organizationError} />
              </ErrorMessage>
            )}
          </FormField>
          <FormField>
            <Label htmlFor="group">
              <FormattedMessage
                id={
                  relationship?.value === 'GUARDIAN'
                    ? 'registration.organizationMembership_group_optional_label'
                    : 'registration.organizationMembership_group_label'
                }
              />
            </Label>
            <TextField
              id="group"
              name="group"
              value={group}
              placeholder={intl.formatMessage({
                id: 'registration.organizationMembership_groupPH'
              })}
              onChange={createHandler<SyntheticEvent<HTMLInputElement>, string>(
                'group',
                extractInputValue
              )}
              onBlur={clearError('group')}
              error={!!groupError}
              colour="yellow"
            />
            {groupError && (
              <ErrorMessage>
                <FormattedMessage id={groupError} />
              </ErrorMessage>
            )}
          </FormField>
          <StyledButtonContainer>
            <Button type="button" colour="yellow" onClick={goToContactSubForm}>
              <FormattedMessage id="registration.MentorDetails_nextButton" />
            </Button>
            <Button
              colour="yellow"
              type="button"
              onClick={goBackToDetailsSubForm}
            >
              <FormattedMessage id="registration.MentorProfile_backButton" />
            </Button>
          </StyledButtonContainer>
        </StyledForm>
      </StyledWrapper>
    )
  } else if (screen === 'CONTACT') {
    return (
      <StyledWrapper>
        {genericError && (
          <AlertWrapper>
            <Alert type="error" ref={alertRef}>
              <AlertContent>
                <FormattedMessage id={genericError} />
              </AlertContent>
            </Alert>
          </AlertWrapper>
        )}
        <StyledWelcomeText>
          <FormattedMessage id="registration.MentorProfile_H1" />
        </StyledWelcomeText>
        <StyledForm onSubmit={handleSubmit} ref={focusRef} tabIndex={-1}>
          <FormField>
            <Label htmlFor="email">
              <FormattedMessage id="registration.MentorProfile_email" />
            </Label>
            <TextField
              id="email"
              name="email"
              value={email}
              placeholder={intl.formatMessage({
                id: 'registration.MentorProfile_emailPH'
              })}
              onChange={createHandler<SyntheticEvent<HTMLInputElement>, string>(
                'email',
                extractInputValue
              )}
              colour="yellow"
              error={!!emailError}
              onBlur={clearError('email')}
            />
            {emailError && (
              <ErrorMessage>
                <FormattedMessage id={emailError} />
              </ErrorMessage>
            )}
          </FormField>
          <FormField>
            <Label htmlFor="password">
              <FormattedMessage id="general.password" />
            </Label>
            <Password
              id="password"
              name="password"
              value={password}
              placeholder={intl.formatMessage({
                id: 'general.passwordPlaceHolder'
              })}
              onChange={createHandler<SyntheticEvent<HTMLInputElement>, string>(
                'password',
                extractInputValue
              )}
              colour="yellow"
              error={!!passwordError}
              onBlur={clearError('password')}
            />
            {passwordError ? (
              <ErrorMessage>
                <FormattedMessage id={passwordError} />
              </ErrorMessage>
            ) : (
              <SideNote>
                <FormattedMessage id="registration.MentorProfile01_Fail_error2" />
              </SideNote>
            )}
          </FormField>
          <FormField>
            <Label htmlFor="repeatedPassword">
              <FormattedMessage id="registration.MentorProfile_confirm" />
            </Label>
            <Password
              id="repeatedPassword"
              name="repeatedPassword"
              value={repeatedPassword}
              placeholder={intl.formatMessage({
                id: 'registration.MentorProfile_confirmPH'
              })}
              onChange={createHandler<SyntheticEvent<HTMLInputElement>, string>(
                'repeatedPassword',
                extractInputValue
              )}
              colour="yellow"
              error={!!repeatedPasswordError}
              onBlur={clearError('repeatedPassword')}
            />
            {repeatedPasswordError && (
              <ErrorMessage>
                <FormattedMessage id={repeatedPasswordError} />
              </ErrorMessage>
            )}
          </FormField>
          <CheckBoxContainer>
            <CheckBox
              onChange={() =>
                setValues(vals => ({
                  ...vals,
                  termsConditions: !vals.termsConditions
                }))
              }
              checked={termsConditions}
              name="termsConditions"
            />
            <TermsConditionsWrapper>
              <FormattedMessage
                id="registration.MentorProfile_TsCs"
                values={{
                  link: (
                    <InternalLink to={LEGAL_TERMS_ROUTE} target="_blank">
                      <FormattedMessage id="registration.MentorProfile_TsCs_link" />
                    </InternalLink>
                  )
                }}
              />
            </TermsConditionsWrapper>
          </CheckBoxContainer>
          <ReCaptcha
            ref={recaptchaRef}
            onChange={onReCaptchaChange}
            onErrored={onReCaptchaError}
            value={recaptcha}
            recaptchaError={recaptchaError}
          />
          <StyledButtonContainer>
            <Button
              disabled={loading || !termsConditions}
              colour="yellow"
              type="submit"
            >
              <FormattedMessage id="registration.MentorProfile_doneButton" />
            </Button>
            <Button
              colour="yellow"
              type="button"
              onClick={goBackToOrganizationMembershipSubForm}
            >
              <FormattedMessage id="registration.MentorProfile_backButton" />
            </Button>
          </StyledButtonContainer>
        </StyledForm>
      </StyledWrapper>
    )
  }

  return null
}

export default RegistrationForm
