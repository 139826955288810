import React, {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import Id from '../../domain/id'
import {StoreVisitType} from '../../domain/storeVisitType'
import {
  SUPPLIES_SHOP_ROUTE,
  TOOL_SELECTOR_ROUTE
} from '../../global-constants/routingConstants'
import useRecordStoreVisit from '../../hooks/useRecordStoreVisit'
import {getMentor} from '../../store/mentors/mentors.selectors'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import Dialog from '../Dialog'
import FlatButton from '../FlatButton'
import HeadingGraphic from '../HeadingGraphic'
import Layout from '../Layout'
import TitleText from '../TitleText'

type AmazonList = {
  listUri: string
  imageUri: string
  label: string
  visitType: StoreVisitType
}

const amazonLists: AmazonList[] = [
  {
    listUri: 'https://www.amazon.com/finds/post/list/amzn1.ideas.219XC91T0UMMY',
    imageUri: require('./assets/recipe.png'),
    label: 'suppliesShop.lists.recipe',
    visitType: 'RECIPE'
  },
  {
    listUri: 'https://amzn.to/3sWs9j1',
    imageUri: require('./assets/stand.png'),
    label: 'suppliesShop.lists.stand',
    visitType: 'STAND'
  },
  {
    listUri: 'https://amzn.to/47QRABm',
    imageUri: require('./assets/twist.png'),
    label: 'suppliesShop.lists.twist',
    visitType: 'TWIST'
  }
]

function SuppliesShopPage() {
  const intl = useIntl()
  const mentor = useSelector(getMentor)
  const [visitType, setVisitType] = useState<StoreVisitType | null>(null)
  const {recordStoreVisit} = useRecordStoreVisit({})
  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="suppliesShop.title" />
        </TitleText>
      }
    >
      <div
        className="
          mx-auto mb-2 w-full space-y-2 px-2
          md:mb-4 md:space-y-4 md:px-4 lg:mb-6 lg:space-y-6 lg:px-6
          xl:mb-8 xl:max-w-7xl xl:space-y-8 xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={SUPPLIES_SHOP_ROUTE}
            to={SUPPLIES_SHOP_ROUTE}
            label={<FormattedMessage id="suppliesShop.pageTitle" />}
          />
        </Breadcrumb>
        <HeadingGraphic
          title={intl.formatMessage({id: 'suppliesShop.title'})}
        />
        <div className="typography-headline-5">
          <FormattedMessage id="suppliesShop.detail" />
        </div>
        <div className="typography-headline-5">
          <FormattedMessage id="suppliesShop.listHeading" />
        </div>
        <ul className="flex flex-wrap">
          {amazonLists.map((amazonList, index) => (
            <li key={index} className="mb-7 w-56">
              <a
                target="_blank"
                href={amazonList.listUri}
                className="flex flex-col items-center"
                rel="noreferrer"
                onClick={() => setVisitType(amazonList.visitType)}
                aria-labelledby={`shop-${amazonList.visitType}`}
              >
                <img
                  role="img"
                  alt=""
                  aria-labelledby={`shop-${amazonList.visitType}`}
                  src={amazonList.imageUri}
                  className="h-48 w-48"
                />
                <div
                  className="typography-headline-5 text-center"
                  id={`shop-${amazonList.visitType}`}
                >
                  <FormattedMessage id={amazonList.label} />
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <PurchaseConfirmationDialog
        open={visitType !== null}
        onClose={hasPurchased => {
          if (visitType) {
            recordStoreVisit({
              adultId: Id.parse<'Adult'>(mentor.id),
              type: visitType,
              purchased: hasPurchased
            })
            setVisitType(null)
          }
        }}
        storeVisitType={visitType}
      />
    </Layout>
  )
}

function PurchaseConfirmationDialog({
  open,
  onClose,
  storeVisitType
}: {
  open: boolean
  onClose: (hasPurchased: boolean) => void
  storeVisitType: StoreVisitType | null
}) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        // the user should not be able to dismiss the popup, so onClose will only be fired by the buttons
        return
      }}
    >
      <Dialog.Title
        className="typography-headline-4"
        id="purchase-confirmation-dialog-heading"
      >
        <FormattedMessage
          id={`suppliesShop.confirmation.heading.${
            !storeVisitType ? 'stand' : storeVisitType.toString().toLowerCase()
          }`}
        />
      </Dialog.Title>
      <Dialog.Description
        className="typography-headline-6 mt-8 rounded-md text-center"
        id="rollover-dialog-description"
      >
        <FormattedMessage id="suppliesShop.confirmation.body" />
      </Dialog.Description>
      <div
        className="mt-8 flex justify-center gap-10"
        id="rollover-dialog-buttons"
      >
        <div className="w-1/4">
          <FlatButton
            onClick={() => onClose(true)}
            type="button"
            variant="success"
            width="full"
          >
            <FormattedMessage id="suppliesShop.confirmation.yes" />
          </FlatButton>
        </div>
        <div className="w-1/4">
          <FlatButton
            onClick={() => onClose(false)}
            type="button"
            variant="success"
            width="full"
          >
            <FormattedMessage id="suppliesShop.confirmation.no" />
          </FlatButton>
        </div>
      </div>
    </Dialog>
  )
}

export default SuppliesShopPage
