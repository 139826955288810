import React from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route} from 'react-router-dom'
import {LOGIN_ROUTE} from '../../global-constants/routingConstants'
import {Role} from '../../store/authentication/authentication.type'
import {AppState} from '../../store/store'

type RouteProps = React.ComponentProps<typeof Route>

type Props = {readonly allowedRoles: Role[]} & Omit<
  RouteProps,
  'component' | 'render' | 'children'
> &
  // Only support the `component` Route prop for now
  // Support for the render props can be added when needed
  Required<Pick<RouteProps, 'component'>>

function PrivateRoute({component: Component, allowedRoles, ...rest}: Props) {
  const roles = useSelector((state: AppState) => state.auth.roles)
  const hasMatchingRole = allowedRoles.some(role => roles.indexOf(role) !== -1)
  return (
    <Route
      {...rest}
      render={props =>
        hasMatchingRole ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{pathname: LOGIN_ROUTE, state: {from: props.location}}}
          />
        )
      }
    />
  )
}

export default PrivateRoute
