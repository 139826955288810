import React from 'react'
import {FormattedMessage} from 'react-intl'
import MyLemonadeDayAppIcon from '../icons/MyLemonadeDayAppIcon'
import AppStoreDownloadLink from '../links/AppStoreDownloadLink'
import PlayStoreDownloadLink from '../links/PlayStoreDownloadLink'
import StyledButton from '../StyledButton'

type Props = Readonly<{
  onFooterClose: () => void
  open: boolean
}>

function Footer({onFooterClose, open}: Props) {
  return (
    <footer
      className={`
        ${
          !open ? 'translate-y-full transition-transform duration-1000' : ''
        } fixed bottom-0 hidden w-full flex-col items-center space-y-3 
        bg-white py-4 shadow-[0_-1px_4px] shadow-[rgba(0,0,0,0.2)] lg:flex
      `}
    >
      <p className="typography-caption text-center text-navy-primary">
        <FormattedMessage id="layout.footerText" />
      </p>
      <div className="flex w-full flex-1 justify-center space-x-2">
        <MyLemonadeDayAppIcon />
        <AppStoreDownloadLink />
        <PlayStoreDownloadLink />
      </div>
      <div className="absolute right-4 bottom-0 flex h-full items-center">
        <StyledButton
          onClick={() => {
            onFooterClose()
          }}
          type="button"
        >
          <FormattedMessage id="general.Close" />
        </StyledButton>
      </div>
    </footer>
  )
}

export default Footer
