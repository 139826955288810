import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router'
import ServerError from '../../errors/server'
import {NOTIFICATION_QUERY_PARAM} from '../../global-constants/constants'
import {userResetPassword} from '../../store/reset-password/reset-password.action'
import {ResetPassword} from '../../store/reset-password/reset-password.types'
import {AppDispatch, AppState} from '../../store/store'
import ResetPasswordForm from '../components/ResetPassword/ResetPasswordForm'

type Props = RouteComponentProps<Record<string, string>>

const TOKEN_QUERY_PARAM_KEY = 'token'
const PASSWORD_RESET_SUCCESS_QUERY_PARAM_VALUE = 'password-reset-succeeded'

function ForgotPasswordFormContainer({location, history}: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const isSubmitting = useSelector(
    (state: AppState) => state.resetPassword.inFlight
  )
  const [error, setError] = useState<ServerError | null>(null)
  return (
    <ResetPasswordForm
      error={error}
      submitting={isSubmitting}
      onSubmit={(data: ResetPassword) =>
        dispatch(userResetPassword(data))
          .then(() =>
            history.push(
              `/login?${NOTIFICATION_QUERY_PARAM}=${PASSWORD_RESET_SUCCESS_QUERY_PARAM_VALUE}`
            )
          )
          .catch(error => setError(error))
      }
      token={new URLSearchParams(location.search).get(TOKEN_QUERY_PARAM_KEY)}
      onClearError={() => setError(null)}
    />
  )
}

export default withRouter(ForgotPasswordFormContainer)
