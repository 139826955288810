import React from 'react'
import ToggleInput from './ToggleInput'

type Props = Readonly<{
  checked: boolean
  onChange: (value: boolean) => void
}>

function GenderToggle({checked, onChange}: Props) {
  return (
    <ToggleInput
      checked={checked}
      description="admin.communities.gender.description"
      label="admin.communities.gender.title"
      onChange={onChange}
    />
  )
}

export default GenderToggle
