import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import Event from '../domain/event'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'
import {CommunityId} from '../store/communities/communities.type'

type Options = Readonly<{
  communityId: CommunityId
  excludePast?: boolean
  onError?: (error: Error) => void
  onSuccess?: (events: ReadonlyArray<Event>) => void
}>

function useEvents({
  communityId,
  excludePast = true,
  onError,
  onSuccess
}: Options): {
  error: Error | null
  isLoading: boolean
  events: ReadonlyArray<Event> | undefined
  refetchEvents: () => void
} {
  const accessToken = useSelector(getAccessToken)
  const [events, setEvents] = useState<ReadonlyArray<Event> | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchEvents = useCallback(() => {
    setError(null)
    setIsLoading(true)
    getEvents(accessToken, communityId, excludePast)
      .then(events => {
        setEvents(events)
        onSuccess?.(events)
      })
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setIsLoading(false))
  }, [accessToken, communityId, excludePast, onError, onSuccess])

  useEffect(() => {
    fetchEvents()
  }, [fetchEvents])

  return {error, isLoading, events, refetchEvents: fetchEvents}
}

async function getEvents(
  accessToken: string,
  communityId: CommunityId,
  excludePast: boolean
): Promise<ReadonlyArray<Event>> {
  const url = new URL(`communities/${communityId}/events`, API_URL)
  url.searchParams.set('excludePast', excludePast.toString())
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    }
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }

  const body = await response.json()

  return parseResponseBody(body)
}

export function parseResponseBody(candidate: unknown): ReadonlyArray<Event> {
  if (!Array.isArray(candidate)) {
    throw new Error('Unexpected response body. Expected an array of Events.')
  }

  try {
    return candidate.map(Event.parse)
  } catch (error) {
    throw new Error(
      `Unexpected response body. Expected an array of Events. ${error.message}`,
      {cause: error}
    )
  }
}

export default useEvents
