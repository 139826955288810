import {PlusCircleIcon} from '@heroicons/react/24/outline'
import React, {FormEvent, useCallback, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useRouteMatch} from 'react-router'
import Community, {CommunityId} from '../../domain/community'
import Event from '../../domain/event'
import Id from '../../domain/id'
import {
  COMMUNITY_NOT_FOUND_ERROR_IDENTIFIER,
  EVENT_NOT_FOUND_ERROR_IDENTIFIER,
  EVENT_OVERLAPPING_ERROR_IDENTIFIER
} from '../../errors/errorIdentifiers'
import ServerError from '../../errors/server'
import {
  COMMUNITIES_ROUTE,
  COMMUNITY_ID_ROUTE_PLACEHOLDER,
  COMMUNITY_TOOL_SELECTOR_ROUTE,
  EVENT_ID_ROUTE_PLACEHOLDER,
  TOOL_SELECTOR_ROUTE
} from '../../global-constants/routingConstants'
import useCommunity from '../../hooks/useCommunity'
import useCreateEvent from '../../hooks/useCreateEvent'
import useEvents from '../../hooks/useEvents'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import DateRangePicker from '../DateRangePicker'
import Dialog from '../Dialog'
import Divider from '../Divider'
import FlatButton from '../FlatButton'
import Layout from '../Layout'
import MentorRegistrationLink from '../links/MentorRegistrationLink'
import LoadingIndicator from '../LoadingIndicator'
import TitleText from '../TitleText'
import Toast from '../Toast'
import EventRow from './EventRow'

function EventsPage() {
  const {
    params: {communityId}
  } = useRouteMatch<{communityId: CommunityId}>()

  const [error, setError] = useState<Error | null>(null)
  const onError = useCallback(error => setError(error), [setError])
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [addDialogOpen, setAddDialogOpen] = useState(false)

  const {isLoading, events, refetchEvents} = useEvents({
    communityId: communityId,
    onError
  })
  const {community} = useCommunity({
    communityId
  })
  let communityToolSelectorRoute = COMMUNITY_TOOL_SELECTOR_ROUTE.replace(
    COMMUNITY_ID_ROUTE_PLACEHOLDER,
    communityId
  )
  let communityName = ''
  if (community) {
    communityToolSelectorRoute = communityToolSelectorRoute.replace(
      EVENT_ID_ROUTE_PLACEHOLDER,
      community.currentEventId
    )
    communityName = Community.formatName(community)
  }

  function onSuccess(message: string) {
    onError(null)
    refetchEvents()
    setSuccessMessage(message)
  }

  // Remove any events preceding the current event regardless of dates
  const currentEventIndex: number | undefined = events?.findIndex(
    event => event.current
  )
  const slicedEvents = events?.slice(currentEventIndex)

  const currentEvent: Event | undefined = slicedEvents?.find(e => e.current)
  const upcomingEvent: Event | undefined =
    slicedEvents && slicedEvents.length > 1 ? slicedEvents[1] : undefined
  const futureEvents: ReadonlyArray<Event> =
    slicedEvents && slicedEvents.length > 2 ? slicedEvents.slice(2) : []

  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="admin.events.title" />
        </TitleText>
      }
      toast={
        <>
          {error && (
            <Toast errorCount={1} variant="error">
              <FormattedMessage id={getI18nErrorMessageId(error)} />
            </Toast>
          )}
          {successMessage && <Toast variant="success">{successMessage}</Toast>}
        </>
      }
    >
      <div
        className="
          mx-auto mb-2 w-full space-y-2 px-2
          md:mb-4 md:space-y-4 md:px-4 lg:mb-6 lg:space-y-6 lg:px-6
          xl:mb-8 xl:max-w-7xl xl:space-y-8 xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={COMMUNITIES_ROUTE}
            to={COMMUNITIES_ROUTE}
            label={<FormattedMessage id="manageCommunity.community_manager" />}
          />
          <BreadcrumbItem
            path={COMMUNITY_TOOL_SELECTOR_ROUTE}
            to={communityToolSelectorRoute}
            label={communityName}
          />
        </Breadcrumb>
        <div className="my-2 flex justify-between md:my-4 lg:my-6 xl:my-8">
          <h2 className="typography-headline-4 whitespace-nowrap">
            <FormattedMessage id="admin.events.heading" />
          </h2>
          <MentorRegistrationLink communityId={communityId} />
        </div>
        <>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              {currentEvent && (
                <ul
                  aria-labelledby="current-event-heading"
                  className="space-y-2 lg:space-y-4"
                >
                  <h3
                    className="typography-headline-5"
                    id="current-event-heading"
                  >
                    <FormattedMessage id="admin.events.currentEvent" />
                  </h3>
                  <Divider />
                  <li>
                    <EventRow
                      event={currentEvent}
                      onDeleteError={onError}
                      onDeleteSuccess={onSuccess}
                      onRollOverError={onError}
                      onRollOverSuccess={onSuccess}
                      onUpdateError={onError}
                      onUpdateSuccess={onSuccess}
                      variant="current"
                    />
                  </li>
                </ul>
              )}
              <ul
                aria-labelledby="upcoming-event-heading"
                className="space-y-2 lg:space-y-4"
              >
                <h3
                  className="typography-headline-5"
                  id="upcoming-event-heading"
                >
                  <FormattedMessage id="admin.events.upcomingEvent" />
                </h3>
                <Divider />
                {upcomingEvent ? (
                  <li>
                    <EventRow
                      event={upcomingEvent}
                      onDeleteError={onError}
                      onDeleteSuccess={onSuccess}
                      onRollOverError={onError}
                      onRollOverSuccess={onSuccess}
                      onUpdateError={onError}
                      onUpdateSuccess={onSuccess}
                      onRolloverNotificationError={onError}
                      onRolloverNotificationSuccess={onSuccess}
                    />
                  </li>
                ) : (
                  <li>
                    <p className="mx-auto w-fit">
                      <FormattedMessage id="admin.events.noUpcomingEvents" />
                    </p>
                  </li>
                )}
              </ul>
              {futureEvents.length > 0 && (
                <ul
                  aria-labelledby="future-events-heading"
                  className="space-y-2 lg:space-y-4"
                >
                  <h3
                    className="typography-headline-5"
                    id="future-events-heading"
                  >
                    <FormattedMessage id="admin.events.futureEvents" />
                  </h3>
                  <Divider />
                  {futureEvents.map(futureEvent => (
                    <li key={futureEvent.id}>
                      <EventRow
                        event={futureEvent}
                        onDeleteError={onError}
                        onDeleteSuccess={onSuccess}
                        onRollOverError={onError}
                        onRollOverSuccess={onSuccess}
                        onUpdateError={onError}
                        onUpdateSuccess={onSuccess}
                        onRolloverNotificationError={onError}
                        onRolloverNotificationSuccess={onSuccess}
                      />
                    </li>
                  ))}
                </ul>
              )}
              <div className="mx-auto w-fit">
                <FlatButton
                  variant="success"
                  icon={<PlusCircleIcon className="h-6 w-6" />}
                  onClick={() => {
                    setError(null)
                    setSuccessMessage(null)
                    setAddDialogOpen(true)
                  }}
                >
                  <FormattedMessage id="admin.events.add" />
                </FlatButton>
              </div>
            </>
          )}
        </>
      </div>
      <AddEventDialog
        communityId={communityId}
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onError={onError}
        onSuccess={onSuccess}
      />
    </Layout>
  )
}

type AddEventDialogProps = {
  communityId: CommunityId
  open: boolean
  onClose: () => void
  onError: (error: Error) => void
  onSuccess: (message: string) => void
}

function AddEventDialog({
  open,
  onClose,
  communityId,
  onError,
  onSuccess
}: AddEventDialogProps) {
  const intl = useIntl()
  const {busy, createEvent} = useCreateEvent({
    communityId: communityId,
    onError: onError,
    onSuccess: () =>
      onSuccess(intl.formatMessage({id: 'admin.events.eventAddSuccess'}))
  })
  const [dateRangeErrorMessage, setDateRangeErrorMessage] = useState<
    string | null
  >(null)
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  )
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear() + 1, 0, 1)
  )
  const formId = 'add-event'

  function handleFormSubmission() {
    let valid = true

    if (startDate > endDate) {
      setDateRangeErrorMessage(
        intl.formatMessage({
          id: 'admin.communities.dates.validationMessage'
        })
      )
      valid = false
    }

    if (endDate < new Date()) {
      setDateRangeErrorMessage(
        intl.formatMessage({
          id: 'admin.events.eventDatesPastValidation'
        })
      )
      valid = false
    }

    if (valid) {
      clearDateRangeValidationErrors()
      createEvent({
        communityId: Id.parse(communityId),
        startDate: startDate,
        endDate: endDate,
        current: false
      })
      onClose()
    }
  }

  function clearDateRangeValidationErrors() {
    setDateRangeErrorMessage(null)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="flex flex-col items-center space-y-4">
        <Dialog.Title className="typography-headline-4">
          <FormattedMessage id="admin.events.add" />
        </Dialog.Title>
        <form
          aria-label={intl.formatMessage({
            id: 'admin.events.add'
          })}
          name="create-event"
          id={formId}
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleFormSubmission()
          }}
        >
          <div className="flex w-full">
            <DateRangePicker
              errorMessage={dateRangeErrorMessage}
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={startDate => {
                setStartDate(startDate)
                clearDateRangeValidationErrors()
              }}
              onEndDateChange={endDate => {
                setEndDate(endDate)
                clearDateRangeValidationErrors()
              }}
              label={intl.formatMessage({id: 'admin.events.eventDate'})}
            />
          </div>
          <div className="mt-6 flex items-center justify-center gap-10">
            <div className="w-1/4">
              <FlatButton variant="success" onClick={onClose} width="full">
                <FormattedMessage id="general.cancel" />
              </FlatButton>
            </div>
            <div className="w-1/4">
              <FlatButton
                disabled={busy}
                variant="success"
                form={formId}
                type="submit"
                width="full"
              >
                <FormattedMessage id="general.save" />
              </FlatButton>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  )
}

function getI18nErrorMessageId(error: Error): string {
  if (ServerError.includes(error, COMMUNITY_NOT_FOUND_ERROR_IDENTIFIER)) {
    return 'admin.communities.notFoundError'
  } else if (ServerError.includes(error, EVENT_NOT_FOUND_ERROR_IDENTIFIER)) {
    return 'admin.events.notFoundError'
  } else if (ServerError.includes(error, EVENT_OVERLAPPING_ERROR_IDENTIFIER)) {
    return 'admin.events.overlappingErrorMessage'
  } else {
    return 'general.GenericError'
  }
}

export default EventsPage
