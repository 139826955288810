import {isNonBlankString} from '../responseRefiners'

export type Email = string & {brand: 'Email'}

export const EMAIL_REGEX = /^\S+@\S+\.\S+$/

/**
 * Parse an email address from an unknown candidate,
 * validating that it is a well-formed email address.
 *
 * @param candidate The candidate email address to parse.
 */
function parse(candidate: unknown): Email {
  if (!isNonBlankString(candidate)) {
    throw new Error('Email may not be blank, empty or null')
  }

  const trimmedCandidate = candidate.trim()
  if (!EMAIL_REGEX.test(trimmedCandidate)) {
    throw new Error('Email must be a valid email address')
  }

  return trimmedCandidate as Email
}

const email = {parse}
export default email
