import React from 'react'
import {useIntl} from 'react-intl'
import FocusableImage from '../../FocusableImage'
import {UnstyledExternalLink} from '../ExternalLink'
import youtubeIconUrl from './assets/youtube_icon.svg'

export const YOUTUBE_URL = 'https://www.youtube.com/c/lemonadeday'

function YoutubeLink() {
  const intl = useIntl()
  return (
    <UnstyledExternalLink to={YOUTUBE_URL}>
      <FocusableImage
        alt={intl.formatMessage({id: 'general.youtube'})}
        height={33}
        src={youtubeIconUrl}
        variant={'social'}
        width={33}
      />
    </UnstyledExternalLink>
  )
}

export default YoutubeLink
