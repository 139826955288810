import React from 'react'
import {IntlProvider} from 'react-intl'
import {useSelector} from 'react-redux'
import messages from './messages'
import {AppState} from './store/store'
import {UIState} from './store/ui/ui.types'

interface Props {
  children: React.ReactNode
}

function I18N({children}: Props) {
  const {locale} = useSelector<AppState, UIState>(state => state.ui)
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  )
}

export default I18N
