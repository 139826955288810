import * as api from '../../api'
import {getAdult} from '../adults/adults.selectors'
import {AppThunkAction} from '../store'
import {FluxStandardAction} from '../type'
import {CodeFields, CreateMentorFields, Mentor} from './mentors.type'

export const ATTEMPT_CREATE_MENTOR = 'mentors/ATTEMPT_CREATE_MENTOR'
export const CREATE_MENTOR = 'mentors/CREATE_MENTOR'
export const ATTEMPT_UPDATE_MENTOR = 'mentors/ATTEMPT_UPDATE_MENTOR'
export const UPDATE_MENTOR = 'mentors/UPDATE_MENTOR'

type AttemptCreateMentorAction = FluxStandardAction<
  typeof ATTEMPT_CREATE_MENTOR
>

type CreateMentorAction = FluxStandardAction<typeof CREATE_MENTOR, Mentor>

export type AttemptUpdateMentorAction = FluxStandardAction<
  typeof ATTEMPT_UPDATE_MENTOR
>

export type UpdateMentorAction = FluxStandardAction<
  typeof UPDATE_MENTOR,
  Mentor
>

export type MentorsActions =
  | AttemptCreateMentorAction
  | CreateMentorAction
  | AttemptUpdateMentorAction
  | UpdateMentorAction

export function createMentor(
  mentorFields: CreateMentorFields
): AppThunkAction<AttemptCreateMentorAction | CreateMentorAction> {
  return async (dispatch, getState) => {
    const {
      ui: {locale},
      mentors: {creating}
    } = getState()
    if (!creating) {
      dispatch({type: ATTEMPT_CREATE_MENTOR})
      try {
        const mentor = await api.createMentor({...mentorFields, locale})
        dispatch({type: CREATE_MENTOR, payload: mentor})
      } catch (error) {
        dispatch({type: CREATE_MENTOR, error: true, payload: error})
        throw error
      }
    }
  }
}

export function updateMentor(
  data: CodeFields
): AppThunkAction<AttemptUpdateMentorAction | UpdateMentorAction> {
  return async (dispatch, getState) => {
    const state = getState()
    const adult = getAdult(state)

    dispatch({type: ATTEMPT_UPDATE_MENTOR})
    try {
      const mentor = await api.updateMentor({...adult, ...data}, state)
      dispatch({type: UPDATE_MENTOR, payload: mentor})
    } catch (error) {
      dispatch({type: UPDATE_MENTOR, error: true, payload: error})
      throw error
    }
  }
}
