import {
  ArrowDownTrayIcon,
  BuildingOffice2Icon,
  Cog8ToothIcon,
  ShoppingBagIcon,
  UserPlusIcon,
  UsersIcon
} from '@heroicons/react/24/outline'
import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {
  COMMUNITIES_ROUTE,
  EDUCATIONAL_RESOURCES_ROUTE,
  LEARNERS_ROUTE,
  SUPPLIES_SHOP_ROUTE,
  TOOL_SELECTOR_ROUTE,
  USER_SEARCH_ROUTE
} from '../../global-constants/routingConstants'
import {getRoles} from '../../store/authentication/authentication.selectors'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import Divider from '../Divider'
import Layout from '../Layout'
import TitleText from '../TitleText'
import NavigationItem from './NavigationItem'

function ToolSelectorPage() {
  const intl = useIntl()
  const roles = useSelector(getRoles)
  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="admin.toolSelector.title" />
        </TitleText>
      }
    >
      <div
        className="
          mx-auto mb-2 w-full px-2
          md:mb-4 md:px-4 lg:mb-6 lg:w-2/3
          lg:px-6 xl:mb-8 xl:max-w-7xl xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
        </Breadcrumb>
        <h2 className="typography-headline-4 my-2 md:my-4 lg:my-6 xl:my-8">
          <FormattedMessage id="admin.toolSelector.subTitle" />
        </h2>
        <ul
          role="list"
          className="mx-auto flex w-full flex-1 flex-col rounded bg-white"
        >
          <NavigationItem
            name={intl.formatMessage({
              id: 'learners.title'
            })}
            route={LEARNERS_ROUTE}
            icon={
              roles.includes('ROLE_ADMIN') ? (
                <UsersIcon className="h-12 w-12 rounded-full bg-mustard p-2" />
              ) : (
                <UserPlusIcon className="h-12 w-12 rounded-full bg-teal p-2" />
              )
            }
          />
          {roles.includes('ROLE_ADMIN') && (
            <>
              <Divider />
              <NavigationItem
                name={intl.formatMessage({
                  id: 'manageCommunity.community_manager'
                })}
                route={COMMUNITIES_ROUTE}
                icon={
                  <BuildingOffice2Icon className="h-12 w-12 rounded-full bg-green p-2" />
                }
              />
              <Divider />
              <NavigationItem
                name={intl.formatMessage({
                  id: 'admin.userSettings.title'
                })}
                route={USER_SEARCH_ROUTE}
                icon={
                  <Cog8ToothIcon className="h-12 w-12 rounded-full bg-teal p-2" />
                }
              />
            </>
          )}
          {roles.includes('ROLE_MENTOR') && (
            <>
              <Divider />
              <NavigationItem
                name={intl.formatMessage({
                  id: 'educationalResources.title'
                })}
                route={EDUCATIONAL_RESOURCES_ROUTE}
                icon={
                  <span className="inline-flex rounded-full bg-teal">
                    <ArrowDownTrayIcon className="h-12 w-12  p-2" />
                  </span>
                }
              />
              <Divider />
              <NavigationItem
                name={intl.formatMessage({
                  id: 'suppliesShop.pageTitle'
                })}
                route={SUPPLIES_SHOP_ROUTE}
                icon={
                  <span className="inline-flex rounded-full bg-teal">
                    <ShoppingBagIcon className="h-12 w-12  p-2" />
                  </span>
                }
              />
            </>
          )}
        </ul>
      </div>
    </Layout>
  )
}

export default ToolSelectorPage
