import React from 'react'
import {useIntl} from 'react-intl'
import appIconUri from './assets/mld-app-icon.png'
import appIconUri2X from './assets/mld-app-icon@2x.png'
import appIconUri3X from './assets/mld-app-icon@3x.png'

function MyLemonadeDayAppIcon() {
  const intl = useIntl()

  return (
    <img
      alt={intl.formatMessage({id: 'general.MLD'})}
      className="shrink-0"
      src={appIconUri}
      srcSet={`${appIconUri}, ${appIconUri2X} 2x, ${appIconUri3X} 3x`}
    />
  )
}

export default MyLemonadeDayAppIcon
