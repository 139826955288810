import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router'
import {NOTIFICATION_QUERY_PARAM} from '../../global-constants/constants'
import {TOOL_SELECTOR_ROUTE} from '../../global-constants/routingConstants'
import {logUserIn} from '../../store/authentication/authentication.action'
import {AppDispatch} from '../../store/store'
import LoginForm from '../components/LoginForm'

type Props = RouteComponentProps<Record<string, string>>

function LoginFormContainer({history, location}: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  return (
    <LoginForm
      error={error}
      submitting={submitting}
      onSubmit={credentials => {
        setSubmitting(true)
        setError(null)
        dispatch(logUserIn(credentials))
          .then(() => {
            setSubmitting(false)
            history.push(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (location.state && location.state.from) || TOOL_SELECTOR_ROUTE
            )
          })
          .catch(err => {
            setSubmitting(false)
            setError(err)
            console.error(err)
          })
      }}
      notificationType={new URLSearchParams(location.search).get(
        NOTIFICATION_QUERY_PARAM
      )}
      onClearError={() => setError(null)}
    />
  )
}

export default withRouter(LoginFormContainer)
