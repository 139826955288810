import React from 'react'
import {useIntl} from 'react-intl'
import FocusableImage from '../../FocusableImage'
import {UnstyledExternalLink} from '../ExternalLink'
import instagramIconUri from './assets/instagram_icon.svg'

export const INSTAGRAM_URL = 'https://www.instagram.com/lemonadedaynational'

function InstagramLink() {
  const intl = useIntl()
  return (
    <UnstyledExternalLink to={INSTAGRAM_URL}>
      <FocusableImage
        alt={intl.formatMessage({id: 'general.instagram'})}
        height={33}
        src={instagramIconUri}
        variant={'social'}
        width={33}
      />
    </UnstyledExternalLink>
  )
}

export default InstagramLink
