import {css} from 'styled-components'

const shared = css`
  display: block;
  text-align: center;
`

export const heading = css`
  ${shared};
  margin-bottom: 2rem;
`

export const text = css`
  ${shared};
  margin-bottom: 1rem;
`

export const contentPadding = css`
  padding: 0 1rem 4rem 1rem;
`
export const buttonReset = css`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  outline: inherit;
  padding: 0;
  margin: 0;
`
