import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import Community, {CommunityId} from '../domain/community'
import Id from '../domain/id'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  onSuccess?: (communityId: CommunityId) => void
  onError?: (error: Error) => void
}>

export type CreateCommunityRequestBody = Omit<Community, 'id'> &
  Readonly<{
    firstEventStartDate: string
    firstEventEndDate: string
  }>

function useCreateCommunity({onSuccess, onError}: Options): {
  busy: boolean
  createCommunity: (requestBody: CreateCommunityRequestBody) => void
  error: Error | null
} {
  const accessToken = useSelector(getAccessToken)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const createCommunity = (requestBody: CreateCommunityRequestBody) => {
    setError(null)
    setBusy(true)
    postCommunity(accessToken, requestBody)
      .then(id => onSuccess?.(id))
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setBusy(false))
  }

  return {busy, error, createCommunity}
}

async function postCommunity(
  accessToken: string,
  requestBody: CreateCommunityRequestBody
): Promise<CommunityId> {
  const url = new URL('communities', API_URL).toString()
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    },
    body: JSON.stringify(requestBody)
  })

  if (response.status !== 201) {
    return ServerError.fromResponse(response)
  }

  const location = response.headers.get('Location')
  if (location == null) {
    throw new Error(`Unexpected Location header ${location}`)
  }

  return Id.parse(location.slice(location.lastIndexOf('/') + 1))
}

export default useCreateCommunity
