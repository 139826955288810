import {isNonBlankString} from '../responseRefiners'

export type Username = string & {brand: 'Username'}

function parse(candidate: unknown): Username {
  if (!isNonBlankString(candidate)) {
    throw new Error('Name may not be blank, empty or null')
  }

  const trimmedCandidate = candidate.trim()
  if (trimmedCandidate.length < 3 || trimmedCandidate.length > 15) {
    throw new Error('Name must be between 3 & 15 characters in length')
  }

  return trimmedCandidate as Username
}

const name = {parse}
export default name
