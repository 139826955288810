import {isBoolean, isPlainObject} from '../responseRefiners'
import CommunityStatus from './communityStatus'
import CommunityType from './communityType'
import CountryCode from './countryCode'
import CurrencyCode from './currencyCode'
import {EventId} from './event'
import Id from './id'
import LanguageTag from './languageTag'
import Name from './name'
import StateCode from './stateCode'
import TzIdentifier from './tzIdentifier'
import Zip from './zip'

export type CommunityId = Id<'Community'>

type Community = Readonly<{
  id: CommunityId
  type: CommunityType
  status: CommunityStatus
  name: Name
  state: StateCode | null
  country: CountryCode
  currency: CurrencyCode
  locale: LanguageTag
  timeZone: TzIdentifier
  zips: ReadonlyArray<Zip>
  ethnicityEnabled: boolean
  genderEnabled: boolean
  currentEventId: EventId
}>

function parse(candidate: unknown): Community {
  if (!isPlainObject(candidate) || !Array.isArray(candidate.zips)) {
    throw new Error(
      `Unexpected Community ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }

  return {
    id: Id.parse(candidate.id),
    type: CommunityType.parse(candidate.type),
    status: CommunityStatus.parse(candidate.status),
    name: Name.parse(candidate.name),
    state: candidate.state == null ? null : StateCode.parse(candidate.state),
    country: CountryCode.parse(candidate.country),
    currency: CurrencyCode.parse(candidate.currency),
    locale: LanguageTag.parse(candidate.locale),
    timeZone: TzIdentifier.parse(candidate.timeZone),
    zips: candidate.zips.map(rawZip => Zip.parse(rawZip)),
    ethnicityEnabled: parseEthnicityEnabled(candidate.ethnicityEnabled),
    genderEnabled: parseGenderEnabled(candidate.genderEnabled),
    currentEventId: Id.parse(candidate.currentEventId)
  }
}

function parseEthnicityEnabled(candidate: unknown): boolean {
  if (!isBoolean(candidate)) {
    throw new Error(
      `Unexpected Community.ethnicityEnabled ${candidate}, expected boolean`
    )
  }

  return candidate
}

function parseGenderEnabled(candidate: unknown): boolean {
  if (!isBoolean(candidate)) {
    throw new Error(
      `Unexpected Community.genderEnabled ${candidate}, expected boolean`
    )
  }

  return candidate
}

/**
 * Format {@link Community} name as name, country or name, state, country.
 *
 * @param community - A partial {@link Community}.
 * @param community.name - A {@link Name} name.
 * @param community.state - A {@link StateCode} or null.
 * @param community.country - A {@link CountryCode}.
 */
function formatName({
  name,
  state,
  country
}: Pick<Community, 'name' | 'state' | 'country'>): string {
  const stateCode = state?.split('-')[1]
  return stateCode == null
    ? `${name}, ${country}`
    : `${name}, ${stateCode}, ${country}`
}

const Community = {
  parse,
  formatName
}
export default Community
