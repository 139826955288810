import React from 'react'
import {
  BASE_CLASSES as BUTTON_BASE_CLASSES,
  getVariantClasses as getButtonVariantClasses
} from '../../StyledButton/index'
import {BASE_CLASSES, getVariantClasses} from '../util'

type Props = Readonly<{
  /**
   * Link Content
   */
  children: React.ReactNode
  /**
   * The href to navigate to.
   */
  to: string
}>

/**
 * A styled anchor opened with referrerPolicy=same-origin and target=_blank.
 */
function ExternalLink({children, to}: Props) {
  return (
    <UnstyledExternalLink
      className={`
        ${BASE_CLASSES}
        ${getVariantClasses('primary')}
      `}
      to={to}
    >
      {children}
    </UnstyledExternalLink>
  )
}

/**
 * An anchor opened with referrerPolicy=same-origin and target=_blank and styled
 * as a Button.
 */
export function ExternalLinkStyledAsButton({children, to}: Props) {
  return (
    <UnstyledExternalLink
      className={`
        ${BUTTON_BASE_CLASSES}
        ${getButtonVariantClasses('primary')}
      `}
      to={to}
    >
      {children}
    </UnstyledExternalLink>
  )
}

export function UnstyledExternalLink({
  children,
  className,
  to
}: Props &
  Readonly<{
    className?: string
  }>) {
  return (
    <a
      className={
        className == null
          ? 'outline-none focus:outline-none focus-visible:outline-none'
          : className
      }
      href={to}
      referrerPolicy="same-origin"
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  )
}

export default ExternalLink
