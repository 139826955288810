import {isNonBlankString, isPlainObject} from '../responseRefiners'
import PercentageFloatingPoint, {
  PercentageFloatingPoint as PercentageFloatingPointT
} from './percentageFloatingPoint'
import PositiveInteger, {PositiveInt as PositiveIntegerT} from './positiveInt'

export type Program<DateType extends Date | string = Date> = Readonly<{
  lastSyncDate: DateType | null
  mostRecentlyStartedModule: PositiveIntegerT | null
  mostRecentlyStartedLesson: PositiveIntegerT | null
  progress: PercentageFloatingPointT
}>

function parse(candidate: unknown): Program {
  if (
    !isPlainObject(candidate) ||
    (candidate.lastSyncDate !== null &&
      !isNonBlankString(candidate.lastSyncDate))
  ) {
    throw new Error(
      `Unexpected Program ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }

  return {
    lastSyncDate:
      candidate.lastSyncDate === null ? null : new Date(candidate.lastSyncDate),
    mostRecentlyStartedModule:
      candidate.mostRecentlyStartedModule === null
        ? null
        : PositiveInteger.parse(candidate.mostRecentlyStartedModule),
    mostRecentlyStartedLesson:
      candidate.mostRecentlyStartedLesson === null
        ? null
        : PositiveInteger.parse(candidate.mostRecentlyStartedLesson),
    progress: PercentageFloatingPoint.parse(candidate.progress)
  }
}

const program = {parse}
export default program
