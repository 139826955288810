import {AppState} from '../store'

export function getAccessToken(state: AppState) {
  if (state == null || state.auth == null || state.auth.accessToken == null) {
    throw new Error('No access token in state')
  }
  return state.auth.accessToken
}

export function getFirstLogin(state: AppState) {
  if (state == null || state.auth == null || state.auth.firstLogin == null) {
    return false
  }
  return state.auth.firstLogin
}

export function isAdminOnly(state: AppState) {
  const roles = getRoles(state)
  const singleRole = roles.length === 1
  const adminRole = roles.find(r => r === 'ROLE_ADMIN') != null
  const mentorRole = roles.find(r => r === 'ROLE_MENTOR') != null

  return singleRole && adminRole && !mentorRole
}

export function getRoles(state: AppState) {
  return state.auth.roles
}
