import React from 'react'
import {useIntl} from 'react-intl'
import CountryCode from '../domain/countryCode'
import CurrencyCode from '../domain/currencyCode'
import SelectInput from './SelectInput/SelectInput'

const CURRENCIES = [
  {code: 'CAD', nameMessageId: 'general.currency.cad'},
  {code: 'USD', nameMessageId: 'general.currency.usd'}
] as const

const CURRENCY_CODES_BY_COUNTRY_CODE = {
  CA: 'CAD',
  MX: null,
  US: 'USD'
} as const

type Currency = typeof CURRENCIES[number]

type Props = Readonly<{
  errorMessage?: string | null
  name?: string
  value: CurrencyCode | null
  onChange?: (value: CurrencyCode | null) => void
}>

function CurrencySelect({
  errorMessage = null,
  name = 'currency',
  value,
  onChange
}: Props) {
  const intl = useIntl()
  const options = CURRENCIES.map((option: Currency) => ({
    id: option.code,
    name: intl.formatMessage({id: option.nameMessageId})
  })).sort((a, b) => new Intl.Collator(intl.locale).compare(a.name, b.name))

  return (
    <SelectInput
      errorMessage={errorMessage}
      name={name}
      label={intl.formatMessage({id: 'admin.communities.currency.label'})}
      placeholderText={intl.formatMessage({
        id: 'admin.communities.selectSearch'
      })}
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export function getCurrencyCodeByCountryCode<C extends CountryCode>(
  countryCode: C | null
): CurrencyCode | null {
  return countryCode == null
    ? null
    : CURRENCY_CODES_BY_COUNTRY_CODE[countryCode]
}

export default CurrencySelect
