import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import {CommunityId} from '../domain/community'
import {LearnerId} from '../domain/learner'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  onError?: (error: Error) => void
  onSuccess?: () => void
}>

function useMigrateLearnerToCommunity({onError, onSuccess}: Options): {
  busy: boolean
  error: Error | null
  migrateLearnerToCommunity: (
    learnerId: LearnerId,
    communityId: CommunityId,
    migrateProgress: boolean
  ) => void
} {
  const accessToken = useSelector(getAccessToken)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const migrateLearnerToCommunity = (
    learnerId: LearnerId,
    communityId: CommunityId,
    migrateProgress = false
  ) => {
    setBusy(true)
    setError(null)

    putMigrateLearnerToCommunity(
      accessToken,
      learnerId,
      communityId,
      migrateProgress
    )
      .then(() => onSuccess?.())
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setBusy(false))
  }

  return {busy, error, migrateLearnerToCommunity}
}

async function putMigrateLearnerToCommunity(
  accessToken: string,
  learnerId: LearnerId,
  communityId: CommunityId,
  migrateProgress = false
): Promise<void> {
  const url = new URL(
    `learners/${learnerId}/communities/${communityId}`,
    API_URL
  )
  url.searchParams.append('migrateProgress', `${migrateProgress}`)

  const response = await fetch(url.toString(), {
    method: 'PUT',
    headers: {
      ...authorizationHeaderFromAccessToken(accessToken)
    }
  })

  if (response.status !== 204) {
    return ServerError.fromResponse(response)
  }
}

export default useMigrateLearnerToCommunity
