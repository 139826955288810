import {ChevronRightIcon} from '@heroicons/react/24/outline'
import React from 'react'
import {UnstyledInternalLink} from '../links/InternalLink'

type Props = Readonly<{
  name: string
  route: string
  icon?: React.ReactNode
}>

function NavigationItem({name, route, icon: Icon}: Props) {
  return (
    <li aria-label={name} key={name}>
      <UnstyledInternalLink
        aria-label={name}
        className="
          group flex items-center justify-between gap-x-3 rounded px-2 py-4 outline-none
          visited:text-purple
          hover:text-teal-secondary hover:underline hover:underline-offset-2
          focus:outline-none focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-navy
        "
        to={route}
      >
        <span className="group flex items-center gap-x-3">
          <span className="text-navy">{Icon}</span>
          <span className="typography-headline-5">{name}</span>
        </span>
        <span
          aria-hidden
          className="ml-auto w-6 min-w-max whitespace-nowrap px-2.5 py-0.5 text-center"
        >
          <ChevronRightIcon className="h-6 w-6" />
        </span>
      </UnstyledInternalLink>
    </li>
  )
}

export default NavigationItem
