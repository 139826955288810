import {Switch} from '@headlessui/react'
import React, {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {LearnerView} from './learnerView'
import {TABLE} from './learnerViews'
import LearnersViewStore from './learnerViewStore'

type Props = Readonly<{
  view: LearnerView
  onToggle: (view: LearnerView) => void
}>

function LearnerViewToggle({view, onToggle}: Props) {
  const [isTableView, setIsTableView] = useState(view === TABLE)

  function onLearnersViewChange() {
    setIsTableView(!isTableView)
    onToggle(LearnersViewStore.toggleView())
  }

  return (
    <div
      id="learner-view-toggle"
      className="flex flex-row items-center justify-center space-x-4"
    >
      <Switch.Group>
        <Switch.Label className="typography-body-1 text-navy">
          <FormattedMessage id="learners.toggle.cards" />
        </Switch.Label>
        <Switch
          checked={isTableView}
          onChange={() => {
            onLearnersViewChange()
          }}
          className={`${
            isTableView ? 'bg-navy' : 'bg-teal'
          } relative inline-flex h-4 w-11 cursor-pointer 
          items-center rounded-full focus:outline-none 
          focus-visible:ring-4 focus-visible:ring-navy 
          focus-visible:ring-offset-2`}
        >
          <span className="sr-only">
            <FormattedMessage id="learners.toggle.screenReader" />
          </span>
          <span
            className={`${
              isTableView ? 'translate-x-5' : 'translate-x-0'
            } shadow- pointer-events-none absolute left-0 
          inline-block h-6 w-6 transform rounded-full bg-mustard
          transition-transform duration-200 ease-in-out`}
          />
        </Switch>
        <Switch.Label className="typography-body-1 text-navy">
          <FormattedMessage id="learners.toggle.table" />
        </Switch.Label>
      </Switch.Group>
    </div>
  )
}

export default LearnerViewToggle
