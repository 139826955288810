import React, {useCallback, useEffect, useState} from 'react'
import Cropper from 'react-easy-crop'
import {Area, MediaSize} from 'react-easy-crop/types'
import {FormattedMessage} from 'react-intl'
import Dialog from '../../Dialog'
import StyledButton from '../../StyledButton'
import {getCroppedImg} from './canvasUtils'
import DragAndDrop from './DragAndDrop'

interface Props {
  onCrop: (croppedFile: File) => void
  onCancel: () => void
  open: boolean
}

const ImageCropper = ({onCrop, onCancel, open}: Props) => {
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [zoom, setZoom] = useState(0.9)
  const [showCropper, setShowCropper] = useState(false)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [fileSizeError, setFileSizeError] = useState(false)
  const [imageSizeWarning, setImageSizeWarning] = useState(false)

  useEffect(() => {
    if (imageFile) {
      const fileSize = imageFile.size / 1024 / 1024

      if (fileSize >= 2) {
        setFileSizeError(true)
        setShowCropper(false)
      } else {
        setFileSizeError(false)
      }
    }
  }, [imageFile, fileSizeError, showCropper])

  const retryUpload = () => {
    setImageFile(null)
    setShowCropper(false)
    setImageSizeWarning(false)
  }

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      if (imgSrc && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(
          imageFile ? imageFile.name : 'sponsor_image',
          imgSrc,
          croppedAreaPixels,
          imageFile ? imageFile.type : 'image/png'
        )

        if (croppedImage) {
          onCrop(croppedImage)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [imgSrc, croppedAreaPixels, imageFile, onCrop])

  const readFile = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const onUpload = async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length > 0) {
      const imageDataUrl = await readFile(acceptedFiles[0])
      if (imageDataUrl) {
        setImgSrc(imageDataUrl.toString())
      }
      setImageFile(acceptedFiles[0])
      setShowCropper(true)
    }
  }

  const onMediaLoaded = (media: MediaSize) => {
    setImageSizeWarning(media.naturalWidth < 693 || media.naturalHeight < 390)
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <div className="m-auto flex max-w-[80%] flex-col items-start space-y-4">
        <Dialog.Title className="typography-headline-5 mx-auto text-teal">
          <FormattedMessage id="manageCommunity.upload_image" />
        </Dialog.Title>
        {fileSizeError && (
          <div className="w-full ">
            <FormattedMessage id="manageCommunity.file_size_warning" />
          </div>
        )}
        {imageSizeWarning && (
          <div className="h-full w-full text-center">
            <FormattedMessage id="manageCommunity.image_size_warning" />
            &nbsp;
            <span className="cursor-pointer" onClick={retryUpload}>
              <FormattedMessage id="manageCommunity.new_image" />
            </span>
          </div>
        )}
        {!showCropper && <DragAndDrop onDrop={onUpload} />}

        {showCropper && imgSrc && (
          <>
            {!imageSizeWarning && (
              <div className="w-full p-2 text-right text-teal">
                <span className="cursor-pointer" onClick={retryUpload}>
                  <FormattedMessage id="manageCommunity.new_image" />
                </span>
              </div>
            )}
            <div className="relative m-auto h-96 max-h-[50vh] w-full">
              <Cropper
                image={imgSrc}
                crop={crop}
                zoom={zoom}
                minZoom={0.5}
                aspect={16 / 9}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onMediaLoaded={onMediaLoaded}
                zoomSpeed={0.1}
              />
            </div>
            <input
              type="range"
              className="h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-teal"
              value={zoom}
              min={0.5}
              max={3}
              step={0.1}
              onChange={({target: {value}}) => setZoom(Number(value))}
            />
            <div className="ml-auto flex">
              <StyledButton onClick={showCroppedImage} disabled={false}>
                <FormattedMessage id="manageCommunity.crop" />
              </StyledButton>
            </div>
          </>
        )}
      </div>
    </Dialog>
  )
}

export default ImageCropper
