import React from 'react'
import styled from 'styled-components'
import PublicFooter from '../../components/Footer/PublicFooter'
import Header from './Header'

interface Props {
  children?: React.ReactNode
  noShadow?: boolean
  footer?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`

const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 8px;
  width: 100%;
`

function Layout({children, noShadow, footer = true}: Props) {
  return (
    <Container>
      <Header noShadow={noShadow} />
      <Content>{children}</Content>
      {!!footer && <PublicFooter />}
    </Container>
  )
}

export default Layout
