import {CheckCircleIcon} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import React, {ReactNode} from 'react'

type Variant = 'success' | 'error'

type Props = Readonly<{
  children: ReactNode
  errorCount?: number | undefined
  variant?: Variant
}>

function Toast({children, errorCount = 0, variant = 'success'}: Props) {
  return (
    <div className="m-auto mt-4 flex w-full flex-auto items-end justify-end px-4 sm:px-6 lg:w-8/12 lg:px-8">
      <div
        className={clsx(
          'typography-body-1 lg:max-w-fit-content inline-flex w-full items-center rounded px-3 py-4 md:w-1/2 lg:w-2/3',
          variant === 'success' ? 'bg-green/50' : 'bg-red/50'
        )}
      >
        {getVariantIcons(variant, errorCount)}
        <span className="pl-2">{children}</span>
      </div>
    </div>
  )
}

function getVariantIcons(variant: Variant, errorCount: number) {
  if (variant === 'success') {
    return (
      <div className="rounded-full bg-green p-1">
        <CheckCircleIcon className="h-7 w-7" />
      </div>
    )
  } else if (variant === 'error') {
    if (errorCount > 0) {
      return (
        <div className="typography-subtitle-2 inline-flex h-7 w-7 items-center justify-center rounded-full bg-red-primary ">
          {errorCount}
        </div>
      )
    } else {
      return
    }
  }
}

export default Toast
