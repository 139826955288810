export default {
  general: {
    GenericError:
      'Sorry, something unexpected happened. Please contact your admin.',
    SessionExpired: 'Your session has expired.\nPlease log in again.',
    Logout: 'Logout',
    Close: 'Close',
    AppStoreAltText: 'Download My Lemonade Day on the App Store',
    PlayStoreAltText: 'Get My Lemonade Day on Google Play',
    MLD: 'My Lemonade Day',
    menu: 'Menu',
    busy: 'Busy...',
    facebook: 'Join the conversation on Facebook',
    instagram: 'Join the conversation on Instagram',
    pinterest: 'Join the conversation on Pinterest',
    twitter: 'Join the conversation on Twitter',
    youtube: 'Join the conversation on Youtube',
    sort: 'Sort',
    delete: 'Delete {thing}',
    active: 'Active',
    inactive: 'Inactive',
    blankErrorMessage: '* may not be blank',
    open: 'Open',
    save: 'Save',
    cancel: 'Cancel',
    confirm: 'Yes',
    edit: 'Edit',
    top: 'Top',
    clear: 'Clear',
    country: {
      ca: 'Canada (CA)',
      mx: 'Mexico (MX)',
      us: 'United States (US)'
    },
    currency: {
      cad: 'Canadian Dollar (CAD)',
      usd: 'United States Dollar (USD)'
    },
    locale: {
      'en-us': 'English (US)',
      'es-mx': 'Spanish (MX)'
    },
    state: {
      'ca-ab': 'Alberta (AB)',
      'ca-bc': 'British Columbia (BC)',
      'ca-mb': 'Manitoba (MB)',
      'ca-nb': 'New Brunswick (NB)',
      'ca-nl': 'Newfoundland and Labrador (NL)',
      'ca-ns': 'Nova Scotia (NS)',
      'ca-nt': 'Northwest Territories (NT)',
      'ca-nu': 'Nunavut (NU)',
      'ca-on': 'Ontario (ON)',
      'ca-pe': 'Prince Edward Island (PE)',
      'ca-qc': 'Quebec (QC)',
      'ca-sk': 'Saskatchewan (SK)',
      'ca-yt': 'Yukon (YT)',
      'mx-agu': 'Aguascalientes (AGU)',
      'mx-bcn': 'Baja California (BCN)',
      'mx-bcs': 'Baja California Sur (BCS)',
      'mx-cam': 'Campeche (CAM)',
      'mx-chh': 'Chihuahua (CHH)',
      'mx-chp': 'Chiapas (CHP)',
      'mx-coa': 'Coahuila de Zaragoza (COA)',
      'mx-col': 'Colima (COL)',
      'mx-dur': 'Durango (DUR)',
      'mx-gro': 'Guerrero (GRO)',
      'mx-gua': 'Guanajuato (GUA)',
      'mx-hid': 'Hidalgo (HID)',
      'mx-jal': 'Jalisco (JAL)',
      'mx-mex': 'México (MEX)',
      'mx-mic': 'Michoacán de Ocampo (MIC)',
      'mx-mor': 'Morelos (MOR)',
      'mx-nay': 'Nayarit (NAY)',
      'mx-nle': 'Nuevo León (NLE)',
      'mx-oax': 'Oaxaca (OAX)',
      'mx-pue': 'Puebla (PUE)',
      'mx-que': 'Querétaro (QUE)',
      'mx-roo': 'Quintana Roo (ROO)',
      'mx-sin': 'Sinaloa (SIN)',
      'mx-slp': 'San Luis Potosí (SLP)',
      'mx-son': 'Sonora (SON)',
      'mx-tab': 'Tabasco (TAB)',
      'mx-tam': 'Tamaulipas (TAM)',
      'mx-tla': 'Tlaxcala (TLA)',
      'mx-ver': 'Veracruz de Ignacio de la Llave (VER)',
      'mx-yuc': 'Yucatán (YUC)',
      'mx-zac': 'Zacatecas (ZAC)',
      'us-ak': 'Alaska (AK)',
      'us-al': 'Alabama (AL)',
      'us-ar': 'Arkansas (AR)',
      'us-az': 'Arizona (AZ)',
      'us-ca': 'California (CA)',
      'us-co': 'Colorado (CO)',
      'us-ct': 'Connecticut (CT)',
      'us-de': 'Delaware (DE)',
      'us-fl': 'Florida (FL)',
      'us-ga': 'Georgia (GA)',
      'us-hi': 'Hawaii (HI)',
      'us-ia': 'Iowa (IA)',
      'us-id': 'Idaho (ID)',
      'us-il': 'Illinois (IL)',
      'us-in': 'Indiana (IN)',
      'us-ks': 'Kansas (KS)',
      'us-ky': 'Kentucky (KY)',
      'us-la': 'Louisiana (LA)',
      'us-ma': 'Massachusetts (MA)',
      'us-md': 'Maryland (MD)',
      'us-me': 'Maine (ME)',
      'us-mi': 'Michigan (MI)',
      'us-mn': 'Minnesota (MN)',
      'us-mo': 'Missouri (MO)',
      'us-ms': 'Mississippi (MS)',
      'us-mt': 'Montana (MT)',
      'us-nc': 'North Carolina (NC)',
      'us-nd': 'North Dakota (ND)',
      'us-ne': 'Nebraska (NE)',
      'us-nh': 'New Hampshire (NH)',
      'us-nj': 'New Jersey (NJ)',
      'us-nm': 'New Mexico (NM)',
      'us-nv': 'Nevada (NV)',
      'us-ny': 'New York (NY)',
      'us-oh': 'Ohio (OH)',
      'us-ok': 'Oklahoma (OK)',
      'us-or': 'Oregon (OR)',
      'us-pa': 'Pennsylvania (PA)',
      'us-ri': 'Rhode Island (RI)',
      'us-sc': 'South Carolina (SC)',
      'us-sd': 'South Dakota (SD)',
      'us-tn': 'Tennessee (TN)',
      'us-tx': 'Texas (TX)',
      'us-ut': 'Utah (UT)',
      'us-va': 'Virginia (VA)',
      'us-vt': 'Vermont (VT)',
      'us-wa': 'Washington (WA)',
      'us-wi': 'Wisconsin (WI)',
      'us-wv': 'West Virginia (WV)',
      'us-wy': 'Wyoming (WY)'
    },
    password: 'Password',
    passwordPlaceHolder: 'Type your password',
    recaptchaValidationFailed: 'Recaptcha validation failed',
    recaptchaError: 'Recaptcha has encountered an error'
  },
  home: {
    welcome: 'Welcome to Lemonade Day'
  },
  layout: {
    footerText:
      'Download the "My Lemonade Day" app to access the learning content.'
  },
  learners: {
    mentorCode: {
      label: 'Your Mentor Code'
    },
    toggle: {
      cards: 'Cards',
      screenReader: 'Toggle Card or Table View',
      table: 'Table'
    },
    title: 'Your Learner Manager',
    tutorial: 'How do I add a Learner?',
    reasonForMentorCode: 'What is the mentor code for?',
    error_heading: 'Whoops!',
    error_body:
      'We were unable to load your learners.\nPlease check your network and try again.',
    error_retry: 'Retry',
    loading: 'Loading…',
    request_failure: 'Request Failure',
    request_failure_try_again: 'Please try again later.',
    duplicateLearner_title: 'Duplicate Learner',
    duplicateLearner_summary:
      'A learner with this name already exists in your table or the table of another connected mentor.',
    duplicateLearner_description:
      'Each learner requires a unique first and last name combination.\nWe suggest including a middle initial.',
    duplicateLearner_dismiss: 'Ok',
    guardianChange_title: 'Change Legal Guardian Email?',
    guardianChange_description:
      'Changing the guardian email will result in the removal of the following learner data: photo uploads, stand location information and contest entries.',
    guardianChange_confirmation:
      'Are you sure you want to change the Guardian Email?\nYou cannot undo this action.',
    guardianChange_dismiss: 'No',
    guardianChange_confirm: 'Yes',
    removeLearner_title: 'Remove Learner?',
    removeLearner_description:
      'Removing this learner will result in the removal of the following learner data: photo uploads, stand location information and contest entries.',
    removeLearner_confirmation:
      'Are you sure you want to remove this learner?\nYou cannot undo this action.',
    removeLearner_confirm: 'Remove',
    removeLearner_dismiss: 'No',
    resetLearnerPassword: {
      title: "Reset Learner's Password?",
      description:
        'Once you are happy with the password below, please communicate the new password to you learner and click confirm to complete the reset.',
      confirmation:
        "Are you sure you want to reset this learner's password?\nYou cannot undo this action.",
      safety:
        'Once you close this popup, the preview of this password will be lost for safety.',
      regenerate: 'Generate password',
      confirm: 'Confirm',
      dismiss: 'Cancel'
    },
    learnerNotFoundTitle: 'Learner not found',
    learnerNotFoundDescription:
      'Unable to update learner, as their account may have been deleted',
    tours: {
      addLearner: {
        toggle:
          "If you're adding a group of learners, try using the learner table instead!",
        names:
          'Learner First Name and Last Name are the only required fields. Each of your learners will need a unique name.',
        guardian:
          "Optionally, you can add a guardian email for your learner or if you're assuming that responsibility, you can add your email to accept permissions. This step is optional but your learner will not have access to upload photos, add a location or enter contests without guardian permission.",
        save: 'Once you\'ve added your learner, click the "Save this Learner" button.'
      },
      consent: {
        text: 'You will be asked to give 3 types of permissions for each learner.'
      },
      editLearner: {
        card: {
          edit: 'If there are any mistakes, you can edit learner information by tapping the "Edit" button.',
          view: 'Tap "View More" for more information on a learner, to change their password or remove them.'
        },
        row: {
          edit: 'If there are any mistakes, you can edit learner information by tapping the pencil icon.',
          view: 'Tap the profile icon for more information on a learner, to change their password or remove them.'
        }
      },
      back: 'Back',
      done: 'Done',
      next: 'Next',
      skip: 'Skip',
      uploadCsv: 'You can use a csv to upload multiple learners at once'
    }
  },
  registration: {
    WebHeaderNav_loginButton: 'Login',
    WebHeaderNav_registerButton: 'Register',
    WebHeaderNav_logo: 'Lemonade Day Logo',
    WebFooterNav_footerTitle:
      'With your help, we will plant the seeds that change lives.',
    WebFooterNav_donateButton: 'Donate',
    MentorDetails_H1: 'Welcome, we are excited for you to join us as a mentor!',
    MentorDetails_H2: 'Please enter your mentor details',
    MentorDetails_title_dropdown: 'Title (Optional)',
    MentorDetails_title_dropdown_notHere: 'Not here?',
    MentorDetails_title_dropdown_option:
      '{title, select, miss {Miss} mr {Mr} mrs {Mrs} dr {Dr} prof {Prof} rev {Rev} sir {Sir} sr {Sister} fr {Father} other {Other}}',
    MentorDetails_selectPH: 'Select',
    MentorDetails_firstName: 'First name',
    MentorDetails_firstNamePH: 'Type your first name',
    MentorDetails_lastName: 'Last name',
    MentorDetails_lastNamePH: 'Type your last name',
    MentorDetails_contactNumber: 'Phone Number (Optional)',
    MentorDetails_contactNumberPH: '000-000-0000',
    MentorDetails_community_dropdown: 'Community',
    MentorDetails_communityPH: 'Type your community',
    MentorDetails_zipCode_dropdown: 'Zip code / Postal code',
    MentorDetails_zipCodePH: 'Type your zip code / postal code',
    mentorDetails_findDropdown:
      'How did you hear about My Lemonade Day? (Optional)',
    mentorDetails_findDropdown_PH: '*Please specify',
    MentorDetails_referrer_dropdown_option:
      "{referrer, select, nickelodeon {Nickelodeon} community_event {Community Event} social_media {Social Media} youth_organization {Youth Organization} faith_based_organization {Faith Based Organization} school {School} advertisement {Advertisement} raising_canes {Raising Cane's} other {Other}}",
    mentorDetails_findDropdown_option8: 'Other (*Please specify)',
    MentorDetails_nextButton: 'Next',
    MentorDetails_Fail_errorTitle: 'Title is required',
    MentorDetails_Fail_errorFirstName: 'First name is required',
    MentorDetails_Fail_errorPhone: 'Phone number invalid',
    MentorDetails_Fail_errorLastName: 'Last name is required',
    MentorDetails_Fail_errorCommunity: 'Community is required',
    MentorDetails_Fail_errorZip: 'Zip code / postal code is required',
    MentorDetails_Fail_errorReferrer: 'Referrer is required',
    MentorProfile_H1: 'Register by entering your email and creating a password',
    MentorProfile_email: 'Email',
    MentorProfile_emailPH: 'Type in your email address',
    MentorProfile_confirm: 'Confirm password',
    MentorProfile_confirmPH: 'Confirm your password',
    MentorProfile_backButton: 'Back',
    MentorProfile_doneButton: 'Done',
    MentorProfile_TsCs: 'I accept the terms of this {link}',
    MentorProfile_TsCs_link: 'Agreement and Privacy Policy',
    MentorProfile01_Fail_error1: 'Email already in use',
    MentorProfile01_Fail_error2: '*Password must have at least 8 characters',
    MentorProfile01_Fail_error3: 'Password does not match',
    MentorProfile01_Fail_error4: 'Email address is required',
    MentorProfile01_Fail_error5: 'Password is required',
    MentorProfile01_Fail_error6: 'Invalid email address',
    MentorProfile_ServerError_generic:
      'Sorry, something unexpected happened. Please contact your admin.',
    MentorProfile_ServerError_serverInvalidPassword:
      'Password must have between 8 and 255 characters',
    MentorProfile_ServerError_serverLegalAcceptedDate:
      'You must accept the terms',
    MentorProfile_ServerError_serverCommunityIdNotFound:
      'That community was not found',
    MentorProfile_ServerError_serverNoAssociatedZip:
      'Zip code / postal code not associated with community.',
    CommunityNotHere_H1: '{numberOfCommunities, number} Communities so far!',
    CommunityNotHere_B1: 'Lemonade Day is expanding its reach every day!',
    CommunityNotHere_B2:
      'Unfortunately your community has not been registered yet.',
    CommunityNotHere_BackButton: 'Back',
    VerifyEmail_H1: 'Thank you for registering.',
    VerifyEmail_B1:
      'Please verify your email address before you can create learner profiles.',
    VerifyEmail_B2: 'Check your spam folder if you can’t find our email.',
    mentorLogin_H1: 'Enter your login email and password',
    mentorLogin_email: 'Email',
    mentorLogin_emailPH: 'Type your email address here',
    mentorLogin_passwordPH: 'Type your password here',
    mentorLogin_forgotPassword: 'Forgot Password?',
    mentorLogin_forgotPassword_send: 'Send',
    mentorLogin_resetPassword_Email_H1: 'Enter your email below.',
    mentorLogin_resetPassword_Email_H2:
      'We will send you a password reset email.',
    mentorLogin_loginButton: 'Login',
    mentorLogin_signup: 'Don’t have an account?',
    mentorLogin_signup_link: 'Register',
    mentorLoginFail_error: 'Incorrect email or password',
    resetPassword_Success_B1: 'Your password has been successfully changed.',
    LegalTerms_H1: 'Agreement and Privacy Policy',
    LegalTerms_subtitle:
      'We take your privacy very seriously. We will never share or sell your information.',
    LegalTerms_section1_title: 'Commitment to your privacy ',
    LegalTerms_section1_p:
      'This site is owned and operated by P4L dba Lemonade Day (Lemonade Day). Your privacy on the Internet is of the utmost importance to us. At Lemonade Day, we want to make your experience online enjoyable, educational, and safe.Because we gather certain types of information about our users, we feel you should fully understand the terms and conditions surrounding the capture and use of that information. This privacy statement discloses what information we gather and how we use it.',
    LegalTerms_section2_title: 'Information Lemonade Day gathers and tracks',
    LegalTerms_section2_p:
      'Lemonade Day gathers two types of information about users: Information that users provide through optional, voluntary submissions. These are voluntary submissions to receive our electronic newsletters, to participate in our message boards or forums, to email a friend, and from participation in polls and surveys:',
    LegalTerms_section2_p1:
      'Information Lemonade Day gathers through aggregated tracking information derived mainly by tallying page views throughout our sites. This information allows us to better tailor our content to readers’ needs and to help our advertisers and sponsors better understand the demographics of our audience. Because Lemonade Day derives its revenue mainly from sponsorships and advertising, providing such aggregated demographic data is essential to keeping our service free to users. Under no circumstances does Lemonade Day divulge any information about an individual user to a third party.',
    LegalTerms_section3_title:
      'Lemonade Day gathers user information in the following processes:',
    LegalTerms_section3_p:
      'Optional Voluntary Information. We offer the following free services, which require some type of voluntary submission of personal information by users:',
    LegalTerms_section3_p1:
      '1. Electronic newsletters (Dispatches): We will offer a free electronic newsletter to users. Lemonade Day gathers the email addresses of users who voluntarily subscribe. Users may remove themselves from this mailing list by following the link provided in every newsletter that points users to the subscription management page. Users can also subscribe to the newsletters at the time of registration.',
    LegalTerms_section3_p2:
      '2. Message boards/forums: Users of the site’s Message Boards and Forums must register separately for these services (both are free of charge) in order to post messages, although they needn’t register to visit the site. During registration the user is required to supply a username, password, and email address.',
    LegalTerms_section3_p3:
      '3. “E-mail this to a friend” Service: Our site users can choose to electronically forward a link, page, or documents to someone else by clicking “e-mail this to a friend”. The user must provide their email address, as well as that of the recipient. This information is used only in the case of transmission errors and, of course, to let the recipient know who sent the email. The information is not used for any other purpose.',
    LegalTerms_section3_p4:
      '4. Surveys: Lemonade Day may occasionally conduct user surveys to better target our content to our audience. We sometimes share the aggregated demographic information in these surveys with our sponsors, advertisers and partners. We never share any of this information about specific individuals with any third party.',
    LegalTerms_section4_title: 'Children',
    LegalTerms_section4_p:
      'Consistent with the Federal Children’s Online Privacy Protection Act of 1998 (COPPA), we will never knowingly request personally identifiable information from anyone under the age of 13 without requesting parental consent.',
    LegalTerms_section5_title: 'Usage tracking',
    LegalTerms_section5_p:
      'Lemonade Day tracks user traffic patterns throughout all of our sites. However, we do not correlate this information with data about individual users. Lemonade Day does break down overall usage statistics according to a user’s domain name, browser type, and MIME type by reading this information from the browser string (information contained in every user’s browser).',
    LegalTerms_section5_p1:
      'Lemonade Day sometimes tracks and catalogs the search terms that users enter in our Search function, but this tracking is never associated with individual users. We use tracking information to determine which areas of our sites users like and don’t like based on traffic to those areas. We do not track what individual users read, but rather how well each page performs overall. This helps us continue to build a better service for you.',
    LegalTerms_section5_p2:
      'We may place a text file called a “cookie” in the browser files of your computer. The cookie itself does not contain Personal Information although it will enable us to relate your use of this site to information that you have specifically and knowingly provided. But the only personal information a cookie can contain is information you supply yourself. A cookie can’t read data off your hard disk or read cookie files created by other sites. Lemonade Day uses cookies to track user traffic patterns (as described above). Our advertising system delivers a one-time cookie to better track ad impressions and click rates.',
    LegalTerms_section5_p3:
      'You can refuse cookies by turning them off in your browser. If you’ve set your browser to warn you before accepting cookies, you will receive the warning message with each cookie. You do not need to have cookies turned on to use this site. However, you do need cookies to participate actively in message boards, forums, polling and surveys.',
    LegalTerms_section6_title: 'Use of information ',
    LegalTerms_section6_p:
      'Lemonade Day uses any information voluntarily given by our users to enhance their experience in our network of sites, whether to provide interactive or personalized elements on the sites or to better prepare future content based on the interests of our users.',
    LegalTerms_section6_p1:
      'As stated above, we use information that users voluntarily provide in order to send out electronic newsletters and to enable users to participate in polls, surveys, message boards, and forums. We send out newsletters to subscribers on a regular schedule (depending on the newsletter), and occasionally send out special editions when we think subscribers might be particularly interested in something we are doing. Lemonade Day never shares newsletter mailing lists with any third parties, including advertisers, sponsors or partners.',
    LegalTerms_section6_p2:
      'Lemonade Day creates aggregate reports on user demographics and traffic patterns for advertisers, sponsors and partners. This allows our advertisers to advertise more effectively, and allows our users to receive advertisements that are pertinent to their needs. Because we don’t track the usage patterns of individual users, an advertiser or sponsor will never know that a specific user clicked their ad. We will not disclose any information about any individual user except to comply with applicable law or valid process or to protect the personal safety of our users or the public.',
    LegalTerms_section7_title: 'Sharing of the information ',
    LegalTerms_section7_p:
      'Lemonade Day uses the above-described information to tailor our content to suit your needs and help our advertisers better understand our audience’s demographics. This is essential to keeping our service free. We will not share information about individual users with any third party, except to comply with applicable law or valid process or to protect the personal safety of our users or the public.',
    LegalTerms_section8_title: 'Security',
    LegalTerms_section8_p:
      'Lemonade Day operates secure data networks protected by industry standard firewall and password protection systems. Our security and privacy policies are periodically reviewed and enhanced as necessary and only authorized individuals have access to the information provided by our customers.',
    LegalTerms_section9_title: 'Opt-out policy',
    LegalTerms_section9_p:
      'We give users options wherever necessary and practical. Such choices include: Opting not to register to receive our electronic newsletters.Opting not to participate in certain interactive areas, which completely alleviates the need to gather any personally identifiable information from our users',
    LegalTerms_section10_title: 'Your consent',
    LegalTerms_section10_p:
      'By using this site, you consent to the collection and use of this information by Lemonade Day If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.',
    resetPassword_Sent_H1: 'Check your mail.',
    resetPassword_Sent_B1:
      'We have emailed you a link where you can change your password.',
    resetPassword_Sent_B2:
      'Check your spam folder if you can’t find our email.',
    resendVerificationEmail_Sent_H1: 'Check your mail.',
    resendVerificationEmail_Sent_B1:
      'Please verify your email address before you can create learner profiles.',
    resendVerificationEmail_Sent_B2:
      'Check your spam folder if you can’t find our email.',
    resetPassword_Sent_backButton: 'Back',
    resetPassword_header1: 'Reset your password',
    resetPassword_newPasswordPH: 'Type in your password',
    resetPassword_confirmPassword: 'Confirm password',
    resetPassword_confirmPasswordPH: 'Confirm your password',
    resetPassword_changeButton: 'Change',
    resetPassword_Fail_error1: '*Password must have at least 8 characters',
    resetPassword_Fail_error2: 'Password is required',
    resetPassword_Fail_error3: 'Password does not match',
    resetPassword_ServerError_generic:
      'Sorry, something unexpected happened. Please try again or contact your admin.',
    ForgotPassword_ServerError_generic:
      'Sorry, something unexpected happened. Please try again or contact your admin.',
    organizationMembership_H1:
      'Let us know which organization you are a member of?',
    organizationMembership_relationship_label: 'Role',
    organizationMembership_relationship_options:
      '{relationship, select, GUARDIAN {Guardian / Parent / Mentor} GROUP_LEADER {Group Leader} TEACHER {Teacher} other {Other}}',
    organizationMembership_relationship_error: 'Relationship is required',
    organizationMembership_organization_label: 'Organization/School',
    organizationMembership_organization_optional_label:
      'Organization/School (Optional)',
    organizationMembership_organizationPH:
      'Enter the name of your organization',
    organizationMembership_organization_error: 'Organization is required',
    organizationMembership_group_label: 'Group',
    organizationMembership_group_optional_label: 'Group (Optional)',
    organizationMembership_groupPH: 'Enter the name of your group',
    organizationMembership_group_error: 'Group is required'
  },
  login: {
    verifiedMentor_Login_top_popUp_H1: 'Thank you for verifying your email.',
    verifiedMentor_Login_Fail_top_popUp_H1:
      'A problem occurred with verifying your email. Please try again.',
    verifiedMentor_Login_Fail_top_popUp_H2: 'For help email {supportEmail}',
    mentorLoginFail_top_popUp_H1: 'Please verify your email before logging in.',
    mentorLoginFail_top_popUp_H2: 'Resend the email'
  },
  learnerCreator: {
    error_general_title: 'Something went wrong!',
    error_general_support: 'If the issue persists please contact support.',
    error_general_dismiss: 'I understand',
    error_duplicate_title: 'Duplicate Learner',
    error_duplicate_summary:
      'A learner with this name already exists in your table or the table of another connected mentor.',
    error_duplicate_description:
      "Each learner requires a unique first and last name combination. We've automatically added a random number, but please update it accordingly. We suggest including a middle initial.",
    error_duplicate_cancel: 'Discard this update',
    error_duplicate_save: 'Save update',
    error_duplicate_failed:
      'Attempt failed, first and last name combination is not unique',
    topbar_button_save: 'Save',
    topbar_button_cancel: 'Cancel',
    topbar_button_upload_csv: 'Upload CSV',
    pagination_clear_all: 'Clear all filters',
    pagination_page: 'Page',
    pagination_of: 'of',
    pagination_show: 'Show',
    addLearner_tooltip_invited: 'Learner has not registered yet.',
    addLearner_tooltip_locked:
      'Account locked. Too many incorrect password entries.',
    addLearner_tooltip_reset:
      'Learner still needs to respond to password reset.',
    addLearner_tooltip_add: 'Add learner',
    addLearner_tooltip_not_sent: 'Send email to guardian',
    addLearner_tooltip_sent: 'Email already sent to guardian',
    addLearner_tooltip_disabled: 'Sending of email disabled',
    addLearner_filter_search_placeholder: 'Search Records',
    addLearner_filter_min: 'Min',
    addLearner_filter_max: 'Max',
    addLearner_filter_lesson: 'Lesson',
    addLearner_filter_to: 'to',
    addLearner_all: 'All',
    addLearner_title: 'Register a Learner',
    addLearner_section1_title: 'Learner',
    addLearner_section1_column1: 'Profile',
    addLearner_section1_column1_filter_title: 'Filter profile by',
    addLearner_section1_column1_filter_option_1: 'Not yet registered',
    addLearner_section1_column1_filter_option_2: 'Registered',
    addLearner_section1_column1_filter_option_3: 'Locked out of account',
    addLearner_section1_column2: 'First Name',
    addLearner_section1_column2_filter_title: 'Filter first name by',
    addLearner_section1_column2PH: 'Add First Name',
    addLearner_section1_column2_required: 'First Name is required',
    addLearner_section1_column3_filter_title: 'Filter last name by',
    addLearner_section1_column3: 'Last Name',
    addLearner_section1_column3PH: 'Add Last Name',
    addLearner_section1_column3_required: 'Last Name is required',
    addLearner_section1_duplicate_learner_for_created_by_mentor:
      'You already have a learner with this name in your table. Please alter this name, we suggest including a middle initial.',
    addLearner_section1_duplicate_learner_for_connected_mentor:
      'Another mentor connected to this learner has already used this name in their table. Please alter this name, we suggest including a middle initial.',
    addLearner_section2_title: 'Permission',
    addLearner_section2_column1: 'Media',
    addLearner_section2_column1_filter_title: 'Filter media entries by',
    addLearner_section2_column1_filter_option_1: 'All',
    addLearner_section2_column1_filter_option_2: 'Accepted',
    addLearner_section2_column1_filter_option_3: 'Declined',
    addLearner_section2_column1_filter_option_4: 'Not specified',
    addLearner_section2_column2: 'Location',
    addLearner_section2_column2_filter_title: 'Filter location entries by',
    addLearner_section2_column2_filter_option_1: 'All',
    addLearner_section2_column2_filter_option_2: 'Accepted',
    addLearner_section2_column2_filter_option_3: 'Declined',
    addLearner_section2_column2_filter_option_4: 'Not specified',
    addLearner_section2_column3: 'Contest Entry',
    addLearner_section2_column3_filter_title: 'Filter contest entries by',
    addLearner_section2_column3_filter_option_1: 'All',
    addLearner_section2_column3_filter_option_2: 'Accepted',
    addLearner_section2_column3_filter_option_3: 'Declined',
    addLearner_section2_column3_filter_option_4: 'Not specified',
    addLearner_section2_column4: 'Guardian Email',
    addLearner_section2_column4_filter_title: 'Filter email by',
    addLearner_section2_column4PH: 'Add Email Address',
    addLearner_section2_column4_invalid: 'Email Address is invalid',
    addLearner_section2_column5: 'Sent',
    addLearner_section2_column5_filter_title: 'Filter Mentor invitations by',
    addLearner_section2_column5_filter_option_1: 'Sent',
    addLearner_section2_column5_filter_option_2: 'Not sent',
    addLearner_section2_column5_filter_option_3: 'Is Mentor',
    addLearner_section2_column6: 'Contact Number',
    addLearner_section2_column6_filter_title: 'Filter contact number by',
    addLearner_section2_column6PH: 'Add Contact Number',
    addLearner_section2_column6_invalid: 'Contact number is invalid',
    addLearner_section3_title: 'Program',
    addLearner_section3_now:
      'Now have your learner download the MLD app to create their profile',
    addLearner_section3_next_step_full: 'What do we do next?',
    addLearner_section3_next_step_short: 'What next?',
    addLearner_section3_column1: 'Sync',
    addLearner_section3_column1_filter_title: 'Filter sync by',
    addLearner_section3_column2: 'Lesson',
    addLearner_section3_column2_filter_title: 'Filter lesson by',
    addLearner_section3_column3: 'Progress',
    addLearner_section3_column3_filter_title: 'Filter progress by',
    addLearner_section3_column4: 'Results',
    addLearner_section3_column4_filter_title: 'Filter results by',
    addLearner_section3_column4_filter_option_1: 'Not submitted',
    addLearner_section3_column4_filter_option_2: 'Submitted',
    addLearner_section3_column4_filter_option_3: 'In progress',
    addLearner_mentorCode_title: 'Mentor Code',
    addLearner_mentorCode_button: 'What happens now?',
    addLearner_save_entry: 'Save This Learner',
    mentorCode_popup_step: 'step',
    mentorCode_popup_back: 'Back',
    mentorCode_popup_next: 'Next',
    mentorCode_popup_done: 'Done',
    mentorCode_popup1_title: 'Put learners in the table',
    mentorCode_popup1_body:
      'The only mandatory step to add your learner is entering their first and last name into the table. If you wish to skip the permissions step click the “Save This Learner" button now. Repeat for all your learners.',
    mentorCode_popup2_title: 'Add a Guardian',
    mentorCode_popup2_body:
      'This step is optional, but your learner will not have access to upload photos, add a location or enter contests without guardian permissions. Add their guardian’s email address to request permissions or if you are assuming the responsibility of guardian, add your email.',
    mentorCode_popup3_title: 'Download the App',
    mentorCode_popup3_body:
      'Depending on the device your learner has available to them, open the <apple>App store</apple> or the <google>Play store</google>, search for My Lemonade Day and download the app.',
    mentorCode_popup4_title: 'Open the App',
    mentorCode_popup4_body:
      'Look for the My Lemonade Day icon on your device, click it to open the app.',
    mentorCode_popup5_title: 'Add a Learner Profile',
    mentorCode_popup5_body:
      'From the profile page click the <highlight>Add Learner</highlight> button.',
    mentorCode_popup6_title: 'Create an Account',
    mentorCode_popup6_body:
      'If your learner has not registered for My Lemonade Day, tap the <highlight>Register</highlight> button. Otherwise have your learner add their existing profile by clicking the <highlight>Login</highlight> button.',
    mentorCode_popup7_title: 'Add their Name',
    mentorCode_popup7_body:
      'Have your learner enter their first and last name, this name must match exactly to the first and last name you entered into your learner table.',
    mentorCode_popup8_title: 'Add <highlight>Your</highlight> Mentor Code',
    mentorCode_popup8_body:
      'Have the learner enter <highlight>{mentorCode}</highlight> into the mentor code box. This must be exact for them to continue.',
    mentorCode_popup9_title: 'Confirm Community',
    mentorCode_popup9_body:
      'We have pre-filled your community and zip/postal code for your learner. If your learner’s zip/postal code is different, they can change it, otherwise have them continue with the pre-filled information.',
    mentorCode_popup10_title: 'Pick a Username',
    mentorCode_popup10_body:
      'Now your learner needs to choose a unique and appropriate username. Have them keep it as simple as possible so they won’t struggle to remember it in the future.',
    mentorCode_popup11_title: 'Create a Secret Sticker',
    mentorCode_popup11_body:
      'Lastly your learner must create their secret sticker. This is in place of a classic password. Make sure they do not share their password with anyone for safety.',
    mentorCode_popup12_title: 'Start Learning!',
    mentorCode_popup12_body:
      'Now they are done! Have them click <highlight>Go!</highlight> to enter the program or if you have another learner to add to this device click the back button.',
    change_legal_guardian_email_popup_title: 'Change guardian email?',
    change_legal_guardian_email_popup_message:
      'A change to the guardian email will result in the removal of the following learner data: photo uploads, stand location information and contest entries.',
    change_legal_guardian_email_popup_submessage:
      'You cannot undo this action.',
    change_legal_guardian_email_popup_accept: 'Yes',
    change_legal_guardian_email_popup_decline: 'No'
  },
  learnerProfile: {
    learner_profile_popup_title: 'Learner Profile',
    learner_profile_popup_title_progress: 'Progress',
    learner_profile_popup_title_date_last_synced: 'Date last synced',
    learner_profile_popup_title_age: 'Age',
    learner_profile_popup_title_grade: 'Grade',
    learner_profile_popup_title_gender: 'Gender',
    learner_profile_popup_title_ethnicity: 'Ethnicity',

    learner_profile_popup_gender_male: 'Male',
    learner_profile_popup_gender_female: 'Female',
    learner_profile_popup_gender_no_answer: 'Not answered',
    learner_profile_popup_gender_unknown: 'Unknown',

    learner_profile_popup_ethnicity_african_american: 'African American',
    learner_profile_popup_ethnicity_american_indian_or_alaska_native:
      'American Indian/Alaska Native',
    learner_profile_popup_ethnicity_asian: 'Asian',
    learner_profile_popup_ethnicity_hispanic_or_latino: 'Hispanic/Latino',
    learner_profile_popup_ethnicity_native_hawaiian_or_pacific_islander:
      'Native Hawaiian/Pacific Islander',
    learner_profile_popup_ethnicity_no_answer: 'Not answered',
    learner_profile_popup_ethnicity_white: 'White',
    learner_profile_popup_ethnicity_unknown: 'Unknown',

    learner_profile_popup_reset_password: 'Reset Password',
    learner_profile_popup_username_label: 'Username',

    learner_profile_popup_remove_learner_button: 'Remove learner?',
    learner_profile_popup_forgot_password_warning:
      'This learner has forgotten their password.',

    learner_profile_popup_reset_password_title: "Reset learner's password?",
    learner_profile_popup_reset_password_message:
      "Are you sure you want to reset this learner's password?",
    learner_profile_popup_reset_password_submessage:
      'You cannot undo this action.',
    learner_profile_popup_reset_password_yes: 'Reset',
    learner_profile_popup_reset_password_no: 'No',

    learner_profile_popup_reset_password_complete_title:
      "Your Learner's New Password",
    learner_profile_popup_reset_password_complete_message:
      'Please communicate this password to',
    learner_profile_popup_reset_password_complete_submessage:
      'This is their password from now on.',
    learner_profile_popup_reset_password_complete_warning:
      'Once you close this popup the preview of this password will be lost for safety.',

    learner_profile_popup_password_reset_text:
      'Password reset. Ask your learner to log into the app again.',
    learner_profile_popup_remove_learner_title: 'Remove Learner?',
    learner_profile_popup_remove_learner_message:
      'Are you sure you want to remove this learner?',
    learner_profile_popup_remove_learner_submessage:
      'You cannot undo this action.',
    learner_profile_popup_remove_learner_submessage_legal_guardian:
      'Removing this learner will result in the removal of the following learner data: photo uploads, stand location information and contest entries. You cannot undo this action.',
    learner_profile_popup_remove_learner_yes: 'Remove',
    learner_profile_popup_remove_learner_no: 'No',
    learner_profile_popup_remove_learner_failed_title: 'Request failure',
    learner_profile_popup_remove_learner_failed_message:
      'Please try again later.'
  },
  learnerConsent: {
    learner_consent_accept_button_text: 'Accept',
    learner_consent_decline_button_text: 'Decline',
    learner_consent_terms_text: 'I understand these',
    learner_consent_terms_conditions: 'terms and conditions',
    learner_consent_media_title: 'Media Permission',
    learner_consent_media_text:
      'As the Guardian, I give consent for this child to upload media into the My Lemonade Day app. I am aware that these photos may be used by Lemonade Day for contests.',
    learner_consent_media_warning:
      'This learner must go online for their app to reflect this permission.',
    learner_consent_location_title: 'Location Permission',
    learner_consent_location_text:
      'As the Guardian, I give consent for this child to post the address, time of operation, menu, and nearby business of their lemonade stand on the public Lemonade Day brand your stand map.',
    learner_consent_location_warning:
      'This learner must go online for their app to reflect this permission.',
    learner_consent_competition_title: 'Contest Entry Permission',
    learner_consent_competition_text:
      'As the Guardian, I give consent for this child to enter into Lemonade Day contests thought the My Lemonade Day app. I am aware that I will be contacted in the event that this child is the winner of said contests.',
    learner_consent_competition_warning:
      'This learner must go online for their app to reflect this permission.'
  },
  learnerProfileLearnerInfoPopup: {
    learner_profile_learner_info_popup_title: 'Learner Information',
    learner_profile_learner_info_popup_text1:
      'In order to add a learner, you must enter their first and last name and then click the plus button.\nIf you make an edit tap the pencil button in the top right corner of the table to make changes.',
    learner_profile_learner_info_popup_text2:
      'When the learner creates their profile, you will be able to view the additional information they provide.'
  },
  learnerProfileLegalGuardianEmailPopup: {
    learner_profile_legal_guardian_email_popup_title:
      'Legal Guardian Information',
    learner_profile_legal_guardian_email_popup_text1:
      'Legal consent for certain data must be given by the learner’s parent or guardian. In order for them to give consent they must register as a mentor.',
    learner_profile_legal_guardian_email_popup_text2:
      'If you are the learner’s guardian enter your email address into the Guardian Email Address column to be able to give the appropriate consent.'
  },
  learnerProfileProgramPopup: {
    learner_profile_program_popup_title: 'Program Information',
    learner_profile_program_popup_text:
      'Once a learner has registered and begun going through the lessons, you will be able to see and track their progress.\n\nAs soon as your learner completes lesson 4.3 their results are automatically submitted to Lemonade Day.'
  },
  learnerProfilePermissionPopup: {
    learner_profile_permission_popup_title1: 'Permissions Information',
    learner_profile_permission_popup_text1:
      'This step is optional. You can come back later by tapping the pencil button at the top left of your learner table.\n\nLearners enter a lot of data into the My Lemonade Day app and we are serious about protecting all it. Some of these data points require the legal consent of the learner’s guardian.',
    learner_profile_permission_popup_title2: 'Media Uploads',
    learner_profile_permission_popup_text2:
      'There are several lessons where we ask the learner to upload media. A guardian must consent before we can allow the learner to upload media. If a guardian’s consent is not given, the learner will be able to continue past interactions within the lesson that includes uploading images and will not have to upload the media.',
    learner_profile_permission_popup_title3: 'Stand Location',
    learner_profile_permission_popup_text3:
      'In order to help assist learners with customer traffic, Lemonade Day has a public map where learners can publish the date, time and location of their stand. A guardian must give consent, before we can allow learners to input a location where they’ll be present. If legal consent is not given, the lessons that will ask a learner for this information will be locked and the learner can continue past them.',
    learner_profile_permission_popup_title4: 'Contest Entry',
    learner_profile_permission_popup_text4:
      'As a reward to the learner for all their hard work, Lemonade Day holds contests every year. One is a random bike drawing in each community and the other is the National Youth Entrepreneur of the Year award. A guardian must give consent to allow the learner to enter these contests.',
    learner_profile_permission_popup_note:
      'If permissions are not given, the program will not show 100% completion however, learners can still progress through all of the lessons.'
  },
  learnerTaskProgress: {
    learner_task_progress_title: 'My Lemonade Day App Progress',
    learner_task_progress_module1_title: 'My Goals',
    learner_task_progress_module2_title: 'My Plan',
    learner_task_progress_module3_title: 'My Stand',
    learner_task_progress_module4_title: 'My Results',
    learner_task_progress_module_switcher: 'Module Switcher',
    learner_task_progress_prev_module: 'Previous Module',
    learner_task_progress_next_module: 'Next Module',

    learner_task_progress_module11_title: 'Being an entrepreneur',
    learner_task_progress_module12_title: 'Send, Save, Share',
    learner_task_progress_module21_title: 'Business Plan',
    learner_task_progress_module22_title: 'My Team',
    learner_task_progress_module23_title: 'Finding My Location',
    learner_task_progress_module24_title: 'The Customer',
    learner_task_progress_module25_title: 'Customer Service',
    learner_task_progress_module26_title: 'My Expenses',
    learner_task_progress_module27_title: 'Designing A Product',
    learner_task_progress_module28_title: 'Design a Stand',
    learner_task_progress_module29_title: 'Find Your Twist',
    learner_task_progress_module210_title: 'Advertising',
    learner_task_progress_module211_title: 'Final Business Plan',
    learner_task_progress_module31_title: 'Getting an Investor',
    learner_task_progress_module32_title: 'Supply Shopping',
    learner_task_progress_module33_title: 'Making it Happen',
    learner_task_progress_module34_title: 'Last Minute Preparations',
    learner_task_progress_module35_title: 'Lemonade Day is Here!',
    learner_task_progress_module41_title: 'Reflection',
    learner_task_progress_module42_title: 'Accounting',
    learner_task_progress_module43_title: 'Splitting Your Profit!'
  },
  termsAndConditions: {
    consent_h1: 'Consent',
    consent_p1_s1: `As the parent or guardian of the child/ren named above, I consent to my child/ren's participation in Lemonade Day. `,
    consent_p1_s2: `I understand that Lemonade Day is a family event, and I agree that I (or another responsible adult whom I approve) will assist and supervise my child/ren. `,
    consent_p1_s3: `I understand that the organizers of Lemonade Day are not and will not be responsible for supervising my child/ren.`,
    consent_p2_s1: `I also understand that certain municipalities may have certain health and safety requirements so that my child/ren can participate in Lemonade Day. `,
    consent_p2_s2: `I agree to comply with and ensure that my child/ren comply with these requirements. `,
    consent_p2_s3: `(Go to LemonadeDay.org and check your city's website for some of the local requirements.) `,
    consent_p2_s4: `I also understand that certain municipalities must be provided the name of the person who will supervise my child/ren and the exact location of my child/ren's lemonade stand ahead of time, and I agree to comply with these requirements.`,
    release_indemnity_h1: 'Release and Indemnity',
    release_indemnity_p1: `I hereby release, discharge, and covenant not to sue P4L d/b/a Lemonade Day, the organizers of Lemonade Day, and their directors, officers, employees, agents, volunteers, representatives, owners, members, affiliates, successors, assigns and anyone associated with P4L or Lemonade Day (collectively, "Released Parties"), from all liability to me, my child/ren, my and my child/ren's personal representatives, assigns, heirs and next of kin, for any and all claims, demands, actions, complaints, suits, losses or damages on account of any injury to me or my child/ren or in connection with my child/ren's participation in Lemonade Day, including, but not limited to, personal injuries or property damage, caused or alleged to be caused, in whole or in part, by the negligence of the Released Parties or otherwise.`,
    release_indemnity_p2: `If, despite this release, I, my child/ren, or my or my child/ren's personal representatives, assigns, heirs or next of kin, make a claim against any of the Released Parties named above, I agree to indemnify, defend and hold harmless the Released Parties from any litigation expenses, attorney fees, loss, liability, damage, or cost incurred, due to a claim made against any of the Released Parties, even if such injury is caused by the negligence of the Released Parties.`,
    release_indemnity_p3: `Furthermore, I agree to release, indemnify, defend and hold harmless the Released Parties from and against any and all claims, demands, actions, complaints, suits, losses, damages or other forms of liability that any of them may sustain (a) arising out of my or my child/ren's failure to comply with applicable laws; and (b) arising out of any damage or injury caused by me or my child/ren in connection with my child/ren's participation in Lemonade Day.`,
    release_indemnity_p4_s1: `I have read this parental consent, understand that by agreeing, I give up substantial rights that I and/or my child/ren would otherwise have to recover damages for losses occasioned by the released parties' fault, and sign it voluntarily and without inducement. `,
    release_indemnity_p4_s2: `I also confirm that I am the parent or legal guardian of the child/ren named above.`,
    photography_h1: 'Photography Disclaimer',
    photography_p1_s1: `I hereby grant P4L d/b/a Lemonade Day, permission to make still photographs, video recordings and audio and other recordings of me and/or my child/ren and/or using my name and/or my child/ren's name and/or my likeness and/or my child/ren's likeness and/or to use of verbal quotes from me and/or my child/ren (the "Authorized Materials") and to reproduce and distribute the Authorized Materials in or across any media. `,
    photography_p1_s2: `I also give Lemonade Day permission to use the completed Authorized Materials, and to use my or my child/ren's name and likeness, for Lemonade Day promotional and commercial purposes without any compensation or remuneration to me and/or my child/ren; in like and related regard, Lemonade Day will not charge or assess me or my child/ren any fees or service charges for my or my child/ren's voluntary participation in any audiovisual product production. `,
    photography_p1_s3: `I authorize Lemonade Day to use, edit, copy, publish, or exhibit any Authorized Materials for any lawful purpose anywhere in the world. `,
    photography_p1_s4: `I waive the right to review any materials produce by Lemonade Day, including those which use my or my child/ren's name and likeness and/or are Authorized Materials. `,
    photography_p1_s5: `Further, I relinquish and grant to Lemonade Day all rights, title and interest in and to the Authorized Materials that I and/or my child/ren may have, including, but not limited to, completed still photographs, video tapes or audio recordings, negatives, prints, reproductions and copies of the masters, negatives, recordings, duplicates, prints and verbal quotes for print, and I will not object to or take any adverse action whatsoever against Lemonade Day for use, reproduction or the like of any such Authorized Materials.`
  },
  manageCommunity: {
    community_manager: 'Community Manager',
    sponsor_manager_title: 'Community Sponsor Manager',
    community_name: 'Name',
    mentor_registration_link: 'Mentor registration link',
    event_date: 'Current Event Date',
    community_sponsors: 'Sponsors',
    sponsorship_slot_count:
      '{uploaded, number}/{total, number} Sponsorship Slots',
    communities: 'Communities',
    learner_creator: 'Learner Creator',
    search: 'Search',
    done: 'Done',
    confirm_delete: 'Are you sure you want to delete this image?',
    delete: 'Delete',
    upload_image: 'Upload Image',
    image_drag: 'Drag and drop your image (.png or .jpg) here',
    or: 'or',
    browse: 'Browse',
    file_size_warning: 'Please limit file size to 2MB or less.',
    image_size_warning:
      'For the best results, upload an image at 693x390 pixels or larger.',
    new_image: 'Choose a new image',
    crop: 'Crop',
    add_zip_codes: 'Add zip codes',
    enter_zip_codes: 'Enter zip codes',
    zip_code_count: 'zip code count: {count, number}',
    comma_separated_list: 'Use commas to separate zip codes in the list.'
  },
  uploadCSV: {
    heading: 'Upload .csv',
    step1:
      'Step 1. Download the .csv template below. The first 3 rows include an example to guide you.',
    step2:
      'Step 2. Add one learner per row from row 4 onwards. We exclude the first 3 example rows when processing your .csv.',
    step3:
      'Step 3. Choose your completed .csv file below to upload your learners.',
    step4:
      'Step 4. If your data was added successfully, close this window and see your learners added to the table.',
    button_text: 'Choose file',
    input_text: '.csv file name',
    requirements_header: 'File Validation Requirements',
    requirements: 'Requirements',
    column_1_title: 'Column 1:',
    column_1_detail: 'First Name',
    column_2_title: 'Column 2:',
    column_2_detail: 'Last Name',
    column_3_title: 'Column 3:',
    column_3_detail: 'Legal Guardian Email',
    column_4_title: 'Column 4:',
    column_4_detail: 'Legal Guardian Contact Number',
    download_template: 'Download the learner information .csv template',
    uploading: 'Uploading file',
    success: 'Learners Uploaded Successfully',
    validationError: 'File invalid',
    file_name: 'File name:',
    file_validated: 'File validated:',
    file_size: 'File size:',
    line_count: 'Row count:',
    unexpected_columns: 'Unexpected number of columns.',
    invalid_csv: 'Please check that your .csv is formatted correctly.',
    errors: 'Errors:',
    warnings: 'Warnings:',
    empty_csv:
      'Your .csv had 3 rows or less. Please note that the first 3 rows of the .csv are ignored because these are the example rows from the template. Please add your learners from row 4 onwards.',
    line_number: 'Row {lineNumber, number}: ',
    invalid_guardian_email: 'Guardian email address is invalid.',
    empty_first_name: `Learner's first name is missing.`,
    empty_last_name: `Learner's last name is missing.`,
    duplicate_rows:
      'There is more than one learner with this name in your .csv.'
  },
  learnerCard: {
    username: 'Username: {username}',
    guardianEmail_label: 'Guardian Email',
    guardianContactNumber_label: 'Guardian Phone Number',
    age_label: 'Age',
    grade:
      '{grade, select, FIRST {1} SECOND {2} THIRD {3} FOURTH {4} FIFTH {5} SIXTH {6} SEVENTH {7} EIGHTH {8} OTHER {Other} other {Other}}',
    grade_label: 'Grade',
    gender:
      '{gender, select, MALE {Male} FEMALE {Female} NO_ANSWER {Prefer not to answer} OTHER {Other} other {Other}}',
    gender_label: 'Gender',
    ethnicity:
      '{ethnicity, select, AFRICAN_AMERICAN {African American} AMERICAN_INDIAN_OR_ALASKA_NATIVE {American Indian/Alaska Native} ASIAN {Asian} HISPANIC_OR_LATINO {Hispanic/Latino} NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER {Native Hawaiian/Pacific Islander} NO_ANSWER {Prefer not to answer} WHITE {White} OTHER {Other} other {Other}}',
    ethnicity_label: 'Ethnicity',
    lesson_label: 'Lesson',
    lastSync_label: 'Last Synced',
    edit_label: 'Edit {learnerName}',
    cancel_label: 'Cancel editing {learnerName}',
    viewMore: 'View more',
    viewMore_label: 'View more about {learnerName}',
    viewLess: 'View less',
    viewLess_label: 'View less about {learnerName}',
    more_label: 'More about {learnerName}',
    resetPassword: 'Reset password',
    resettingPassword: 'Resetting password...',
    resettingPassword_label: 'Resetting password of {learnerName}',
    resetPassword_label: 'Reset the password of {learnerName}',
    removeLearner: 'Remove learner',
    removeLearner_label: 'Remove {learnerName}',
    removingLearner: 'Removing learner...',
    removingLearner_label: 'Removing {learnerName}',
    saveChanges: 'Save changes',
    saveChanges_label: 'Save changes to {learnerName}',
    savingChanges: 'Saving changes...',
    savingChanges_label: 'Saving changes to {learnerName}',
    saveLearner_label: 'Save this learner',
    consent: 'Guardian Consent',
    consentType:
      '{consentType, select, location {Location} media {Media} competition {Contest} other {Other}}',
    manageConsent_label:
      'Manage {consentType, select, location {location} media {media} competition {contest} other {other}} consent for {learnerName}',
    pendingConsent: 'Pending',
    grantedConsent: 'Granted',
    deniedConsent: 'Denied',
    consentTermsAndConditions: 'terms & conditions',
    firstName: 'First Name',
    firstName_label: 'Learner First Name',
    lastName: 'Last Name',
    lastName_label: 'Learner Last Name',
    emailErrorMessage: '* must be a valid email address',
    optional_suffix: '{prefix} (Optional)',
    disabled_guardian_warning:
      'Only the current guardian is able to edit the guardian email and contact number.',
    addNewLearner_header: 'Add New Learner',
    addLearnerForm_label: 'Add learner form',
    notRegistered_label: 'not registered',
    notRegistered_link: 'Learner not registered on the App',
    done: 'Done',
    downloadApp:
      'Download the app on your learner’s device to access the learning content.',
    rememberMentorCode: 'Remember your Mentor Code for Registration',
    viewMentorCode: 'Mentor Code: {mentorCode}',
    consent_disabled_warning: 'Only guardians may manage consent'
  },
  consentView: {
    heading: 'Grant Consent',
    step: '{step}/{totalSteps}',
    consentHeading:
      '{consentType, select, location {Location Permission} media {Media Permission} competition {Contest Permission} other {Other Permission}}',
    consentDescription:
      '{consentType, select, location {As the Guardian, I grant consent for this child to post the address, time of operation, menu, and nearby business of their lemonade stand on the public Lemonade Day brand your stand map.} media {As the Guardian, I grant consent for this child to upload media into the My Lemonade Day app. I am aware that these photos may be used by Lemonade Day for contests.} competition {As the Guardian, I grant consent for this child to enter into Lemonade Day contests thought the My Lemonade Day app. I am aware that I will be contacted in the event that this child is the winner of said contests.} other {As the Guardian, I grant consent for this child.}}',
    consentWarning:
      'This learner must go online for their app to reflect this permission.',
    consentTerms: 'I understand these <linkto>terms & conditions</linkto>',
    deny: 'Deny',
    denying: 'Denying...',
    denied: 'Denied',
    grant: 'Grant',
    granting: 'Granting...',
    granted: 'Granted',
    checkedErrorMessage: '* must be checked',
    next: 'Next',
    previous: 'Previous'
  },
  email: {
    unsubscribedSuccessfully: 'Unsubscribed successfully',
    embarrassment:
      'Well, this is embarrassing. It looks like something has gone wrong.',
    unsubscribeFailed: 'Unsubscription unsuccessful',
    contactSupport: 'Please contact support for further assistance'
  },
  admin: {
    communities: {
      select: 'Select a community',
      search: 'Search communities',
      selectOne: 'Select one',
      selectSearch: 'Select to search',
      notFoundError: 'Community not found. Please try again.',
      add: {
        title: 'Add a new community',
        formLabel: 'Add community form',
        success: 'A new community has been created successfully.',
        save: 'Save this community'
      },
      edit: {
        title: 'Edit community',
        formLabel: 'Edit community form',
        success: 'The community has been updated successfully.'
      },
      name: {
        label: 'Name',
        validationMessage: 'Name is required'
      },
      country: {
        label: 'Country',
        validationMessage: 'Country is required'
      },
      state: 'State',
      currency: {
        label: 'Currency',
        validationMessage: 'Currency is required'
      },
      language: {
        label: 'Language',
        validationMessage: 'Language is required'
      },
      ethnicity: {
        title: 'Ethnicity Enabled',
        description:
          'Do you want to include the ethnicity question in the survey in lesson 1.1?'
      },
      gender: {
        title: 'Gender Enabled',
        description:
          'Do you want to include the gender question in the survey in lesson 1.1?'
      },
      timeZone: {
        label: 'Time Zone',
        validationMessage: 'Time zone is required'
      },
      joinActiveCommunitiesDescription:
        'Learners and mentors are able to join active communities',
      duplicateCommunity:
        'Duplicate community name, please choose another name',
      validationMessage: 'Errors - certain inputs are invalid, see below',
      dates: {
        title: 'First Event',
        description: 'This can be updated later',
        start: 'Start',
        end: 'End',
        pickerStartLabel: 'First event start date',
        pickerEndLabel: 'First event end date',
        validationMessage: 'Start date may not be after end date'
      }
    },
    communityToolSelector: {
      title: 'Community Tool Selector',
      subTitle: 'What would you like to manage?',
      events: 'Community Events',
      sponsors: 'Community Sponsors',
      settings: 'Community Settings'
    },
    edit_community: {
      heading: 'Community settings'
    },
    events: {
      title: 'Event Manager',
      heading: 'Community Events',
      toDate: 'to',
      currentEvent: 'Current event',
      upcomingEvent: 'Upcoming event',
      futureEvents: 'Future events',
      add: 'Add an upcoming event',
      noUpcomingEvents: 'No upcoming events',
      deleteConfirmationTitle: 'Delete upcoming event',
      edit: 'Edit',
      editTitle: 'Edit event',
      editDescription:
        'Editing the current event will change the number of days counting down to My Lemonade Day in the app for all learners in this community.',
      deleteConfirmationDescription1:
        'Are you sure that you want to delete this upcoming event?',
      deleteConfirmationDescription2: 'This action cannot be undone.',
      deleteSuccessMessage: 'Event deleted',
      eventDate: 'Event date',
      eventDatesPastValidation: 'Upcoming events must be scheduled in future',
      eventAddSuccess: 'Event added successfully',
      eventUpdateSuccess: 'Event updated successfully',
      overlappingErrorMessage:
        'Event overlaps with an existing event, please choose alternative dates.',
      rollOverTitle: 'Roll over upcoming event',
      rollOverConfirmationDescription1:
        'Are you sure you want to roll over to this event?',
      rollOverConfirmationDescription2: 'All learner progress will be reset.',
      rollOverConfirmationDescription3: 'This action cannot be undone.',
      rollOverSuccessMessage: 'Community event rolled over successfully',
      notFoundError: 'Event not found. Please try again.',
      eventDates: 'Event Dates',
      rolloverNotificationTitle:
        'Send email notifying users of upcoming rollover',
      rolloverNotificationDescription:
        'Are you sure that you want to email all mentors in this community to warn them of the upcoming rollover?',
      eventRolloverDate: 'Roll over date:',
      eventNotificationSuccessful: 'Mentors emailed successfully.'
    },
    sponsors: {
      title: 'Community Sponsor Manager',
      subTitle: 'Community sponsors',
      communityName: 'Name'
    },
    toolSelector: {
      title: 'Tool Selector',
      subTitle: 'What would you like to do?'
    },
    userSettings: {
      title: 'User Settings and Migration',
      heading: 'Find a user',
      userType: {
        label: 'Select user type',
        placeholder: 'Select',
        learner: 'Learner',
        mentor: 'Mentor'
      },
      searchIndexLabel: 'Search by',
      searchType: {
        username: 'Username',
        name: 'Name',
        email: 'Email'
      },
      usernameLabel: 'Username',
      firstNameLabel: 'First name',
      lastNameLabel: 'Last name',
      emailLabel: 'Search by email address',
      searchSubmit: 'Search',
      searchPrompt:
        'No results were found. Please try a different term and search again.',
      pageSizeExceeded:
        'Maximum number of results displayed. Please add more characters if the correct user is not shown.',
      nameColumn: 'Name',
      communityColumn: 'Community'
    },
    learnerProfile: {
      title: 'Learner Profile',
      status: 'Account Status',
      progress: 'Progress',
      lastSync: 'Last Day Synced',
      learnerCommunity: 'Learner Community',
      editTitle: 'Change community',
      formLabel: 'Edit Learner',
      nameLabel: 'Name',
      community: {
        label: 'Select new community',
        placeholder: 'Type new community',
        validationMessage: 'Please select a community',
        communityConflict:
          'Please select a community that the learner is not currently in'
      },
      moveProgress: {title: 'Move current progress data', description: ' '},
      migrateLearner: 'Change community',
      migrateLearnerSuccess: 'Community changed successfully'
    },
    mentorProfile: {
      title: 'Mentor Profile',
      firstName: 'Mentor First Name',
      lastName: 'Mentor Last Name',
      email: 'Email',
      communityLabel: 'Mentor Community',
      mentorNotFoundTitle: 'Mentor not found',
      formLabel: 'Edit Mentor',
      editDialogTitle: 'Change community',
      currentCommunity: 'Current community',
      community: {
        label: 'Select new community',
        placeholder: 'Type new community',
        validationMessage: 'Please select a community',
        communityConflict:
          'Please select a community that the mentor is not currently in'
      },
      migrateLearners: {
        title: "Move all this mentor's learners",
        description: ' '
      },
      migrateProgress: {
        title: 'Move current learner progress data',
        description: ' '
      },
      migrateMentor: 'Change community'
    }
  },
  account: {
    delete: {
      formLabel: 'Account Deletion Request',
      identifierLabel: 'Username/\nEmail address',
      identifierError: 'Type your username/email address',
      description:
        'If you want us to delete all of your My Lemonade Day app account data, fill in this form and we will make sure that it happens within 5 working days.',
      messageLabel: 'Tell us how much of your info you would like us to remove',
      messageError: 'Enter some details about the deletion of your account',
      submit: 'Submit',
      submitError: `We couldn't find any users with those details. Please check the username/email and password and try again.`,
      submitSuccess: 'Your account deletion request has been submitted.'
    }
  },
  educationalResources: {
    title: 'Download Educational Resources',
    shortTitle: 'Educational Resources',
    resourceKey: {
      MLD_MENTOR_GUIDE: 'My Lemonade Day Mentor Guide',
      TEACHER_GUIDE_YOUTH: `Teacher's Guide - Youth`,
      TEACHER_GUIDE_TEEN: `Teacher's Guide - Teen`,
      PROGRAM_TIMELINE: 'General Info - Program Timeline',
      IMPLEMENTATION_GUIDE: 'Implementation Guide',
      GENERAL_FAQ_EN_US: 'General FAQ',
      GENERAL_FAQ_ES_MX: 'General FAQ - Spanish',
      MENTOR_FAQ_EN_US: 'Mentors + Teachers FAQ',
      MENTOR_FAQ_ES_MX: 'Mentors + Teachers FAQ Spanish',
      JOURNAL: 'My Lemonade Day Printable Journal',
      COMMON_CORE_TEKS: 'Common Core + TEKS Alignment',
      FAITH_MENTOR_GUIDE_EN_US: 'Faith-Based Mentor Guide',
      FAITH_MENTOR_GUIDE_ES_MX: 'Faith-Based Mentor Guide - Spanish',
      TEACHER_SUITE: 'Digital Teacher Resource Suite',
      SUPPLY_LIST: 'Supply List',
      PACKING_LIST: 'Packing List',
      CUSTOMER_SERVICE_QUESTIONS: 'Customer Service Questions',
      JUNIOR_MARKET_JOURNAL: 'Junior Market Journal',
      OFFICIAL_STAND_SIGN: 'Official Stand Sign'
    },
    public: {
      title: 'Our Lemonade Day Store',
      storeDescription:
        'In our Lemonade Day Store, you can download a host of free and premium resources that will help your learners to make the most of the My Lemonade Day experience.',
      buttonText: 'Go to store'
    }
  },
  suppliesShop: {
    title: 'Supplies',
    pageTitle: 'Shop for Supplies',
    detail:
      'Lesson 3.2 in the My Lemonade Day app deals with shopping for supplies. We recommend waiting for your learners to reach this lesson so that they can be involved in the shopping process!',
    listHeading:
      'Here is a selection of Amazon Ideas Lists to help you find what you need:',
    lists: {
      recipe: 'Recipe Supplies',
      stand: 'Stand Supplies',
      twist: 'Twist Supplies'
    },
    confirmation: {
      heading: {
        stand: 'Did you buy any stand supplies off our list?',
        recipe: 'Did you buy any recipe supplies off our list?',
        twist: 'Did you buy any twist supplies off our list?'
      },
      body: 'We appreciate your feedback.',
      yes: 'Yes I did',
      no: `No I didn't`
    }
  }
}
