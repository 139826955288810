export type PercentageFloatingPoint = number & {
  brand: 'PercentageFloatingPoint'
}

/**
 * Format the given {@link PercentageFloatingPoint} as a percentage string.
 *
 * e.g 0.11 = 11%
 *
 * @param candidate The {@link PercentageFloatingPoint} to format.
 */
function format(candidate: PercentageFloatingPoint): string {
  return `${candidate * 100}%`
}

/**
 * Parse an unknown type to Percentage represented as a floating point number.
 * i.e. a positive floating point number between zero and one
 *
 * @param candidate The candidate to parse.
 */
function parse(candidate: unknown): PercentageFloatingPoint {
  if (typeof candidate !== 'number' || candidate < 0 || candidate > 1) {
    throw new Error(`Unexpected PercentageFloatingPoint ${candidate}`)
  }

  return candidate as PercentageFloatingPoint
}

const progress = {format, parse}
export default progress
