import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import Event from '../domain/event'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  onSuccess?: () => void
  onError?: (error: Error) => void
}>

function useUpdateEvent({onSuccess, onError}: Options): {
  isBusy: boolean
  error: Error | null
  updateEvent: (event: Event) => void
} {
  const accessToken = useSelector(getAccessToken)
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const updateEvent = (event: Event) => {
    setError(null)
    setIsBusy(true)
    putEvent(accessToken, event)
      .then(() => onSuccess?.())
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => {
        setIsBusy(false)
      })
  }
  return {isBusy, error, updateEvent}
}

async function putEvent(accessToken: string, event: Event): Promise<void> {
  const url = new URL(
    `communities/${event.communityId}/events/${event.id}`,
    API_URL
  ).toString()
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    },
    body: JSON.stringify(event)
  })

  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
}

export default useUpdateEvent
