import {batch} from 'react-redux'
import {login} from '../../api'
import {UpdateAdultAction} from '../adults/adults.action'
import {UpdateMentorAction} from '../mentors/mentors.action'
import {AppThunkAction} from '../store'
import {updateLocale} from '../ui/ui.actions'
import {TUTORIAL_SEEN_KEY} from '../ui/ui.constants'
import {UpdateLocaleActionType} from '../ui/ui.types'
import {
  AttemptUserLogin,
  Login,
  LoginCredentials,
  LogOutAction,
  ReceiveUserLogIn
} from './authentication.type'

export const LOGOUT_ACTION = 'auth/LOGOUT'
export const ATTEMPT_USER_LOGIN = 'auth/ATTEMPT_USER_LOGIN'
export const RECEIVE_USER_LOG_IN = 'auth/RECEIVE_USER_LOG_IN'

export function logUserIn(
  credentials: LoginCredentials
): AppThunkAction<
  | AttemptUserLogin
  | ReceiveUserLogIn
  | UpdateLocaleActionType
  | UpdateMentorAction
  | UpdateAdultAction
> {
  return async function (dispatch, getState) {
    const {
      auth: {isAuthing}
    } = getState()

    if (!isAuthing) {
      dispatch({
        type: ATTEMPT_USER_LOGIN
      })

      try {
        const {
          access_token: accessToken,
          refresh_token: refreshToken,
          firstLogin,
          roles,
          adult
        } = await login(credentials)
        dispatch(
          userLoggedIn({
            accessToken,
            refreshToken,
            firstLogin,
            roles,
            adult
          })
        )
      } catch (error) {
        dispatch({
          type: RECEIVE_USER_LOG_IN,
          payload: error,
          error: true
        })
        throw error
      }
    }
  }
}

export function userLoggedIn(
  loginData: Login
): AppThunkAction<
  ReceiveUserLogIn | UpdateLocaleActionType | UpdateAdultAction
> {
  return async function (dispatch) {
    try {
      batch(() => {
        window.sessionStorage &&
          sessionStorage.setItem('auth', JSON.stringify(loginData))
        dispatch({
          type: RECEIVE_USER_LOG_IN,
          payload: loginData
        })

        const adultData = loginData.adult
        dispatch(updateLocale(adultData.locale))
      })
    } catch (error) {
      dispatch({
        type: RECEIVE_USER_LOG_IN,
        payload: error,
        error: true
      })
      throw error
    }
  }
}

export function logUserOut(): LogOutAction {
  // Remove saved auth data in sessionStorage.
  if (window.sessionStorage) {
    sessionStorage.removeItem('auth')
    sessionStorage.removeItem(TUTORIAL_SEEN_KEY)
  }

  return {
    type: LOGOUT_ACTION
  }
}
