import {useIntl} from 'react-intl'

/**
 * Format an ISO 8601 string as a time relative to now.
 */
function useTimeRelativeToNow(dateTime: Date | null): string | null {
  const intl = useIntl()
  if (dateTime == null) {
    return null
  }
  const [value, unit] = calculateTimeSince(dateTime)
  return intl.formatRelativeTime(-Math.floor(value), unit)
}

export function calculateTimeSince(
  dateTime: Date,
  now: Date = new Date()
): [number, Intl.RelativeTimeFormatUnit] {
  let diff = now.valueOf() - dateTime.valueOf()
  // Convert to seconds.
  diff = diff / 1000
  if (diff < 60) {
    return [diff, 'seconds']
  }
  // Convert to minutes.
  diff = diff / 60
  if (diff < 60) {
    return [diff, 'minutes']
  }
  // Convert to hours.
  diff = diff / 60
  if (diff < 24) {
    return [diff, 'hours']
  }
  // Convert to days.
  diff = diff / 24
  return [diff, 'days']
}

export default useTimeRelativeToNow
