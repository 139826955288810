import produce from 'immer'
import {Reducer} from 'redux'
import {AppActions} from '../store'
import {
  ATTEMPT_USER_LOGIN,
  LOGOUT_ACTION,
  RECEIVE_USER_LOG_IN
} from './authentication.action'
import {AuthState} from './authentication.type'

const initialState: AuthState = {
  error: false,
  isAuthing: false,
  accessToken: null,
  refreshToken: null,
  firstLogin: false,
  adultId: null,
  roles: []
}

const reducer: Reducer<AuthState, AppActions> = (
  state = initialState,
  action: AppActions
) => {
  return produce(state, draft => {
    switch (action.type) {
      case ATTEMPT_USER_LOGIN:
        draft.error = false
        draft.isAuthing = true
        break

      case RECEIVE_USER_LOG_IN:
        if (action.error) {
          draft.error = true
          draft.isAuthing = false
          break
        }
        draft.isAuthing = false
        draft.accessToken = action.payload.accessToken
        draft.refreshToken = action.payload.refreshToken
        draft.firstLogin = action.payload.firstLogin

        draft.adultId = action.payload.adult.id
        draft.roles = action.payload.roles

        break

      // todo: test.
      case LOGOUT_ACTION:
        draft.isAuthing = false
        draft.refreshToken = null
        draft.accessToken = null
        draft.adultId = null
        draft.roles = []
        break
    }
  })
}

export default reducer
