import React from 'react'
import {useIntl} from 'react-intl'
import breakpoints from '../../breakpoints'
import {ROOT_ROUTE} from '../../global-constants/routingConstants'
import {UnstyledInternalLink} from '../links/InternalLink'
import Menu from '../Menu'
import logo from './assets/business_app_logo.png'
import logo2X from './assets/business_app_logo@2x.png'
import logo3X from './assets/business_app_logo@3x.png'
import logo4X from './assets/business_app_logo@4x.png'
import lemmyLogo from './assets/lemmy_logo.png'
import lemmyLogo2X from './assets/lemmy_logo@2x.png'
import lemmyLogo3X from './assets/lemmy_logo@3x.png'
import lemmyLogo4X from './assets/lemmy_logo@4x.png'
import LanguageSelector from './LanguageSelector'

type Props = Readonly<{
  control?: React.ReactNode
  onHowToAddLearners?: () => void
  title: React.ReactNode
}>

function Header({control = null, title, onHowToAddLearners}: Props) {
  const intl = useIntl()

  return (
    <header
      className="
        z-10 flex w-full flex-row items-center justify-between
        bg-white p-4 shadow-[0_1px_4px] shadow-[rgba(0,0,0,0.2)]
      "
    >
      {/* Logo */}
      <div className="flex-shrink-0">
        <UnstyledInternalLink to={ROOT_ROUTE}>
          <picture>
            <source
              media={breakpoints.md}
              srcSet={`${logo}, ${logo2X} 2x, ${logo3X} 3x, ${logo4X} 4x`}
            />
            <img
              src={lemmyLogo}
              srcSet={`${lemmyLogo}, ${lemmyLogo2X} 2x, ${lemmyLogo3X} 3x, ${lemmyLogo4X} 4x`}
              alt={intl.formatMessage({id: 'registration.WebHeaderNav_logo'})}
              className="
                h-8 w-12 rounded outline-none focus:outline-none
                focus-visible:outline-none focus-visible:ring-4
                focus-visible:ring-navy md:h-[54px] md:w-[288px]
              "
            />
          </picture>
        </UnstyledInternalLink>
      </div>
      {/* Title */}
      {title}
      {/* Buttons */}
      <div
        className="
          flex flex-initial flex-col flex-wrap items-center justify-center
          sm:space-y-4 xl:space-y-8
        "
      >
        <div className="flex flex-row items-start justify-around">
          <div className="mr-4 hidden sm:block xl:mr-8">
            <LanguageSelector />
          </div>
          <Menu onHowToAddLearners={() => onHowToAddLearners?.()} />
        </div>
        <div className="hidden lg:block">{control}</div>
      </div>
    </header>
  )
}

export default Header
