import {getAccessToken} from './store/authentication/authentication.selectors'
import {AppState} from './store/store'

export function authorizationHeaderFromState(state: AppState) {
  return authorizationHeaderFromAccessToken(getAccessToken(state))
}

export function authorizationHeaderFromAccessToken(accessToken: string) {
  return {Authorization: `Bearer ${accessToken}`}
}
