import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import EducationResource from '../domain/educationResource'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  onSuccess?: (educationResources: ReadonlyArray<EducationResource>) => void
  onError?: (error: Error) => void
}>

function useEducationResources({onSuccess, onError}: Options = {}): {
  error: Error | null
  isLoading: boolean
  educationResources: ReadonlyArray<EducationResource> | undefined
} {
  const accessToken = useSelector(getAccessToken)
  const [educationResources, setEducationResources] = useState<
    ReadonlyArray<EducationResource> | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchEducationResources = useCallback(() => {
    setError(null)
    setIsLoading(true)
    getEducationResources(accessToken)
      .then(educationResourcesResponse => {
        setEducationResources(educationResourcesResponse)
        onSuccess?.(educationResourcesResponse)
      })
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setIsLoading(false))
  }, [accessToken, onError, onSuccess])

  useEffect(() => {
    fetchEducationResources()
  }, [fetchEducationResources])

  return {error, isLoading, educationResources}
}

async function getEducationResources(
  accessToken: string
): Promise<ReadonlyArray<EducationResource>> {
  const url = new URL('educationalResources', API_URL)
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    }
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }

  const body = await response.json()

  return parseResponseBody(body)
}

export function parseResponseBody(
  candidate: unknown
): ReadonlyArray<EducationResource> {
  if (!Array.isArray(candidate)) {
    throw new Error(
      'Unexpected response body. Expected an array of EducationResource.'
    )
  }

  try {
    return candidate.map(EducationResource.parse)
  } catch (error) {
    throw new Error(
      `Unexpected response body. Expected an array of EducationResource. ${error.message}`,
      {cause: error}
    )
  }
}

export default useEducationResources
