import {useCallback, useState} from 'react'
import {API_URL} from '../config'
import AccountDeletionRequest from '../domain/accountDeletionRequest'
import {captureException} from '../errors'
import ServerError from '../errors/server'

type Options = Readonly<{
  onSuccess?: () => void
  onError?: (error: Error) => void
}>

function useRequestAccountDeletion({
  onSuccess,
  onError
}: Options = {}): Readonly<{
  busy: boolean
  error: Error | null
  requestAccountDeletion: (
    accountDeletionRequest: AccountDeletionRequest
  ) => void
}> {
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const requestAccountDeletion = useCallback(
    (accountDeletionRequest: AccountDeletionRequest) => {
      setBusy(true)
      setError(null)
      postAccountDeletionRequest(accountDeletionRequest)
        .then(() => {
          onSuccess?.()
        })
        .catch(error => {
          captureException(error)
          setError(error)
          onError?.(error)
        })
        .finally(() => setBusy(false))
    },
    [onSuccess, onError]
  )
  return {busy, error, requestAccountDeletion}
}

async function postAccountDeletionRequest(
  accountDeletionRequest: AccountDeletionRequest
): Promise<void> {
  const url = new URL('accountDeletionRequests', API_URL)
  const res = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(accountDeletionRequest)
  })
  if (res.status !== 201) {
    return ServerError.fromResponse(res)
  }
}

export default useRequestAccountDeletion
