import {produce} from 'immer'
import {Reducer} from 'redux'
import {AppActions} from '../store'
import {
  ATTEMPT_GET_COMMUNITIES,
  RECEIVE_COMMUNITIES
} from './communities.action'
import {CommunitiesState, Community} from './communities.type'

const initialState: CommunitiesState = {
  byId: {},
  byZip: {},
  error: null,
  currentEventId: '',
  fetching: false
}

const reducer: Reducer<CommunitiesState, AppActions> = (
  state = initialState,
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case ATTEMPT_GET_COMMUNITIES:
        draft.error = null
        draft.fetching = true
        break
      case RECEIVE_COMMUNITIES:
        draft.fetching = false
        if (action.error) {
          draft.error = action.payload
          break
        }
        handleReceiveCommunitiesMapByIdAction(draft, action)
        break
    }
  })

function handleReceiveCommunitiesMapByIdAction(
  state: CommunitiesState,
  action: {payload: Community[]}
) {
  // Clear zip state so that communities are not duplicated
  state.byZip = {}
  for (const community of action.payload) {
    state.byId[community.id] = community
    handleReceiveCommunitiesMapByZipAction(state, community)
  }
}

function handleReceiveCommunitiesMapByZipAction(
  state: CommunitiesState,
  community: Community
) {
  community.zips.forEach(zip => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.byZip.hasOwnProperty(zip)) {
      state.byZip[zip] = [...state.byZip[zip], community.id]
    } else {
      state.byZip[zip] = [community.id]
    }
  })
}

export default reducer
