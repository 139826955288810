import {isNonBlankString, isPlainObject} from '../responseRefiners'
import Email, {Email as EmailT} from './email'

export type Guardian<DateType extends Date | string = Date> = Readonly<{
  email: EmailT | null
  contactNumber: string | null
  inviteLastSentDate: DateType | null
  mentorId: string | null
}>

function parse(candidate: unknown): Guardian {
  if (
    !isPlainObject(candidate) ||
    (candidate.contactNumber !== null &&
      !isNonBlankString(candidate.contactNumber)) ||
    (candidate.inviteLastSentDate !== null &&
      !isNonBlankString(candidate.inviteLastSentDate)) ||
    (candidate.mentorId !== null && !isNonBlankString(candidate.mentorId))
  ) {
    throw new Error(
      `Unexpected Guardian ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }

  return {
    email: candidate.email === null ? null : Email.parse(candidate.email),
    contactNumber: candidate.contactNumber,
    inviteLastSentDate:
      candidate.inviteLastSentDate === null
        ? null
        : new Date(candidate.inviteLastSentDate),
    mentorId: candidate.mentorId
  }
}

const guardian = {parse}
export default guardian
