import clsx from 'clsx'
import React from 'react'

export default function UserProfileTable({
  children,
  isLoading
}: {
  children: React.ReactNode
  isLoading?: boolean
}) {
  return (
    <table
      className={`min-w-full divide-y divide-grey-tertiary-20 ${
        isLoading && 'animate-pulse'
      }`}
    >
      <tbody>{children}</tbody>
    </table>
  )
}

export function UserProfileRow({
  left,
  middle,
  right,
  noBorder,
  compact
}: {
  left: React.ReactNode
  middle?: React.ReactNode
  right?: React.ReactNode
  noBorder?: boolean
  compact?: boolean
}) {
  return (
    <tr
      className={clsx(
        compact ? 'typography-headline-6' : 'typography-headline-5',
        !noBorder && 'border-b border-grey-tertiary-10'
      )}
    >
      <th
        className="font-semibold table-cell whitespace-nowrap py-4 px-4 text-left text-navy-secondary after:content-[':']"
        scope="row"
      >
        {left}
      </th>
      {middle && (
        <td className="table-cell whitespace-nowrap py-4 px-4 text-left text-navy-secondary">
          {middle}
        </td>
      )}
      {right && (
        <td className="table-cell whitespace-nowrap px-4 py-4 text-navy-secondary">
          <div className="flex justify-end">{right}</div>
        </td>
      )}
    </tr>
  )
}
