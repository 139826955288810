/* eslint-env node */

// The environment that the app is running in.
export const ENV = process.env.NODE_ENV || 'development'
// The base URL of the server. MUST have a trailing slash.
export const SERVER_URL =
  process.env.REACT_APP_SERVER_URL ||
  'https://server.d.us-east-2.mylemonadeday.org/'
// Add the suffix for the business API. MUST have a trailing slash.
export const API_URL = new URL('business/', SERVER_URL)
// The ReCaptcha site key.
export const SITE_KEY =
  process.env.REACT_APP_SITE_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
// The public support email address.
export const SUPPORT_EMAIL =
  process.env.REACT_APP_SUPPORT_EMAIL || 'support@lemonadeday.org'
