import React, {KeyboardEvent, useState} from 'react'
import {useIntl} from 'react-intl'
import {Props as SelectProps} from 'react-select/base'
import Creatable from 'react-select/creatable'
import {ActionMeta, ValueType} from 'react-select/src/types'
import Select from './index'
import {
  CustomClearIndicator,
  CustomLoadingIndicator,
  customStyles,
  customTheme
} from './selectCommonComponents'

const MODE_SELECT = 'MODE_SELECT'
const MODE_CREATE = 'MODE_CREATE'
const OTHER_OPTION_VALUE = 'other_option'

export type Props<OptionType> = SelectProps<OptionType> & {
  error?: string
  createOption: (
    label: string,
    value: string,
    isUserFreeText: boolean
  ) => OptionType
  changeHandler: (
    value: ValueType<OptionType> | null,
    action: ActionMeta
  ) => void
}

type Mode = typeof MODE_CREATE | typeof MODE_SELECT

const componentStyles = {
  input: (styles: React.CSSProperties): React.CSSProperties => ({
    ...styles,
    color: '#fff',
    padding: 0,
    margin: 0,
    fontFamily: 'inherit',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    '& input': {
      font: 'inherit'
    }
  })
}

function SelectWithTextOther<OptionType>({
  styles,
  theme,
  components,
  createOption,
  changeHandler,
  ...props
}: Props<OptionType>) {
  const [inputValue, setInputValue] = useState('')
  const [mode, setMode] = useState<Mode>(MODE_SELECT)
  const intl = useIntl()

  function switchToSelectStartState() {
    changeHandler(null, {action: 'clear'})
    setMode(MODE_SELECT)
  }

  function switchToSelectSelectedTextInput() {
    changeHandler(createOption(inputValue, inputValue, true), {
      action: 'create-option'
    })
    setMode(MODE_SELECT)
    setInputValue('')
  }

  const otherOption = createOption(
    intl.formatMessage({id: 'registration.mentorDetails_findDropdown_option8'}),
    OTHER_OPTION_VALUE,
    true
  )
  const options = (
    props.options == null ? [otherOption] : [...props.options, otherOption]
  ) as OptionType[]

  if (mode === MODE_CREATE) {
    return (
      <Creatable<OptionType>
        styles={{...customStyles, ...componentStyles, ...styles}}
        theme={defaultTheme => ({...defaultTheme, ...customTheme, ...theme})}
        components={{
          ...components,
          IndicatorSeparator: null,
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: null,
          LoadingIndicator: CustomLoadingIndicator
        }}
        {...props}
        autoFocus
        menuIsOpen={false}
        inputValue={inputValue}
        onInputChange={val => setInputValue(val)}
        onKeyDown={(event: KeyboardEvent<HTMLElement>) => {
          if (event.key === 'Backspace') {
            if (inputValue === '') {
              switchToSelectStartState()
            }
          } else if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault()
            switchToSelectSelectedTextInput()
          }
        }}
        onBlur={() => {
          if (inputValue.trim() === '') {
            switchToSelectStartState()
          } else {
            switchToSelectSelectedTextInput()
          }
        }}
        placeholder={intl.formatMessage({
          id: 'registration.mentorDetails_findDropdown_PH'
        })}
        value={null}
      />
    )
  }

  return (
    <Select
      {...props}
      options={options}
      value={props.value}
      onChange={(selected: ValueType<OptionType>, action) => {
        changeHandler(selected, action)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (selected && selected.value === OTHER_OPTION_VALUE) {
          setMode(MODE_CREATE)
        }
      }}
    />
  )
}

export default SelectWithTextOther
