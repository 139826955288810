/**
 * The available breakpoints.
 *
 * N.B. This must be kept in sync with the Tailwind CSS config.
 */
export default {
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  '2xl': '(min-width: 1536px)'
}
