export const EMAIL_REGEXP = /^\S+@\S+\.\S+$/

export function validateEmail(email: string) {
  const trimmedEmail = email.trim()
  if (trimmedEmail === '') {
    return 'registration.MentorProfile01_Fail_error4'
  } else if (!EMAIL_REGEXP.test(trimmedEmail)) {
    return 'registration.MentorProfile01_Fail_error6'
  }
  return null
}

export function validatePassword(
  password: string,
  requiredKey: string,
  minimumRequirementsKey: string
): string | null {
  if (password === '') {
    return requiredKey
  } else if (password.length < 8) {
    return minimumRequirementsKey
  }
  return null
}

export function passwordsMatch(repeated: string, password: string): boolean {
  return repeated === password
}
