import * as GRADES from './grades'
import {
  EIGHTH,
  FIFTH,
  FIRST,
  FOURTH,
  OTHER,
  SECOND,
  SEVENTH,
  SIXTH,
  THIRD
} from './grades'

export type Grade = ValueOf<typeof GRADES>

const EXPECTED_GRADES = Object.values(GRADES)

function parse(candidate: unknown): Grade {
  if (
    candidate !== FIRST &&
    candidate !== SECOND &&
    candidate !== THIRD &&
    candidate !== FOURTH &&
    candidate !== FIFTH &&
    candidate !== SIXTH &&
    candidate !== SEVENTH &&
    candidate !== EIGHTH &&
    candidate !== OTHER
  ) {
    throw new Error(
      `Unexpected Grade ${candidate}, expected one of ${EXPECTED_GRADES.join(
        ', '
      )}`
    )
  }

  return candidate
}

function parseOtherGrade(candidate: unknown): string | null {
  if (candidate !== null && typeof candidate !== 'string') {
    throw new Error('OtherGrade may not be blank or empty')
  }

  return candidate == null ? null : candidate.trim()
}

const grade = {parse, parseOtherGrade}
export default grade
