import React from 'react'
import {useIntl} from 'react-intl'
import FocusableImage from '../../FocusableImage'
import {UnstyledExternalLink} from '../ExternalLink'
import twitterIconUrl from './assets/twitter_icon.svg'

export const TWITTER_URL = 'https://twitter.com/LemonadeDay'

function TwitterLink() {
  const intl = useIntl()
  return (
    <UnstyledExternalLink to={TWITTER_URL}>
      <FocusableImage
        alt={intl.formatMessage({id: 'general.twitter'})}
        height={33}
        src={twitterIconUrl}
        variant={'social'}
        width={33}
      />
    </UnstyledExternalLink>
  )
}

export default TwitterLink
