import produce from 'immer'
import {Reducer} from 'redux'
import {AppActions} from '../store'
import {
  ATTEMPT_RESET_PASSWORD,
  RECEIVE_RESET_PASSWORD_RESPONSE
} from './reset-password.action'
import {ResetPasswordState} from './reset-password.types'

const initialState: ResetPasswordState = {
  error: false,
  inFlight: false
}

const reducer: Reducer<ResetPasswordState, AppActions> = (
  state = initialState,
  action: AppActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case ATTEMPT_RESET_PASSWORD:
        draft.error = false
        draft.inFlight = true
        break

      case RECEIVE_RESET_PASSWORD_RESPONSE:
        if (action.error) {
          draft.error = true
          draft.inFlight = false
          break
        }
        draft.inFlight = false
        break
    }
  })

export default reducer
