import {CheckBadgeIcon, StarIcon} from '@heroicons/react/24/outline'
import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useRouteMatch} from 'react-router'
import Community, {CommunityId} from '../../domain/community'
import {EventId} from '../../domain/event'
import {
  COMMUNITIES_ROUTE,
  COMMUNITY_EVENTS_ROUTE,
  COMMUNITY_ID_ROUTE_PLACEHOLDER,
  COMMUNITY_TOOL_SELECTOR_ROUTE,
  EDIT_COMMUNITY_ROUTE,
  EVENT_ID_ROUTE_PLACEHOLDER,
  SPONSOR_IMAGES_ROUTE,
  TOOL_SELECTOR_ROUTE
} from '../../global-constants/routingConstants'
import useCommunity from '../../hooks/useCommunity'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import Divider from '../Divider'
import CommunitySettingsIcon from '../icons/CommunitySettingsIcon'
import Layout from '../Layout'
import TitleText from '../TitleText'
import NavigationItem from '../ToolSelectorPage/NavigationItem'

function CommunityToolSelectorPage() {
  const intl = useIntl()
  const {
    params: {communityId, eventId}
  } = useRouteMatch<{communityId: CommunityId; eventId: EventId}>()
  let communityToolSelectorRoute = COMMUNITY_TOOL_SELECTOR_ROUTE.replace(
    COMMUNITY_ID_ROUTE_PLACEHOLDER,
    communityId
  )
  const {community} = useCommunity({
    communityId
  })
  let communityName = ''
  if (community) {
    communityToolSelectorRoute = communityToolSelectorRoute.replace(
      EVENT_ID_ROUTE_PLACEHOLDER,
      community.currentEventId
    )
    communityName = Community.formatName(community)
  }

  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="admin.communityToolSelector.title" />
        </TitleText>
      }
    >
      <div
        className="mx-auto mb-2 w-full px-2
          md:mb-4 md:px-4 lg:mb-6 lg:w-2/3
          lg:px-6 xl:mb-8 xl:max-w-7xl xl:px-8"
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={COMMUNITIES_ROUTE}
            to={COMMUNITIES_ROUTE}
            label={<FormattedMessage id="manageCommunity.community_manager" />}
          />
          <BreadcrumbItem
            path={COMMUNITY_TOOL_SELECTOR_ROUTE}
            to={communityToolSelectorRoute}
            label={communityName}
          />
        </Breadcrumb>
        <ul aria-labelledby="heading" className="w-full">
          <h2
            id="heading"
            className="typography-headline-4 my-2 md:my-4 lg:my-6 xl:my-8"
          >
            <FormattedMessage id="admin.communityToolSelector.subTitle" />
          </h2>
          <div className="rounded bg-white">
            <NavigationItem
              name={intl.formatMessage({
                id: 'admin.communityToolSelector.settings'
              })}
              route={EDIT_COMMUNITY_ROUTE.replace(
                COMMUNITY_ID_ROUTE_PLACEHOLDER,
                communityId
              ).replace(EVENT_ID_ROUTE_PLACEHOLDER, eventId)}
              icon={
                <CommunitySettingsIcon className="h-12 w-12 rounded-full bg-green p-1" />
              }
            />
            <Divider />
            <NavigationItem
              name={intl.formatMessage({
                id: 'admin.communityToolSelector.events'
              })}
              route={COMMUNITY_EVENTS_ROUTE.replace(
                COMMUNITY_ID_ROUTE_PLACEHOLDER,
                communityId
              )}
              icon={
                <StarIcon className="h-12 w-12 rounded-full bg-green p-2" />
              }
            />
            <Divider />
            <NavigationItem
              name={intl.formatMessage({
                id: 'admin.communityToolSelector.sponsors'
              })}
              route={SPONSOR_IMAGES_ROUTE.replace(
                COMMUNITY_ID_ROUTE_PLACEHOLDER,
                communityId
              ).replace(EVENT_ID_ROUTE_PLACEHOLDER, eventId)}
              icon={
                <CheckBadgeIcon className="h-12 w-12 rounded-full bg-green p-2" />
              }
            />
          </div>
        </ul>
      </div>
    </Layout>
  )
}

export default CommunityToolSelectorPage
