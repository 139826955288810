import {
  authorizationHeaderFromAccessToken,
  authorizationHeaderFromState
} from './auth'
import {API_URL, SERVER_URL} from './config'
import CommunityStatus from './domain/communityStatus'
import CommunityType from './domain/communityType'
import ServerError from './errors/server'
import {Adult} from './store/adults/adults.type'
import {
  LoginCredentials,
  LoginDto,
  ResendEmailVerification
} from './store/authentication/authentication.type'
import {Community} from './store/communities/communities.type'
import {ForgotPassword} from './store/forgot-password/forgot-password.type'
import {CreateLearner, LearnerDTO} from './store/learners/learners.type'
import {getMentor} from './store/mentors/mentors.selectors'
import {CreateMentorFields, Mentor} from './store/mentors/mentors.type'
import {ResetPassword} from './store/reset-password/reset-password.types'
import {
  SponsorImage,
  SponsorImageKey
} from './store/sponsorImages/sponsorImages.types'
import {AppState} from './store/store'

export async function getCommunities(
  types: Array<CommunityType>,
  statuses: Array<CommunityStatus>
): Promise<Community[]> {
  const url = new URL('communities', API_URL)
  if (types.length > 0) {
    url.searchParams.append('types', types.join(','))
  }
  if (statuses.length > 0) {
    url.searchParams.append('statuses', statuses.join(','))
  }
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
  return response.json()
}

export async function createMentor(
  mentorFields: CreateMentorFields
): Promise<Mentor> {
  const url = new URL('mentors/', API_URL)
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(mentorFields)
  })
  if (response.status !== 201) {
    return ServerError.fromResponse(response)
  }
  return response.json()
}

export async function updateMentor(
  mentor: Mentor,
  state: AppState
): Promise<Mentor> {
  const url = new URL(`mentors/${mentor.id}`, API_URL)
  const response = await fetch(url.toString(), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authorizationHeaderFromState(state)
    },
    body: JSON.stringify(mentor)
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
  return response.json()
}

// todo: wip while server is implemented.
// todo: the typing here can be improved. throwServerError *can* throw.
export async function login(credentials: LoginCredentials): Promise<LoginDto> {
  const url = new URL('auth/adults/login', SERVER_URL)
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
  return response.json()
}

export async function updateAdult(
  data: Adult,
  state: AppState
): Promise<Adult> {
  const adult: Adult = {
    id: data.id,
    email: data.email,
    phoneNumber: data.phoneNumber,
    title: data.title,
    firstName: data.firstName,
    lastName: data.lastName,
    communityId: data.communityId,
    locale: data.locale,
    zip: data.zip
  }
  const url = new URL(`adults/${data.id}/`, API_URL)
  const response = await fetch(url.toString(), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authorizationHeaderFromState(state)
    },
    body: JSON.stringify(adult)
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
  return response.json()
}

export async function forgotPassword(data: ForgotPassword): Promise<void> {
  const url = new URL('auth/adults/forgot-password/', SERVER_URL)
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
}

export async function resetPassword({
  password,
  token
}: ResetPassword): Promise<void> {
  const url = new URL('auth/adults/reset-password/', SERVER_URL)
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authorizationHeaderFromAccessToken(token)
    },
    body: JSON.stringify({password})
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
}

export async function resendEmailVerification(
  data: ResendEmailVerification
): Promise<Record<string, unknown>> {
  const url = new URL(
    'auth/adults/resend-email-address-verification/',
    SERVER_URL
  )
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
  return response.json()
}

export async function createLearner(
  data: CreateLearner,
  state: AppState
): Promise<LearnerDTO> {
  const url = new URL('learners/', API_URL)
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authorizationHeaderFromState(state)
    },
    body: JSON.stringify(data)
  })
  if (response.status !== 201) {
    return ServerError.fromResponse(response)
  }
  return response.json()
}

export async function getLearners(state: AppState): Promise<LearnerDTO[]> {
  const authenticatedMentor = getMentor(state)

  const urlPath = new URL('learners', API_URL)
  urlPath.searchParams.append('mentorId', authenticatedMentor.id)

  const response = await fetch(urlPath.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authorizationHeaderFromState(state)
    }
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
  return response.json()
}

export async function getSponsorImages(
  communityId: string,
  eventId: string,
  state: AppState
): Promise<Array<SponsorImage>> {
  const url = new URL('sponsorImages', API_URL)
  url.searchParams.set('communityId', communityId)
  url.searchParams.set('eventId', eventId)
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...authorizationHeaderFromState(state)
    }
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }
  return response.json()
}

export async function uploadSponsorImage(
  communityId: string,
  eventId: string,
  sponsorImageKey: SponsorImageKey,
  image: File,
  state: AppState
): Promise<string> {
  const url = new URL(
    `communities/${communityId}/events/${eventId}/sponsorImages/${sponsorImageKey}`,
    API_URL
  )
  const formData = new FormData()
  formData.append('image', image)
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      ...authorizationHeaderFromState(state)
    },
    body: formData
  })
  if (response.status !== 201) {
    return ServerError.fromResponse(response)
  }
  const location = response.headers.get('Location')
  if (location == null) {
    throw new Error('Missing Location header')
  }
  return location
}

export async function deleteSponsorImage(
  communityId: string,
  eventId: string,
  key: SponsorImageKey,
  state: AppState
): Promise<void> {
  const url = new URL(
    `communities/${communityId}/events/${eventId}/sponsorImages/${key}`,
    API_URL
  )
  const response = await fetch(url.toString(), {
    method: 'DELETE',
    headers: {...authorizationHeaderFromState(state)}
  })
  if (response.status !== 204) {
    return ServerError.fromResponse(response)
  }
}

export async function getUploadedImage(
  src: string,
  accessToken: string
): Promise<string> {
  // Get the image location from the server.
  const response = await fetch(src, {
    method: 'GET',
    headers: {...authorizationHeaderFromAccessToken(accessToken)}
  })
  if (response.status !== 204) {
    return ServerError.fromResponse(response)
  }
  const imageLocation = response.headers.get('Location')
  if (imageLocation == null) {
    throw new Error('Missing Location header')
  }

  // Get the image from the location returned by the server.
  const imageResponse = await fetch(imageLocation, {method: 'GET'})
  if (imageResponse.status !== 200) {
    return ServerError.fromResponse(imageResponse)
  }

  const blob = await imageResponse.blob()
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
  })
}

export async function postCSV(csvFile: File, state: AppState): Promise<void> {
  const url = new URL(`learners/csv`, API_URL)
  const formData = new FormData()
  formData.append('csv', csvFile)
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      ...authorizationHeaderFromState(state)
    },
    body: formData
  })
  if (response.status !== 201) {
    return ServerError.fromResponse(response)
  }
}
