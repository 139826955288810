import React from 'react'
import {useIntl} from 'react-intl'
import CountryCode from '../domain/countryCode'
import LanguageTag from '../domain/languageTag'
import {LANGUAGE_TAG_EN_US, LANGUAGE_TAG_ES_MX} from '../messages'
import SelectInput from './SelectInput/SelectInput'

const LOCALES = [
  {languageTag: LANGUAGE_TAG_EN_US, nameMessageId: 'general.locale.en-us'},
  {languageTag: LANGUAGE_TAG_ES_MX, nameMessageId: 'general.locale.es-mx'}
] as const

const LANGUAGE_TAG_BY_COUNTRY_CODE = {
  CA: LANGUAGE_TAG_EN_US,
  MX: LANGUAGE_TAG_ES_MX,
  US: LANGUAGE_TAG_EN_US
} as const

type Locale = typeof LOCALES[number]

type Props = Readonly<{
  errorMessage?: string | null
  name?: string
  value: LanguageTag | null
  onChange?: (value: LanguageTag | null) => void
}>

function LocaleSelect({
  errorMessage = null,
  name = 'locale',
  value,
  onChange
}: Props) {
  const intl = useIntl()
  const options = LOCALES.map((option: Locale) => ({
    id: option.languageTag,
    name: intl.formatMessage({id: option.nameMessageId})
  }))

  return (
    <SelectInput
      errorMessage={errorMessage}
      name={name}
      label={intl.formatMessage({id: 'admin.communities.language.label'})}
      placeholderText={intl.formatMessage({
        id: 'admin.communities.selectSearch'
      })}
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export function getLanguageTagByCountryCode<C extends CountryCode>(
  countryCode: C | null
): LanguageTag | null {
  return countryCode == null ? null : LANGUAGE_TAG_BY_COUNTRY_CODE[countryCode]
}

export default LocaleSelect
