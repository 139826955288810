import React, {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useHistory} from 'react-router'
import {DUPLICATE_COMMUNITY_ERROR_IDENTIFIER} from '../../errors/errorIdentifiers'
import ServerError from '../../errors/server'
import {
  COMMUNITIES_ROUTE,
  COMMUNITIES_SELECTED_COMMUNITY_ID_TEMPLATE_ROUTE,
  TOOL_SELECTOR_ROUTE
} from '../../global-constants/routingConstants'
import useCreateCommunity from '../../hooks/useCreateCommunity'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import Layout from '../Layout'
import TitleText from '../TitleText'
import Toast from '../Toast'
import AddCommunityForm from './AddCommunityForm'

function AddCommunityPage() {
  const history = useHistory()

  const [validationErrorCount, setValidationErrorCount] = useState(0)

  const {busy, error, createCommunity} = useCreateCommunity({
    onSuccess: communityId => {
      history.push(
        COMMUNITIES_SELECTED_COMMUNITY_ID_TEMPLATE_ROUTE.replace(
          ':communityId',
          communityId
        )
      )
    }
  })

  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="manageCommunity.community_manager" />
        </TitleText>
      }
      toast={
        <AddCommunityToast
          error={error}
          validationErrorCount={validationErrorCount}
        />
      }
    >
      <div
        className="
          mx-auto mb-2 w-full px-2
          md:mb-4 md:px-4 lg:mb-6 lg:px-6
          xl:mb-8 xl:max-w-7xl xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={COMMUNITIES_ROUTE}
            to={COMMUNITIES_ROUTE}
            label={<FormattedMessage id="manageCommunity.community_manager" />}
          />
        </Breadcrumb>
        <h2 className="typography-headline-4 my-2 md:my-4 lg:my-6 xl:my-8">
          <FormattedMessage id="admin.communities.add.title" />
        </h2>
        <AddCommunityForm
          busy={busy}
          onSubmit={createCommunity}
          onValidationFailure={setValidationErrorCount}
        />
      </div>
    </Layout>
  )
}

type ToastProps = {
  error?: Error | null
  validationErrorCount: number
}

function AddCommunityToast({
  error = null,
  validationErrorCount = 0
}: ToastProps) {
  return (
    <>
      {error && (
        <Toast errorCount={1} variant="error">
          {ServerError.includes(error, DUPLICATE_COMMUNITY_ERROR_IDENTIFIER) ? (
            <FormattedMessage id="admin.communities.duplicateCommunity" />
          ) : (
            <FormattedMessage id="general.GenericError" />
          )}
        </Toast>
      )}
      {validationErrorCount > 0 && (
        <Toast errorCount={validationErrorCount} variant="error">
          <FormattedMessage id="admin.communities.validationMessage" />
        </Toast>
      )}
    </>
  )
}

export default AddCommunityPage
