import {Popover} from '@headlessui/react'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  EDUCATIONAL_RESOURCES_ROUTE,
  LOGIN_ROUTE,
  SUPPLIES_SHOP_ROUTE,
  TOOL_SELECTOR_ROUTE
} from '../../global-constants/routingConstants'
import {logUserOut} from '../../store/authentication/authentication.action'
import {getRoles} from '../../store/authentication/authentication.selectors'
import {AppDispatch} from '../../store/store'
import LanguageSelector from '../Header/LanguageSelector'
import MyLemonadeDayAppIcon from '../icons/MyLemonadeDayAppIcon'
import AppStoreDownloadLink from '../links/AppStoreDownloadLink'
import InternalLink from '../links/InternalLink'
import PlayStoreDownloadLink from '../links/PlayStoreDownloadLink'
import StyledButton from '../StyledButton'

type Props = Readonly<{
  onHowToAddLearners?: () => void
}>

function Menu({onHowToAddLearners}: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const roles = useSelector(getRoles)

  return (
    <Popover>
      {({close}) => (
        <>
          <Popover.Button as="div">
            <StyledButton type={'button'}>
              <FormattedMessage id="general.menu" />
            </StyledButton>
          </Popover.Button>
          {/* Menu Items */}
          <Popover.Panel
            className="
              fixed top-0 right-0 z-10 flex h-full w-full max-w-xs flex-col
              overflow-hidden bg-navy px-1 text-white
              shadow-[-1px_1px_4px] shadow-[rgba(0,0,0,0.2)]
              sm:overflow-visible md:h-5/6
            "
          >
            {/* Language selector and Menu button */}
            <div
              className="
                flex cursor-pointer items-center justify-between 
                border-b-2 border-white border-opacity-50 p-4 text-white 
                sm:justify-end
              "
            >
              <div
                className="
                  flex h-full items-center justify-self-start sm:hidden
                "
              >
                <LanguageSelector />
              </div>
              <StyledButton type="button" onClick={() => close()}>
                <FormattedMessage id="general.Close" />
              </StyledButton>
            </div>

            {roles.includes('ROLE_ADMIN') && (
              <div
                className="border-b-2 border-white border-opacity-50 p-4
                  outline-none focus:outline-none focus-visible:outline-none
                  lg:block
                "
              >
                <InternalLink to={TOOL_SELECTOR_ROUTE} variant="menu-item">
                  <FormattedMessage id="admin.toolSelector.title" />
                </InternalLink>
              </div>
            )}
            {roles.includes('ROLE_MENTOR') && (
              <MenuButton
                hideOnLargeScreens={true}
                labelId="learners.tutorial"
                onClick={() => onHowToAddLearners?.()}
              />
            )}
            <MenuButton
              labelId="general.Logout"
              onClick={() => {
                dispatch(logUserOut())
                history.push(LOGIN_ROUTE)
                close()
              }}
            />
            {roles.includes('ROLE_MENTOR') && (
              <>
                <div className="p-4 outline-none focus:outline-none focus-visible:outline-none lg:block">
                  <InternalLink
                    to={EDUCATIONAL_RESOURCES_ROUTE}
                    variant="menu-item"
                  >
                    <FormattedMessage id="educationalResources.title" />
                  </InternalLink>
                </div>
                <div className="p-4 outline-none focus:outline-none focus-visible:outline-none lg:block">
                  <InternalLink to={SUPPLIES_SHOP_ROUTE} variant="menu-item">
                    <FormattedMessage id="suppliesShop.pageTitle" />
                  </InternalLink>
                </div>
              </>
            )}

            {/* MLD and App store logos and  */}
            <div className="absolute bottom-0 flex flex-col items-center justify-center space-y-4 p-4">
              {/* MLD App logo and download instructions */}
              <div className="flex flex-row space-x-4">
                <MyLemonadeDayAppIcon />
                <p className="typography-caption">
                  <FormattedMessage id="layout.footerText" />
                </p>
              </div>
              {/* App store icons */}
              <div className="flex w-full flex-row justify-between">
                <AppStoreDownloadLink />
                <PlayStoreDownloadLink />
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

function MenuButton({
  hideOnLargeScreens,
  labelId,
  onClick
}: Readonly<{
  hideOnLargeScreens?: boolean
  labelId: string
  onClick: () => void
}>) {
  return (
    <button
      className={`
        ${hideOnLargeScreens && 'lg:hidden'}
        group flex cursor-pointer items-start 
        border-b-2 border-white border-opacity-50 p-4 text-white 
        outline-none group-focus:outline-none 
        group-focus-visible:outline-none
      `}
      onClick={() => onClick()}
    >
      <span
        className="
          rounded p-1 outline-none group-hover:text-sky
          group-focus:outline-none group-focus-visible:outline-none
          group-focus-visible:ring-4 group-focus-visible:ring-white
        "
      >
        <FormattedMessage id={labelId} />
      </span>
    </button>
  )
}

export default Menu
