import React from 'react'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {
  REGISTER_LEARNER_ROUTE,
  REGISTER_ROUTE,
  TOOL_SELECTOR_ROUTE
} from '../../../global-constants/routingConstants'
import {getRoles} from '../../../store/authentication/authentication.selectors'
import {Role} from '../../../store/authentication/authentication.type'

export default function RootPage() {
  const roles: Role[] = useSelector(getRoles)

  if (roles.some(role => role === 'ROLE_ADMIN'))
    return <Redirect to={TOOL_SELECTOR_ROUTE} />
  else if (roles.some(role => role === 'ROLE_MENTOR'))
    return <Redirect to={REGISTER_LEARNER_ROUTE} />
  else return <Redirect to={REGISTER_ROUTE} />
}
