import React from 'react'
import {FormattedMessage} from 'react-intl'
import styled from 'styled-components'
import CheckMail from '../../../components/email/assets/mentor_gotmail_image-fs8.png'
import CheckMail2x from '../../../components/email/assets/mentor_gotmail_image@2x-fs8.png'
import CheckMail3x from '../../../components/email/assets/mentor_gotmail_image@3x-fs8.png'
import CheckMail4x from '../../../components/email/assets/mentor_gotmail_image@4x-fs8.png'
import {contentPadding, heading, text} from '../../../styled/mixins'
import {Body4, BoldBody3, Heading1 as H1} from '../Typography'

interface Props {
  heading: string
  mainText: string
  description: string
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${contentPadding}
`

const Heading = styled(H1)`
  ${heading}
`

const MainText = styled(BoldBody3)`
  ${text}
`

const Description = styled(Body4)`
  ${text}
`

const Postbox = styled.img`
  max-width: 100%;
  padding: 0 10px;
  object-fit: contain;
  margin-bottom: 2rem;
  margin-left: -8%;

  @media screen and (min-width: 436px) {
    margin-left: -50px;
  }
`

function CheckYourMail({heading, mainText, description}: Props) {
  return (
    <Content>
      <Postbox
        src={CheckMail}
        srcSet={`${CheckMail}, ${CheckMail2x} 2x, ${CheckMail3x} 3x, ${CheckMail4x} 4x`}
      />
      <Heading>
        <FormattedMessage id={heading} />
      </Heading>
      <MainText>
        <FormattedMessage id={mainText} />
      </MainText>
      <Description>
        <FormattedMessage id={description} />
      </Description>
    </Content>
  )
}

export default CheckYourMail
