import React from 'react'

type Props = Readonly<{
  children: React.ReactNode
}>

function TitleText({children}: Props) {
  return (
    <h1 className="typography-headline-6 sm:typography-headline-5 text-center leading-6 text-navy">
      {children}
    </h1>
  )
}

export default TitleText
