import {isNonBlankString} from '../responseRefiners'
import * as COUNTRY_CODES from './countryCodes'
import {CA, MX, US} from './countryCodes'

/**
 * An ISO 3166 Country Code.
 */
type CountryCode = ValueOf<typeof COUNTRY_CODES>

const EXPECTED_COUNTRY_CODES = Object.values(COUNTRY_CODES)

/**
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is an expected ISO 3166 country code.
 */
function isValid(candidate: unknown): boolean {
  return candidate === CA || candidate === MX || candidate === US
}

/**
 * Parse a {@link CountryCode} from a candidate of unknown type.
 *
 * @param candidate The candidate to parse.
 * @return A valid typed {@link CountryCode}.
 */
function parse(candidate: unknown): CountryCode {
  if (!isNonBlankString(candidate)) {
    throw new Error(
      `CountryCode <${candidate}> may not be blank, empty or null`
    )
  }

  const trimmedCountryCode = candidate.trim()
  if (!isValid(trimmedCountryCode)) {
    throw new Error(
      `Unexpected CountryCode ${candidate}, expected one of ${EXPECTED_COUNTRY_CODES.join(
        ', '
      )}`
    )
  }

  return trimmedCountryCode as CountryCode
}

const CountryCode = {isValid, parse}
export default CountryCode
