import * as ETHNICITIES from './ethnicities'
import {
  AFRICAN_AMERICAN,
  AMERICAN_INDIAN_OR_ALASKA_NATIVE,
  ASIAN,
  HISPANIC_OR_LATINO,
  NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER,
  NO_ANSWER,
  OTHER,
  WHITE
} from './ethnicities'

export type Ethnicity = ValueOf<typeof ETHNICITIES>

const EXPECTED_ETHNICITIES = Object.values(ETHNICITIES)

function parse(candidate: unknown): Ethnicity {
  if (
    candidate !== AFRICAN_AMERICAN &&
    candidate !== AMERICAN_INDIAN_OR_ALASKA_NATIVE &&
    candidate !== ASIAN &&
    candidate !== HISPANIC_OR_LATINO &&
    candidate !== NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER &&
    candidate !== NO_ANSWER &&
    candidate !== WHITE &&
    candidate !== OTHER
  ) {
    throw new Error(
      `Unexpected Ethnicity ${candidate}, expected on of ${EXPECTED_ETHNICITIES.join(
        ', '
      )}`
    )
  }

  return candidate
}

function parseOtherEthnicity(candidate: unknown): string | null {
  if (candidate !== null && typeof candidate !== 'string') {
    throw new Error('OtherEthnicity may not be blank or empty')
  }

  return candidate == null ? null : candidate.trim()
}

const ethnicity = {parse, parseOtherEthnicity}
export default ethnicity
