import {isNonBlankString} from '../responseRefiners'
import * as LANGUAGE_TAGS from './languageTags'
import {EN_US, ES_MX} from './languageTags'

/**
 * An IETF BCP 47 language tag.
 */
type LanguageTag = ValueOf<typeof LANGUAGE_TAGS>

const EXPECTED_LANGUAGE_TAGS = Object.values(LANGUAGE_TAGS)

/**
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is an expected IETF BCP 47 language tag.
 */
function isValid(candidate: unknown): boolean {
  return candidate === EN_US || candidate === ES_MX
}

/**
 * Parse an IETF BCP 47 {@link LanguageTag} from a candidate of unknown type.
 *
 * @param candidate The candidate to parse.
 * @return A valid typed {@link LanguageTag}.
 */
function parse(candidate: unknown): LanguageTag {
  if (!isNonBlankString(candidate)) {
    throw new Error(`LanguageTag ${candidate}, may not be blank, empty or null`)
  }

  const trimmedCandidate = candidate.trim()
  if (!isValid(trimmedCandidate)) {
    throw new Error(
      `Unexpected LanguageTag ${candidate}, expected one of ${EXPECTED_LANGUAGE_TAGS.join(
        ', '
      )}`
    )
  }

  return trimmedCandidate as LanguageTag
}

const LanguageTag = {isValid, parse}
export default LanguageTag
