import styled, {css} from 'styled-components'
import colours from '../../colours'

const body = css`
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
`

// B1_reg
export const Body1 = styled.span`
  ${body}
  font-size: 1.5rem;
`

// B1_bold
export const BoldBody1 = styled(Body1)`
  font-weight: 700;
`

// B2_reg
export const Body2 = styled.span`
  ${body}
  font-size: 1.375rem;
`

// B2_bold
export const BoldBody2 = styled(Body2)`
  font-weight: 700;
`

// B3_reg
export const Body3 = styled.span`
  ${body}
  font-size: 1rem;
`

// B3_bold
export const BoldBody3 = styled(Body3)`
  font-weight: 700;
`

// B4_reg
export const Body4 = styled.span`
  ${body}
  font-size: 0.875rem;
`

// B4_bold
export const BoldBody4 = styled(Body4)`
  font-weight: 700;
`

// B5_reg
export const body5 = css`
  ${body}
  font-size: 0.75rem;
`
export const Body5 = styled.span`
  ${body5}
`

// B5_bold
export const BoldBody5 = styled(Body5)`
  font-weight: 700;
`

// B6_reg
export const Body6 = styled.span`
  ${body}
  font-size: 0.5625rem;
  line-height: 0.5625rem;
`

// H1
export const Heading1 = styled.h1`
  font-family: 'Caveat Brush', cursive;
  font-weight: 400;
  font-size: 2.1875rem;
  color: ${colours.colours.turquoise};
  /* override UA styles. margin set by mixin src/styled/mixins.ts */
  margin: 0;
`
