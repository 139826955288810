import {Switch} from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'
import {FormattedMessage} from 'react-intl'

type Props = Readonly<{
  checked: boolean
  description: string
  label: string
  onChange: (checked: boolean) => void
}>

function ToggleInput({checked, description, label, onChange}: Props) {
  return (
    <Switch.Group>
      <div className="flex w-full items-center justify-between p-2 text-navy">
        <div>
          <Switch.Label className="typography-headline-5 block after:content-[':']">
            <FormattedMessage id={label} />
          </Switch.Label>
          <p className="typography-body-2">
            <FormattedMessage id={description} />
          </p>
        </div>
        <span className="flex items-center space-x-4">
          <span className="typography-body-1">
            <FormattedMessage
              id={
                checked
                  ? 'learners.guardianChange_confirm'
                  : 'learners.guardianChange_dismiss'
              }
            />
          </span>
          <Switch
            checked={checked}
            onChange={onChange}
            className={clsx(
              checked ? 'bg-green' : 'bg-grey-tertiary-30',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent outline-none transition-colors duration-200 ease-in-out focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-navy focus-visible:ring-offset-2'
            )}
            type="button"
          >
            <span
              aria-hidden
              className={clsx(
                checked ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </span>
      </div>
    </Switch.Group>
  )
}

export default ToggleInput
