import produce from 'immer'
import {Reducer} from 'redux'
import {AppActions} from '../store'
import {
  ATTEMPT_GET_LEARNERS,
  CREATE_LEARNER,
  GET_LEARNERS
} from './learners.action'
import {Learner, LearnerState} from './learners.type'

const initialState: LearnerState = {
  byId: {},
  newIds: [],
  error: null,
  fetching: false,
  pages: {}
}

const reducer: Reducer<LearnerState, AppActions> = (
  state = initialState,
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case CREATE_LEARNER:
        if (action.error) {
          draft.error = action.payload
          break
        }
        draft.byId[action.payload.id] = action.payload
        draft.newIds.push(action.payload.id)
        break
      case ATTEMPT_GET_LEARNERS:
        draft.fetching = true
        draft.error = null
        break
      case GET_LEARNERS:
        if (action.error) {
          draft.error = action.payload
          draft.fetching = false
          break
        }
        handleGetLearnersAction(draft, action)
        break
    }
  })

function handleGetLearnersAction(
  state: LearnerState,
  action: {payload: Learner[]; meta: {pageId: string}}
) {
  const learnerIds = []
  for (const learner of action.payload) {
    state.byId[learner.id] = learner
    learnerIds.push(learner.id)
  }
  state.newIds = []
  state.pages[action.meta.pageId] = {ids: learnerIds}
  state.fetching = false
}

export default reducer
