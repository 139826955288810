import LearnerView, {LearnerView as LearnerViewT} from './learnerView'
import {CARDS, TABLE} from './learnerViews'

export const LEARNER_VIEW_STORAGE_KEY = 'learnerView'

function getView(): LearnerViewT {
  const storedView = localStorage.getItem(LEARNER_VIEW_STORAGE_KEY)
  return storedView == null
    ? LearnerView.parse(CARDS)
    : LearnerView.parse(storedView)
}

function setView(view: LearnerViewT): void {
  return localStorage.setItem(LEARNER_VIEW_STORAGE_KEY, view)
}

function toggleView(): LearnerViewT {
  const view = getView()
  switch (view) {
    case CARDS:
      setView(TABLE)
      return TABLE
    case TABLE:
      setView(CARDS)
      return CARDS
    default:
      throw new Error(`Unexpected LearnersView<${view}>`)
  }
}

export default {getView, toggleView, setView}
