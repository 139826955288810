import React from 'react'
import styled from 'styled-components'
import {ReactComponent as TickIcon} from './assets/tick.svg'

const CHECKBOX_HEIGHT = 22

interface Props {
  onChange: () => void
  checked: boolean
  name: string
}

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.625rem;
`

const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  appearance: none;
  position: absolute;
  visibility: hidden;
`

const FakeCheckbox = styled.div`
  display: flex;
  width: ${CHECKBOX_HEIGHT}px;
  height: ${CHECKBOX_HEIGHT}px;
  border-radius: 5px;
  border: 1px solid #28414b;
  padding: 2px 2px 1px 1px;
`

function CheckBox({onChange, checked, name}: Props) {
  return (
    <Container
      onClick={onChange}
      onKeyPress={ev => {
        ev.preventDefault()
        if (ev.key === ' ') {
          onChange()
        }
      }}
      role="checkbox"
      aria-checked={checked}
      tabIndex={0}
    >
      <HiddenCheckbox name={name} checked={checked} onChange={onChange} />
      <FakeCheckbox>
        {checked && <TickIcon width="100%" height="100%" />}
      </FakeCheckbox>
    </Container>
  )
}

export default CheckBox
