import flatten from 'flat'
import en from './en-US'
import es from './es-MX'

export const LANGUAGE_TAG_EN_US = 'en-US'
export const LANGUAGE_TAG_ES_MX = 'es-MX'

type NestedMessages = typeof en
type FlattenedMessages = Readonly<{[key: string]: string}>

export default {
  'en-US': flatten<NestedMessages, FlattenedMessages>(en),
  'es-MX': flatten<NestedMessages, FlattenedMessages>(es)
}
