import {useContext, useEffect} from 'react'
import {ShepherdTourContext} from 'react-shepherd'

type Props = Readonly<{
  onCancel: () => void
  onComplete: () => void
}>

/**
 * A component which has the sole purpose of accepting callbacks which should
 * be called when the corresponding events are emitted on the tour that the
 * component is rendered in the context of.
 */
function TourEventHandler({onCancel, onComplete}: Props) {
  const tour = useContext(ShepherdTourContext)
  useEffect(() => {
    if (tour == null) {
      return
    }
    tour.once('cancel', onCancel)
    tour.once('complete', onComplete)
    return () => {
      tour.off('cancel', onCancel)
      tour.off('complete', onComplete)
    }
  }, [tour, onCancel, onComplete])
  return null
}

export default TourEventHandler
