import {isPlainObject} from '../responseRefiners'
import {ErrorIdentifier} from './index'

export const NAME = 'ServerError'

interface ServerError extends Error {
  identifiers: ReadonlyArray<string>
  indexedIdentifiers: Readonly<{[index: number]: ReadonlyArray<string>}>
  links: Readonly<{[name: string]: Readonly<{href: string}>}>
  status: number
}

type ErrorResponse = Readonly<{
  body?: unknown
  message?: string
  status: number
}>

type ErrorResponseBody = Readonly<{
  errorIdentifiers: ReadonlyArray<string>
  _links: Readonly<{[name: string]: {href: string}}>
  message: string
}>

type IndexedErrorResponseBody = Readonly<{
  indexedErrorIdentifiers: Readonly<{[index: number]: ReadonlyArray<string>}>
  _links: Readonly<{[name: string]: {href: string}}>
  message: string
}>

export class DefaultServerError extends Error implements ServerError {
  identifiers: ReadonlyArray<string>
  indexedIdentifiers: Readonly<{[index: number]: ReadonlyArray<string>}>
  links: Readonly<{[name: string]: Readonly<{href: string}>}>
  status: number

  constructor({status, body, message}: ErrorResponse) {
    super(message ?? `Unexpected error response: ${status}`)
    Object.setPrototypeOf(this, new.target.prototype)

    this.name = NAME
    this.status = status

    if (isErrorResponseBody(body)) {
      this.identifiers = body.errorIdentifiers
      this.indexedIdentifiers = {}
      this.links = body._links ?? {}
      this.message = message ?? body.message
    } else if (isIndexedErrorResponseBody(body)) {
      this.identifiers = []
      this.indexedIdentifiers = body.indexedErrorIdentifiers
      this.links = body._links ?? {}
      this.message = message ?? body.message
    } else {
      this.identifiers = []
      this.indexedIdentifiers = {}
      this.links = {}
    }
  }
}

function isErrorResponseBody(
  candidate: unknown
): candidate is ErrorResponseBody {
  return (
    isPlainObject(candidate) &&
    typeof candidate.message === 'string' &&
    Array.isArray(candidate.errorIdentifiers) &&
    candidate.errorIdentifiers.every(id => typeof id === 'string')
  )
}

function isIndexedErrorResponseBody(
  candidate: unknown
): candidate is IndexedErrorResponseBody {
  return (
    isPlainObject(candidate) &&
    typeof candidate.message === 'string' &&
    isPlainObject(candidate.indexedErrorIdentifiers) &&
    Object.values(candidate.indexedErrorIdentifiers).every(
      value => Array.isArray(value) && value.every(id => typeof id === 'string')
    )
  )
}

function from(status: number, body?: unknown, message?: string): never {
  throw new DefaultServerError({status, body, message})
}

async function fromResponse(res: Response): Promise<never> {
  const contentType = res.headers.get('Content-Type')
  const body =
    contentType == null || !/json/.test(contentType) ? null : await res.json()
  throw new DefaultServerError({body, status: res.status})
}

function includes(err: Error, identifier: ErrorIdentifier): boolean {
  if (isServerError(err)) {
    return err.identifiers.some(id => id === identifier)
  }
  return false
}

function isServerError(err: Error): err is ServerError {
  return err.name === NAME
}

const ServerError = {from, fromResponse, includes, isServerError}
export default ServerError
