import React, {Suspense, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {ShepherdOptionsWithType, ShepherdTour} from 'react-shepherd'
import {useMedia} from 'react-use'
import 'shepherd.js/dist/css/shepherd.css'
import breakpoints from '../../breakpoints'
import {getMentor} from '../../store/mentors/mentors.selectors'
import {Mentor} from '../../store/mentors/mentors.type'
import {AppState} from '../../store/store'
import tourCompletionStore from '../../tours/tourCompletionStore'
import {ADD_LEARNER_CARD} from '../../tours/tours'
import FlatButton from '../FlatButton'
import Layout from '../Layout'
import LoadingIndicator from '../LoadingIndicator'
import TitleText from '../TitleText'
import TourEventHandler from '../TourEventHandler'
import {LearnerView} from './learnerView'
import {CARDS, TABLE} from './learnerViews'
import LearnersViewStore from './learnerViewStore'
import LearnerViewToggle from './LearnerViewToggle'
import MentorCodeBanner from './MentorCodeBanner'

const LearnerCardsView = React.lazy(() => import('./LearnerCardsView'))
const LearnerDashboardPage = React.lazy(
  () => import('../../legacy/components/pages/LearnerDashboardPage')
)

type Props = Readonly<{
  view: LearnerView
  onHowToAddLearners: () => void
  onViewToggle: (view: LearnerView) => void
}>

function LearnersPage({view, onHowToAddLearners, onViewToggle}: Props) {
  const mentor = useSelector<AppState, Mentor>(state => getMentor(state))

  return (
    <Layout
      control={<LearnerViewToggle view={view} onToggle={onViewToggle} />}
      hasFooter={view === CARDS}
      title={<Title onHowToAddLearners={onHowToAddLearners} />}
      onHowToAddLearners={onHowToAddLearners}
    >
      <MentorCodeBanner mentorCode={mentor.code} />
      <Suspense fallback={<LoadingIndicator />}>
        {view === CARDS && <LearnerCardsView />}
        {view === TABLE && <LearnerDashboardPage />}
      </Suspense>
    </Layout>
  )
}

function Title({
  onHowToAddLearners
}: Readonly<{onHowToAddLearners: () => void}>) {
  return (
    <div className="flex flex-col items-center lg:absolute lg:left-1/2 lg:-translate-x-1/2">
      <TitleText>
        <FormattedMessage id="learners.title" />
      </TitleText>
      <div className="hidden lg:flex">
        <FlatButton variant="transparent" onClick={() => onHowToAddLearners()}>
          <FormattedMessage id="learners.tutorial" />
        </FlatButton>
      </div>
    </div>
  )
}

function LearnersPageWithTour() {
  const [view, setView] = useState<LearnerView>(LearnersViewStore.getView)
  const mentor = useSelector(getMentor)
  const isLargeScreen = useMedia(breakpoints.lg)
  const [, setToursRestartedAt] = useState<Date | null>(null)
  const intl = useIntl()
  if (
    view !== 'CARDS' ||
    tourCompletionStore.isTourComplete(ADD_LEARNER_CARD, mentor.id)
  ) {
    return (
      <LearnersPage
        view={view}
        onViewToggle={setView}
        onHowToAddLearners={() => {
          if (view === 'CARDS') {
            tourCompletionStore.markAddLearnerToursIncomplete(mentor.id)
          }
          if (view === 'TABLE') {
            tourCompletionStore.markTableViewToursIncomplete(mentor.id)
          }
          setToursRestartedAt(new Date())
        }}
      />
    )
  }
  const steps: Array<ShepherdOptionsWithType> = [
    {
      classes: 'tooltip',
      id: 'toggle',
      attachTo: {
        element: '#learner-view-toggle',
        on: 'auto'
      },
      text: intl.formatMessage({id: 'learners.tours.addLearner.toggle'}),
      showOn() {
        // The toggle is only shown on large screens.
        return isLargeScreen
      },
      buttons: [
        {
          classes: 'tour-button',
          text: intl.formatMessage({id: 'learners.tours.skip'}),
          action() {
            this.next()
          }
        }
      ]
    },
    {
      classes: 'tooltip',
      id: 'names',
      attachTo: {
        element: '#add-learner-names',
        on: 'auto'
      },
      text: intl.formatMessage({id: 'learners.tours.addLearner.names'}),
      buttons: [
        {
          classes: 'tour-button',
          text: intl.formatMessage({id: 'learners.tours.next'}),
          action() {
            this.next()
          }
        }
      ]
    },
    {
      classes: 'tooltip space-between',
      id: 'guardian',
      attachTo: {
        element: '#add-learner-guardian',
        on: 'auto'
      },
      text: intl.formatMessage({
        id: 'learners.tours.addLearner.guardian'
      }),
      buttons: [
        {
          classes: 'tour-button',
          text: intl.formatMessage({id: 'learners.tours.back'}),
          action() {
            this.back()
          }
        },
        {
          classes: 'tour-button',
          text: intl.formatMessage({id: 'learners.tours.next'}),
          action() {
            this.next()
          }
        }
      ]
    },
    {
      classes: 'tooltip space-between',
      id: 'save',
      attachTo: {
        element: '#add-learner-button',
        on: 'auto'
      },
      text: intl.formatMessage({
        id: 'learners.tours.addLearner.save'
      }),
      buttons: [
        {
          classes: 'tour-button',
          text: intl.formatMessage({id: 'learners.tours.back'}),
          action() {
            this.back()
          }
        },
        {
          classes: 'tour-button',
          text: intl.formatMessage({id: 'learners.tours.next'}),
          action() {
            this.hide()
          }
        }
      ]
    }
  ]

  function markAddLearnerTourComplete() {
    tourCompletionStore.markTourComplete(ADD_LEARNER_CARD, mentor.id)
  }

  return (
    <ShepherdTour
      steps={steps}
      tourOptions={{
        useModalOverlay: true,
        defaultStepOptions: {
          cancelIcon: {
            enabled: true
          },
          modalOverlayOpeningPadding: 12,
          modalOverlayOpeningRadius: 4,
          popperOptions: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 24]
                }
              }
            ]
          }
        }
      }}
    >
      <LearnersPage
        view={view}
        onViewToggle={setView}
        onHowToAddLearners={() => {
          tourCompletionStore.markAddLearnerToursIncomplete(mentor.id)
          setToursRestartedAt(new Date())
        }}
      />
      <TourEventHandler
        onCancel={markAddLearnerTourComplete}
        onComplete={markAddLearnerTourComplete}
      />
    </ShepherdTour>
  )
}

export default LearnersPageWithTour
