import React from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {
  getAllZips,
  getZipsByCommunityId
} from '../../store/communities/communities.selectors'
import {AppState} from '../../store/store'
import {SelectWithFreeTextOption} from '../components/pages/registration/form-data.type'
import {Props as SelectProps} from '../components/Select'
import SelectWithTextOther from '../components/Select/SelectWithTextOther'

type Props = SelectProps<SelectWithFreeTextOption> & {
  selectedCommunityId: string | null
}

function ZipCodeSelect({selectedCommunityId, onChange, ...props}: Props) {
  const intl = useIntl()
  const zipCodes = useSelector<AppState, Array<string>>(state =>
    selectedCommunityId
      ? getZipsByCommunityId(state, selectedCommunityId)
      : getAllZips(state)
  )
  const isLoading = useSelector<AppState, boolean>(
    state => state.communities.fetching
  )
  return (
    <SelectWithTextOther<SelectWithFreeTextOption>
      isClearable
      name="zipcode"
      placeholder={intl.formatMessage({
        id: 'registration.MentorDetails_zipCodePH'
      })}
      options={zipCodes.map(zip => ({
        label: zip,
        value: zip,
        freeTextUserInput: false
      }))}
      createOption={(
        label: string,
        value: string,
        isUserFreeText: boolean
      ): SelectWithFreeTextOption => ({
        label,
        value,
        freeTextUserInput: isUserFreeText
      })}
      isLoading={isLoading}
      changeHandler={(value, action) => {
        onChange && onChange(value, action)
      }}
      {...props}
    />
  )
}

export default ZipCodeSelect
