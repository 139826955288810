import React from 'react'
import {FormattedMessage} from 'react-intl'
import {CommunityId} from '../../domain/community'
import {
  COMMUNITY_ID_ROUTE_PLACEHOLDER,
  MENTOR_REGISTRATION_LINK
} from '../../global-constants/routingConstants'
import {InternalLinkStyledAsFlatButton} from './InternalLink'

type Props = {
  communityId: CommunityId
}

function MentorRegistrationLink({communityId}: Props) {
  const mentorRegistrationLink = MENTOR_REGISTRATION_LINK.replace(
    COMMUNITY_ID_ROUTE_PLACEHOLDER,
    communityId
  )
  return (
    <InternalLinkStyledAsFlatButton
      variant="success"
      to={mentorRegistrationLink}
    >
      <FormattedMessage id="manageCommunity.mentor_registration_link" />
    </InternalLinkStyledAsFlatButton>
  )
}

export default MentorRegistrationLink
