import {isNonBlankString, isPlainObject} from '../responseRefiners'

type ZipPill = Readonly<{code: string; deletable: boolean}>

/**
 * Confirms if the candidate is a valid {@link Zip}.
 *
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is a valid {@link Zip}.
 */
function isValid(candidate: unknown): boolean {
  return isPlainObject(candidate)
}

/**
 * Parse a {@link Zip} from a candidate of unknown type.
 *
 * @param candidate The candidate to parse.
 * @return A valid typed {@link Zip}.
 */
function parse(candidate: unknown): ZipPill {
  if (!isPlainObject(candidate)) {
    throw new Error(`Unexpected ZipPill ${candidate}, expected an object`)
  }
  if (!isNonBlankString(candidate.zip)) {
    throw new Error(
      `Unexpected ZipPill.code ${candidate.zip}, expected a non-blank string`
    )
  }
  if (typeof candidate.deletable !== 'boolean') {
    throw new Error(
      `Unexpected ZipPill.deletable ${candidate.deletable}, expected a boolean`
    )
  }

  return {
    code: candidate.zip,
    deletable: candidate.deletable
  }
}

const ZipPill = {isValid, parse}
export default ZipPill
