import clsx from 'clsx'
import React, {ComponentProps} from 'react'
import {isNonBlankString} from '../../responseRefiners'
import ValidationErrorMessage from '../ValidationErrorMessage'

/**
 * A TextInput component for use in the admin interface consisting of a text input and label in a row
 * followed by an optional error label below.
 *
 * @param errorMessage The error message to display below the input.
 * @param id The id attribute of the input.
 * @param label The value of the input label.
 * @param inputProps Any additional standard input attributes.
 */
function TextInput({
  errorMessage = null,
  id,
  label,
  ...inputProps
}: Readonly<{
  errorMessage?: string | null
  id: string
  label: string
}> &
  Omit<ComponentProps<'input'>, 'className'>) {
  const errorElementId = `${id}-error`
  const labelElementId = `${id}-label`
  const isError = isNonBlankString(errorMessage)

  return (
    <div className="flex flex-col items-end p-2 text-navy">
      <div className="flex w-full items-center justify-between">
        <label
          className="typography-headline-5 after:content-[':']"
          id={labelElementId}
          htmlFor={id}
        >
          {label}
        </label>
        <input
          {...inputProps}
          aria-describedby={errorElementId}
          aria-errormessage={errorElementId}
          aria-invalid={isError}
          aria-labelledby={labelElementId}
          className={clsx(
            'w-full max-w-[640px] rounded-md border-0 p-2 shadow-sm outline-none ring-2 ring-inset',
            'focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-green',
            isError && 'ring-red',
            !isError && 'ring-steel-tertiary-20'
          )}
          id={id}
        />
      </div>
      <ValidationErrorMessage id={errorElementId}>
        {errorMessage}
      </ValidationErrorMessage>
    </div>
  )
}

export default TextInput
