import {produce} from 'immer'
import {Reducer} from 'redux'
import {AppActions} from '../store'
import {
  DeleteSponsorImageAction,
  DELETE_SPONSOR_IMAGE,
  ReceiveSponsorImagesAction,
  RECEIVE_SPONSOR_IMAGES,
  UploadSponsorImageAction,
  UPLOAD_SPONSOR_IMAGE
} from './sponsorImages.actions'
import {SponsorImage, SponsorImagesState} from './sponsorImages.types'

const initialState: SponsorImagesState = {
  byIds: {}
}

const reducer: Reducer<SponsorImagesState, AppActions> = (
  state = initialState,
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case DELETE_SPONSOR_IMAGE:
        handleDeleteSponsorImage(draft, action)
        break
      case RECEIVE_SPONSOR_IMAGES:
        handleReceiveSponsorImages(draft, action)
        break
      case UPLOAD_SPONSOR_IMAGE:
        handleUploadSponsorImage(draft, action)
        break
    }
  })

function handleDeleteSponsorImage(
  state: SponsorImagesState,
  action: DeleteSponsorImageAction
) {
  if (action.error) {
    // Don't update state.
    return
  }
  const {key, communityId, eventId} = action.meta
  if (
    state.byIds[communityId] == null ||
    state.byIds[communityId][eventId] == null
  ) {
    // Nothing to do.
    return
  }
  delete state.byIds[communityId][eventId][key]
}

function handleReceiveSponsorImages(
  state: SponsorImagesState,
  action: ReceiveSponsorImagesAction
) {
  if (action.error) {
    // Don't update state.
    return
  }
  for (const sponsorImage of action.payload) {
    addSponsorImageToState(state, sponsorImage, action.meta)
  }
}

function handleUploadSponsorImage(
  state: SponsorImagesState,
  action: UploadSponsorImageAction
) {
  if (action.error) {
    // Don't update state.
    return
  }
  addSponsorImageToState(state, action.payload, action.meta)
}

function addSponsorImageToState(
  state: SponsorImagesState,
  sponsorImage: SponsorImage,
  {communityId, eventId}: {communityId: string; eventId: string}
) {
  if (state.byIds[communityId] == null) {
    state.byIds[communityId] = {}
  }
  if (state.byIds[communityId][eventId] == null) {
    state.byIds[communityId][eventId] = {}
  }
  state.byIds[communityId][eventId][sponsorImage.key] = sponsorImage
}

export default reducer
