export const CHECK_MAIL_ROUTE = '/check-mail'
export const COMMUNITIES_ROUTE = '/communities'
export const REGISTER_ROUTE = '/register'
export const SELECTED_COMMUNITY_ID_SEARCH_PARAM = 'selectedCommunityId'
export const MESSAGE_SEARCH_PARAM = 'message'
export const SELECTED_COMMUNITY_ID_PARAM = 'scid'
export const COMMUNITY_ID_ROUTE_PLACEHOLDER = ':communityId'
export const MENTOR_REGISTRATION_LINK = `${REGISTER_ROUTE}?${SELECTED_COMMUNITY_ID_PARAM}=${COMMUNITY_ID_ROUTE_PLACEHOLDER}`
export const COMMUNITIES_SELECTED_COMMUNITY_ID_TEMPLATE_ROUTE = `${COMMUNITIES_ROUTE}?${SELECTED_COMMUNITY_ID_SEARCH_PARAM}=${COMMUNITY_ID_ROUTE_PLACEHOLDER}&${MESSAGE_SEARCH_PARAM}=created`
export const COMMUNITIES_UPDATED_COMMUNITY_ID_TEMPLATE_ROUTE = `${COMMUNITIES_ROUTE}?${SELECTED_COMMUNITY_ID_SEARCH_PARAM}=${COMMUNITY_ID_ROUTE_PLACEHOLDER}&${MESSAGE_SEARCH_PARAM}=updated`
export const COMMUNITY_NOT_HERE_ROUTE = '/community-not-here'
export const EMAIL_SENT_ROUTE = '/email-sent'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const LEGAL_TERMS_ROUTE = '/legal-terms'
export const LEARNERS_ROUTE = '/learners'
export const LOGIN_ROUTE = '/login'
export const COMMUNITIES_PREFIX_ROUTE = '/communities'
export const ADMIN_PREFIX_ROUTE = '/admin'
export const EVENTS_PREFIX_ROUTE = '/events'
export const EVENT_ID_ROUTE_PLACEHOLDER = `:eventId`
export const COMMUNITY_TOOL_SELECTOR_ROUTE = `${COMMUNITIES_PREFIX_ROUTE}/${COMMUNITY_ID_ROUTE_PLACEHOLDER}${EVENTS_PREFIX_ROUTE}/${EVENT_ID_ROUTE_PLACEHOLDER}`
export const SPONSORS_PREFIX_ROUTE = '/sponsors'
export const SPONSOR_IMAGES_ROUTE = `${COMMUNITIES_PREFIX_ROUTE}/${COMMUNITY_ID_ROUTE_PLACEHOLDER}${EVENTS_PREFIX_ROUTE}/${EVENT_ID_ROUTE_PLACEHOLDER}${SPONSORS_PREFIX_ROUTE}`
export const REGISTERED_ROUTE = '/registered'
export const REGISTER_LEARNER_ROUTE = '/register-learner'
export const RESET_PASSWORD_ROUTE = '/reset-password'
export const TOOL_SELECTOR_ROUTE = '/tool-selector'
export const ROOT_ROUTE = '/'
export const TERMS_ROUTE = '/terms-and-conditions'
export const EDIT_COMMUNITY_ROUTE = `${COMMUNITIES_PREFIX_ROUTE}/${COMMUNITY_ID_ROUTE_PLACEHOLDER}/edit`
export const ADD_COMMUNITY_ROUTE = `${COMMUNITIES_PREFIX_ROUTE}/add`
export const COMMUNITY_EVENTS_ROUTE = `${COMMUNITIES_PREFIX_ROUTE}/${COMMUNITY_ID_ROUTE_PLACEHOLDER}${EVENTS_PREFIX_ROUTE}`
export const LEARNER_ID_ROUTE_PLACEHOLDER = ':learnerId'
export const MENTOR_ID_ROUTE_PLACEHOLDER = ':mentorId'
export const USER_SEARCH_ROUTE = `${ADMIN_PREFIX_ROUTE}/users`
export const LEARNER_PROFILE_ROUTE = `${ADMIN_PREFIX_ROUTE}/learners/${LEARNER_ID_ROUTE_PLACEHOLDER}`
export const MENTOR_PROFILE_ROUTE = `${ADMIN_PREFIX_ROUTE}/mentors/${MENTOR_ID_ROUTE_PLACEHOLDER}`
export const EMAIL_UNSUBSCRIBED_ROUTE = '/email-unsubscribed'
export const EMAIL_UNSUBSCRIBE_FAILED_ROUTE = '/email-unsubscribe-failed'
export const ACCOUNT_DELETION_ROUTE = '/account/delete'
export const EDUCATIONAL_RESOURCES_ROUTE = '/educational-resources'
export const SUPPLIES_SHOP_ROUTE = '/shop'
