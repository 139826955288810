import produce from 'immer'
import {Reducer} from 'redux'
import {AppActions} from '../store'
import {
  ATTEMPT_FORGOT_PASSWORD,
  RECEIVE_FORGOT_PASSWORD_RESPONSE
} from './forgot-password.action'
import {PasswordState} from './forgot-password.type'

const initialState: PasswordState = {
  error: false,
  inFlight: false
}

const reducer: Reducer<PasswordState, AppActions> = (
  state = initialState,
  action: AppActions
) => {
  return produce(state, draft => {
    switch (action.type) {
      case ATTEMPT_FORGOT_PASSWORD:
        draft.error = false
        draft.inFlight = true
        break

      case RECEIVE_FORGOT_PASSWORD_RESPONSE:
        if (action.error) {
          draft.error = true
          draft.inFlight = false
          break
        }
        draft.inFlight = false
        break
    }
  })
}

export default reducer
