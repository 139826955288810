import React from 'react'
import CommunityStatus from '../../domain/communityStatus'
import {ACTIVE, INACTIVE} from '../../domain/communityStatuses'
import ToggleInput from '../ToggleInput'

type Props = Readonly<{
  status: CommunityStatus
  onChange: (status: CommunityStatus) => void
}>

function CommunityStatusToggle({status, onChange}: Props) {
  return (
    <ToggleInput
      checked={status === ACTIVE}
      description="admin.communities.joinActiveCommunitiesDescription"
      label="general.active"
      onChange={checked => onChange(checked ? ACTIVE : INACTIVE)}
    />
  )
}

export default CommunityStatusToggle
