import {RadioGroup} from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'
import {FormattedMessage} from 'react-intl'

type Props<TValue> = {
  name: string
  label: string
  value: TValue
  onChange: (value: TValue) => void
  options: {value: TValue; label: string}[]
}

function RadioButtonGroupInput<TValue>({
  name,
  label,
  value,
  options,
  onChange
}: Props<TValue>) {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      name={name}
      id={name}
      className="flex items-center justify-between py-2 px-2"
    >
      <RadioGroup.Label className="typography-headline-5">
        <FormattedMessage id={label} />
      </RadioGroup.Label>
      <div className="flex rounded-md">
        {options.map((option, index) => (
          <RadioGroup.Option
            key={index}
            value={option.value}
            className="focus-visible:z-10 focus-visible:overflow-hidden focus-visible:rounded-md focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-navy"
            as="button"
            type="button"
          >
            {({checked}) => (
              <span
                className={clsx(
                  'typography-headline-5 flex items-center justify-center gap-2 border px-6 py-2 focus-visible:rounded-md disabled:bg-steel-tertiary-10 disabled:text-white',
                  checked
                    ? 'font-medium border-teal bg-teal text-navy'
                    : 'border-grey-tertiary-10',
                  index === 0 && 'rounded-l-md',
                  index === options.length - 1 && 'rounded-r-md'
                )}
              >
                {option.label}
              </span>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default RadioButtonGroupInput
