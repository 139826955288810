import React from 'react'

function DateTimeDisplay({displayValue}: {displayValue: string}) {
  return (
    <div className="min-w-fit rounded bg-steel-tertiary-10 px-2 py-3">
      {displayValue}
    </div>
  )
}

export default DateTimeDisplay
