// Created from the time zone database version 2023c
import TimeZone from './timeZone'
import TzIdentifier from './tzIdentifier'

const CA_TIME_ZONES = [
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Cambridge_Bay',
    description: 'Mountain - NU (west)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MDT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Dawson',
    description: 'MST - Yukon (west)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-07:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MST'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Dawson_Creek',
    description: 'MST - BC (Dawson Cr, Ft St John)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-07:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MST'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Edmonton',
    description: 'Mountain - AB; BC (E); NT (E); SK (W)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MDT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Fort_Nelson',
    description: 'MST - BC (Ft Nelson)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-07:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MST'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Glace_Bay',
    description: 'Atlantic - NS (Cape Breton)',
    type: 'Canonical',
    utcOffsetStd: '-04:00',
    utcOffsetDst: '-03:00',
    abbreviationStd: 'AST',
    abbreviationDst: 'ADT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Goose_Bay',
    description: 'Atlantic - Labrador (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-04:00',
    utcOffsetDst: '-03:00',
    abbreviationStd: 'AST',
    abbreviationDst: 'ADT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Halifax',
    description: 'Atlantic - NS (most areas); PE',
    type: 'Canonical',
    utcOffsetStd: '-04:00',
    utcOffsetDst: '-03:00',
    abbreviationStd: 'AST',
    abbreviationDst: 'ADT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Inuvik',
    description: 'Mountain - NT (west)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MDT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Iqaluit',
    description: 'Eastern - NU (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Moncton',
    description: 'Atlantic - New Brunswick',
    type: 'Canonical',
    utcOffsetStd: '-04:00',
    utcOffsetDst: '-03:00',
    abbreviationStd: 'AST',
    abbreviationDst: 'ADT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Rankin_Inlet',
    description: 'Central - NU (central)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Regina',
    description: 'CST - SK (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CST'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Resolute',
    description: 'Central - NU (Resolute)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/St_Johns',
    description: 'Newfoundland; Labrador (southeast)',
    type: 'Canonical',
    utcOffsetStd: '-03:30',
    utcOffsetDst: '-02:30',
    abbreviationStd: 'NST',
    abbreviationDst: 'NDT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Swift_Current',
    description: 'CST - SK (midwest)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CST'
  },
  {
    countryCode: 'CA, BS',
    tzIdentifier: 'America/Toronto',
    description: 'Eastern - ON, QC (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Vancouver',
    description: 'Pacific - BC (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-08:00',
    utcOffsetDst: '-07:00',
    abbreviationStd: 'PST',
    abbreviationDst: 'PDT'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Whitehorse',
    description: 'MST - Yukon (east)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-07:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MST'
  },
  {
    countryCode: 'CA',
    tzIdentifier: 'America/Winnipeg',
    description: 'Central - ON (west); Manitoba',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  }
] as const

const MX_TIME_ZONES = [
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Bahia_Banderas',
    description: 'Bahía de Banderas',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CST'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Cancun',
    description: 'Quintana Roo',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EST'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Chihuahua',
    description: 'Chihuahua (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CST'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Ciudad_Juarez',
    description: 'Chihuahua (US border - west)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MDT'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Hermosillo',
    description: 'Sonora',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-07:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MST'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Matamoros',
    description: 'Coahuila, Nuevo León, Tamaulipas (US border)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Mazatlan',
    description: 'Baja California Sur, Nayarit (most areas), Sinaloa',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-07:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MST'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Merida',
    description: 'Campeche, Yucatán',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CST'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Mexico_City',
    description: 'Central Mexico',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CST'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Monterrey',
    description: 'Durango; Coahuila, Nuevo León, Tamaulipas (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CST'
  },
  {
    countryCode: 'MX',
    tzIdentifier: 'America/Ojinaga',
    description: 'Chihuahua (US border - east)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  }
] as const

const US_TIME_ZONES = [
  {
    countryCode: 'US',
    tzIdentifier: 'America/Adak',
    description: 'Alaska - western Aleutians',
    type: 'Canonical',
    utcOffsetStd: '-10:00',
    utcOffsetDst: '-09:00',
    abbreviationStd: 'HST',
    abbreviationDst: 'HDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Anchorage',
    description: 'Alaska (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-09:00',
    utcOffsetDst: '-08:00',
    abbreviationStd: 'AKST',
    abbreviationDst: 'AKDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Boise',
    description: 'Mountain - ID (south); OR (east)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Chicago',
    description: 'Central (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Denver',
    description: 'Mountain (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-06:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Detroit',
    description: 'Eastern - MI (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Indiana/Indianapolis',
    description: 'Eastern - IN (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Indiana/Knox',
    description: 'Central - IN (Starke)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Indiana/Marengo',
    description: 'Eastern - IN (Crawford)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Indiana/Petersburg',
    description: 'Eastern - IN (Pike)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Indiana/Tell_City',
    description: 'Central - IN (Perry)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Indiana/Vevay',
    description: 'Eastern - IN (Switzerland)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Indiana/Vincennes',
    description: 'Eastern - IN (Da, Du, K, Mn)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Indiana/Winamac',
    description: 'Eastern - IN (Pulaski)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Juneau',
    description: 'Alaska - Juneau area',
    type: 'Canonical',
    utcOffsetStd: '-09:00',
    utcOffsetDst: '-08:00',
    abbreviationStd: 'AKST',
    abbreviationDst: 'AKDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Kentucky/Louisville',
    description: 'Eastern - KY (Louisville area)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Kentucky/Monticello',
    description: 'Eastern - KY (Wayne)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Los_Angeles',
    description: 'Pacific',
    type: 'Canonical',
    utcOffsetStd: '-08:00',
    utcOffsetDst: '-07:00',
    abbreviationStd: 'PST',
    abbreviationDst: 'PDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Menominee',
    description: 'Central - MI (Wisconsin border)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Metlakatla',
    description: 'Alaska - Annette Island',
    type: 'Canonical',
    utcOffsetStd: '-09:00',
    utcOffsetDst: '-08:00',
    abbreviationStd: 'AKST',
    abbreviationDst: 'AKDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/New_York',
    description: 'Eastern (most areas)',
    type: 'Canonical',
    utcOffsetStd: '-05:00',
    utcOffsetDst: '-04:00',
    abbreviationStd: 'EST',
    abbreviationDst: 'EDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Nome',
    description: 'Alaska (west)',
    type: 'Canonical',
    utcOffsetStd: '-09:00',
    utcOffsetDst: '-08:00',
    abbreviationStd: 'AKST',
    abbreviationDst: 'AKDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/North_Dakota/Beulah',
    description: 'Central - ND (Mercer)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/North_Dakota/Center',
    description: 'Central - ND (Oliver)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/North_Dakota/New_Salem',
    description: 'Central - ND (Morton rural)',
    type: 'Canonical',
    utcOffsetStd: '-06:00',
    utcOffsetDst: '-05:00',
    abbreviationStd: 'CST',
    abbreviationDst: 'CDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Phoenix',
    description: 'MST - AZ (most areas), Creston BC',
    type: 'Canonical',
    utcOffsetStd: '-07:00',
    utcOffsetDst: '-07:00',
    abbreviationStd: 'MST',
    abbreviationDst: 'MST'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Sitka',
    description: 'Alaska - Sitka area',
    type: 'Canonical',
    utcOffsetStd: '-09:00',
    utcOffsetDst: '-08:00',
    abbreviationStd: 'AKST',
    abbreviationDst: 'AKDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'America/Yakutat',
    description: 'Alaska - Yakutat',
    type: 'Canonical',
    utcOffsetStd: '-09:00',
    utcOffsetDst: '-08:00',
    abbreviationStd: 'AKST',
    abbreviationDst: 'AKDT'
  },
  {
    countryCode: 'US',
    tzIdentifier: 'Pacific/Honolulu',
    description: 'Hawaii',
    type: 'Canonical',
    utcOffsetStd: '-10:00',
    utcOffsetDst: '-10:00',
    abbreviationStd: 'HST',
    abbreviationDst: 'HST'
  }
] as const

export const TIME_ZONES_BY_COUNTRY_CODE = {
  CA: CA_TIME_ZONES,
  MX: MX_TIME_ZONES,
  US: US_TIME_ZONES
} as const

export const TIME_ZONES = Object.values(TIME_ZONES_BY_COUNTRY_CODE).flat()

export const CA_TZ_IDENTIFIERS: ReadonlyArray<TzIdentifier> = CA_TIME_ZONES.map(
  (timeZone: TimeZone) => timeZone.tzIdentifier
)
export const MX_TZ_IDENTIFIERS: ReadonlyArray<TzIdentifier> = MX_TIME_ZONES.map(
  (timeZone: TimeZone) => timeZone.tzIdentifier
)
export const US_TZ_IDENTIFIERS: ReadonlyArray<TzIdentifier> = US_TIME_ZONES.map(
  (timeZone: TimeZone) => timeZone.tzIdentifier
)
export const TZ_IDENTIFIERS: ReadonlyArray<TzIdentifier> =
  CA_TZ_IDENTIFIERS.concat(MX_TZ_IDENTIFIERS).concat(US_TZ_IDENTIFIERS)
