import {isNonBlankString, isPlainObject} from '../responseRefiners'
import AccountStatus, {AccountStatus as AccountStatusT} from './accountStatus'
import AvatarKey, {AvatarKey as AvatarKeyT} from './avatarKey'
import {CommunityId} from './community'
import Consents, {Consents as ConsentsT} from './consents'
import Ethnicity, {Ethnicity as EthnicityT} from './ethnicity'
import Gender, {Gender as GenderT} from './gender'
import Grade, {Grade as GradeT} from './grade'
import Guardian, {Guardian as GuardianT} from './guardian'
import Id from './id'
import LanguageTag from './languageTag'
import Name from './name'
import PercentageFloatingPoint, {
  PercentageFloatingPoint as PercentageFloatingPointT
} from './percentageFloatingPoint'
import PositiveInteger, {PositiveInt as PositiveIntegerT} from './positiveInt'
import Program, {Program as ProgramT} from './program'
import Username, {Username as UsernameT} from './username'

export type LearnerId = Id<'Learner'>
/**
 * The BaseLearner type contains the properties we will always receive for a learner, and is extended by other types
 * */
export type BaseLearner = Readonly<{
  id: LearnerId
  communityName: Name
  currentEventId: Id<'Event'> | null
  accountStatus: AccountStatusT
  avatarKey: AvatarKeyT
  firstName: Name
  lastName: Name
  username: UsernameT | null
  age: PositiveIntegerT | null
  gender: GenderT | null
  otherGender: string | null
  grade: GradeT | null
  otherGrade: string | null
  ethnicity: EthnicityT | null
  otherEthnicity: string | null
}>

/**
 * The ListedLearner type is returned by /learners
 * */
export type ListedLearner<DateType extends Date | string = Date> = BaseLearner &
  Readonly<{
    consents: ConsentsT<DateType>
    legalGuardian: GuardianT<DateType>
    program: ProgramT<DateType>
  }>

/**
 * The IndividualLearner type is returned by /learner/${learnerId}
 * */
export type IndividualLearner<DateType extends Date | string = Date> =
  BaseLearner &
    Readonly<{
      mostRecentlyStartedLesson: PositiveIntegerT | null
      mostRecentlyStartedModule: PositiveIntegerT | null
      locale: LanguageTag
      communityId: CommunityId
      programProgress: PercentageFloatingPointT
      lastSyncDate: DateType | null
    }>

function parseBaseLearner(candidate: unknown): BaseLearner {
  if (!isPlainObject(candidate)) {
    throw new Error(
      `Unexpected Learner ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }
  return {
    id: Id.parse(candidate.id),
    currentEventId:
      candidate.currentEventId === null
        ? null
        : Id.parse(candidate.currentEventId),
    accountStatus: AccountStatus.parse(candidate.accountStatus),
    avatarKey: AvatarKey.parse(candidate.avatarKey),
    communityName: Name.parse(candidate.communityName),
    firstName: Name.parse(candidate.firstName),
    lastName: Name.parse(candidate.lastName),
    username:
      candidate.username === null ? null : Username.parse(candidate.username),
    age: candidate.age === null ? null : PositiveInteger.parse(candidate.age),
    gender: candidate.gender === null ? null : Gender.parse(candidate.gender),
    otherGender:
      candidate.otherGender === null
        ? null
        : Gender.parseOtherGender(candidate.otherGender),
    grade: candidate.grade === null ? null : Grade.parse(candidate.grade),
    otherGrade:
      candidate.otherGrade === null
        ? null
        : Grade.parseOtherGrade(candidate.otherGrade),
    ethnicity:
      candidate.ethnicity === null
        ? null
        : Ethnicity.parse(candidate.ethnicity),
    otherEthnicity:
      candidate.otherEthnicity === null
        ? null
        : Ethnicity.parseOtherEthnicity(candidate.otherEthnicity)
  }
}

function parseListedLearner(candidate: unknown): ListedLearner {
  if (!isPlainObject(candidate)) {
    throw new Error(
      `Unexpected Learner ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }

  return {
    ...parseBaseLearner(candidate),
    consents: Consents.parse(candidate.consents),
    legalGuardian: Guardian.parse(candidate.legalGuardian),
    program: Program.parse(candidate.program)
  }
}

function parseIndividualLearner(candidate: unknown): IndividualLearner {
  if (
    !isPlainObject(candidate) ||
    (candidate.lastSyncDate !== null &&
      !isNonBlankString(candidate.lastSyncDate))
  ) {
    throw new Error(
      `Unexpected Learner ${
        candidate == null ? candidate : JSON.stringify(candidate)
      }`
    )
  }

  return {
    ...parseBaseLearner(candidate),
    mostRecentlyStartedLesson:
      candidate.mostRecentlyStartedLesson === null
        ? null
        : PositiveInteger.parse(candidate.mostRecentlyStartedLesson),
    mostRecentlyStartedModule:
      candidate.mostRecentlyStartedModule === null
        ? null
        : PositiveInteger.parse(candidate.mostRecentlyStartedModule),
    locale: LanguageTag.parse(candidate.locale),
    communityId: Id.parse(candidate.communityId),
    communityName: Name.parse(candidate.communityName),
    programProgress: PercentageFloatingPoint.parse(candidate.programProgress),
    lastSyncDate:
      candidate.lastSyncDate === null ? null : new Date(candidate.lastSyncDate)
  }
}

function formatName(learner: ListedLearner | IndividualLearner): string {
  return `${learner.firstName} ${learner.lastName}`
}

function isUnderGuardianshipOf(
  learner: ListedLearner,
  mentor: {id: string}
): boolean {
  return (
    learner.legalGuardian.mentorId !== null &&
    learner.legalGuardian.mentorId === mentor.id
  )
}

const Learner = {
  formatName,
  isUnderGuardianshipOf,
  parseBaseLearner,
  parseListedLearner,
  parseIndividualLearner
}
export default Learner
