import React, {useState} from 'react'
import styled from 'styled-components'
import TextField from '../TextField'
import {ReactComponent as HideSvg} from './assets/mentor_login_reg_hide_icon_optimized.svg'
import {ReactComponent as ShowSvg} from './assets/mentor_login_reg_view_icon_optimized.svg'

type Props = Omit<React.ComponentPropsWithoutRef<typeof TextField>, 'type'>

type CharacterType = 'PLAIN' | 'MASKED'

const PASSWORD_TOGGLE_ICON_SIZE = 24

const StyledTextFieldContainer = styled.div`
  display: inline-block;
  width: 100%;
`

const StyledFieldWrapper = styled.div`
  position: relative;
`

const StyledToggleIndicator = styled.span`
  position: relative;
  float: right;
  margin-top: -30px;
  margin-right: 14px;
  height: ${PASSWORD_TOGGLE_ICON_SIZE}px;
`

function Password(props: Props) {
  const [characterType, setCharacterType] = useState<CharacterType>('MASKED')

  const isPlain = characterType === 'PLAIN'

  return (
    <StyledTextFieldContainer>
      <StyledFieldWrapper>
        <TextField {...props} type={isPlain ? 'text' : 'password'} />
        <StyledToggleIndicator
          onClick={() => setCharacterType(isPlain ? 'MASKED' : 'PLAIN')}
        >
          {isPlain ? (
            <HideSvg
              height={PASSWORD_TOGGLE_ICON_SIZE}
              width={PASSWORD_TOGGLE_ICON_SIZE}
            />
          ) : (
            <ShowSvg
              height={PASSWORD_TOGGLE_ICON_SIZE}
              width={PASSWORD_TOGGLE_ICON_SIZE}
            />
          )}
        </StyledToggleIndicator>
      </StyledFieldWrapper>
    </StyledTextFieldContainer>
  )
}

export default Password
