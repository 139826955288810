import React from 'react'
import styled from 'styled-components'
import colours from '../../colours'

interface TextFieldProps {
  colour: 'yellow'
  error?: boolean
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & TextFieldProps

const StyledInput = styled.input<TextFieldProps>`
  font-size: 1rem;
  font-family: 'Roboto Slab', serif, sans-serif;
  border-radius: 18px;
  min-height: 36px;
  background-color: ${props => colours.components.textFields[props.colour]};
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 100%;
  border: ${props =>
    props.error
      ? `2px solid ${colours.colours.errorRed}`
      : '2px solid transparent'};
  ::placeholder {
    color: #ffffff;
    opacity: 0.7;
  }
  :focus {
    border: 2px solid ${colours.colours.darkText};
    outline: none;
  }
  :-webkit-credentials-auto-fill-button {
    margin-right: 25px;
  }
`

const StyledTextFieldContainer = styled.div`
  display: inline-block;
  width: 100%;
`

function TextField(props: Props) {
  return (
    <StyledTextFieldContainer>
      <StyledInput
        // if lastpass installed, ignore adding styles
        data-lpignore="true"
        {...props}
      />
    </StyledTextFieldContainer>
  )
}

export default TextField
