import React, {useEffect, useRef, useState} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {FormattedMessage, IntlShape, useIntl} from 'react-intl'
import styled from 'styled-components'
import {errorFields} from '../../errors/errorFields'
import {
  RECAPTCHA_ERROR_IDENTIFIER,
  RECAPTCHA_REQUEST_TOKEN_ERROR_IDENTIFIER,
  RECAPTCHA_VERIFICATION_ERROR_IDENTIFIER
} from '../../errors/errorIdentifiers'
import ServerError from '../../errors/server'
import {validateEmail} from '../../validations'
import Alert from './Alert'
import Button from './Button'
import ErrorMessage from './ErrorMessage/ErrorMessage'
import FormField from './FormField'
import Label from './Label/Label'
import ReCaptcha from './ReCaptcha'
import TextField from './TextField'
import * as Typography from './Typography'

export interface Payload {
  email: string
  recaptchaUserResponseToken: string
}

interface Props {
  locationState: string
  error: Error | null
  submitting: boolean

  onSubmit(payload: Payload): void

  onClearError(): void
}

interface Errors {
  generic: string | null
  recaptcha: string | null
  email: string | null
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`

const HeadingWrapper = styled.div`
  margin: 3rem 0;
`

const Heading = styled(Typography.BoldBody1)`
  text-align: center;
  margin-bottom: 0;
`
const LeadText = styled(Typography.Body1)`
  margin-top: 0;
  text-align: center;
  margin-bottom: 0;
`
const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const AlertContent = styled(Typography.BoldBody3)`
  text-align: center;
`

function ForgotPasswordForm({
  locationState,
  error,
  submitting,
  onSubmit,
  onClearError
}: Props) {
  const intl = useIntl()

  const [email, setEmail] = useState(locationState)
  const [captchaToken, setCaptchaToken] = useState<string | null>('')
  const [errors, setErrors] = useState<Errors>({
    generic: null,
    recaptcha: '',
    email: null
  })

  const {
    generic: genericError,
    recaptcha: recaptchaError,
    email: emailError
  } = errors

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const alertRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (genericError !== '' && alertRef.current != null) {
      alertRef.current.scrollIntoView()
    }
  }, [alertRef, genericError])

  /* Reset generic error when redoing captcha */
  useEffect(() => {
    if (captchaToken !== '' || captchaToken != null) {
      setErrors(values => ({...values, generic: null}))
    }
  }, [captchaToken])

  useEffect(() => {
    if (error != null) {
      if (ServerError.isServerError(error)) {
        setErrors(values => ({...values, ...mapServerErrors(error, intl)}))
        if (recaptchaRef.current != null) {
          recaptchaRef.current.reset()
        }
      }
    } else {
      setErrors({generic: null, recaptcha: '', email: null})
    }
  }, [error, intl, setErrors])

  function onReCaptchaChange(value: string | null) {
    if (value == null) {
      setCaptchaToken(value)
      return // Show nothing as null is returned when captcha expires
    } else {
      setCaptchaToken(value)
      setErrors(values => ({...values, recaptcha: ''}))
    }
  }

  function onReCaptchaError() {
    setErrors(values => ({
      ...values,
      recaptcha: 'general.recaptchaError'
    }))
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const emailError = validateEmail(email)
    setErrors(values => ({...values, email: emailError}))
    if (captchaToken === '' || captchaToken == null) {
      setErrors(values => ({
        ...values,
        recaptcha: 'general.recaptchaValidationFailed'
      }))
    }
    if (emailError === null && recaptchaError === '' && captchaToken != null) {
      onSubmit({email, recaptchaUserResponseToken: captchaToken})
    } else {
      onClearError()
    }
  }

  return (
    <Form onSubmit={event => handleSubmit(event)} noValidate>
      <HeadingWrapper>
        {genericError && (
          <AlertWrapper>
            <Alert type="error" ref={alertRef}>
              <AlertContent>{genericError}</AlertContent>
            </Alert>
          </AlertWrapper>
        )}
        <Heading as="h1">
          <FormattedMessage id="registration.mentorLogin_resetPassword_Email_H1" />
        </Heading>
        <LeadText as="h2">
          <FormattedMessage id="registration.mentorLogin_resetPassword_Email_H2" />
        </LeadText>
      </HeadingWrapper>
      <FormField>
        <Label htmlFor="email">
          <FormattedMessage id="registration.mentorLogin_email" />
        </Label>
        <TextField
          id="email"
          name="email"
          type="email"
          required
          colour="yellow"
          placeholder={intl.formatMessage({
            id: 'registration.mentorLogin_emailPH'
          })}
          value={email}
          onChange={ev => setEmail(ev.target.value)}
          error={!!errors.email}
          onBlur={() => setErrors(values => ({...values, email: null}))}
        />
        {emailError && (
          <ErrorMessage>
            <FormattedMessage id={emailError} />
          </ErrorMessage>
        )}
      </FormField>
      <ReCaptcha
        ref={recaptchaRef}
        onChange={onReCaptchaChange}
        onErrored={onReCaptchaError}
        value={captchaToken}
        recaptchaError={recaptchaError}
      />
      <Actions>
        <Button type="submit" disabled={submitting} colour="yellow">
          <FormattedMessage id="registration.mentorLogin_forgotPassword_send" />
        </Button>
      </Actions>
    </Form>
  )
}

function mapServerErrors(error: ServerError, intl: IntlShape) {
  const errors: Errors = {
    generic: '',
    recaptcha: '',
    email: ''
  }

  if (error.status !== 400 && error.status !== 409) {
    errors.generic = intl.formatMessage({
      id: 'registration.ForgotPassword_ServerError_generic'
    })
    return errors
  }
  error.identifiers.forEach(identifier => {
    if (
      identifier === RECAPTCHA_ERROR_IDENTIFIER ||
      identifier === RECAPTCHA_VERIFICATION_ERROR_IDENTIFIER ||
      identifier === RECAPTCHA_REQUEST_TOKEN_ERROR_IDENTIFIER
    ) {
      errors.recaptcha = intl.formatMessage({id: errorFields[identifier]})
    }
  })
  return errors
}

export default ForgotPasswordForm
