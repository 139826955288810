import React from 'react'
import {FormattedMessage} from 'react-intl'
import Dialog from '../../Dialog'
import {StyledButton} from '../../StyledButton'

interface Props {
  onAccept: () => void
  onCancel: () => void
  open: boolean
}

const DeleteConfirmationPopup = ({onAccept, onCancel, open}: Props) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <div className="flex flex-col justify-center space-y-8">
        <p className="m-auto">
          <FormattedMessage id="manageCommunity.confirm_delete" />
        </p>
        <div className="flex w-full justify-between">
          <StyledButton variant="danger" onClick={onCancel}>
            <FormattedMessage id="learnerCreator.topbar_button_cancel" />
          </StyledButton>
          <StyledButton variant="primary" onClick={onAccept}>
            <FormattedMessage id="manageCommunity.delete" />
          </StyledButton>
        </div>
      </div>
    </Dialog>
  )
}

export default DeleteConfirmationPopup
