import React, {useCallback, useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useRouteMatch} from 'react-router'
import Community, {CommunityId} from '../../domain/community'
import {EventId} from '../../domain/event'
import {
  COMMUNITIES_ROUTE,
  COMMUNITY_ID_ROUTE_PLACEHOLDER,
  COMMUNITY_TOOL_SELECTOR_ROUTE,
  EVENT_ID_ROUTE_PLACEHOLDER,
  TOOL_SELECTOR_ROUTE
} from '../../global-constants/routingConstants'
import useCommunity from '../../hooks/useCommunity'
import * as sponsorImageActionCreators from '../../store/sponsorImages/sponsorImages.actions'
import * as sponsorImageSelectors from '../../store/sponsorImages/sponsorImages.selectors'
import {SponsorImageKey} from '../../store/sponsorImages/sponsorImages.types'
import {AppDispatch, AppState} from '../../store/store'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import Layout from '../Layout'
import MentorRegistrationLink from '../links/MentorRegistrationLink'
import LoadingIndicator from '../LoadingIndicator'
import TitleText from '../TitleText'
import Toast from '../Toast'
import SponsorshipSlot from './components/SponsorshipSlot'

function SponsorsPage() {
  const {
    params: {communityId, eventId}
  } = useRouteMatch<{communityId: CommunityId; eventId: EventId}>()
  const queryString = window.location.search
  const searchParams = new URLSearchParams(queryString)
  let communityName = searchParams.get('CommunityDisplayName')
  const dispatch = useDispatch<AppDispatch>()
  const sponsorImages = useSelector<
    AppState,
    Record<SponsorImageKey, string | null>
  >(state =>
    communityId == null || eventId == null
      ? sponsorImageSelectors.DEFAULT_SPONSOR_IMAGES
      : sponsorImageSelectors.selectSponsorImagesForCommunityAndEvent(
          state,
          communityId,
          eventId
        )
  )
  const [loading, setLoading] = useState(false)
  const [uploadingImages, setUploadingImages] = useState<
    Record<string, boolean>
  >({})
  const [error, setError] = useState<Error | null>(null)
  const {community} = useCommunity({
    communityId
  })
  if (community) {
    communityName = Community.formatName(community)
  }

  useEffect(() => {
    if (communityId != null && eventId != null) {
      setLoading(true)
      dispatch(
        sponsorImageActionCreators.fetchSponsorImages(communityId, eventId)
      )
        .catch(setError)
        .finally(() => setLoading(false))
    }
  }, [communityId, eventId, dispatch])

  const handleUpload = useCallback(
    (key: SponsorImageKey, image: File) => {
      if (communityId != null && eventId != null) {
        setUploadingImages(uploadingImages => ({
          ...uploadingImages,
          [key]: true
        }))
        dispatch(
          sponsorImageActionCreators.uploadSponsorImage(
            communityId,
            eventId,
            key,
            image
          )
        )
          .catch(setError)
          .finally(() => {
            setUploadingImages(uploadingImages => ({
              ...uploadingImages,
              [key]: false
            }))
          })
      }
    },
    [communityId, eventId, dispatch]
  )

  const handleDelete = useCallback(
    (key: SponsorImageKey) => {
      if (communityId != null && eventId != null) {
        dispatch(
          sponsorImageActionCreators.deleteSponsorImage(
            communityId,
            eventId,
            key
          )
        ).catch(setError)
      }
    },
    [communityId, eventId, dispatch]
  )

  const getUploadStartValues = (uploadKey: string) => {
    if (!uploadKey.includes('MODULE')) {
      const uploadStartKey = uploadKey.replace(
        '_COMPLETION',
        '_START'
      ) as SponsorImageKey
      return {
        key: uploadStartKey,
        uri: sponsorImages[uploadStartKey]
      }
    }
    return {
      key: uploadKey as SponsorImageKey,
      uri: sponsorImages[uploadKey as SponsorImageKey]
    }
  }

  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="admin.sponsors.title" />
        </TitleText>
      }
      toast={<SponsorsToast error={error} />}
    >
      <div
        className="
          mx-auto mb-2 max-w-screen-xl space-y-2
          px-2 md:mb-4 md:space-y-4 md:px-4 lg:mb-6 lg:space-y-6
          lg:px-6 xl:mb-8 xl:space-y-8 xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={COMMUNITIES_ROUTE}
            to={COMMUNITIES_ROUTE}
            label={<FormattedMessage id="manageCommunity.community_manager" />}
          />
          <BreadcrumbItem
            path={COMMUNITY_TOOL_SELECTOR_ROUTE}
            to={COMMUNITY_TOOL_SELECTOR_ROUTE.replace(
              COMMUNITY_ID_ROUTE_PLACEHOLDER,
              communityId
            ).replace(EVENT_ID_ROUTE_PLACEHOLDER, eventId)}
            label={communityName}
          />
        </Breadcrumb>
        <div className="my-2 flex justify-between md:my-4 lg:my-6 xl:my-8">
          <h2 className="typography-headline-4 whitespace-nowrap">
            <FormattedMessage id="admin.sponsors.subTitle" />
          </h2>
          <MentorRegistrationLink communityId={communityId} />
        </div>
        <div className="mt-8 flow-root">
          <div className="overflow-x-auto">
            <div className="inline-block w-full bg-white py-2 align-middle sm:px-6 lg:px-8">
              <table className="w-full">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left sm:pl-3"
                    >
                      Placement
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      Landing Pages
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      Badge Awards
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <td scope="col" colSpan={3} className="bg-white p-8">
                    <LoadingIndicator />
                  </td>
                ) : (
                  <tbody className="bg-white">
                    {Object.entries(sponsorImages).map(
                      ([key, uri]) =>
                        !key.includes('_START') && (
                          <SponsorshipSlot
                            key={key}
                            uploadKey={key as SponsorImageKey}
                            uri={uri}
                            uploading={uploadingImages[key]}
                            onUpload={handleUpload}
                            onDelete={handleDelete}
                            uploadStartKey={getUploadStartValues(key).key}
                            uploadStartUri={getUploadStartValues(key).uri}
                            uploadingStartKey={
                              uploadingImages[getUploadStartValues(key).key]
                            }
                          />
                        )
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

type ToastProps = Readonly<{error: Error | null}>

function SponsorsToast({error}: ToastProps) {
  return (
    <>
      {error && (
        <Toast errorCount={1} variant="error">
          <FormattedMessage id="general.GenericError" />
        </Toast>
      )}
    </>
  )
}

export default SponsorsPage
