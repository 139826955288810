import clsx from 'clsx'
import React, {ReactNode} from 'react'
import {isNonBlankString} from '../../responseRefiners'

type Props = Readonly<{
  children?: ReactNode | null
  id: string
}>

/**
 * A {@link label} element with role region and aria-live polite to displaying input validation error messages.
 *
 * @param id The id of this element so that it may be bound to the input with aria-errormessage in the parent.
 * @param message The message to display as the child of the {@link label}.
 */
function ValidationErrorMessage({children, id}: Props) {
  const isError =
    typeof children === 'string' ? isNonBlankString(children) : children != null

  return (
    <label
      aria-atomic
      aria-live="off"
      className={clsx(
        'typography-caption text-right text-red',
        isError ? 'visible' : 'invisible'
      )}
      id={id}
      role="region"
    >
      {isError ? children : null}
    </label>
  )
}

export default ValidationErrorMessage
