import React from 'react'
import {useIntl} from 'react-intl'
import FocusableImage from '../../FocusableImage'
import {UnstyledExternalLink} from '../ExternalLink'
import facebookIconUri from './assets/facebook_icon.svg'

export const FACEBOOK_URL = 'https://www.facebook.com/lemonadeday'

function FacebookLink() {
  const intl = useIntl()
  return (
    <UnstyledExternalLink to={FACEBOOK_URL}>
      <FocusableImage
        alt={intl.formatMessage({id: 'general.facebook'})}
        height={33}
        src={facebookIconUri}
        variant={'social'}
        width={33}
      />
    </UnstyledExternalLink>
  )
}

export default FacebookLink
