import {AppState} from '../store'

/**
 * Returns adult ONLY if adult exists in state, or throws an error
 * */
export function getAdult(state: AppState) {
  const adult = getPossibleAdult(state)
  if (adult === null) {
    throw new Error('No Adult in state')
  }
  return adult
}

/**
 * Returns null if there is no adult in state
 * (This avoids assuming that an exception thrown by getAdult() should not be handled)
 * */
export function getPossibleAdult(state: AppState) {
  const adult =
    state == null || state.adults == null || state.auth.adultId == null
      ? null
      : state.adults.byId[state.auth.adultId]
  return adult
}
