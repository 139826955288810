import * as React from 'react'
import {FormattedMessage} from 'react-intl'
import {ExternalLinkStyledAsButton} from '../links/ExternalLink'
import FacebookLink from '../links/FacebookLink'
import InstagramLink from '../links/InstagramLink'
import PinterestLink from '../links/PinterestLink'
import TwitterLink from '../links/TwitterLink'
import YoutubeLink from '../links/YoutubeLink'

function PublicFooter() {
  return (
    <footer
      className="
        flex w-full flex-col items-center justify-center space-y-4 bg-navy p-4
        lg:flex-row lg:justify-between lg:space-x-4 lg:space-y-0 lg:px-12
      "
    >
      <div
        className="
          flex flex-col items-center justify-center space-y-4
          sm:flex-row sm:space-x-4 sm:space-y-0
        "
      >
        <ExternalLinkStyledAsButton to="https://lemonadeday.org/donate-national">
          <FormattedMessage id="registration.WebFooterNav_donateButton" />
        </ExternalLinkStyledAsButton>
        <span className="typography-body-2 text-center text-white">
          <FormattedMessage id="registration.WebFooterNav_footerTitle" />
        </span>
      </div>
      <div className="flex max-w-xs justify-between space-x-4">
        <FacebookLink />
        <TwitterLink />
        <InstagramLink />
        <PinterestLink />
        <YoutubeLink />
      </div>
    </footer>
  )
}

export default PublicFooter
