import React from 'react'
import {FormattedMessage} from 'react-intl'
import styled from 'styled-components'
import {contentPadding, heading, text} from '../../../../styled/mixins'
import Layout from '../../Layout'
import {Body4, BoldBody3, Heading1 as H1} from '../../Typography'
import chuffedLemmy from './assets/mentor_thanks_reg_image.png'
import chuffedLemmy2X from './assets/mentor_thanks_reg_image@2x.png'
import chuffedLemmy3X from './assets/mentor_thanks_reg_image@3x.png'
import chuffedLemmy4X from './assets/mentor_thanks_reg_image@4x.png'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${contentPadding}
`

const Heading = styled(H1)`
  ${heading}
`

const MainText = styled(BoldBody3)`
  ${text}
`

const Description = styled(Body4)`
  ${text}
`

const Lemmie = styled.img`
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 2rem;
`

function RegistrationSuccess() {
  return (
    <Layout noShadow>
      <Content>
        <Lemmie
          src={chuffedLemmy}
          srcSet={`${chuffedLemmy}, ${chuffedLemmy2X} 2x, ${chuffedLemmy3X} 3x, ${chuffedLemmy4X} 4x`}
        />
        <Heading>
          <FormattedMessage id="registration.VerifyEmail_H1" />
        </Heading>
        <MainText>
          <FormattedMessage id="registration.VerifyEmail_B1" />
        </MainText>
        <Description>
          <FormattedMessage id="registration.VerifyEmail_B2" />
        </Description>
      </Content>
    </Layout>
  )
}

export default RegistrationSuccess
