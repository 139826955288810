import {useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import Id from '../domain/id'
import {StoreVisitType} from '../domain/storeVisitType'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  onSuccess?: () => void
  onError?: (error: Error) => void
}>

type RequestBody = Readonly<{
  adultId: Id<'Adult'>
  type: StoreVisitType
  purchased: boolean
}>

function useRecordStoreVisit({onError, onSuccess}: Options): {
  busy: boolean
  recordStoreVisit: (storeVisit: RequestBody) => void
  error: Error | null
} {
  const accessToken = useSelector(getAccessToken)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const recordStoreVisit = (requestBody: RequestBody) => {
    setBusy(true)
    setError(null)

    postStoreVisit(accessToken, requestBody)
      .then(() => onSuccess?.())
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => setBusy(false))
  }

  return {busy, error, recordStoreVisit}
}

async function postStoreVisit(accessToken: string, requestBody: RequestBody) {
  const url = new URL(
    `adults/${requestBody.adultId}/storeVisits`,
    API_URL
  ).toString()

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    },
    body: JSON.stringify(requestBody)
  })

  if (response.status !== 201) {
    return ServerError.fromResponse(response)
  }
}

export default useRecordStoreVisit
