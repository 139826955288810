import React from 'react'
import {useIntl} from 'react-intl'
import CountryCode from '../domain/countryCode'
import SelectInput from './SelectInput/SelectInput'

const COUNTRIES = [
  {code: 'CA', nameMessageId: 'general.country.ca'},
  {code: 'MX', nameMessageId: 'general.country.mx'},
  {code: 'US', nameMessageId: 'general.country.us'}
] as const

type Country = typeof COUNTRIES[number]

type Props = Readonly<{
  errorMessage?: string | null
  name?: string
  value: CountryCode | null
  onChange?: (value: CountryCode | null) => void
}>

function CountrySelect({
  errorMessage = null,
  name = 'country',
  value,
  onChange
}: Props) {
  const intl = useIntl()
  const options = COUNTRIES.map((option: Country) => ({
    id: option.code,
    name: intl.formatMessage({id: option.nameMessageId})
  })).sort((a, b) => new Intl.Collator(intl.locale).compare(a.name, b.name))

  return (
    <SelectInput
      errorMessage={errorMessage}
      name={name}
      label={intl.formatMessage({id: 'admin.communities.country.label'})}
      placeholderText={intl.formatMessage({
        id: 'admin.communities.selectSearch'
      })}
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export default CountrySelect
