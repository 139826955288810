import React, {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {
  TOOL_SELECTOR_ROUTE,
  USER_SEARCH_ROUTE
} from '../../global-constants/routingConstants'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import Layout from '../Layout'
import TitleText from '../TitleText'
import Toast from '../Toast'
import SearchLearner from './SearchLearner'
import SearchMentor from './SearchMentor'
import {FormContainer, UserTypeSelector} from './sections'
import {UserType} from './types'

function UserSearchPage() {
  const [successMessage] = useState()
  const [error, setError] = useState<boolean>(false)
  const [selectedUserType, setSelectedUserType] = useState<UserType | null>(
    null
  )
  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="admin.userSettings.title" />
        </TitleText>
      }
      toast={
        <>
          {error && (
            <Toast errorCount={1} variant="error">
              <FormattedMessage id="general.GenericError" />
            </Toast>
          )}
          {successMessage && <Toast variant="success">{successMessage}</Toast>}
        </>
      }
    >
      <div
        className="
          mx-auto mb-2 w-full px-2
          md:mb-4 md:px-4 lg:mb-6 lg:px-6
          xl:mb-8 xl:max-w-7xl xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={USER_SEARCH_ROUTE}
            to={USER_SEARCH_ROUTE}
            label={<FormattedMessage id="admin.userSettings.title" />}
          />
        </Breadcrumb>
        <h2 className="typography-headline-4 my-2 md:my-4 lg:my-6 xl:my-8">
          <FormattedMessage id="admin.userSettings.heading" />
        </h2>
        {!selectedUserType && (
          <FormContainer>
            <UserTypeSelector onSelect={setSelectedUserType} />
          </FormContainer>
        )}
        {selectedUserType === 'learner' && (
          <SearchLearner
            setError={setError}
            defaultPageSize={100}
            setSelectedUserType={setSelectedUserType}
          />
        )}
        {selectedUserType === 'mentor' && (
          <SearchMentor
            setError={setError}
            defaultPageSize={100}
            setSelectedUserType={setSelectedUserType}
          />
        )}
      </div>
    </Layout>
  )
}

export default UserSearchPage
