import * as api from '../../api'
import {AppThunkAction} from '../store'
import {FluxStandardAction} from '../type'
import {Adult} from './adults.type'
export const ATTEMPT_UPDATE_ADULT = 'adults/ATTEMPT_UPDATE_ADULT'
export const UPDATE_ADULT = 'adults/UPDATE_ADULT'

export type AttemptUpdateAdultAction = FluxStandardAction<
  typeof ATTEMPT_UPDATE_ADULT
>

export type UpdateAdultAction = FluxStandardAction<typeof UPDATE_ADULT, Adult>

export type AdultsActions = AttemptUpdateAdultAction | UpdateAdultAction

export function updateAdult(
  data: Adult
): AppThunkAction<AttemptUpdateAdultAction | UpdateAdultAction> {
  return async (dispatch, getState) => {
    const state = getState()

    dispatch({type: ATTEMPT_UPDATE_ADULT})
    try {
      const adult = await api.updateAdult(data, state)
      dispatch({type: UPDATE_ADULT, payload: adult})
    } catch (error) {
      dispatch({type: UPDATE_ADULT, error: true, payload: error})
      throw error
    }
  }
}
