import {Combobox} from '@headlessui/react'
import {CheckIcon, ChevronDownIcon} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import React, {ReactNode, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {isNonBlankString} from '../../responseRefiners'
import ValidationErrorMessage from '../ValidationErrorMessage'

function classNames(...classes: Array<boolean | string | undefined>) {
  return classes.filter(Boolean).join(' ')
}

export type OptionType<ID extends string> = Readonly<{
  id: ID
  name: string
}>

type Props<ID extends string> = Readonly<{
  errorMessage?: string | null
  name: string
  label: ReactNode
  description?: ReactNode
  value?: ID | null
  options: ReadonlyArray<OptionType<ID>>
  onChange?: (option: ID | null) => void
  placeholderText?: string
}>

function SelectInput<ID extends string = string>({
  errorMessage = null,
  name,
  label,
  description,
  options,
  onChange,
  value,
  placeholderText = 'admin.communities.selectOne'
}: Props<ID>) {
  const errorElementId = `${name}-error`
  const isError = isNonBlankString(errorMessage)
  const [query, setQuery] = useState('')

  const filteredOptions =
    query === ''
      ? options
      : options.filter(option => {
          return option.name.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox
      as="div"
      className="flex items-center justify-between p-2"
      name={name}
      value={value}
      onChange={value => {
        onChange?.(value)
      }}
    >
      <div>
        <Combobox.Label className="typography-headline-5 block w-full text-navy after:content-[':']">
          {label}
        </Combobox.Label>
        {description && (
          <p data-testid={`${name}-description`} className="typography-body-2">
            {description}
          </p>
        )}
      </div>
      <div className="relative flex w-full max-w-[640px] flex-grow flex-col items-end">
        <Combobox.Input
          aria-describedby={errorElementId}
          aria-errormessage={errorElementId}
          aria-invalid={isError}
          className={clsx(
            'w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-navy shadow-sm outline-none ring-2 ring-inset',
            'placeholder:text-navy focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-green',
            isError ? 'ring-red' : 'ring-steel-tertiary-20'
          )}
          displayValue={(value: ID) => {
            return options.find(option => option.id === value)?.name ?? ''
          }}
          id={name}
          onChange={event => setQuery(event.target.value)}
          placeholder={placeholderText}
        />
        <Combobox.Button className="absolute right-0 top-0 flex items-center rounded-r-md focus:outline-none">
          <ChevronDownIcon
            aria-hidden
            className="h-9 w-9 rounded-r-md text-steel-tertiary-20"
          />
          <span className="sr-only">
            <FormattedMessage id="general.open" />
          </span>
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-9 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredOptions.map(option => (
              <Combobox.Option
                key={option.id}
                value={option.id}
                className={({active}) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? ' bg-green text-white' : ' text-navy'
                  )
                }
              >
                {({active, selected}) => (
                  <>
                    <span className={classNames('block truncate')}>
                      {option.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-navy-primary'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
        <ValidationErrorMessage id={errorElementId}>
          {errorMessage}
        </ValidationErrorMessage>
      </div>
    </Combobox>
  )
}

export default SelectInput
