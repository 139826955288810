import React from 'react'
import {FormattedMessage} from 'react-intl'
import {SUPPORT_EMAIL} from '../../config'
import PublicFooter from '../Footer/PublicFooter'
import PublicHeader from '../Header/PublicHeader'
import ExternalLink from '../links/ExternalLink'
import MailImage from './MailImage'

function EmailUnsubscribeFailedPage() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-between">
      <PublicHeader />
      <main className="flex w-full flex-col items-center justify-center space-y-4 px-4 pb-4">
        <MailImage />
        <h1 className="typography-headline-4 text-center text-teal-tertiary-20">
          <FormattedMessage id="email.unsubscribeFailed" />
        </h1>
        <p className="typography-body-1">
          <FormattedMessage id="email.embarrassment" />
        </p>
        <ExternalLink
          to={`mailto:${SUPPORT_EMAIL}?subject=Email unsubscription failure`}
        >
          <FormattedMessage id="email.contactSupport" />
        </ExternalLink>
      </main>
      <PublicFooter />
    </div>
  )
}

export default EmailUnsubscribeFailedPage
