import {isNonBlankString} from '../../responseRefiners'
import * as LearnerViews from './learnerViews'
import {CARDS, TABLE} from './learnerViews'

export type LearnerView = ValueOf<typeof LearnerViews>

const EXPECTED_VIEWS = Object.values(LearnerViews)

function parse(candidate: unknown): LearnerView {
  if (!isNonBlankString(candidate)) {
    throw new Error(`LearnerView ${candidate}, may not be blank, empty or null`)
  }

  if (candidate !== CARDS && candidate !== TABLE) {
    throw new Error(
      `Unexpected LearnerView ${candidate}, expected one of ${EXPECTED_VIEWS.join(
        ', '
      )}`
    )
  }

  return candidate as LearnerView
}

const learnersView = {parse}
export default learnersView
