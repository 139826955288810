import {XMarkIcon} from '@heroicons/react/24/outline'
import React, {FormEvent, useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {CommunityId} from '../../domain/community'
import Id from '../../domain/id'
import {Mentor} from '../../domain/mentor'
import useMigrateMentorToCommunity from '../../hooks/useMigrateMentorToCommunity'
import {getCommunitiesAction} from '../../store/communities/communities.action'
import {getAllCommunities} from '../../store/communities/communities.selectors'
import {AppDispatch} from '../../store/store'
import Dialog from '../Dialog'
import Divider from '../Divider'
import FlatButton from '../FlatButton'
import LoadingIndicator from '../LoadingIndicator'
import SelectInput from '../SelectInput/SelectInput'
import ToggleInput from '../ToggleInput'
import UserProfileTable, {UserProfileRow} from '../UserProfileTable'

type Props = {
  open: boolean
  onClose: () => void
  onError: (error: Error) => void
  onSuccess: () => void
  mentor: Mentor
}

function EditMentorDialog({open, onClose, onSuccess, onError, mentor}: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const communities = useSelector(getAllCommunities)
  const intl = useIntl()
  const formId = 'edit-mentor'
  const [loading, setLoading] = useState(false)
  const [communityErrorMessage, setCommunityErrorMessage] = useState<
    string | null
  >(null)
  const [newCommunityId, setNewCommunityId] = useState<CommunityId | null>(null)
  const [migrateProgress, setMigrateProgress] = useState(false)
  const [migrateLearners, setMigrateLearners] = useState(false)
  const {busy, migrateMentorToCommunity} = useMigrateMentorToCommunity({
    onSuccess,
    onError
  })

  useEffect(() => {
    setLoading(true)
    dispatch(getCommunitiesAction())
      .catch(onError)
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, onError])

  function handleFormSubmission() {
    let valid = true

    if (!Id.isValid(newCommunityId)) {
      setCommunityErrorMessage(
        intl.formatMessage({
          id: 'admin.mentorProfile.community.validationMessage'
        })
      )
      valid = false
    }

    if (newCommunityId === mentor?.communityId) {
      setCommunityErrorMessage(
        intl.formatMessage({
          id: 'admin.mentorProfile.community.communityConflict'
        })
      )
      valid = false
    }

    if (valid && mentor && newCommunityId) {
      migrateMentorToCommunity(
        mentor.id,
        newCommunityId,
        migrateProgress,
        migrateLearners
      )
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="flex flex-col items-center">
        <div className="-mt-6 mb-6 flex w-full items-center justify-between">
          <Dialog.Title className="typography-headline-4">
            <FormattedMessage id="admin.mentorProfile.editDialogTitle" />
          </Dialog.Title>
          <XMarkIcon className="-mr-2 -mt-2 h-7 w-7" onClick={onClose} />
        </div>
        {loading && communities.length === 0 ? (
          <LoadingIndicator />
        ) : (
          <>
            <Divider />
            {mentor && (
              <UserProfileTable>
                <UserProfileRow
                  compact
                  left={intl.formatMessage({
                    id: 'admin.mentorProfile.firstName'
                  })}
                  right={mentor.firstName}
                />
                <UserProfileRow
                  compact
                  left={intl.formatMessage({
                    id: 'admin.mentorProfile.lastName'
                  })}
                  right={mentor.lastName}
                />
                <UserProfileRow
                  compact
                  left={intl.formatMessage({
                    id: 'admin.mentorProfile.email'
                  })}
                  right={mentor.email}
                />
                <UserProfileRow
                  compact
                  left={intl.formatMessage({
                    id: 'admin.mentorProfile.currentCommunity'
                  })}
                  right={mentor.communityName}
                />
              </UserProfileTable>
            )}
            <form
              aria-label={intl.formatMessage({
                id: 'admin.mentorProfile.formLabel'
              })}
              name="edit-mentor"
              id={formId}
              className="min-w-full"
              onSubmit={(event: FormEvent<HTMLFormElement>) => {
                event.preventDefault()
                handleFormSubmission()
              }}
              noValidate
            >
              <SelectInput
                errorMessage={communityErrorMessage}
                name="community"
                label={intl.formatMessage({
                  id: 'admin.mentorProfile.communityLabel'
                })}
                placeholderText={intl.formatMessage({
                  id: 'admin.mentorProfile.community.placeholder'
                })}
                options={communities.map(community => ({
                  id: community.id,
                  name: community.name
                }))}
                value={newCommunityId}
                onChange={selectedCommunityId => {
                  setNewCommunityId(selectedCommunityId as CommunityId)
                }}
              />
              <Divider />
              <div className="my-1">
                <ToggleInput
                  checked={migrateLearners}
                  description="admin.mentorProfile.migrateLearners.description"
                  label="admin.mentorProfile.migrateLearners.title"
                  onChange={progress => {
                    setMigrateLearners(progress)
                    if (!progress) {
                      setMigrateProgress(progress)
                    }
                  }}
                />
              </div>
              <Divider />
              <div className="my-1">
                <ToggleInput
                  checked={migrateProgress}
                  description="admin.mentorProfile.migrateProgress.description"
                  label="admin.mentorProfile.migrateProgress.title"
                  onChange={progress => {
                    setMigrateProgress(progress)
                    if (progress) {
                      setMigrateLearners(progress)
                    }
                  }}
                />
              </div>
              <Divider />
              <div className="mx-auto mt-2 flex w-full justify-end md:mt-4 lg:mt-6">
                <FlatButton
                  aria-label={intl.formatMessage({
                    id: 'admin.mentorProfile.migrateMentor'
                  })}
                  id="migrate-mentor-button"
                  form={formId}
                  type="submit"
                  variant="primary"
                  disabled={busy}
                >
                  <FormattedMessage id="admin.mentorProfile.migrateMentor" />
                </FlatButton>
              </div>
            </form>
          </>
        )}
      </div>
    </Dialog>
  )
}

export default EditMentorDialog
