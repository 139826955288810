import {AppState} from '../store'
import {SponsorImageKey} from './sponsorImages.types'

export const DEFAULT_SPONSOR_IMAGES: Readonly<
  Record<SponsorImageKey, string | null>
> = {
  MODULE_ONE: null,
  LESSON_ONE_ONE_START: null,
  LESSON_ONE_ONE_COMPLETION: null,
  LESSON_ONE_TWO_START: null,
  LESSON_ONE_TWO_COMPLETION: null,
  MODULE_TWO: null,
  LESSON_TWO_ONE_START: null,
  LESSON_TWO_ONE_COMPLETION: null,
  LESSON_TWO_TWO_START: null,
  LESSON_TWO_TWO_COMPLETION: null,
  LESSON_TWO_THREE_START: null,
  LESSON_TWO_THREE_COMPLETION: null,
  LESSON_TWO_FOUR_START: null,
  LESSON_TWO_FOUR_COMPLETION: null,
  LESSON_TWO_FIVE_START: null,
  LESSON_TWO_FIVE_COMPLETION: null,
  LESSON_TWO_SIX_START: null,
  LESSON_TWO_SIX_COMPLETION: null,
  LESSON_TWO_SEVEN_START: null,
  LESSON_TWO_SEVEN_COMPLETION: null,
  LESSON_TWO_EIGHT_START: null,
  LESSON_TWO_EIGHT_COMPLETION: null,
  LESSON_TWO_NINE_START: null,
  LESSON_TWO_NINE_COMPLETION: null,
  LESSON_TWO_TEN_START: null,
  LESSON_TWO_TEN_COMPLETION: null,
  LESSON_TWO_ELEVEN_START: null,
  LESSON_TWO_ELEVEN_COMPLETION: null,
  MODULE_THREE: null,
  LESSON_THREE_ONE_START: null,
  LESSON_THREE_ONE_COMPLETION: null,
  LESSON_THREE_TWO_START: null,
  LESSON_THREE_TWO_COMPLETION: null,
  LESSON_THREE_THREE_START: null,
  LESSON_THREE_THREE_COMPLETION: null,
  LESSON_THREE_FOUR_START: null,
  LESSON_THREE_FOUR_COMPLETION: null,
  LESSON_THREE_FIVE_START: null,
  LESSON_THREE_FIVE_COMPLETION: null,
  MODULE_FOUR: null,
  LESSON_FOUR_ONE_START: null,
  LESSON_FOUR_ONE_COMPLETION: null,
  LESSON_FOUR_TWO_START: null,
  LESSON_FOUR_TWO_COMPLETION: null,
  LESSON_FOUR_THREE_START: null,
  LESSON_FOUR_THREE_COMPLETION: null
}

export function selectSponsorImagesForCommunityAndEvent(
  state: AppState,
  communityId: string,
  eventId: string
): Record<SponsorImageKey, string | null> {
  const byEvent = state.sponsorImages.byIds[communityId]
  if (byEvent == null) {
    return DEFAULT_SPONSOR_IMAGES
  }
  const byKey = byEvent[eventId]
  if (byKey == null) {
    return DEFAULT_SPONSOR_IMAGES
  }
  const sponsorImages: Record<SponsorImageKey, string | null> = {
    ...DEFAULT_SPONSOR_IMAGES
  }
  for (const sponsorImage of Object.values(byKey)) {
    sponsorImages[sponsorImage.key] = sponsorImage.uri
  }
  return sponsorImages
}
