import React from 'react'
import {FormattedMessage} from 'react-intl'
import styled from 'styled-components'
import {devices} from '../../../device'
import {contentPadding, heading, text} from '../../../styled/mixins'
import Background from '../Background'
import Layout from '../Layout'
import * as Typography from '../Typography'

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Header = styled(Typography.Heading1)`
  ${heading};
  font-size: 2.25rem;
  text-align: left;
  margin-top: 2rem;
`

const SectionHeader = styled(Typography.Heading1)`
  margin-bottom: 2rem;
`

const LeadText = styled(Typography.BoldBody1)`
  ${text};
  text-align: left;
  margin-bottom: 2rem;
`

const Paragraph = styled(Typography.Body3).attrs({as: 'p'})`
  color: #28414b;
  margin-bottom: 2rem;
  text-align: left;

  @media ${devices.tablet} {
    text-align: justify;
  }
`

const ContentWrapper = styled.div`
  max-width: 100%;
  padding: 0 18px;
  ${contentPadding};

  @media ${devices.desktop} {
    max-width: 65%;
  }
`

function LegalTerms() {
  return (
    <Layout noShadow>
      <Content>
        <Background />
        <ContentWrapper>
          <Header>
            <FormattedMessage id="registration.LegalTerms_H1" />
          </Header>
          <LeadText>
            <FormattedMessage id="registration.LegalTerms_subtitle" />
          </LeadText>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section1_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section1_p" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section2_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section2_p" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section2_p1" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section3_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section3_p" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section3_p1" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section3_p2" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section3_p3" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section3_p4" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section4_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section4_p" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section5_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section5_p" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section5_p1" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section5_p2" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section5_p3" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section6_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section6_p" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section6_p1" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section6_p2" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section7_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section7_p" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section8_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section8_p" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section9_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section9_p" />
            </Paragraph>
          </section>
          <section>
            <SectionHeader>
              <FormattedMessage id="registration.LegalTerms_section10_title" />
            </SectionHeader>
            <Paragraph>
              <FormattedMessage id="registration.LegalTerms_section10_p" />
            </Paragraph>
          </section>
        </ContentWrapper>
      </Content>
    </Layout>
  )
}

export default LegalTerms
