import {isNonBlankString} from '../responseRefiners'
import * as CURRENCY_CODES from './currencyCodes'
import {CAD, USD} from './currencyCodes'

/**
 * An ISO 4217 currency code.
 */
type CurrencyCode = ValueOf<typeof CURRENCY_CODES>

const EXPECTED_CURRENCY_CODES = Object.values(CURRENCY_CODES)

/**
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is an expected ISO 4217 currency code.
 */
function isValid(candidate: unknown): boolean {
  return candidate === CAD || candidate === USD
}

/**
 * Parse an ISO 4217 {@link CurrencyCode} from a candidate of unknown type.
 *
 * @param candidate The candidate to parse.
 * @return A valid typed {@link CurrencyCode}.
 */
function parse(candidate: unknown): CurrencyCode {
  if (!isNonBlankString(candidate)) {
    throw new Error(
      `CurrencyCode ${candidate}, may not be blank, empty or null`
    )
  }

  const trimmedCandidate = candidate.trim()
  if (!isValid(trimmedCandidate)) {
    throw new Error(
      `Unexpected CurrencyCode ${candidate}, expected one of ${EXPECTED_CURRENCY_CODES.join(
        ', '
      )}`
    )
  }

  return trimmedCandidate as CurrencyCode
}

const CurrencyCode = {isValid, parse}
export default CurrencyCode
