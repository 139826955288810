import {createBrowserHistory} from 'history'
import {applyMiddleware, combineReducers, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunkMiddleware, {
  ThunkAction,
  ThunkDispatch,
  ThunkMiddleware
} from 'redux-thunk'
import errorReporterMiddleware from '../middleware/errorReporter'
import {AdultsActions} from './adults/adults.action'
import adultsReducer, {AdultState} from './adults/adults.reducer'
import authReducer from './authentication/authentication.reducer'
import {
  AuthenticationActionTypes,
  AuthState
} from './authentication/authentication.type'
import communitiesReducer from './communities/communities.reducer'
import {
  CommunitiesActionTypes,
  CommunitiesState
} from './communities/communities.type'
import forgotPasswordReducer from './forgot-password/forgot-password.reducer'
import {
  PasswordActionTypes,
  PasswordState
} from './forgot-password/forgot-password.type'
import learnersReducer from './learners/learners.reducer'
import {LearnerActionTypes, LearnerState} from './learners/learners.type'
import {MentorsActions} from './mentors/mentors.action'
import mentorsReducer, {MentorsState} from './mentors/mentors.reducer'
import resetPasswordReducer from './reset-password/reset-password.reducer'
import {
  ResetPasswordActionTypes,
  ResetPasswordState
} from './reset-password/reset-password.types'
import {SponsorImageActions} from './sponsorImages/sponsorImages.actions'
import sponsorImagesReducer from './sponsorImages/sponsorImages.reducer'
import {SponsorImagesState} from './sponsorImages/sponsorImages.types'
import uiReducer from './ui/ui.reducer'
import {UIActionTypes, UIState} from './ui/ui.types'

export interface AppState {
  auth: AuthState
  communities: CommunitiesState
  adults: AdultState
  mentors: MentorsState
  ui: UIState
  forgotPassword: PasswordState
  resetPassword: ResetPasswordState
  learners: LearnerState
  sponsorImages: SponsorImagesState
}

export type AppActions =
  | AuthenticationActionTypes
  | CommunitiesActionTypes
  | AdultsActions
  | MentorsActions
  | PasswordActionTypes
  | ResetPasswordActionTypes
  | UIActionTypes
  | LearnerActionTypes
  | SponsorImageActions

export type AppThunkAction<Action extends AppActions> = ThunkAction<
  Promise<void>,
  AppState,
  undefined,
  Action
>

export type AppDispatch = ThunkDispatch<AppState, undefined, AppActions>

const rootReducer = combineReducers<AppState, AppActions>({
  auth: authReducer,
  communities: communitiesReducer,
  adults: adultsReducer,
  mentors: mentorsReducer,
  ui: uiReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  learners: learnersReducer,
  sponsorImages: sponsorImagesReducer
})

export const history = createBrowserHistory()

export default function configureStore(state?: AppState) {
  const middlewareEnhancer = applyMiddleware(
    thunkMiddleware as ThunkMiddleware<AppState, AppActions>,
    errorReporterMiddleware
  )
  const store = createStore(
    rootReducer,
    state,
    composeWithDevTools(middlewareEnhancer)
  )

  return store
}
