import React, {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {withRouter} from 'react-router-dom'
import styled from 'styled-components'
import ExternalLink from '../../../components/links/ExternalLink'
import InternalLink from '../../../components/links/InternalLink'
import {SUPPORT_EMAIL} from '../../../config'
import {devices} from '../../../device'
import {EMAIL_NOT_VERIFIED_ERROR_IDENTIFIER} from '../../../errors/errorIdentifiers'
import ServerError from '../../../errors/server'
import {
  FORGOT_PASSWORD_ROUTE,
  REGISTER_ROUTE
} from '../../../global-constants/routingConstants'
import Alert from '../Alert'
import Button from '../Button'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import FormField from '../FormField'
import Label from '../Label/Label'
import Password from '../Password/Password'
import TextField from '../TextField'
import * as Typography from '../Typography'
import {
  CredentialErrors,
  Credentials,
  NotificationAlert,
  Props
} from './loginForm.type'
import {validateCredentials} from './validations'

const AlertWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;

  @media ${devices.tablet} {
    margin-top: 0;
  }
`

const Heading = styled(Typography.BoldBody1)`
  text-align: center;
  margin-bottom: 2rem;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Actions = styled.div`
  margin-bottom: 2rem;
`

const ForgotPasswordErrorWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 8px;
`

const VerificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const RegisterLinkWrapper = styled(Typography.Body4)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LinkPadding = styled.span`
  padding-left: 8px;
`

const PasswordResetSuccess = styled(Typography.BoldBody3)``

const EmailVerificationNotificationMain = styled(Typography.BoldBody3)`
  display: block;
`

const EmailVerificationNotificationDescription = styled(Typography.Body4)``

const NotificationText = styled.span`
  white-space: pre;
`

function LoginForm({
  error,
  submitting,
  onSubmit,
  notificationType = null,
  onClearError
}: Props) {
  const intl = useIntl()

  const [credentials, setCredentials] = useState<Credentials>({
    email: '',
    password: ''
  })
  const [credentialErrors, setCredentialErrors] = useState<CredentialErrors>({
    passwordError: null,
    emailError: null
  })
  const [notificationTypeAlert, setNotificationTypeAlert] =
    useState<NotificationAlert>(getNotificationAndAlertType(notificationType))
  const {email, password} = credentials
  const {passwordError, emailError} = credentialErrors

  const alertRef = useRef<HTMLDivElement>(null)

  const hasAuthenticationError =
    error != null && ServerError.isServerError(error) && error.status === 401

  const {notificationAlert, alertType} = notificationTypeAlert

  useEffect(() => {
    if (
      error != null &&
      ServerError.isServerError(error) &&
      alertRef.current != null
    ) {
      alertRef.current.scrollIntoView()
    }
  }, [alertRef, error])

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const credentialErrors = validateCredentials(credentials)
    setCredentialErrors(credentialErrors)
    setNotificationTypeAlert({alertType: 'success', notificationAlert: null})

    if (
      credentialErrors.emailError === null &&
      credentialErrors.passwordError === null
    ) {
      onSubmit(credentials)
    } else {
      onClearError()
    }
  }

  function handleCredentialChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist()
    setCredentials((credentials: Credentials) => ({
      ...credentials,
      [event.target.id || event.target.name]: event.target.value
    }))
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {error != null && (
        <AlertWrapper>
          <Alert type="error" ref={alertRef}>
            {ServerError.isServerError(error) &&
            error.status === 403 &&
            ServerError.includes(error, EMAIL_NOT_VERIFIED_ERROR_IDENTIFIER) ? (
              <VerificationWrapper>
                <Typography.BoldBody3>
                  <FormattedMessage id="login.mentorLoginFail_top_popUp_H1" />
                </Typography.BoldBody3>
                {error.links.resend != null && (
                  <ExternalLink to={error.links.resend.href}>
                    <FormattedMessage id="login.mentorLoginFail_top_popUp_H2" />
                  </ExternalLink>
                )}
              </VerificationWrapper>
            ) : (
              <Typography.BoldBody3>
                <FormattedMessage
                  id={
                    hasAuthenticationError
                      ? 'registration.mentorLoginFail_error'
                      : 'registration.MentorProfile_ServerError_generic'
                  }
                />
              </Typography.BoldBody3>
            )}
          </Alert>
        </AlertWrapper>
      )}
      {notificationAlert && (
        <AlertWrapper>
          <Alert type={alertType}>{notificationAlert}</Alert>
        </AlertWrapper>
      )}
      <Heading as="h1">
        <FormattedMessage id="registration.mentorLogin_H1" />
      </Heading>
      <FormField>
        <Label htmlFor="email">
          <FormattedMessage id="registration.mentorLogin_email" />
        </Label>
        <TextField
          id="email"
          name="email"
          type="email"
          required
          colour="yellow"
          placeholder={intl.formatMessage({
            id: 'registration.mentorLogin_emailPH'
          })}
          value={email}
          onChange={e => handleCredentialChange(e)}
          error={hasAuthenticationError || !!emailError}
          onBlur={() =>
            setCredentialErrors((errors: CredentialErrors) => ({
              ...errors,
              emailError: null
            }))
          }
        />
        {emailError && (
          <ErrorMessage>
            <FormattedMessage id={emailError} />
          </ErrorMessage>
        )}
      </FormField>
      <FormField>
        <Label htmlFor="password">
          <FormattedMessage id="general.password" />
        </Label>
        <Password
          id="password"
          name="password"
          required
          colour="yellow"
          placeholder={intl.formatMessage({
            id: 'registration.mentorLogin_passwordPH'
          })}
          value={password}
          onChange={e => handleCredentialChange(e)}
          error={hasAuthenticationError || !!passwordError}
          onBlur={() =>
            setCredentialErrors((errors: CredentialErrors) => ({
              ...errors,
              passwordError: null
            }))
          }
        />
        <ForgotPasswordErrorWrap>
          <InternalLink to={{pathname: FORGOT_PASSWORD_ROUTE, state: email}}>
            <FormattedMessage id="registration.mentorLogin_forgotPassword" />
          </InternalLink>
          {passwordError && (
            <ErrorMessage>
              <FormattedMessage id={passwordError} />
            </ErrorMessage>
          )}
        </ForgotPasswordErrorWrap>
      </FormField>
      <Actions>
        <Button type="submit" disabled={submitting} colour="yellow">
          <FormattedMessage id="registration.mentorLogin_loginButton" />
        </Button>
      </Actions>
      <RegisterLinkWrapper>
        <FormattedMessage id="registration.mentorLogin_signup" />
        <LinkPadding>
          <InternalLink to={REGISTER_ROUTE}>
            <FormattedMessage id="registration.mentorLogin_signup_link" />
          </InternalLink>
        </LinkPadding>
      </RegisterLinkWrapper>
    </Form>
  )
}

function getNotificationAndAlertType(
  notificationType: string | null
): NotificationAlert {
  if (notificationType === 'email-verification-succeeded') {
    return {
      notificationAlert: (
        <NotificationText>
          <FormattedMessage id="login.verifiedMentor_Login_top_popUp_H1" />
        </NotificationText>
      ),
      alertType: 'success'
    }
  }
  if (notificationType === 'email-verification-failed') {
    return {
      notificationAlert: <LoginFailed />,
      alertType: 'error'
    }
  }
  if (notificationType === 'password-reset-succeeded') {
    return {
      notificationAlert: (
        <PasswordResetSuccess>
          <FormattedMessage id="registration.resetPassword_Success_B1" />
        </PasswordResetSuccess>
      ),
      alertType: 'success'
    }
  }
  if (notificationType === 'login-again') {
    return {
      notificationAlert: (
        <NotificationText>
          <FormattedMessage id="general.SessionExpired" />
        </NotificationText>
      ),
      alertType: 'error'
    }
  }
  return {notificationAlert: null, alertType: 'success'}
}

function LoginFailed() {
  return (
    <div>
      <EmailVerificationNotificationMain>
        <FormattedMessage id="login.verifiedMentor_Login_Fail_top_popUp_H1" />
      </EmailVerificationNotificationMain>
      <EmailVerificationNotificationDescription>
        <FormattedMessage
          id="login.verifiedMentor_Login_Fail_top_popUp_H2"
          values={{
            supportEmail: (
              <InternalLink to={`mailto:${SUPPORT_EMAIL}`}>
                {SUPPORT_EMAIL}
              </InternalLink>
            )
          }}
        />
      </EmailVerificationNotificationDescription>
    </div>
  )
}

export default withRouter(LoginForm)
