import React, {useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import Community from '../../domain/community'
import {ACTIVE} from '../../domain/communityStatuses'
import CommunityType from '../../domain/communityType'
import {LICENSED, PUBLIC} from '../../domain/communityTypes'
import {captureException} from '../../errors'
import {getCommunitiesAction} from '../../store/communities/communities.action'
import {
  getAllCommunities,
  getCommunitiesByZip
} from '../../store/communities/communities.selectors'
import {Community as CommunityT} from '../../store/communities/communities.type'
import {AppState} from '../../store/store'
import {SelectOption} from '../components/pages/registration/form-data.type'
import Select, {Props as SelectProps} from '../components/Select'

type Props = SelectProps<SelectOption> & {
  filterCommunities: boolean
  selectedZipId: string | null
}

function CommunitySelect({
  filterCommunities,
  selectedZipId,
  onChange,
  ...props
}: Props) {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const communities = useSelector<AppState, Array<CommunityT>>(state =>
    filterCommunities && selectedZipId
      ? getCommunitiesByZip(state, selectedZipId)
      : getAllCommunities(state)
  )
  const isLoading = useSelector<AppState, boolean>(
    state => state.communities.fetching
  )

  const searchParams = new URLSearchParams(history.location.search)

  // Developers: Use query ?communityType=TEST to register a user in the test community.
  const typeQueryString = searchParams
    .get('communityType')
    ?.trim()
    ?.toUpperCase()

  const initialCommunityId = searchParams.get('scid')

  const previousCommunities = usePrevious<CommunityT[]>(communities)

  useEffect(() => {
    if (
      // An initial community id has been specified.
      initialCommunityId != null &&
      // This is the first time that we're receiving communities.
      (previousCommunities == null || previousCommunities.length === 0) &&
      // An onChange callback has been provided.
      onChange != null
    ) {
      const community = communities.find(
        community => community.id === initialCommunityId
      )
      if (community != null) {
        // Select the initial community.
        onChange(
          {
            label: Community.formatName(community),
            value: community.id
          },
          {action: 'select-option'}
        )
      }
    }
  }, [initialCommunityId, previousCommunities, communities, onChange])

  useEffect(() => {
    if (communities.length === 0) {
      let communityTypes: Array<CommunityType> = [LICENSED, PUBLIC]
      if (typeQueryString != null) {
        try {
          communityTypes = [CommunityType.parse(typeQueryString)]
        } catch (err) {
          captureException(err)
        }
      }
      // Filter by only ACTIVE communities
      dispatch(getCommunitiesAction(communityTypes, [ACTIVE]))
    }
  }, [dispatch, communities, typeQueryString])

  return (
    <Select
      isClearable
      placeholder={intl.formatMessage({
        id: 'registration.MentorDetails_communityPH'
      })}
      name="community"
      options={communities.map((community: CommunityT) => ({
        label: Community.formatName(community),
        value: community.id
      }))}
      onChange={onChange}
      isLoading={isLoading}
      {...props}
    />
  )
}

/**
 * Keep track of a value from the previous render.
 */
function usePrevious<T>(state: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = state
  })
  return ref.current
}

export default CommunitySelect
