import {errorFields} from '../../../../errors/errorFields'
import {
  ADULT_FIRST_NAME_ERROR_IDENTIFIER,
  ADULT_LAST_NAME_ERROR_IDENTIFIER,
  COMMUNITY_ID_ERROR_IDENTIFIER,
  COMMUNITY_NOT_FOUND_ERROR_IDENTIFIER,
  EMAIL_ERROR_IDENTIFIER,
  EMAIL_MALFORMED_ERROR_IDENTIFIER,
  PASSWORD_INVALID_ERROR_IDENTIFIER,
  PHONE_NUMBER_ERROR_IDENTIFIER,
  RECAPTCHA_ERROR_IDENTIFIER,
  RECAPTCHA_REQUEST_TOKEN_ERROR_IDENTIFIER,
  RECAPTCHA_VERIFICATION_ERROR_IDENTIFIER,
  REFERRER_ERROR_IDENTIFIER,
  TITLE_ERROR_IDENTIFIER,
  ZIP_ERROR_IDENTIFIER,
  ZIP_NOT_ASSOCIATED_WITH_COMMUNITY_ERROR_IDENTIFIER
} from '../../../../errors/errorIdentifiers'
import ServerError from '../../../../errors/server'
import {
  passwordsMatch,
  validateEmail,
  validatePassword
} from '../../../../validations'
import {
  RegistrationContactInfo,
  RegistrationContactInfoErrors,
  RegistrationOrganizationMembership,
  RegistrationOrganizationMembershipErrors,
  RegistrationUserDetailErrors,
  RegistrationUserDetails
} from './form-data.type'

export function validateUserDetails(
  values: RegistrationUserDetails
): RegistrationUserDetailErrors {
  const errors: RegistrationUserDetailErrors = {
    title: '',
    name: '',
    surname: '',
    phone: '',
    community: '',
    zip: '',
    referrer: ''
  }

  const {name, surname, community, zip} = values

  if (!name) {
    errors.name = 'registration.MentorDetails_Fail_errorFirstName'
  }

  if (!surname) {
    errors.surname = 'registration.MentorDetails_Fail_errorLastName'
  }

  if (!community || !community.value) {
    errors.community = 'registration.MentorDetails_Fail_errorCommunity'
  }

  if (!zip || !zip.value) {
    errors.zip = 'registration.MentorDetails_Fail_errorZip'
  }

  return errors
}

export function validateOrganizationMembership(
  values: RegistrationOrganizationMembership
): RegistrationOrganizationMembershipErrors {
  const errors: RegistrationOrganizationMembershipErrors = {
    relationship: '',
    organization: '',
    group: ''
  }

  if (!values.relationship || !values.relationship.value) {
    errors.relationship =
      'registration.organizationMembership_relationship_error'
  }

  if (
    values.relationship?.value === 'GROUP_LEADER' ||
    values.relationship?.value === 'TEACHER'
  ) {
    if (values.organization.trim() === '') {
      errors.organization =
        'registration.organizationMembership_organization_error'
    }
    if (values.group.trim() === '') {
      errors.group = 'registration.organizationMembership_group_error'
    }
  }

  return errors
}

export function validateUserContactInfo(
  values: RegistrationContactInfo
): RegistrationContactInfoErrors {
  const errors: RegistrationContactInfoErrors = {
    generic: '',
    email: '',
    password: '',
    repeatedPassword: '',
    recaptcha: ''
  }

  const {email, password, repeatedPassword, recaptcha} = values

  const emailError = validateEmail(email)
  if (emailError != null) {
    errors.email = emailError
  }

  const passwordError = validatePassword(
    password,
    'registration.MentorProfile01_Fail_error5',
    'registration.MentorProfile01_Fail_error2'
  )
  if (passwordError != null) {
    errors.password = passwordError
  }

  if (!passwordsMatch(repeatedPassword, password)) {
    errors.repeatedPassword = 'registration.MentorProfile01_Fail_error3'
  }

  if (!recaptcha) {
    errors.recaptcha = 'general.recaptchaValidationFailed'
  } else if (recaptcha instanceof Error) {
    errors.recaptcha = 'general.recaptchaError'
  }

  return errors
}

export function mapServerErrors(error: ServerError) {
  const errors: RegistrationContactInfoErrors & RegistrationUserDetailErrors = {
    generic: '',
    name: '',
    surname: '',
    phone: '',
    zip: '',
    community: '',
    referrer: '',
    title: '',
    email: '',
    password: '',
    repeatedPassword: '',
    recaptcha: ''
  }

  if (error.status !== 400 && error.status !== 409) {
    errors.generic = 'registration.MentorProfile_ServerError_generic'
    return errors
  }
  error.identifiers.forEach(identifier => {
    if (identifier === TITLE_ERROR_IDENTIFIER) {
      errors.title = errorFields[identifier]
    }
    if (identifier === ADULT_FIRST_NAME_ERROR_IDENTIFIER) {
      errors.name = errorFields[identifier]
    }
    if (identifier === ADULT_LAST_NAME_ERROR_IDENTIFIER) {
      errors.surname = errorFields[identifier]
    }
    if (identifier === PHONE_NUMBER_ERROR_IDENTIFIER) {
      errors.phone = errorFields[identifier]
    }
    if (
      identifier === COMMUNITY_ID_ERROR_IDENTIFIER ||
      identifier === COMMUNITY_NOT_FOUND_ERROR_IDENTIFIER
    ) {
      errors.community = errorFields[identifier]
    }
    if (
      identifier === ZIP_ERROR_IDENTIFIER ||
      identifier === ZIP_NOT_ASSOCIATED_WITH_COMMUNITY_ERROR_IDENTIFIER
    ) {
      errors.zip = errorFields[identifier]
    }
    if (identifier === REFERRER_ERROR_IDENTIFIER) {
      errors.referrer = errorFields[identifier]
    }
    if (
      identifier === EMAIL_ERROR_IDENTIFIER ||
      identifier === EMAIL_MALFORMED_ERROR_IDENTIFIER
    ) {
      errors.email = errorFields[identifier]
    }
    if (identifier === PASSWORD_INVALID_ERROR_IDENTIFIER) {
      errors.password = errorFields[identifier]
    }
    if (
      identifier === RECAPTCHA_ERROR_IDENTIFIER ||
      identifier === RECAPTCHA_VERIFICATION_ERROR_IDENTIFIER ||
      identifier === RECAPTCHA_REQUEST_TOKEN_ERROR_IDENTIFIER
    ) {
      errors.recaptcha = errorFields[identifier]
    }
  })
  return errors
}

export function isValid(
  result:
    | RegistrationUserDetailErrors
    | RegistrationOrganizationMembershipErrors
    | RegistrationContactInfoErrors
) {
  return !Object.values(result).some(value => Boolean(value))
}
