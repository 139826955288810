import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {authorizationHeaderFromAccessToken} from '../auth'
import {API_URL} from '../config'
import Mentor, {Mentor as MentorT, MentorId} from '../domain/mentor'
import {captureException} from '../errors'
import ServerError from '../errors/server'
import {getAccessToken} from '../store/authentication/authentication.selectors'

type Options = Readonly<{
  mentorId: MentorId
  onError?: (error: Error) => void
  onSuccess?: (mentor: Readonly<MentorT>) => void
}>

function useMentor({mentorId, onError, onSuccess}: Options): {
  error: Error | null
  isLoading: boolean
  mentor: MentorT | undefined
  refetchMentor: () => void
} {
  const accessToken = useSelector(getAccessToken)
  const [mentor, setMentor] = useState<Readonly<MentorT> | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchMentor = useCallback(() => {
    setError(null)
    setIsLoading(true)
    getMentor(accessToken, mentorId)
      .then(mentor => {
        setMentor(mentor)
        onSuccess?.(mentor)
      })
      .catch(error => {
        captureException(error)
        setError(error)
        onError?.(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accessToken, onError, onSuccess, mentorId])

  useEffect(() => {
    fetchMentor()
  }, [fetchMentor])

  return {error, isLoading, mentor, refetchMentor: fetchMentor}
}

async function getMentor(
  accessToken: string,
  mentorId: MentorId
): Promise<Readonly<MentorT>> {
  const url = new URL(`mentors/${mentorId}`, API_URL)
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...authorizationHeaderFromAccessToken(accessToken)
    }
  })
  if (response.status !== 200) {
    return ServerError.fromResponse(response)
  }

  const body = await response.json()

  return parseResponseBody(body)
}

export function parseResponseBody(candidate: unknown): Readonly<MentorT> {
  if (typeof candidate !== 'object' || candidate === null) {
    throw new Error('Candidate is not an object')
  }
  try {
    return Mentor.parse(candidate)
  } catch (error) {
    throw new Error(
      `Unexpected response body. Expected a Mentor. ${error.message}`,
      {cause: error}
    )
  }
}

export default useMentor
