import * as COMMUNITY_STATUSES from './communityStatuses'
import {ACTIVE, INACTIVE} from './communityStatuses'

type CommunityStatus = ValueOf<typeof COMMUNITY_STATUSES>

const EXPECTED_COMMUNITY_STATUSES = Object.values(COMMUNITY_STATUSES)

/**
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is an expected {@link CommunityStatus}.
 */
function isValid(candidate: unknown): boolean {
  return candidate === ACTIVE || candidate === INACTIVE
}

/**
 * Parse a {@link CommunityStatus} from a candidate of unknown type.
 *
 * @param candidate The candidate to parse.
 * @return A valid typed {@link CommunityStatus}.
 */
function parse(candidate: unknown): CommunityStatus {
  if (candidate !== ACTIVE && candidate !== INACTIVE) {
    throw new Error(
      `Unexpected CommunityStatus ${candidate}, expected one of ${EXPECTED_COMMUNITY_STATUSES.join(
        ', '
      )}`
    )
  }

  return candidate
}

const CommunityStatus = {isValid, parse}
export default CommunityStatus
