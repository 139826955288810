import React from 'react'
import {SponsorImageKey} from '../../../store/sponsorImages/sponsorImages.types'
import LoadingIndicator from '../../LoadingIndicator'
import ImageUpload from './ImageUpload'

interface Props {
  uploadKey: SponsorImageKey
  uri: string | null
  uploading: boolean
  onUpload: (key: SponsorImageKey, image: File) => void
  onDelete: (key: SponsorImageKey) => void
  uploadStartKey?: SponsorImageKey
  uploadStartUri?: string | null
  uploadingStartKey: boolean
}

function getSponsorKeyDisplayString(uploadKey: string): string {
  const map: {[key: string]: string} = {
    MODULE_ONE: 'Module 1 - My Goals',
    LESSON_ONE_ONE_START: 'Lesson 1.1',
    LESSON_ONE_ONE_COMPLETION: 'Lesson 1.1 - Being an Entrepreneur',
    LESSON_ONE_TWO_START: 'Lesson 1.2',
    LESSON_ONE_TWO_COMPLETION: 'Lesson 1.2 - Spend, Save & Share',
    MODULE_TWO: 'Module 2 - My Plan',
    LESSON_TWO_ONE_START: 'Lesson 2.1',
    LESSON_TWO_ONE_COMPLETION: 'Lesson 2.1 - Business Plan',
    LESSON_TWO_TWO_START: 'Lesson 2.2',
    LESSON_TWO_TWO_COMPLETION: 'Lesson 2.2 - My Team',
    LESSON_TWO_THREE_START: 'Lesson 2.3',
    LESSON_TWO_THREE_COMPLETION: 'Lesson 2.3 - Finding my Location',
    LESSON_TWO_FOUR_START: 'Lesson 2.4',
    LESSON_TWO_FOUR_COMPLETION: 'Lesson 2.4 - The Customer',
    LESSON_TWO_FIVE_START: 'Lesson 2.5',
    LESSON_TWO_FIVE_COMPLETION: 'Lesson 2.5 - Customer Service',
    LESSON_TWO_SIX_START: 'Lesson 2.6',
    LESSON_TWO_SIX_COMPLETION: 'Lesson 2.6 - My Expenses',
    LESSON_TWO_SEVEN_START: 'Lesson 2.7',
    LESSON_TWO_SEVEN_COMPLETION: 'Lesson 2.7 - Designing a Product',
    LESSON_TWO_EIGHT_START: 'Lesson 2.8',
    LESSON_TWO_EIGHT_COMPLETION: 'Lesson 2.8 - Designing a Stand',
    LESSON_TWO_NINE_START: 'Lesson 2.9',
    LESSON_TWO_NINE_COMPLETION: 'Lesson 2.9 - Find your Twist',
    LESSON_TWO_TEN_START: 'Lesson 2.10',
    LESSON_TWO_TEN_COMPLETION: 'Lesson 2.10 - Advertising',
    LESSON_TWO_ELEVEN_START: 'Lesson 2.11',
    LESSON_TWO_ELEVEN_COMPLETION: 'Lesson 2.11 - Business Plan',
    MODULE_THREE: 'Module 3 - My Stand',
    LESSON_THREE_ONE_START: 'Lesson 3.1',
    LESSON_THREE_ONE_COMPLETION: 'Lesson 3.1 - Getting an Investor',
    LESSON_THREE_TWO_START: 'Lesson 3.2',
    LESSON_THREE_TWO_COMPLETION: 'Lesson 3.2 - Supply Shopping',
    LESSON_THREE_THREE_START: 'Lesson 3.3',
    LESSON_THREE_THREE_COMPLETION: 'Lesson 3.3 - Making it Happen',
    LESSON_THREE_FOUR_START: 'Lesson 3.4',
    LESSON_THREE_FOUR_COMPLETION: 'Lesson 3.4 - Last Minute Preparations',
    LESSON_THREE_FIVE_START: 'Lesson 3.5',
    LESSON_THREE_FIVE_COMPLETION: 'Lesson 3.5 - Lemonade Day',
    MODULE_FOUR: 'Module 4 - My Results',
    LESSON_FOUR_ONE_START: 'Lesson 4.1',
    LESSON_FOUR_ONE_COMPLETION: 'Lesson 4.1 - Reflection',
    LESSON_FOUR_TWO_START: 'Lesson 4.2',
    LESSON_FOUR_TWO_COMPLETION: 'Lesson 4.2 - Report to your Investor',
    LESSON_FOUR_THREE_START: 'Lesson 4.3',
    LESSON_FOUR_THREE_COMPLETION: 'Lesson 4.3 - Splitting the Profit'
  }
  return map[uploadKey]
}

const SponsorshipSlot = ({
  uploadKey,
  uri,
  uploading,
  onUpload,
  onDelete,
  uploadStartKey,
  uploadStartUri = null,
  uploadingStartKey
}: Props) => {
  const isModuleRow = uploadKey.includes('MODULE')

  return (
    <tr
      className={
        isModuleRow
          ? 'border-y border-grey-tertiary-30 bg-grey-tertiary-10'
          : ' border-t border-grey-tertiary-10'
      }
    >
      {isModuleRow ? (
        <th
          colSpan={2}
          scope="colgroup"
          className="py-2 pl-4 pr-3 text-left sm:pl-3"
        >
          {getSponsorKeyDisplayString(uploadKey)}
        </th>
      ) : (
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-left sm:pl-3">
          {getSponsorKeyDisplayString(uploadKey)}
        </td>
      )}
      {!isModuleRow && uploadStartKey && (
        <td className="px-3 py-3.5">
          {uploadingStartKey ? (
            <div className="flex h-full w-full justify-start">
              <LoadingIndicator />
            </div>
          ) : (
            <ImageUpload
              uploadKey={uploadStartKey}
              uri={uploadStartUri}
              onUpload={onUpload}
              onDelete={onDelete}
            />
          )}
        </td>
      )}
      <td className="px-3 py-3.5">
        {uploading ? (
          <div className="flex h-full w-full justify-start">
            <LoadingIndicator />
          </div>
        ) : (
          <ImageUpload
            uploadKey={uploadKey}
            uri={uri}
            onUpload={onUpload}
            onDelete={onDelete}
          />
        )}
      </td>
    </tr>
  )
}

export default SponsorshipSlot
