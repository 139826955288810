import React, {useState} from 'react'
import Footer from './Footer'
import showFooterStore from './Footer/showFooterStore'
import Header from './Header'

type Props = Readonly<{
  children?: React.ReactNode
  control?: React.ReactNode
  hasFooter?: boolean
  onHowToAddLearners?: () => void
  title: React.ReactNode
  toast?: React.ReactNode
}>

function Layout({
  children,
  control = null,
  hasFooter = false,
  title,
  onHowToAddLearners,
  toast = null
}: Props) {
  const [showFooter, setShowFooter] = useState<boolean>(
    showFooterStore.getShowFooter()
  )
  const footerOpen = hasFooter && showFooter
  return (
    <div className="flex min-h-screen flex-col items-center bg-[#EBF0F3]">
      <Header
        control={control}
        title={title}
        onHowToAddLearners={() => onHowToAddLearners?.()}
      />
      <main className={`${footerOpen ? 'md:pb-28' : 'pb-0'} w-full`}>
        {toast && toast}
        {children}
      </main>
      <Footer
        onFooterClose={() => {
          showFooterStore.setShowFooter(false)
          setShowFooter(false)
        }}
        open={footerOpen}
      />
    </div>
  )
}

export default Layout
