export const SHOW_FOOTER_STORAGE_KEY = 'showFooter'

function getShowFooter(): boolean {
  const showFooter = sessionStorage.getItem(SHOW_FOOTER_STORAGE_KEY)
  return showFooter == null ? true : showFooter === 'true'
}

function setShowFooter(showFooter: boolean) {
  sessionStorage.setItem(SHOW_FOOTER_STORAGE_KEY, showFooter.toString())
}

const showFooterStore = {getShowFooter, setShowFooter}
export default showFooterStore
