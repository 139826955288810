const MLD_MENTOR_GUIDE = '92f2a7bc-0c72-4e87-86dd-1cc439dffec1'
const TEACHER_GUIDE_YOUTH = '577ad12c-b757-42a1-8bb1-3628c5a48a1c'
const TEACHER_GUIDE_TEEN = '5c8fda32-90d1-4235-9418-a4d6b4f706cc'
const PROGRAM_TIMELINE = '1f1ac771-af61-42da-8626-3cab7f833bb4'
const IMPLEMENTATION_GUIDE = '421c624a-0896-4e36-ba06-7e71ae316945'
const GENERAL_FAQ_EN_US = '2222065e-6069-4683-8d94-7f42f43b2742'
const GENERAL_FAQ_ES_MX = '81ff8169-2087-418c-b6eb-81126290b92d'
const MENTOR_FAQ_EN_US = '2c550233-9681-4db4-968e-970bad481ca2'
const MENTOR_FAQ_ES_MX = 'a6062999-3cdc-403f-bf36-9c21743aa79b'
const JOURNAL = 'a6a6d259-b612-45e8-8cb9-f59f550ba6e4'
const COMMON_CORE_TEKS = '71dba616-3258-4f02-9bf3-82e17c38880c'
const FAITH_MENTOR_GUIDE_EN_US = 'fa648091-a063-4ce8-a9a3-19bd1b526756'
const FAITH_MENTOR_GUIDE_ES_MX = 'adb1e3d7-9dd0-456d-b305-15dd0e8b5d70'
const TEACHER_SUITE = '4137f6bd-7f98-4251-93a7-45e0d91952c5'
const SUPPLY_LIST = '9445f11c-cd15-4aed-8346-dbd50b5cee4e'
const PACKING_LIST = '7ad7ad46-eafb-401a-bc48-ddbca0038daf'
const CUSTOMER_SERVICE_QUESTIONS = '7826440a-d451-4cbf-b2a0-a35798e095ad'
const JUNIOR_MARKET_JOURNAL = 'f0f8b815-d566-4e65-b857-fa4ef748905f'
const OFFICIAL_STAND_SIGN = 'e964f4e8-a9df-4da1-8938-01f7ec222dd3'

export interface IdentifierKey {
  [identifier: string]: string
}

export const educationResourceKeys: IdentifierKey = {
  [MLD_MENTOR_GUIDE]: 'MLD_MENTOR_GUIDE',
  [TEACHER_GUIDE_YOUTH]: 'TEACHER_GUIDE_YOUTH',
  [TEACHER_GUIDE_TEEN]: 'TEACHER_GUIDE_TEEN',
  [PROGRAM_TIMELINE]: 'PROGRAM_TIMELINE',
  [IMPLEMENTATION_GUIDE]: 'IMPLEMENTATION_GUIDE',
  [GENERAL_FAQ_EN_US]: 'GENERAL_FAQ_EN_US',
  [GENERAL_FAQ_ES_MX]: 'GENERAL_FAQ_ES_MX',
  [MENTOR_FAQ_EN_US]: 'MENTOR_FAQ_EN_US',
  [MENTOR_FAQ_ES_MX]: 'MENTOR_FAQ_ES_MX',
  [JOURNAL]: 'JOURNAL',
  [COMMON_CORE_TEKS]: 'COMMON_CORE_TEKS',
  [FAITH_MENTOR_GUIDE_EN_US]: 'FAITH_MENTOR_GUIDE_EN_US',
  [FAITH_MENTOR_GUIDE_ES_MX]: 'FAITH_MENTOR_GUIDE_ES_MX',
  [TEACHER_SUITE]: 'TEACHER_SUITE',
  [SUPPLY_LIST]: 'SUPPLY_LIST',
  [PACKING_LIST]: 'PACKING_LIST',
  [CUSTOMER_SERVICE_QUESTIONS]: 'CUSTOMER_SERVICE_QUESTIONS',
  [JUNIOR_MARKET_JOURNAL]: 'JUNIOR_MARKET_JOURNAL',
  [OFFICIAL_STAND_SIGN]: 'OFFICIAL_STAND_SIGN'
}
