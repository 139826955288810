import React from 'react'
import styled from 'styled-components'
import coloursMap from '../../colours'

interface StyleProps {
  colour: 'yellow' | 'turquoise' | 'green' | 'red'
  disabled?: boolean
}

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & StyleProps

const StyledButtonContainer = styled.div`
  display: inline-block;
  position: relative;
`

export const StyledButton = styled.button<StyleProps>`
  font-size: 1rem;
  font-family: 'Roboto Slab', sans-serif;
  color: ${props =>
    props.colour === 'yellow'
      ? coloursMap.colours.darkText
      : coloursMap.colours.white};
  text-align: center;
  text-decoration: none;
  padding: 0 2rem;
  line-height: 34px;
  display: inline-block;
  border-radius: 18px;
  border-width: 0;
  pointer-events: ${props => (props.disabled ? 'none' : 'unset')};
  background-color: ${props =>
    coloursMap.components.buttons[props.colour].main};
  box-shadow: 0 2px 0
    ${props => coloursMap.components.buttons[props.colour].shadow};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  margin-bottom: 2px;

  :active {
    transform: translateY(2px);
    box-shadow: none;
  }

  :focus {
    outline: none;
  }
`

function Button(props: Props) {
  return (
    <StyledButtonContainer>
      <StyledButton {...props} />
    </StyledButtonContainer>
  )
}

export default Button
