import produce from 'immer'
import {Reducer} from 'redux'
import {RECEIVE_USER_LOG_IN} from '../authentication/authentication.action'
import {CREATE_MENTOR} from '../mentors/mentors.action'
import {AppActions} from '../store'
import {UPDATE_LOCALE} from '../ui/ui.actions'
import {ATTEMPT_UPDATE_ADULT, UPDATE_ADULT} from './adults.action'
import {Adult} from './adults.type'

export interface AdultState {
  byId: {
    [id: string]: Adult
  }
  updating: boolean
}

const initialState = {
  error: null,
  updating: false,
  byId: {}
}

const reducer: Reducer<AdultState, AppActions> = (
  state = initialState,
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case RECEIVE_USER_LOG_IN:
        if (!action.error) {
          if ('code' in action.payload.adult) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {code, ...actionAdult} = action.payload.adult
            draft.byId[actionAdult.id] = actionAdult
            break
          }
          draft.byId[action.payload.adult.id] = action.payload.adult
        }
        break
      case UPDATE_LOCALE:
        if (!action.error && action.meta.adultId != null) {
          const adult = draft.byId[action.meta.adultId]
          if (adult != null) {
            adult.locale = action.payload
          }
        }
        break
      case ATTEMPT_UPDATE_ADULT:
        draft.updating = true
        break
      case UPDATE_ADULT:
        draft.updating = false
        if (!action.error) {
          draft.byId[action.payload.id] = action.payload
        }
        break
      case CREATE_MENTOR:
        if (!action.error) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const {code, ...adult} = action.payload
          draft.byId[adult.id] = adult
        }
        break
    }
  })

export default reducer
