import {getPossibleAdult} from '../adults/adults.selectors'
import {Adult} from '../adults/adults.type'
import {AppState} from '../store'
import {Mentor} from './mentors.type'

type MentorInState =
  | {isMentor: false; adult: Adult | null}
  | {isMentor: true; adult: Mentor}

/**
 * Returns mentor ONLY if mentor exists in state, or throws an error
 */
export function getMentor(state: AppState) {
  const stateValues = findMentor(state)

  if (stateValues.adult == null) {
    throw new Error('No Adult in state')
  }

  if (!stateValues.isMentor) {
    throw new Error(`Adult ${stateValues.adult.id} is not a mentor`)
  }

  return stateValues.adult
}

/**
 * Returns null if there is no mentor in state
 * Should ONLY be used for mentor-specific functions that may be used in a component shared by admin
 */
export function getPossibleMentor(state: AppState) {
  const stateValues = findMentor(state)
  return stateValues.isMentor ? stateValues.adult : null
}

/**
 * Private function to determine the different possible results of the mentor type
 */
function findMentor(state: AppState): MentorInState {
  const adult = getPossibleAdult(state)

  if (adult == null) {
    return {
      isMentor: false,
      adult: null
    }
  }

  const mentorSpecificFields = state.mentors.byId[adult.id]
  const hasMentorRole = state.auth.roles.some(role => role === 'ROLE_MENTOR')

  if (mentorSpecificFields == null && !hasMentorRole) {
    return {
      isMentor: false,
      adult
    }
  }

  if (mentorSpecificFields != null && hasMentorRole) {
    return {
      isMentor: true,
      adult: {...adult, ...mentorSpecificFields}
    }
  }

  // The adult has the ROLE_MENTOR role *or* mentor state exists for the adult,
  // but not both (xor). This is an invalid state which should not be allowed.
  throw new Error('Roles and mentors state do not match')
}
