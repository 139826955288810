import React, {useCallback, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useHistory, useRouteMatch} from 'react-router'
import Community, {CommunityId} from '../../domain/community'
import {DUPLICATE_COMMUNITY_ERROR_IDENTIFIER} from '../../errors/errorIdentifiers'
import ServerError from '../../errors/server'
import {
  COMMUNITIES_ROUTE,
  COMMUNITIES_UPDATED_COMMUNITY_ID_TEMPLATE_ROUTE,
  COMMUNITY_ID_ROUTE_PLACEHOLDER,
  COMMUNITY_TOOL_SELECTOR_ROUTE,
  EVENT_ID_ROUTE_PLACEHOLDER,
  TOOL_SELECTOR_ROUTE
} from '../../global-constants/routingConstants'
import useCommunity from '../../hooks/useCommunity'
import Breadcrumb from '../Breadcrumb'
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem'
import Layout from '../Layout'
import MentorRegistrationLink from '../links/MentorRegistrationLink'
import LoadingIndicator from '../LoadingIndicator'
import TitleText from '../TitleText'
import Toast from '../Toast'
import EditCommunityForm from './EditCommunityForm'

function EditCommunityPage() {
  const {
    params: {communityId}
  } = useRouteMatch<{communityId: CommunityId}>()
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [validationErrorCount, setValidationErrorCount] = useState(0)
  const history = useHistory()
  const {isLoading, community, zips} = useCommunity({
    communityId,
    onError: useCallback(error => setError(error), [setError])
  })
  let communityToolSelectorRoute = COMMUNITY_TOOL_SELECTOR_ROUTE.replace(
    COMMUNITY_ID_ROUTE_PLACEHOLDER,
    communityId
  )
  let communityName = ''
  if (community) {
    communityToolSelectorRoute = communityToolSelectorRoute.replace(
      EVENT_ID_ROUTE_PLACEHOLDER,
      community.currentEventId
    )
    communityName = Community.formatName(community)
  }

  function onCancel() {
    history.push(COMMUNITIES_ROUTE)
  }

  function onUpdateSuccess(message: string) {
    setSuccessMessage(message)
    history.push(
      COMMUNITIES_UPDATED_COMMUNITY_ID_TEMPLATE_ROUTE.replace(
        ':communityId',
        communityId
      )
    )
  }

  return (
    <Layout
      title={
        <TitleText>
          <FormattedMessage id="manageCommunity.community_manager" />
        </TitleText>
      }
      toast={
        <EditCommunityToast
          error={error}
          validationErrorCount={validationErrorCount}
          successMessage={successMessage}
        />
      }
    >
      <div
        className="
          mx-auto mb-2 w-full space-y-2 px-2
          md:mb-4 md:space-y-4 md:px-4 lg:mb-6 lg:space-y-6 lg:px-6
          xl:mb-8 xl:max-w-7xl xl:space-y-8 xl:px-8
        "
      >
        <Breadcrumb>
          <BreadcrumbItem
            path={TOOL_SELECTOR_ROUTE}
            to={TOOL_SELECTOR_ROUTE}
            label={<FormattedMessage id="admin.toolSelector.title" />}
          />
          <BreadcrumbItem
            path={COMMUNITIES_ROUTE}
            to={COMMUNITIES_ROUTE}
            label={<FormattedMessage id="manageCommunity.community_manager" />}
          />
          <BreadcrumbItem
            path={COMMUNITY_TOOL_SELECTOR_ROUTE}
            to={communityToolSelectorRoute}
            label={communityName}
          />
        </Breadcrumb>
        <div className="my-2 flex justify-between md:my-4 lg:my-6 xl:my-8">
          <h2 className="typography-headline-4 whitespace-nowrap">
            <FormattedMessage id="admin.edit_community.heading" />
          </h2>
          <MentorRegistrationLink communityId={communityId} />
        </div>
        {community && zips ? (
          <EditCommunityForm
            busy={isLoading}
            community={community}
            onCancel={onCancel}
            onError={setError}
            onSuccess={onUpdateSuccess}
            onValidationFailure={setValidationErrorCount}
            zips={zips}
          />
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </Layout>
  )
}
type ToastProps = {
  error?: Error | null
  validationErrorCount: number
  successMessage?: string | null
}

function EditCommunityToast({
  error = null,
  validationErrorCount = 0,
  successMessage
}: ToastProps) {
  return (
    <>
      {error && (
        <Toast errorCount={1} variant="error">
          {ServerError.includes(error, DUPLICATE_COMMUNITY_ERROR_IDENTIFIER) ? (
            <FormattedMessage id="admin.communities.duplicateCommunity" />
          ) : (
            <FormattedMessage id="general.GenericError" />
          )}
        </Toast>
      )}
      {validationErrorCount > 0 && (
        <Toast errorCount={validationErrorCount} variant="error">
          <FormattedMessage id="admin.communities.validationMessage" />
        </Toast>
      )}
      {successMessage && <Toast variant="success">{successMessage}</Toast>}
    </>
  )
}

export default EditCommunityPage
