import React, {ReactChild} from 'react'
import styled from 'styled-components'
import colours from '../../../colours'
import * as Typography from '../Typography'

const StyledErrorWrapper = styled.div`
  margin-top: 0.25rem;
  margin-left: 1rem;
`

const StyledError = styled(Typography.Body4)`
  color: ${colours.colours.errorRed};
`

function Error({children}: {children: ReactChild}) {
  return (
    <StyledErrorWrapper>
      <StyledError>{children}</StyledError>
    </StyledErrorWrapper>
  )
}

export default Error
