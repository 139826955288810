import React from 'react'
import styled from 'styled-components'
import ForgotPasswordForm from '../../containers/ForgotPasswordForm.container'
import Background from '../Background'
import Layout from '../Layout'

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 18px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  max-width: 468px;
`

function ForgotPasswordPage() {
  return (
    <Layout noShadow>
      <Content>
        <Background />
        <FormWrapper>
          <ForgotPasswordForm />
        </FormWrapper>
      </Content>
    </Layout>
  )
}

export default ForgotPasswordPage
