import {IntlShape} from 'react-intl'
import {passwordsMatch, validatePassword} from '../../../validations'

export type ResetPasswordErrors = {
  password: string
  repeatedPassword: string
}

export function validate(
  password: string,
  repeatedPassword: string,
  intl: IntlShape
): ResetPasswordErrors {
  const errors: ResetPasswordErrors = {
    password: '',
    repeatedPassword: ''
  }

  const passwordError = validatePassword(
    password,
    'registration.resetPassword_Fail_error2',
    'registration.resetPassword_Fail_error1'
  )
  if (passwordError) {
    errors.password = intl.formatMessage({
      id: passwordError
    })
  }
  if (!passwordsMatch(repeatedPassword, password)) {
    errors.repeatedPassword = intl.formatMessage({
      id: 'registration.resetPassword_Fail_error3'
    })
  }

  return errors
}

export function isValid(result: ResetPasswordErrors): boolean {
  return !Object.values(result).some(value => Boolean(value))
}
