import {isNonBlankString} from '../responseRefiners'
import CountryCode from './countryCode'
import {STATES_BY_COUNTRY_CODE, STATE_CODES} from './statesCodes'

/**
 * ISO_3166-2 code identifying the state.
 */
type StateCode<C extends CountryCode = CountryCode> =
  typeof STATES_BY_COUNTRY_CODE[C][number]['code']

/**
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is an expected ISO 3166-2 state code.
 */
function isValid(candidate: unknown): candidate is StateCode {
  return (
    isNonBlankString(candidate) &&
    STATE_CODES.find(code => code === candidate.trim()) != null
  )
}

/**
 * Parse a {@link StateCode} from a candidate of unknown type.
 *
 * @param candidate The candidate to parse.
 * @return A valid typed {@link StateCode}.
 */
function parse(candidate: unknown): StateCode {
  if (!isNonBlankString(candidate)) {
    throw new Error(`StateCode may not be blank or null`)
  }

  const validatedStateCode = STATE_CODES.find(code => code === candidate.trim())

  if (validatedStateCode == null) {
    throw new Error(
      `Unexpected StateCode ${candidate}, expected one of ${STATE_CODES.join(
        ', '
      )}`
    )
  }

  return validatedStateCode
}

const StateCode = {isValid, parse}
export default StateCode
