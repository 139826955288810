import * as api from '../../api'
import {AppThunkAction} from '../store'
import {
  AttemptGetLearnersAction,
  CreateLearner,
  CreateLearnerAction,
  GetLearnersAction
} from './learners.type'
import {getLearnerPageId, LearnerPageParams} from './learners.utils'

export const CREATE_LEARNER = 'learner/CREATE_LEARNER'
export const ATTEMPT_GET_LEARNERS = 'learner/ATTEMPT_GET_LEARNERS'
export const GET_LEARNERS = 'learner/GET_LEARNERS'

export function createLearner(
  data: CreateLearner
): AppThunkAction<CreateLearnerAction> {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      const learnerDTO = await api.createLearner(data, state)

      dispatch({type: CREATE_LEARNER, payload: learnerDTO})
    } catch (error) {
      dispatch({type: CREATE_LEARNER, error: true, payload: error})
      throw error
    }
  }
}

export function getLearners(
  params: LearnerPageParams
): AppThunkAction<AttemptGetLearnersAction | GetLearnersAction> {
  return async (dispatch, getState) => {
    const pageId = getLearnerPageId(params)
    const state = getState()
    const {
      learners: {fetching}
    } = getState()

    if (!fetching) {
      dispatch({type: ATTEMPT_GET_LEARNERS, meta: {pageId}})

      try {
        const learnerDTOs = await api.getLearners(state)
        dispatch({type: GET_LEARNERS, payload: learnerDTOs, meta: {pageId}})
      } catch (error) {
        dispatch({
          type: GET_LEARNERS,
          error: true,
          payload: error,
          meta: {pageId}
        })
        throw error
      }
    }
  }
}
