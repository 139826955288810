import StateCode from './stateCode'

const CA_STATES = [
  {code: 'CA-AB', nameMessageId: 'general.state.ca-ab'},
  {code: 'CA-BC', nameMessageId: 'general.state.ca-bc'},
  {code: 'CA-MB', nameMessageId: 'general.state.ca-mb'},
  {code: 'CA-NB', nameMessageId: 'general.state.ca-nb'},
  {code: 'CA-NL', nameMessageId: 'general.state.ca-nl'},
  {code: 'CA-NS', nameMessageId: 'general.state.ca-ns'},
  {code: 'CA-NT', nameMessageId: 'general.state.ca-nt'},
  {code: 'CA-NU', nameMessageId: 'general.state.ca-nu'},
  {code: 'CA-ON', nameMessageId: 'general.state.ca-on'},
  {code: 'CA-PE', nameMessageId: 'general.state.ca-pe'},
  {code: 'CA-QC', nameMessageId: 'general.state.ca-qc'},
  {code: 'CA-SK', nameMessageId: 'general.state.ca-sk'},
  {code: 'CA-YT', nameMessageId: 'general.state.ca-yt'}
] as const

const MX_STATES = [
  {code: 'MX-AGU', nameMessageId: 'general.state.mx-agu'},
  {code: 'MX-BCN', nameMessageId: 'general.state.mx-bcn'},
  {code: 'MX-BCS', nameMessageId: 'general.state.mx-bcs'},
  {code: 'MX-CAM', nameMessageId: 'general.state.mx-cam'},
  {code: 'MX-CHH', nameMessageId: 'general.state.mx-chh'},
  {code: 'MX-CHP', nameMessageId: 'general.state.mx-chp'},
  {code: 'MX-COA', nameMessageId: 'general.state.mx-coa'},
  {code: 'MX-COL', nameMessageId: 'general.state.mx-col'},
  {code: 'MX-DUR', nameMessageId: 'general.state.mx-dur'},
  {code: 'MX-GRO', nameMessageId: 'general.state.mx-gro'},
  {code: 'MX-GUA', nameMessageId: 'general.state.mx-gua'},
  {code: 'MX-HID', nameMessageId: 'general.state.mx-hid'},
  {code: 'MX-JAL', nameMessageId: 'general.state.mx-jal'},
  {code: 'MX-MEX', nameMessageId: 'general.state.mx-mex'},
  {code: 'MX-MIC', nameMessageId: 'general.state.mx-mic'},
  {code: 'MX-MOR', nameMessageId: 'general.state.mx-mor'},
  {code: 'MX-NAY', nameMessageId: 'general.state.mx-nay'},
  {code: 'MX-NLE', nameMessageId: 'general.state.mx-nle'},
  {code: 'MX-OAX', nameMessageId: 'general.state.mx-oax'},
  {code: 'MX-PUE', nameMessageId: 'general.state.mx-pue'},
  {code: 'MX-QUE', nameMessageId: 'general.state.mx-que'},
  {code: 'MX-ROO', nameMessageId: 'general.state.mx-roo'},
  {code: 'MX-SIN', nameMessageId: 'general.state.mx-sin'},
  {code: 'MX-SLP', nameMessageId: 'general.state.mx-slp'},
  {code: 'MX-SON', nameMessageId: 'general.state.mx-son'},
  {code: 'MX-TAB', nameMessageId: 'general.state.mx-tab'},
  {code: 'MX-TAM', nameMessageId: 'general.state.mx-tam'},
  {code: 'MX-TLA', nameMessageId: 'general.state.mx-tla'},
  {code: 'MX-VER', nameMessageId: 'general.state.mx-ver'},
  {code: 'MX-YUC', nameMessageId: 'general.state.mx-yuc'},
  {code: 'MX-ZAC', nameMessageId: 'general.state.mx-zac'}
] as const

const US_STATES = [
  {code: 'US-AK', nameMessageId: 'general.state.us-ak'},
  {code: 'US-AL', nameMessageId: 'general.state.us-al'},
  {code: 'US-AR', nameMessageId: 'general.state.us-ar'},
  {code: 'US-AZ', nameMessageId: 'general.state.us-az'},
  {code: 'US-CA', nameMessageId: 'general.state.us-ca'},
  {code: 'US-CO', nameMessageId: 'general.state.us-co'},
  {code: 'US-CT', nameMessageId: 'general.state.us-ct'},
  {code: 'US-DE', nameMessageId: 'general.state.us-de'},
  {code: 'US-FL', nameMessageId: 'general.state.us-fl'},
  {code: 'US-GA', nameMessageId: 'general.state.us-ga'},
  {code: 'US-HI', nameMessageId: 'general.state.us-hi'},
  {code: 'US-IA', nameMessageId: 'general.state.us-ia'},
  {code: 'US-ID', nameMessageId: 'general.state.us-id'},
  {code: 'US-IL', nameMessageId: 'general.state.us-il'},
  {code: 'US-IN', nameMessageId: 'general.state.us-in'},
  {code: 'US-KS', nameMessageId: 'general.state.us-ks'},
  {code: 'US-KY', nameMessageId: 'general.state.us-ky'},
  {code: 'US-LA', nameMessageId: 'general.state.us-la'},
  {code: 'US-MA', nameMessageId: 'general.state.us-ma'},
  {code: 'US-MD', nameMessageId: 'general.state.us-md'},
  {code: 'US-ME', nameMessageId: 'general.state.us-me'},
  {code: 'US-MI', nameMessageId: 'general.state.us-mi'},
  {code: 'US-MN', nameMessageId: 'general.state.us-mn'},
  {code: 'US-MO', nameMessageId: 'general.state.us-mo'},
  {code: 'US-MS', nameMessageId: 'general.state.us-ms'},
  {code: 'US-MT', nameMessageId: 'general.state.us-mt'},
  {code: 'US-NC', nameMessageId: 'general.state.us-nc'},
  {code: 'US-ND', nameMessageId: 'general.state.us-nd'},
  {code: 'US-NE', nameMessageId: 'general.state.us-ne'},
  {code: 'US-NH', nameMessageId: 'general.state.us-nh'},
  {code: 'US-NJ', nameMessageId: 'general.state.us-nj'},
  {code: 'US-NM', nameMessageId: 'general.state.us-nm'},
  {code: 'US-NV', nameMessageId: 'general.state.us-nv'},
  {code: 'US-NY', nameMessageId: 'general.state.us-ny'},
  {code: 'US-OH', nameMessageId: 'general.state.us-oh'},
  {code: 'US-OK', nameMessageId: 'general.state.us-ok'},
  {code: 'US-OR', nameMessageId: 'general.state.us-or'},
  {code: 'US-PA', nameMessageId: 'general.state.us-pa'},
  {code: 'US-RI', nameMessageId: 'general.state.us-ri'},
  {code: 'US-SC', nameMessageId: 'general.state.us-sc'},
  {code: 'US-SD', nameMessageId: 'general.state.us-sd'},
  {code: 'US-TN', nameMessageId: 'general.state.us-tn'},
  {code: 'US-TX', nameMessageId: 'general.state.us-tx'},
  {code: 'US-UT', nameMessageId: 'general.state.us-ut'},
  {code: 'US-VA', nameMessageId: 'general.state.us-va'},
  {code: 'US-VT', nameMessageId: 'general.state.us-vt'},
  {code: 'US-WA', nameMessageId: 'general.state.us-wa'},
  {code: 'US-WI', nameMessageId: 'general.state.us-wi'},
  {code: 'US-WV', nameMessageId: 'general.state.us-wv'},
  {code: 'US-WY', nameMessageId: 'general.state.us-wy'}
] as const

export const STATES_BY_COUNTRY_CODE = {
  CA: CA_STATES,
  MX: MX_STATES,
  US: US_STATES
} as const

export const STATES: ReadonlyArray<{
  code: StateCode
  nameMessageId: string
}> = Object.values(STATES_BY_COUNTRY_CODE).flat()

export const STATE_CODES: ReadonlyArray<StateCode> = STATES.map(
  state => state.code
)
