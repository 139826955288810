import {createSelector} from 'reselect'
import {AppState} from '../store'
import {
  CommunitiesByIdMap,
  CommunitiesByZipMap,
  Community,
  CommunityId
} from './communities.type'

const EMPTY_ZIP_ARRAY: Array<string> = []
const EMPTY_COMMUNITY_ID_ARRAY: Array<CommunityId> = []

const getCommunitiesByIdMap = (state: AppState): CommunitiesByIdMap =>
  state.communities.byId

const getCommunitiesByZipMap = (state: AppState): CommunitiesByZipMap =>
  state.communities.byZip

const getCommunityIdsByZip = (
  state: AppState,
  zip: string
): Array<CommunityId> => {
  const communityIds: Array<CommunityId> = getCommunitiesByZipMap(state)[zip]
  return communityIds == null ? EMPTY_COMMUNITY_ID_ARRAY : communityIds
}

export const getCommunityById = (
  state: AppState,
  communityId: CommunityId
): Community | null => {
  const community = state.communities.byId[communityId]
  return community == null ? null : community
}

export const getZipsByCommunityId = createSelector<
  AppState,
  CommunityId,
  Community | null,
  Array<string>
>(getCommunityById, community =>
  community == null ? EMPTY_ZIP_ARRAY : community.zips
)

export const getCommunitiesByZip = createSelector<
  AppState,
  string,
  Array<CommunityId>,
  CommunitiesByIdMap,
  Array<Community>
>(
  getCommunityIdsByZip,
  getCommunitiesByIdMap,
  (communityIds, communitiesByIdMap) =>
    communityIds
      .map(id => communitiesByIdMap[id])
      .filter(community => community != null)
)

export const getAllCommunities = createSelector<
  AppState,
  CommunitiesByIdMap,
  Array<Community>
>(getCommunitiesByIdMap, communitiesByIdMap =>
  Object.values(communitiesByIdMap)
)

export const getAllZips = createSelector<
  AppState,
  CommunitiesByZipMap,
  Array<string>
>(getCommunitiesByZipMap, communitiesByZipMap =>
  Object.keys(communitiesByZipMap).sort()
)
