import {isNonBlankString} from '../responseRefiners'
import CountryCode from './countryCode'
import {TIME_ZONES_BY_COUNTRY_CODE, TZ_IDENTIFIERS} from './timeZones'

type TzIdentifier<C extends CountryCode = CountryCode> =
  typeof TIME_ZONES_BY_COUNTRY_CODE[C][number]['tzIdentifier']

/**
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is an expected {@link TzIdentifier}.
 */
function isValid(candidate: unknown): boolean {
  return TZ_IDENTIFIERS.some(
    (tzIdentifier: TzIdentifier) => tzIdentifier === candidate
  )
}

/**
 * @param candidate The candidate to test.
 * @return A boolean specifying if the candidate is an expected {@link TzIdentifier}.
 */
function parse(candidate: unknown): TzIdentifier {
  if (!isNonBlankString(candidate)) {
    throw new Error(`TzIdentifier may not be blank, empty or null`)
  }

  const trimmedCandidate = candidate.trim()
  if (!isValid(trimmedCandidate)) {
    throw new Error(`Unexpected TzIdentifier`)
  }

  return trimmedCandidate as TzIdentifier
}

const TzIdentifier = {isValid, parse}
export default TzIdentifier
