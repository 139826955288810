import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {LANGUAGE_TAG_EN_US, LANGUAGE_TAG_ES_MX} from '../../../messages'
import {AppDispatch, AppState} from '../../../store/store'
import {setLocale} from '../../../store/ui/ui.actions'
import {Locale} from '../../../store/ui/ui.types'
import languageEnglishUri from './assets/language-english-icon.png'
import languageEnglishUri2X from './assets/language-english-icon@2x.png'
import languageEnglishUri3X from './assets/language-english-icon@3x.png'
import languageSpanishUri from './assets/language-spanish-icon.png'
import languageSpanishUri2X from './assets/language-spanish-icon@2x.png'
import languageSpanishUri3X from './assets/language-spanish-icon@3x.png'
import separatorUri from './assets/separator.svg'

type Language = {
  locale: Locale
  name: string
  short: string
}
const LANGUAGES: Language[] = [
  {
    locale: LANGUAGE_TAG_EN_US,
    name: 'English',
    short: 'ENG'
  },
  {
    locale: LANGUAGE_TAG_ES_MX,
    name: 'Spanish',
    short: 'ESP'
  }
]

function LanguageSelector() {
  const dispatch = useDispatch<AppDispatch>()
  const updating = useSelector<AppState>(state => state.ui.updating)
  const selectedLocale = useSelector<AppState>(state =>
    state.ui.locale ? state.ui.locale : LANGUAGE_TAG_EN_US
  )
  const selectedLanguage = LANGUAGES.find(
    language => language.locale === selectedLocale
  )
  const [selectorOpen, setSelectorOpen] = useState(false)
  if (selectedLanguage == null) {
    throw new Error(`Language not found for locale: ${selectedLocale}`)
  }

  function handleLanguageChange(locale: Locale): void {
    setSelectorOpen(false)
    dispatch(setLocale(locale))
  }

  return (
    <aside className="relative">
      <button
        className="relative flex flex-nowrap items-center rounded-full border border-grey-tertiary-10 py-1 pl-px leading-8 outline-none focus:outline-none focus-visible:ring-4 focus-visible:ring-navy"
        disabled={updating === true}
        onClick={() => {
          setSelectorOpen(!selectorOpen)
        }}
      >
        <LanguageIcon
          locale={selectedLanguage.locale}
          altText={selectedLanguage.name}
        />
        <span className="px-3">{selectedLanguage.short}</span>
      </button>
      {selectorOpen && (
        <div
          className="absolute left-0 top-11 z-30 flex min-w-fit max-w-lg flex-wrap divide-y divide-grey-tertiary-10 bg-white-primary py-1.5
          text-navy-primary shadow-[0_4px_11px] shadow-[rgba(0,0,0,0.1)] before:absolute before:-top-1 before:left-5 before:z-10 before:h-3
          before:w-3 before:rotate-45 before:bg-white-primary before:content-[''] sm:top-12 sm:right-0
          before:sm:-top-2 before:sm:right-5 md:-left-8 before:md:left-20 before:md:-top-1
        "
        >
          {LANGUAGES.map(lang => (
            <button
              className="relative z-40 flex items-center bg-white-primary py-1 px-5 hover:bg-steel-tertiary-10 focus:bg-steel-tertiary-10 active:bg-steel-tertiary-10"
              disabled={updating === true}
              key={lang.locale}
              onClick={() => {
                if (!updating) {
                  handleLanguageChange(lang.locale)
                }
              }}
            >
              <div className="flex min-w-max items-center space-x-4">
                <LanguageIcon locale={lang.locale} altText={lang.name} />
                <span>{lang.short}</span>
                <img aria-hidden alt="" src={separatorUri} className="h-4" />
              </div>
              <div className="flex items-center justify-between pl-4">
                {lang.name}
              </div>
            </button>
          ))}
        </div>
      )}
    </aside>
  )
}

function LanguageIcon({locale, altText}: {locale: Locale; altText: string}) {
  if (!locale) {
    throw new Error(`Unknown locale: ${locale}`)
  }
  let mainSrc = languageEnglishUri
  let srcSet = `${languageEnglishUri}, ${languageEnglishUri2X} 2x, ${languageEnglishUri3X} 3x`

  if (locale === LANGUAGE_TAG_ES_MX) {
    mainSrc = languageSpanishUri
    srcSet = `${languageSpanishUri}, ${languageSpanishUri2X} 2x, ${languageSpanishUri3X} 3x`
  }
  return (
    <img
      alt={altText}
      aria-hidden
      src={mainSrc}
      srcSet={srcSet}
      className="m-0.5"
    />
  )
}

export default LanguageSelector
