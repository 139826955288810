import uuid from 'uuid/v4'
import {isNonBlankString} from '../responseRefiners'

/**
 * A non blank string.
 */
type Id<Entity extends string> = string & {brand: `${Entity}Id`}

/**
 * Generate a new v4 UUID.
 */
function generate<Entity extends string>(): Id<Entity> {
  return uuid() as Id<Entity>
}

/**
 * Confirms is the candidate is a valid {@link Id}.
 *
 * @param candidate The candidate to test.
 */
function isValid(candidate: unknown): candidate is string {
  return isNonBlankString(candidate)
}

/**
 * Parses an {@link Id} from the candidate if valid or else throws.
 *
 * @param candidate The candidate to parse.
 */
function parse<Entity extends string>(candidate: unknown): Id<Entity> {
  if (!isValid(candidate)) {
    throw new Error('Id may not be blank, empty or null')
  }

  return candidate.trim() as Id<Entity>
}

const Id = {isValid, parse, generate}
export default Id
